import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BASE_URL } from '../../config/constant';

const GuestGuard = ({ children }) => {
    const account = useSelector((state) => state.account);
    const { isLoggedIn } = account;
    const location = useLocation();

    const lastLocation = localStorage.getItem('lastLocation') || '/';
    
    if (isLoggedIn) {
        return <Redirect to={lastLocation} />;
    }

    return <React.Fragment>{children}</React.Fragment>;
};

export default GuestGuard;
