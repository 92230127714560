import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Row, Col, Form, Button, Alert, Table } from 'react-bootstrap';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, PDFViewer, Font, Image } from '@react-pdf/renderer';
import config from './../../../config';
import axios from 'axios';
import Card from '../../../components/Card/MainCard';
import MontserratRegular from './../../../fonts/static/Montserrat-Regular.ttf';
import MontserratBold from './../../../fonts/static/Montserrat-Bold.ttf';

Font.register({
    family: 'Montserrat',
    fonts: [
        {
            src: MontserratRegular,
            fontWeight: 'normal'
        },
        {
            src: MontserratBold,
            fontWeight: 'bold'
        }
        // Register other font weights if needed
    ]
});

const UpdateUser = () => {
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [user, setUser] = useState({});
    const [loan, setLoan] = useState({});
    const [loanRepayments, setLoanRepayments] = useState([]);
    const { id, loanId } = useParams();
    const history = useHistory();
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [pdfBlob, setPdfBlob] = useState(null);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await axios.get(`${apiUrl}/general-expense/${id}`);
                setUser(response.data[0]);
            } catch (error) {
                console.log(error);
            }
        };
        fetchUser();
    }, [id]);

    const generatePdf = () => {
        const blob = new Blob([<PDFContent id={id} />], { type: 'application/pdf' });
        setPdfBlob(blob);
    };

    const getLabelColorClass = (status) => {
        switch (status) {
            case 'Fully Paid':
                return 'label-orange';
            case 'Partially Paid':
                return 'label-blue';
            case 'Ongoing':
                return 'label-till';
            case 'Expense':
                return 'label-brown';
            default:
                return '';
        }
    };

    return (
        <React.Fragment>
            <Row>
                <Col sm={12}>
                    <Link to="/reports/genaral-expenses">
                        <Button variant="primary" className="mb-3 mt-2">
                            <span className="feather icon-arrow-left"></span> Go Back
                        </Button>
                    </Link>
                    <Card title="View Loan Payment" isOption>
                        <Row>
                            <Col sm={12} md={12} className="border-bottom">
                                <div className="row d-flex align-items-center m-b-10">
                                    <div className="col-12">
                                        <div className="pdf-button-container">
                                            <Button onClick={generatePdf} className="no-pdf">
                                                <i className="feather icon-printer f-40 text-c-white" />
                                            </Button>
                                            {pdfBlob && (
                                                <PDFDownloadLink
                                                    document={<PDFContent id={id} user={user} />}
                                                    fileName={`general_expense_transaction_${id}.pdf`}
                                                >
                                                    {({ blob, url, loading, error }) => (loading ? 'Loading...' : 'Print PDF')}
                                                </PDFDownloadLink>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col sm={12} md={12} className="border-bottom">
                                <div className="row d-flex align-items-center m-b-10">
                                    <div className="col-4">
                                        <img
                                            src={avatarImage}
                                            alt="Comapny Logo"
                                            style={{ width: '100px', height: 'auto', align: 'center' }}
                                        />
                                    </div>
                                    <div className="col-4"></div>
                                    <div className="col-4">
                                        {/* <div>SFARMS.CO.KE</div> */}
                                        <div>P.O BOX 800-222</div>
                                        <div>Naivasha</div>
                                        <div>Phone : 254 729 588 892</div>
                                        <div>Email : info@sfarms.co.ke</div>
                                    </div>
                                </div>
                            </Col>

                            <Col sm={12} md={12} className="border-bottom">
                                <div className="row d-flex align-items-center m-t-20 m-b-20">
                                    <div className="col-4">
                                        <p className="m-t-10 m-b-10">Beneficiary: {user.person || '-'}</p>
                                        <p className="m-t-10 m-b-10">Category: {user.water_user || '-'}</p>
                                        <p className="m-t-10 m-b-10">Amount: KES {user?.cost?.toLocaleString('en-US', { minimumFractionDigits: 2 }) || '-'}</p>
                                        <p className="m-t-10 m-b-10">Farm: {user.farm || '-'}</p>
                                    </div>

                                    
                                    <div className="col-4">
                                        <p className="m-t-10 m-b-10">Account: {user.bankAccount || '-'}</p>
                                        <p className="m-t-10 m-b-10">Occurance Date : {new Date(user?.recorded).toLocaleDateString() || '-'}</p>
                                        <p className="m-t-10 m-b-10">Transaction Id : {user.transactionId || '-'}</p>
                                        <p className="m-t-10 m-b-10">Updated : {new Date(user?.createdAt).toLocaleString() || '-'}</p>
                                    </div>
                                    <div className="col-4">
                                        <p>Receipt</p>
                                        {user?.image ? (
                                            <div>
                                                <img src={`${apiUrl}/uploads/${user.image}`} className='receipt-image'/>
                                            </div>
                                        ): (<Button size="sm" variant="secondary">
                                        <span className="feather icon-eye"></span> No Reciept Image
                                    </Button>)}
                                    </div>
                                </div>
                            </Col>

                            <Col sm={12} md={12}>
                                <div className="row d-flex align-items-center m-t-20 m-b-20">
                                    <div className="col-12">
                                        <p className="text-uppercase m-1">Notes : {user.remarks}</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

const avatarImage = '/assets/farm-logo.jpg';

const PDFContent = ({ id, user }) => (
    <Document>
        <Page style={styles.page} size="A5" orientation="landscape">
            <View style={styles.section}>
                <View style={styles.row}>
                    <View style={styles.header} fixed>
                        <View style={styles.rows}>
                            <View style={styles.column}>
                                <Image source={avatarImage} style={styles.letterheadLogo} />
                            </View>

                            <View style={styles.column}></View>
                            <View style={styles.column}>
                                <Text>Address :</Text>
                                <Text>P.O BOX 800-222</Text>
                                <Text>Naivasha</Text>
                                <Text>Phone : 254-787-689-760</Text>
                                <Text>Email : info@sfarms.co.ke</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <Text style={styles.transactionId}>Expense Details ID : #{id}</Text>

                <View style={styles.row}>
                    <View style={styles.column}>
                        <Text>Date : {new Date(user.createdAt).toLocaleString()}</Text>
                    </View>
                    <View style={styles.column}>
                        <Text>Transaction ID : {user.transactionId}</Text>
                    </View>
                    <View style={styles.column}>
                        <Text>Category: {user.water_user || '-'}</Text>
                    </View>
                </View>

                <View style={styles.row}>
                    <View style={styles.column}>
                        <Text>Beneficiary: {user.person || '-'}</Text>
                        <Text>Category: {user.water_user || '-'}</Text>
                        <Text>Amount: KES {user?.cost?.toLocaleString('en-US', { minimumFractionDigits: 2 }) || '-'}</Text>
                    </View>
                    <View style={styles.column}>
                        <Text>Account: {user.bankAccount || '-'}</Text>
                        <Text>Occurance Date : {new Date(user?.recorded).toLocaleDateString() || '-'}</Text>
                        <Text>Transaction Id : {user.transactionId || '-'}</Text>
                    </View>
                    <View style={styles.column}>
                        <Text>Updated : {new Date(user?.createdAt).toLocaleString() || '-'}</Text>
                        <Text>Farm: {user.farm || '-'}</Text>
                        <Text>Beneficiary: {user.person || '-'}</Text>
                    </View>
                </View>

                <Text style={styles.notes}>Notes : {user.remarks}</Text>
            </View>
        </Page>
    </Document>
);

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Montserrat',
        fontSize: 8.5,
        flexDirection: 'row',
        backgroundColor: '#ffffff',
        width: '100%',
        marginTop: 40,
        height: '20px'
    },
    section: {
        // marginTop: 5,
        marginLeft: 50,
        marginRight: 50,
        padding: 10,
        flexGrow: 1
    },
    header: {
        width: '100%',
        alignContent: 'center'
        // alignItems: 'center'
    },
    letterheadLogo: {
        width: 100,
        height: 100
    },
    transactionId: {
        fontSize: 12,
        fontWeight: 'bold',
        marginBottom: 10,
        borderBottom: '0.5px solid grey'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    rows: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 10
    },
    column: {
        width: '50%'
    },
    notes: {
        marginTop: 20,
        fontSize: 10
    }
});

export default UpdateUser;
