import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import { Row, Col, Form, Button, Table, Alert, Pagination} from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../../components/Card/MainCard';
import config from '../../config';
import PaymentsForm from './PaymentsForm'; 
import AccountTransferForm from './AccountTransferForm';
import LoansForm from './LoansForm';

const TransactionHistory = ({resetTypeInput}) => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [transactionData, setTransactionData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(10);
  const history = useHistory();
  const [selectedType, setSelectedType] = useState([]);
  const [transactionDetails, setTransactionDetails] = useState({type: '',});
  const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
  const apiUrl = config[environment].apiUrl;

  useEffect(() => {
    fetchTrialBalance();
  }, [selectedType, apiUrl]);

  const fetchTrialBalance = async () => {
    try {
      const response = await axios.get(`${apiUrl}/transactions`);
      setTransactionData(response.data);
      
    } catch (error) {
      console.error(error);
    }
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currenttransactionData = transactionData.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePerPageChange = (selectedOption) => {
    setUsersPerPage(selectedOption.value);
    setCurrentPage(1); 
  };

 const options = [
   { value: 5, label: '5' },
   { value: 10, label: '10' },
   { value: 25, label: '25' },
   { value: 50, label: '50' },
   { value: 150, label: '150' },
   { value: 200, label: '200' },
   { value: 500, label: '500' },
 ];

 const totalPages = Math.ceil(transactionData.length / usersPerPage);
 
  const type = [
    { value: '', name: '' },
    { value: 'Payments', name: 'Payments' },
  ];

  const types = [
    { value: '', name: '' },
    { value: 'Loans', name: 'Loans' },
  ];

  const typess = [
    { value: '', name: '' },
    { value: 'Accounts Transfer', name: 'Accounts Transfer' },
  ];

  const handleSelectChange = (name, selectedOption,event) => {
    setSelectedType(selectedOption.value);
    setTransactionDetails({ ...transactionDetails, [name]: selectedOption.value });
  };

  const getLabelColorClass = (type) => {
  switch (type) {
    case 'Goats Sales':
      return 'label-orange';
    case 'Income':
      return 'label-blue';
    case '':
      return 'label-till';
    case 'Expense':
      return 'label-brown';
    default:
      return '';
  }
};

const handleView = (transactionId) => {
  const transactionToUpdate = transactionData.find((transaction) => transaction.id === transactionId);
  history.push(`view-transaction/${transactionId}`, { transaction: transactionToUpdate });
};

const handleDelete = async (id, transactionId) => {
  try {
    const confirmed = window.confirm(`Are you sure you want to delete this transaction ${transactionId} ?`);
    if (confirmed) {
      await axios.delete(`${apiUrl}/transaction/${id}`);
      alert('Transaction deleted successfully!');
      window.location.reload();
    }
  } catch (error) {
    alert(error.message);
  }
};

const resetSelectedType = () => {
  setSelectedType(null);
};

  return (
    <React.Fragment>
      <Row>
          <Col sm={12}>
          {success && <Alert variant="success" onClose={() => setSuccess(null)} dismissible >{success}</Alert>}
          {error && <Alert variant="danger" onClose={() => setError(null)} dismissible >{error}</Alert>}
          <ToastContainer />
          </Col>
      </Row>
      <Row>
        <Col>
          <Card title="New Transaction" isOption>
            <Row>
          <Col md={4} sm={12} lg={4}>
                <Form.Group>
                  <Form.Label>Payments:</Form.Label>
                  <Select
                    onChange={(selectedOption) =>
                      handleSelectChange('type', selectedOption)
                    }
                    options={type.map((option) => ({
                      value: option.name,
                      label: option.name,
                    }))}
                    required
                  />
                </Form.Group>
              </Col>

          <Col md={4} sm={12} lg={4}>
                <Form.Group>
                  <Form.Label>Loans:</Form.Label>
                  <Select
                    onChange={(selectedOption) =>
                      handleSelectChange('type', selectedOption)
                    }
                    options={types.map((option) => ({
                      value: option.name,
                      label: option.name,
                    }))}
                    required
                  />
                </Form.Group>
              </Col>

          <Col md={4} sm={12} lg={4}>
                <Form.Group>
                  <Form.Label>Account Transfer:</Form.Label>
                  <Select
                    onChange={(selectedOption) =>
                      handleSelectChange('type', selectedOption)
                    }
                    options={typess.map((option) => ({
                      value: option.name,
                      label: option.name,
                    }))}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                {selectedType === 'Payments' && <PaymentsForm />}
                {/* {selectedType === 'Purchases' && <PurchasesForm />} */}
                {selectedType === 'Accounts Transfer' && <AccountTransferForm 
                    resetSelectedType={resetSelectedType}/>}
                {selectedType === 'Loans' && <LoansForm />}
              </Col>          
              </Row>  
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default TransactionHistory;
