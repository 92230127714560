import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Alert, Button, Modal } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import Card from '../../../components/Card/MainCard';
import axios from 'axios';
import config from '../../../config';
import ConfirmationModal from './ConfirmationModal';
import { usePermissions } from '../../../permissions';

const FormsElements = ({ onCancel }) => {
    const account = useSelector((state) => state.account);
    const [data, setData] = useState({});
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [selectedTagM, setSelectedTagM] = useState(null);
    const [datesSelected, setSelectedDates] = useState(null);
    const [tagMOptions, setTagMOptions] = useState([]);
    const [weanersOptions, setWeanersOptions] = useState([]);
    const [selectedTagNumber, setSelectedTagNumber] = useState([]);
    const formRef = useRef(null);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const { createPermission, deletePermission, editPermission } = usePermissions();
    const [tagNumber, setTagNumber] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [tagNumbers, setTagNumbers] = useState([]);
    const [siloNumber, setSiloNumber] = useState(0);
    const [age, setAge] = useState(null);
    const [selectedPig, setSelectedPig] = useState(null);

    const memoizedApiUrl1 = useMemo(() => {
        return `${apiUrl}/farm`;
    }, [apiUrl]);

    const memoizedApiUrl2 = useMemo(() => {
        return `${apiUrl}/weaners_group_list`;
    }, [apiUrl]);

    const generateTransactionId = () => {
        const timestamp = new Date().getTime();
        const random = Math.floor(Math.random() * 1000);
        return `SF-${random}`;
    };

    useEffect(() => {
        const fetchTagNumbers = async () => {
            try {
                const response = await axios.get(`${apiUrl}/all-pigs`);
                setTagNumbers(response.data.map((item) => item.tagNumber));
            } catch (error) {
                console.error('Error fetching tag numbers:', error);
            }
        };

        fetchTagNumbers();
    }, [tagNumbers]);

    useEffect(() => {
        setTagNumber(generateTagNumber());
    }, [tagNumbers]);

    const generateTagNumber = () => {
        if (!tagNumbers || tagNumbers.length === 0) {
            return 'PG-01';
        }

        const numbers = tagNumbers.map((tag) => parseInt(tag.slice(3)));

        const maxNumber = Math.max(...numbers);

        return `PG-${(maxNumber + 1).toString().padStart(2, '0')}`;
    };

    useEffect(() => {
        const fetchMaxSiloNumber = async () => {
            try {
                const response = await axios.get(`${apiUrl}/pig/max-silo-number`);
                const maxSiloNumber = response.data.maxSiloNumber || 0;
                console.log(maxSiloNumber);
                setSiloNumber(maxSiloNumber + 1);
            } catch (error) {
                console.log(error);
            }
        };
        fetchMaxSiloNumber();
    }, [siloNumber, apiUrl]);

    const handleInput = (fieldName, value) => {
        const updatedData = {
            ...data,
            [fieldName]: value,
            userName: account.user.firstname,
            userId: account.user.id,
            tagNumber: tagNumber,
            siloNumber: siloNumber,
            transactionId: generateTransactionId(),
            cost: 0,
            health: null,
            bankAccount: null,
            farm: selectedTagNumber?.farm,
            sup_name: selectedTagNumber?.farm,
            motherTag: selectedTagNumber?.tags,
            houseNumber: selectedTagNumber?.houseNumber,
            age: age,
            weanersGroup: selectedTagM?.value,
            birth: datesSelected?.value
        };
        setData(updatedData);
        console.log('Updated data:', updatedData);
        if (fieldName === 'weanersGroup') {
            setSelectedTagM(value);
        } else if (fieldName === 'birth') {
            setSelectedDates(value);
            calculateAge(value); // Calculate age when DOB is selected
        }
    };

    const calculateAge = (dob) => {
        const birthDate = new Date(dob.value);
        const today = new Date();

        let years = today.getFullYear() - birthDate.getFullYear();
        let months = today.getMonth() - birthDate.getMonth();
        let days = today.getDate() - birthDate.getDate();

        if (days < 0) {
            months--;
            days += new Date(today.getFullYear(), today.getMonth(), 0).getDate();
        }

        if (months < 0) {
            years--;
            months += 12;
        }

        const formattedAge = `${years} years ${months} months ${days} days`;
        // handleInput('age', formattedAge);
        setAge(formattedAge);
        console.log('Calculated age:', formattedAge); // Debugging log
    };

    const handlePiglets = async (e) => {
        e.preventDefault();
        setShowConfirmationModal(true);
    };

    const handleConfirmation = async (e) => {
        if (!createPermission) {
            return;
        }

        axios
            .post(`${apiUrl}/weaners-to-adults`, data)
            .then((response) => {
                if (response.data) {
                    setSuccess(response.data.message);
                    setTimeout(() => {
                        setSuccess(null);
                    }, 10000);
                    toast.success(response.data.message);
                    onCancel();
                    setData({});
                    formRef.current.reset();
                    setSelectedTagM(null);
                    setShowConfirmationModal(false);
                }
                console.log(response);
            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    setError(`${error.response.data.message}`);
                    toast.error(`${error.response.data.message}`);
                    setShowConfirmationModal(false);
                    onCancel();
                } else {
                    setError('An error occurred. Please try again later.');
                    setShowConfirmationModal(false);
                    onCancel();
                }
                console.log(error);
                setShowConfirmationModal(false);
                onCancel();
            });
    };

    useEffect(() => {
        const fetchTags = async () => {
            try {
                const response = await axios.get(`${apiUrl}/weaners`);
                const data = response.data;
                const filteredData = data.filter((tag) => tag.capacity > 0 && tag.archive !== 1);
                const options = filteredData.map((tag) => ({
                    // const options = data.map((tag) => ({
                    value: tag.weanersGroup,
                    label: tag.weanersGroup
                }));
                setTagMOptions(options);
            } catch (error) {
                console.error('Error fetching Tags:', error);
            }
        };

        fetchTags();
    }, [apiUrl, tagMOptions]);

    useEffect(() => {
        if (selectedTagM) {
            const fetchWeaners = async () => {
                try {
                    const response = await axios.get(memoizedApiUrl2);
                    const data = response.data;
                    const filteredData = data.filter((tag) => tag.weanersGroup === selectedTagM.value);
                    console.log('Filtered weaners data:', filteredData); // Debugging log
                    const options = filteredData.map((weaner) => ({
                        value: new Date(weaner.dob)?.toLocaleDateString(),
                        label: new Date(weaner.dob)?.toLocaleDateString()
                    }));
                    setWeanersOptions(options);
                    console.log('DOB options:', options); // Debugging log
                } catch (error) {
                    console.error('Error fetching Weaners data:', error);
                }
            };

            fetchWeaners();
        }
    }, [selectedTagM, memoizedApiUrl2]);

    useEffect(() => {
        if (datesSelected && selectedTagM) {
            const fetchAdditionalData = async () => {
                try {
                    const response = await axios.get(memoizedApiUrl2);
                    const data = response.data;
                    const selectedWeaner = data.find(
                        (weaner) =>
                            new Date(weaner.dob)?.toLocaleDateString() === datesSelected.value && weaner.weanersGroup === selectedTagM.value
                    );
                    console.log('Selected weaner:', selectedWeaner); // Debugging log
                    setSelectedTagNumber(selectedWeaner);
                } catch (error) {
                    console.error('Error fetching additional data:', error);
                }
            };

            fetchAdditionalData();
        }
    }, [datesSelected, memoizedApiUrl2]);

    const handleBirthDateChange = (e) => {
        const selectedDate = new Date(setSelectedDates);
        handleInput('birth', setSelectedDates);
        const currentDate = new Date();
        const ageInMilliseconds = currentDate - selectedDate;
        const ageDate = new Date(ageInMilliseconds);
        const years = Math.floor(ageInMilliseconds / (365.25 * 24 * 60 * 60 * 1000));
        const months = ageDate.getUTCMonth();
        const days = ageDate.getUTCDate() - 1;

        const formattedAge = `${years} years ${months} months ${days} days`;
        handleInput('age', formattedAge);
    };

    useEffect(() => {
        if (birthDate) {
            handleBirthDateChange({ target: { value: birthDate } });
        }
    }, [birthDate]);

    const pigOptions = [
        { value: 'Wessex Saddleback', label: 'Wessex Saddleback' },
        { value: 'Tamworth', label: 'Tamworth' },
        { value: 'Swedish Landrace', label: 'Swedish Landrace' },
        { value: 'Siberian Black Pied', label: 'Siberian Black Pied' },
        { value: 'Norwegian Landrace', label: 'Norwegian Landrace' },
        { value: 'Guinea hog', label: 'Guinea hog' },
        { value: 'British Lop', label: 'British Lop' }
    ];

    const handleCancelConfirmation = () => {
        setShowConfirmationModal(false);
    };

    return (
        <React.Fragment>
            <Modal show={true} onHide={onCancel} size="xl" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Mature a Weaner</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Row>
                        <Col sm={12}>
                            {success && (
                                <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                                    {success}
                                </Alert>
                            )}
                            {error && (
                                <Alert variant="danger" onClose={() => setError(null)} dismissible>
                                    {error}
                                </Alert>
                            )}
                            <ToastContainer />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form onSubmit={handlePiglets} ref={formRef}>
                                <Row>
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Weaners Group</Form.Label>
                                        <Select
                                            value={selectedTagM}
                                            onChange={(selectedOption) => {
                                                console.log('Selected Weaners Group:', selectedOption); // Debugging log
                                                handleInput('weanersGroup', selectedOption);
                                            }}
                                            options={tagMOptions}
                                            isSearchable
                                            name="weanersGroup"
                                            placeholder="-- Select Weaners Group --"
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>D.O.B</Form.Label>
                                        <Select
                                            value={datesSelected}
                                            onChange={(selectedOption) => {
                                                console.log('Selected DOB:', selectedOption); // Debugging log
                                                handleInput('birth', selectedOption);
                                            }}
                                            options={weanersOptions}
                                            isSearchable
                                            name="birth"
                                            placeholder="-- Select Weaners D.O.B --"
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Mother Tag</Form.Label>
                                        <Form.Control
                                            value={selectedTagNumber?.tags}
                                            size="sm"
                                            required
                                            type="text"
                                            onChange={(e) => handleInput('motherTag', e.target.value)}
                                            name="motherTag"
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Farm</Form.Label>
                                        <Form.Control
                                            value={selectedTagNumber?.farm}
                                            size="sm"
                                            required
                                            onChange={(e) => handleInput('farm', e.target.value)}
                                            type="text"
                                            name="farm"
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>House</Form.Label>
                                        <Form.Control
                                            value={selectedTagNumber?.houseNumber}
                                            size="sm"
                                            required
                                            onChange={(e) => handleInput('houseNumber', e.target.value)}
                                            type="text"
                                            name="houseNumber"
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Age</Form.Label>
                                        <Form.Control
                                            size="sm"
                                            required
                                            value={age}
                                            onChange={(e) => handleInput('age', e.target.value)}
                                            type="text"
                                            name="age"
                                            disabled
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Silo Number</Form.Label>
                                        <Form.Control
                                            size="sm"
                                            required
                                            value={siloNumber}
                                            onChange={(e) => handleInput('siloNumber', e.target.value)}
                                            type="number"
                                            name="siloNumber"
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Tag Number</Form.Label>
                                        <Form.Control
                                            size="sm"
                                            required
                                            onChange={(e) => handleInput('tagNumber', e.target.value)}
                                            type="text"
                                            name="tagNumber"
                                            value={tagNumber}
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Supplier</Form.Label>
                                        <Form.Control
                                            size="sm"
                                            value={selectedTagNumber?.farm}
                                            required
                                            onChange={(e) => handleInput('sup_name', e.target.value)}
                                            type="text"
                                            name="sup_name"
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Weight</Form.Label>
                                        <Form.Control
                                            size="sm"
                                            step="0.01"
                                            required
                                            onChange={(e) => handleInput('weight', e.target.value)}
                                            type="number"
                                            name="weight"
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Pig Type</Form.Label>
                                        <Select
                                            size="sm"
                                            required
                                            value={selectedPig}
                                            options={pigOptions}
                                            isSearchable
                                            onChange={(selectedOption) => setSelectedPig(handleInput('type', selectedOption.value))}
                                            type="text"
                                            name="type"
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Date</Form.Label>
                                        <Form.Control
                                            size="sm"
                                            required
                                            onChange={(e) => handleInput('recorded', e.target.value)}
                                            type="date"
                                            name="recorded"
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md={12} sm={12} lg={12} controlId="formBasic">
                                        <Form.Label>Remarks/Notes</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            required
                                            onChange={(e) => handleInput('remarks', e.target.value)}
                                            type="text"
                                            name="remarks"
                                        />
                                    </Form.Group>
                                </Row>
                                <Modal.Footer>
                                    {createPermission && <Button type="submit">Submit</Button>}
                                    <Button variant="secondary" onClick={onCancel}>
                                        Cancel
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <ConfirmationModal show={showConfirmationModal} onHide={handleCancelConfirmation} onConfirm={handleConfirmation} />
        </React.Fragment>
    );
};

export default FormsElements;
