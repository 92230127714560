import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Form, Button, Table } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import config from '../../config';
import Card from '../../components/Card/MainCard';
import axios from 'axios';
import TableComponent from './exportTable';
import { CSVLink } from 'react-csv';
import { exportComponentAsPDF, exportComponentAsPNG, exportComponentAsJPEG } from 'react-component-export-image';

const SamplePage = () => {
    const [reportData, setReportData] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState('');
    const [year, setYear] = useState('');
    const [selectedAnimal, setSelectedAnimal] = useState(null);
    const [tableColumns, setTableColumns] = useState([]);
    const [monthss, setMonth] = useState('');
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const tableRef = useRef();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (selectedAnimal) {
            fetchTableColumns();
        }
    }, [selectedAnimal]);

    const fetchTableColumns = () => {
        setIsLoading(true);

        axios
            .get(`${apiUrl}/api/tables?animal=${selectedAnimal?.value}`)
            .then((response) => {
                const tables = response.data;
                setTableColumns(tables);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const generateYearlyWaterRefillReport = () => {
        if (!selectedAnimal || !year) {
            return; // or handle the missing animal or year case
        }
        axios
            .get(`${apiUrl}/api/reports/yearly-water-refills?animal=${selectedAnimal.value}&year=${year}&month=${monthss || ''}`)
            .then((response) => {
                setReportData(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const generateWeeklyWaterRefillReport = () => {
        if (!selectedAnimal) {
            return; // or handle the missing animal case
        }

        axios
            .get(`${apiUrl}/api/reports/weekly-water-refills?animal=${selectedAnimal.value}`)
            .then((response) => {
                setReportData(response.data);
                console.log(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const generateDailyWaterRefillReport = () => {
        if (!selectedAnimal) {
            return; // or handle the missing animal case
        }

        axios
            .get(`${apiUrl}/api/reports/daily-water-refills?animal=${selectedAnimal.value}`)
            .then((response) => {
                setReportData(response.data);
                console.log(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    };

    const generateMonthlyWaterRefillReport = () => {
        if (!selectedAnimal || !selectedMonth) {
            return; // or handle the missing animal or month case
        }

        axios
            .get(`${apiUrl}/api/reports/monthly-water-refills?animal=${selectedAnimal.value}&month=${selectedMonth}`)
            .then((response) => {
                setReportData(response.data);
                console.log(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];

    const handleYearChange = (event) => {
        setYear(event.target.value);
    };

    const handleMonthsChange = (event) => {
        setMonth(event.target.value);
    };

    const handleAnimalChange = (selectedOption) => {
        setSelectedAnimal(selectedOption);
    };

    const month = [
        { value: '', label: '-- Select a Month --' },
        { value: '1', label: 'January' },
        { value: '2', label: 'February' },
        { value: '3', label: 'March' },
        { value: '4', label: 'April' },
        { value: '5', label: 'May' },
        { value: '6', label: 'June' },
        { value: '7', label: 'July' },
        { value: '8', label: 'August' },
        { value: '9', label: 'September' },
        { value: '10', label: 'October' },
        { value: '11', label: 'November' },
        { value: '12', label: 'December' }
    ];

    const animalOptions = [
        { value: 'poultry', label: 'Poultry' },
        { value: 'goats', label: 'Goat' },
        { value: 'eggs_sell', label: 'Egg' },
        { value: 'pigs', label: 'Pig' },
        { value: 'water', label: 'Water' },
        { value: 'feed', label: 'Feed' },
        { value: 'vaccine', label: 'Vaccine' },
        { value: 'users', label: 'Employee' },
        { value: 'suppliers', label: 'Supplier' },
        { value: 'customers', label: 'Customer' },
        { value: 'vetenary', label: 'Vetenary' }
    ];

    const handleExportPDF = () => {
        exportComponentAsPDF(tableRef);
    };

    const handleExportPNG = () => {
        exportComponentAsPNG(tableRef);
    };

    const handleExportJPEG = () => {
        exportComponentAsJPEG(tableRef);
    };

    const exportData = () => {
        const csvData = reportData.map((item) => {
            return tableColumns.reduce((data, column) => {
                data[column.value] = item[column.value];
                return data;
            }, {});
        });

        return csvData;
    };

    return (
        <React.Fragment>
            <ToastContainer />

            <Row>
                <Col>
                    <Card title="Filter for desired report" isOption>
                        <Row>
                            <Col md={6} sm={12} lg={4}>
                                <Form.Group>
                                    <Select
                                        options={animalOptions}
                                        value={selectedAnimal}
                                        onChange={handleAnimalChange}
                                        isClearable
                                        required
                                        isSearchable
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Select Month:</Form.Label>
                                    <Form.Control as="select" value={selectedMonth} onChange={handleMonthChange} disabled={!selectedAnimal}>
                                        <option value="">-- Select a Month --</option>
                                        {months.map((month, index) => (
                                            <option key={index} value={index + 1}>
                                                {month}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group>
                                    <Button onClick={generateMonthlyWaterRefillReport} disabled={!selectedAnimal || !selectedMonth}>
                                        Monthly
                                    </Button>
                                </Form.Group>
                            </Col>
                            <Col md={6} sm={12} lg={4}>
                                <Form.Group>
                                    <Button onClick={generateDailyWaterRefillReport} disabled={!selectedAnimal}>
                                        Daily
                                    </Button>

                                    <Button onClick={generateWeeklyWaterRefillReport} disabled={!selectedAnimal}>
                                        Weekly
                                    </Button>
                                </Form.Group>
                            </Col>
                            <Col md={6} sm={12} lg={4} className="border">
                                <Form.Group>
                                    <Form.Label>Year:</Form.Label>
                                    <Form.Control type="number" value={year} onChange={handleYearChange} disabled={!selectedAnimal} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Month:</Form.Label>
                                    <Form.Control as="select" value={monthss} onChange={handleMonthsChange} disabled={!selectedAnimal}>
                                        {month.map((m) => (
                                            <option key={m.value} value={m.value}>
                                                {m.label}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group>
                                    <Button onClick={generateYearlyWaterRefillReport} disabled={!selectedAnimal || !year}>
                                        Yearly
                                    </Button>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Card title="Report Results" isOption>
                        {reportData.length > 0 ? (
                            <>
                                {/* <Button variant="primary" onClick={handleExportPDF}>
                Export as PDF
              </Button> */}
                                <Button variant="primary" onClick={handleExportPNG}>
                                    Export as PNG
                                </Button>
                                <Button variant="primary" onClick={handleExportJPEG}>
                                    Export as JPEG
                                </Button>
                                <Button>
                                    <CSVLink data={exportData()} filename="report.csv">
                                        Export as CSV
                                    </CSVLink>
                                </Button>
                            </>
                        ) : null}
                        <div className="table-container" ref={tableRef}>
                            {isLoading ? (
                                <p>Loading...</p>
                            ) : (
                                <>
                                    {reportData.length > 0 ? (
                                        <>
                                            <div ref={tableRef}>
                                                <Table striped bordered hover responsive>
                                                    <thead>
                                                        <tr>
                                                            {tableColumns.map((column) => (
                                                                <th key={column.value}>{column.label}</th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {reportData.map((item, index) => (
                                                            <tr key={index}>
                                                                {tableColumns.map((column) => (
                                                                    <td key={column.value}>{item[column.value]}</td>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </>
                                    ) : (
                                        <p>No data available</p>
                                    )}
                                </>
                            )}
                        </div>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default SamplePage;
