import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Button, Alert, Modal } from 'react-bootstrap';
import { useHistory, useParams, Link } from 'react-router-dom';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../../../components/Card/MainCard';
import axios from 'axios';
import ConfirmationModal from '../../ui-elements/basic/ConfirmationModal';
import { usePermissions } from '../../../permissions';
import config from '../../../config';

const SamplePage = ({ onCancel }) => {
    const account = useSelector((state) => state.account);

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const formRef = useRef(null);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [supplierOptions, setSupplierOptions] = useState([]);
    const [housesOptions, setHousesOptions] = useState([]);
    const [groupsOptions, setGroupsOptions] = useState([]);
    const [selectedSup, setSelectedSup] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState([]);
    const [selectedHouse, setSelectedHouse] = useState([]);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const { createPermission, deletePermission, editPermission } = usePermissions();
    const [filteredPigletsSired, setFilteredPigletsSired] = useState([]);
    // const [nextWeanersGroup, setNextWeanersGroup] = useState('');
    // const [nextHouseNumber, setNextHouseNumber] = useState('');
    const [validationError, setValidationError] = useState(null);
    // const filteredPigletsSired = [{ pigletsSired: 20 }];
    const [data, setData] = useState({
        userName: account.user.firstname,
        userId: account.user.id,
        tagNumber: '',
        pigletsSired: '',
        weanersGroup: '',
        houseNumber: '',
        dob: '',
        farm: '',
        recorded: ''
    });
    const [loadCard, setloadCard] = useState(false);

    const memoizedApiUrl4 = useMemo(() => {
        return `${apiUrl}/piglets_sired`;
    }, [apiUrl]);

    const memoizedApiUrl5 = useMemo(() => {
        return `${apiUrl}/weaners_group_list`;
    }, [apiUrl]);

    const memoizedApiUrl6 = useMemo(() => {
        return `${apiUrl}/weaners`;
    }, [apiUrl]);

    const memoizedApiUrl7 = useMemo(() => {
        return `${apiUrl}/houses`;
    }, [apiUrl]);

    // const fetchLastWeanersGroup = async () => {
    //     try {
    //         // Fetch the last weaners group from the database
    //         const response = await axios.get(memoizedApiUrl5);
    //         const weaners = response.data;

    //         if (Array.isArray(weaners) && weaners.length > 0) {
    //             const lastWeanersGroup = weaners[weaners.length - 1].weanersGroup;

    //             // Check if lastWeanersGroup is defined and not empty
    //             if (lastWeanersGroup) {
    //                 // Extract the last group number and increment it to generate the next group ID
    //                 const lastGroupNumber = parseInt(lastWeanersGroup.split('-')[1]);
    //                 const nextGroupNumber = lastGroupNumber + 1;
    //                 const nextGroupID = `WG-${nextGroupNumber.toString().padStart(2, '0')}`;
    //                 setNextWeanersGroup(nextGroupID);
    //             } else {
    //                 // If no weaners group exists (unexpected scenario), set the default group ID to 'WG-01'
    //                 setNextWeanersGroup('WG-01');
    //             }
    //         } else {
    //             // If no weaners group data found (initial scenario), set the default group ID to 'WG-01'
    //             setNextWeanersGroup('WG-01');
    //         }
    //     } catch (error) {
    //         console.error('Error fetching last weaners group:', error);
    //     }
    // };

    // const fetchLastHouseNumber = async () => {
    //     try {
    //         // Fetch the last house number from the database
    //         const response = await axios.get(memoizedApiUrl5);
    //         const houseNumberData = response.data;

    //         if (Array.isArray(houseNumberData) && houseNumberData.length > 0) {
    //             const lastHouseNumber = houseNumberData[houseNumberData.length - 1].houseNumber;

    //             if (lastHouseNumber) {
    //                 const maxHouseNumber = 50;
    //                 const last = parseInt(lastHouseNumber.split('-')[1]);
    //                 const nextHouseNum = (last % maxHouseNumber) + 1;
    //                 const nextHouseID = `House-${nextHouseNum.toString().padStart(2, '0')}`;
    //                 setNextHouseNumber(nextHouseID);
    //             } else {
    //                 setNextHouseNumber('House-01');
    //             }
    //         } else {
    //             setNextHouseNumber('House-01');
    //         }
    //     } catch (error) {
    //         console.error('Error fetching last house number:', error);
    //     }
    // };

    // useEffect(() => {
    //     fetchLastWeanersGroup();
    //     fetchLastHouseNumber();
    // }, [apiUrl]);

    const handleInput = (fieldName, value) => {
        if (fieldName === 'pigletsSired') {
            const maxPigletsSired = filteredPigletsSired[0]?.pigletsSired || 0;
            if (parseInt(value) > maxPigletsSired) {
                setValidationError(`Value cannot exceed ${maxPigletsSired}`);
            } else {
                setValidationError(null);
            }
        }
        setData((prevData) => ({
            ...prevData,
            [fieldName]: value,
            tagNumber: selectedSup,
            dob: filteredPigletsSired[0]?.dateSired,
            // pigletsSired: filteredPigletsSired[0]?.pigletsSired,
            farm: filteredPigletsSired[0]?.farm,
            weanersGroup: selectedGroup,
            houseNumber: selectedHouse
        }));
    };

    // console.log(data);

    // const cardReloadHandlerLocal = () => {
    //     setloadCard(true);
    //     setInterval(() => {
    //         setloadCard(false);
    //     }, 3000);
    // };

    const handleFeed = async (e) => {
        e.preventDefault();
        if (validationError) {
            return toast.error(validationError);
        }

        setShowConfirmationModal(true);
    };

    const handleConfirmation = async (e) => {
        if (!createPermission) {
            return;
        }
        axios
            .post(memoizedApiUrl5, data)
            .then((response) => {
                if (response.data) {
                    setSuccess(response.data.message);
                    setTimeout(() => {
                        setSuccess(null);
                    }, 10000);
                    toast.success(response.data.message);
                    onCancel();
                    setShowConfirmationModal(false);
                    formRef.current.reset();
                    setFilteredPigletsSired('');
                    // window.location.reload();
                    setSelectedSup('');
                    setSelectedHouse('');
                    setSelectedGroup('');
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    setError(`${error.response.data.message}`);
                    toast.error(`${error.response.data.message}`);
                    setShowConfirmationModal(false);
                    onCancel();
                } else {
                    setError('An error occurred. Please try again later.');
                    setShowConfirmationModal(false);
                    onCancel();
                }
            });
    };

    useEffect(() => {
        const fetchSuppliers = async () => {
            try {
                const response = await axios.get(memoizedApiUrl4);
                const data = response.data;
                const filteredData = data.filter((tag) => tag.pigletsSired > 0 );
                // const filteredData = data.filter((tag) => tag.moved === 0 && tag.pigletsSired > 0);
                const options = filteredData.map((supplier) => ({
                    value: supplier?.id,
                    label: `${supplier?.tagNumber} - ${new Date(supplier?.dateSired).toLocaleDateString()}`
                }));
                setSupplierOptions(options);
                console.log(options);
            } catch (error) {
                console.error('Error fetching suppliers:', error);
            }
        };
        const fetchHouses = async () => {
            try {
                const response = await axios.get(memoizedApiUrl7);
                const data = response.data;
                // const filteredData = data.filter((tag) => tag.moved === 0);
                const options = data.map((supplier) => ({
                    value: supplier.houseNumber,
                    label: supplier.houseNumber
                }));
                setHousesOptions(options);
                console.log(options);
            } catch (error) {
                console.error('Error fetching houses:', error);
            }
        };
        const fetchGroups = async () => {
            try {
                const response = await axios.get(memoizedApiUrl6);
                const data = response.data;
                const filteredData = data.filter((tag) => tag.archive === 0);
                const options = filteredData.map((supplier) => ({
                    value: supplier.weanersGroup,
                    label: supplier.weanersGroup
                }));
                setGroupsOptions(options);
                console.log(options);
            } catch (error) {
                console.error('Error fetching weaners:', error);
            }
        };

        fetchSuppliers();
        fetchGroups();
        fetchHouses();
    }, [memoizedApiUrl4, memoizedApiUrl6, memoizedApiUrl7]);

    const handleCancelConfirmation = () => {
        // Handle cancel action if needed
        setShowConfirmationModal(false);
    };

    useEffect(() => {
        const fetchPigletsSired = async () => {
            if (selectedSup) {
                try {
                    const response = await axios.get(memoizedApiUrl4);
                    const pigletsSiredData = response.data;
                    const filteredData = response.data.filter((item) => !item.archive);
                    const filteredPiglets = filteredData?.filter((piglet) => piglet?.id === selectedSup);
                    setFilteredPigletsSired(filteredPiglets);
                    console.log('Filtered Piglets Sired:', filteredPiglets);
                } catch (error) {
                    console.error('Error fetching piglets sired:', error);
                }
            }
        };

        fetchPigletsSired();
    }, [selectedSup, memoizedApiUrl4]);

    return (
        <React.Fragment>
            <Modal show={true} onHide={onCancel} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Move Piglets</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <Row>
                        <Col sm={12}>
                            {success && (
                                <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                                    {success}
                                </Alert>
                            )}
                            {error && (
                                <Alert variant="danger" onClose={() => setError(null)} dismissible>
                                    {error}
                                </Alert>
                            )}
                            <ToastContainer />
                        </Col>
                    </Row> */}
                    <Form onSubmit={handleFeed} ref={formRef}>
                        <Row>
                            <Col>
                                <Row>
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Mother Tags</Form.Label>
                                        <Select
                                            onChange={(selectedOption) => setSelectedSup(selectedOption.value)}
                                            options={supplierOptions}
                                            isSearchable
                                            type="text"
                                            name="tagNumber"
                                            value={selectedSup.label}
                                            placeholder="-- Select Tag --"
                                            required
                                        />
                                    </Form.Group>

                                    {/* <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                    <Form.Label>Capacity (No. of Sired Piglets)</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        required
                                        value={filteredPigletsSired[0]?.pigletsSired}
                                        type="number"
                                        name="pigletsSired"
                                        disabled
                                    />
                                </Form.Group> */}

                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Capacity (No. of Piglets to move)</Form.Label>
                                        <Form.Control
                                            size="sm"
                                            required
                                            type="number"
                                            name="pigletsSired"
                                            // value={data.pigletsSired}
                                            onChange={(e) => handleInput('pigletsSired', e.target.value)}
                                        />
                                        {validationError ? (
                                            <Alert variant="danger">{validationError}</Alert>
                                        ) : (
                                            <Form.Text>(No. of Sired Piglets) - {filteredPigletsSired[0]?.pigletsSired}</Form.Text>
                                        )}
                                    </Form.Group>

                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasicWeanersGroup">
                                        <Form.Label>Weaners Group</Form.Label>
                                        <Select
                                            onChange={(selectedOption) => setSelectedGroup(selectedOption.value)}
                                            options={groupsOptions}
                                            value={selectedGroup?.value}
                                            isSearchable
                                            size="sm"
                                            required
                                            type="text"
                                            name="weanersGroup"
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasicHouse">
                                        <Form.Label>House</Form.Label>
                                        <Select
                                            onChange={(selectedOption) => setSelectedHouse(selectedOption.value)}
                                            options={housesOptions}
                                            value={selectedHouse?.value}
                                            isSearchable
                                            size="sm"
                                            required
                                            type="text"
                                            name="houseNumber"
                                        />
                                    </Form.Group>
                                    {/* <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasicWeanersGroup">
                                    <Form.Label>Weaners Group</Form.Label>
                                    <Form.Control size="sm" required value={nextWeanersGroup} type="text" name="weanersGroup" disabled />
                                </Form.Group>

                                <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasicHouse">
                                    <Form.Label>House</Form.Label>
                                    <Form.Control size="sm" required value={nextHouseNumber} type="text" name="houseNumber" disabled />
                                </Form.Group> */}
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>D.O.B</Form.Label>
                                        <Form.Control
                                            size="sm"
                                            required
                                            type="text"
                                            value={new Date(filteredPigletsSired[0]?.dateSired).toLocaleDateString()}
                                            name="dob"
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Farm</Form.Label>
                                        <Form.Control
                                            size="sm"
                                            required
                                            type="text"
                                            value={filteredPigletsSired[0]?.farm}
                                            name="farm"
                                            disabled
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Date</Form.Label>
                                        <Form.Control
                                            size="sm"
                                            required
                                            onChange={(e) => handleInput('recorded', e.target.value)}
                                            type="date"
                                            name="recorded"
                                        />
                                    </Form.Group>
                                </Row>
                                <Modal.Footer>
                                    {createPermission && <Button type="submit">Submit</Button>}
                                    <Button variant="secondary" onClick={onCancel}>
                                        Cancel
                                    </Button>
                                </Modal.Footer>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
            <ConfirmationModal show={showConfirmationModal} onHide={handleCancelConfirmation} onConfirm={handleConfirmation} />
        </React.Fragment>
    );
};

export default SamplePage;
