import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Tab, Tabs, Alert, Button, Modal } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.css';
import config from '../../config';
import Card from '../../components/Card/MainCard';
import axios from 'axios';
import ConfirmationModal from '../ui-elements/basic/ConfirmationModal';
import { usePermissions } from '../../permissions';

const SamplePage = ({ onCancel }) => {
    const account = useSelector((state) => state.account);
    const [data, setData] = useState({});
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const formRef = useRef(null);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [farmOptions, setFarmOptions] = useState([]);
    const [farm, setSelectedFarm] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const { createPermission, deletePermission, editPermission } = usePermissions();
    const [selectedType, setSelectedType] = useState([]);


    const memoizedApiUrl1 = useMemo(() => {
        return `${apiUrl}/farm`;
    }, [apiUrl]);

    useEffect(() => {
        const fetchFarms = async () => {
            try {
                const response = await axios.get(memoizedApiUrl1);
                const farmData = response.data;
                const options = farmData.map((farm) => ({
                    value: farm.name,
                    label: farm.name
                }));

                setFarmOptions(options);
            } catch (error) {
                console.error('Error fetching Farms:', error);
            }
        };

        fetchFarms();
    }, [memoizedApiUrl1]);

    const handleInpute = (fieldName, value) => {
        if (fieldName === 'farm') {
            setSelectedFarm(value);
            setData((prevData) => ({ ...prevData, [fieldName]: value }));
        } else {
            setData((prevData) => ({ ...prevData, [fieldName]: value }));
        }
        console.log(data);
    };

    const handleInput = (name, value) => {
        // const { name, value } = e.target;

        setData({ ...data, [name]: value, userName: account.user.firstname, userId: account.user.id });
        console.log(data);
    };

    const handleGoat = async (e) => {
        e.preventDefault();
        setShowConfirmationModal(true);
    };

    const handleConfirmation = async (e) => {
        if (!createPermission) {
            return;
        }
        try {
            const response = await axios.post(`${apiUrl}/new-supplier`, data);

            if (response.data.message === 'Supplier already exists') {
                toast.error(`Supplier ${data.name} already exists`);
            } else {
                setSuccess(response.data.message);
                setTimeout(() => {
                    setSuccess(null);
                }, 10000);
                toast.success(`Supplier ${data.name} added successfully`);
                formRef.current.reset();
                onCancel();
                setShowConfirmationModal(false);
            }
        } catch (error) {
            toast.error(`Supplier ${data.name} already exists`);
            setError(`Supplier ${data.name} already exists`);
            console.log(error.message);
            setShowConfirmationModal(false);
        }
    };

    const handleCancelConfirmation = () => {
        // Handle cancel action if needed
        setShowConfirmationModal(false);
    };

    const typeOptions = [
        { value: 'General Supplier', label: 'General Supplier' },
        { value: 'Pigs Supplier', label: 'Pigs Supplier' },
        { value: 'Poultry Supplier', label: 'Poultry Supplier' },
        { value: 'Goats Supplier', label: 'Goats Supplier' },
        { value: 'Assets Supplier', label: 'Assets Supplier' },
    ];


    return (
        <React.Fragment>
            <Modal show={true} onHide={onCancel} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Supplier</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={12}>
                            {success && (
                                <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                                    {success}
                                </Alert>
                            )}
                            {error && (
                                <Alert variant="danger" onClose={() => setError(null)} dismissible>
                                    {error}
                                </Alert>
                            )}
                            <ToastContainer />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form onSubmit={handleGoat} ref={formRef}>
                                <Row>
                                    <Form.Group as={Col} md={4} sm={12} lg={4} controlId="formBasicName">
                                        <Form.Label>Supplier Name</Form.Label>
                                        {/* <Form.Text className="text-muted">We'll never share your email with anyone else.</Form.Text> */}
                                    </Form.Group>
                                    <Form.Group as={Col} md={8} sm={12} lg={8} controlId="formBasicName">
                                        <Form.Control size="sm" required onChange={(e) => handleInput('name', e.target.value)} type="text" name="name" />
                                        {/* <Form.Text className="text-muted">We'll never share your email with anyone else.</Form.Text> */}
                                    </Form.Group>
                                    <Form.Group as={Col} md={4} sm={12} lg={4} controlId="formBasicTel">
                                        <Form.Label>Phone Number</Form.Label>
                                        {/* <Form.Text className="text-muted">We'll never share your email with anyone else.</Form.Text> */}
                                    </Form.Group>
                                    <Form.Group as={Col} md={8} sm={12} lg={8} controlId="formBasicTel">
                                        <Form.Control size="sm" required onChange={(e) => handleInput('phone', e.target.value)} type="number" name="phone" />
                                        {/* <Form.Text className="text-muted">We'll never share your email with anyone else.</Form.Text> */}
                                    </Form.Group>
                                    <Form.Group as={Col} md={4} sm={12} lg={4} controlId="formBasicAddress">
                                        <Form.Label>Email Address</Form.Label>
                                        {/* <Form.Text className="text-muted">We'll never share your email with anyone else.</Form.Text> */}
                                    </Form.Group>
                                    <Form.Group as={Col} md={8} sm={12} lg={8} controlId="formBasicAddress">
                                        <Form.Control size="sm" required onChange={(e) => handleInput('email', e.target.value)} type="email" name="email" />
                                        {/* <Form.Text className="text-muted">We'll never share your email with anyone else.</Form.Text> */}
                                    </Form.Group>
                                    <Form.Group as={Col} md={4} sm={12} lg={4} controlId="formBasicTel">
                                        <Form.Label>Supplier Speciality</Form.Label>
                                        {/* <Form.Text className="text-muted">We'll never share your email with anyone else.</Form.Text> */}
                                    </Form.Group>
                                    <Form.Group as={Col} md={8} sm={12} lg={8} controlId="formBasicTel">
                                    <Select
                                        onChange={(selectedOption) =>
                                            setSelectedType(handleInput('speciality', selectedOption.value))
                                        }
                                        options={typeOptions}
                                        isSearchable
                                        type="text"
                                        name="speciality"
                                        placeholder="-- Select Speciality --"
                                        required
                                    />
                                    </Form.Group>
                                    <Form.Group as={Col} md={4} sm={12} lg={4} controlId="formBasicAddress">
                                        <Form.Label>Supplier Location</Form.Label>
                                        {/* <Form.Text className="text-muted">We'll never share your email with anyone else.</Form.Text> */}
                                    </Form.Group>
                                    <Form.Group as={Col} md={8} sm={12} lg={8} controlId="formBasicAddress">
                                        <Form.Control size="sm" required onChange={(e) => handleInput('location', e.target.value)} type="text" name="location" />
                                        {/* <Form.Text className="text-muted">We'll never share your email with anyone else.</Form.Text> */}
                                    </Form.Group>
                                    {/* <Form.Group as={Col} md={4} sm={12} lg={4} >
                                <Form.Label>Farm</Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} md={8} sm={12} lg={8} >
                                <Select
                                options={farmOptions}
                                onChange={(selectedOption) => handleInpute('farm', selectedOption.value)}
                                isSearchable
                                name="farm"
                                />
                            </Form.Group> */}
                                </Row>
                                <Modal.Footer>
                                    {createPermission && <Button type="submit">Submit</Button>}
                                    <Button variant="secondary" onClick={onCancel}>
                                        Cancel
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <ConfirmationModal show={showConfirmationModal} onHide={handleCancelConfirmation} onConfirm={handleConfirmation} />
        </React.Fragment>
    );
};

export default SamplePage;
