import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { useParams, useHistory } from 'react-router-dom';
import { Row, Col, Form, Button, Alert, Modal } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Font, PDFViewer } from '@react-pdf/renderer';
import config from '../../config';
import PaymentsForm from './PaymentsForm';
import PurchasesForm from './PurchasesForm';
import AccountTransferForm from './AccountTransferForm';
import LoansForm from './LoansForm';
import { usePermissions } from '../../permissions';

const TransactionHistory = () => {
    const [error, setError] = useState(null);
    const formRef = useRef(null);
    const [success, setSuccess] = useState(null);
    const [transactionData, setTransactionData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage, setUsersPerPage] = useState(10);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const history = useHistory();
    const [selectedSuppliers, setSelectedSuppliers] = useState([]);
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [users, setUsers] = useState([]);
    // const [farm, setFarms] = useState([]);
    const [vet, setVet] = useState([]);
    const [selectedType, setSelectedType] = useState('');
    const [showModal, setShowModal] = useState(true);
    const [selectedPaymentType, setSelectedPaymentType] = useState('');
    const [farmOptions, setFarmOptions] = useState([]);
    const [farm, setSelectedFarm] = useState(null);
    const { createPermission, deletePermission, editPermission } = usePermissions();

    useEffect(() => {
        const fetchFarms = async () => {
            try {
                const response = await axios.get(`${apiUrl}/farm`);
                const farmData = response.data;
                const options = farmData.map((farm) => ({
                    value: farm.name,
                    label: farm.name
                }));

                setFarmOptions(options);
            } catch (error) {
                console.error('Error fetching Farms:', error);
            }
        };

        fetchFarms();
    }, [farm]);

    const handleInpute = (fieldName, value) => {
        if (fieldName === 'farm') {
            setSelectedFarm(value);
            setTransactionDetails((prevData) => ({ ...prevData, [fieldName]: value }));
        } else {
            setTransactionDetails((prevData) => ({ ...prevData, [fieldName]: value }));
        }
        console.log(transactionDetails);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const generateTransactionId = () => {
        const timestamp = new Date().getTime();
        const random = Math.floor(Math.random() * 1000);
        return `SF-${random}`;
    };
    const [transactionDetails, setTransactionDetails] = useState({
        coInput: '',
        category: '',
        bankAccount: '',
        amount: '',
        type: '',
        date: new Date().toISOString().split('T')[0],
        method: '',
        notes: '',
        transactionType: 'Payment Transaction',
        transactionId: generateTransactionId(),
        farm: ''
    });

    console.log(transactionDetails);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!createPermission) {
            return;
        }

        try {
            const response = await axios.post(`${apiUrl}/api/payment_transaction`, transactionDetails);

            if (response.data.success) {
                setShowModal(false);
                toast.success('Transaction submitted successfully.');
                setSuccess('Transaction submitted successfully.');
                setTransactionDetails({
                    coInput: '',
                    bankAccount: '',
                    amount: '',
                    type: '',
                    date: new Date().toISOString().split('T')[0],
                    method: '',
                    notes: '',
                    transactionId: generateTransactionId(),
                    farm: ''
                });
            } else {
                toast.error('Transaction submission failed.');
                setError('Transaction submission failed.');
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                setError(error.response.data.error);
                toast.error(error.response.data.error);
            } else {
                toast.error('Error submitting transaction.');
                setError('Error submitting transaction.');
                console.error('Error submitting transaction:', error);
            }
        }
    };

    useEffect(() => {
        fetchSuppliers();
        fetchCustomers();
        fetchAccounts();
        fetchUsers();
        // fetchFarms();
        fetchVets();
    }, [selectedPaymentType, selectedType, farm, apiUrl]);

    const fetchSuppliers = async () => {
        try {
            const response = await axios.get(`${apiUrl}/suppliers`);
            setSuppliers(response.data);
        } catch (error) {
            console.error('Error fetching suppliers:', error);
        }
    };

    const fetchCustomers = async () => {
        try {
            const response = await axios.get(`${apiUrl}/customer`);
            setCustomers(response.data);
        } catch (error) {
            console.error('Error fetching customers:', error);
        }
    };

    const fetchUsers = async () => {
        try {
            const response = await axios.get(`${apiUrl}/users`);
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const fetchAccounts = async () => {
        try {
            const response = await axios.get(`${apiUrl}/accounts`);
            const accounts = response.data;

            // const filteredData = accounts.filter((tag) => tag.farm === farm);
            setAccounts(accounts);
        } catch (error) {
            console.error('Error fetching customers:', error);
        }
    };

    const fetchVets = async () => {
        try {
            const response = await axios.get(`${apiUrl}/vetenary`);
            setVet(response.data);
        } catch (error) {
            console.error('Error fetching customers:', error);
        }
    };

    const incomeAccounts = accounts.filter((account) => account.accountName === 'Income');
    const expenseAccounts = accounts.filter((account) => account.accountName !== 'Income');

    useEffect(() => {
        fetchTrialBalance();
    }, []);

    const fetchTrialBalance = async () => {
        try {
            const response = await axios.get(`${apiUrl}/transactions`);
            setTransactionData(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleTypeChange = (selectedOption) => {
        setSelectedType(selectedOption.value);
        setSelectedSuppliers([]);
        setSelectedCustomers([]);
        setSelectedUsers([]);
        setVet([]);
        // setFarms([]);
        setTransactionDetails({ ...transactionDetails, category: selectedType, coInput: '' });
    };

    console.log(selectedType);

    const availableOptions =
        selectedType === 'supplier'
            ? suppliers
            : selectedType === 'customer'
            ? customers
            : selectedType === 'employee'
            ? users
            : selectedType === 'vetenary'
            ? vet
            : [];

    // const availableOptions =
    // selectedType === 'supplier'
    //   ? suppliers.filter((supplier) => supplier.farm === farm)
    //   : selectedType === 'customer'
    //   ? customers.filter((customer) => customer.farm === farm)
    //   : selectedType === 'employee'
    //   ? users.filter((user) => user.farm === farm)
    //   : selectedType === 'vetenary'
    //   ? vet.filter((veterinary) => veterinary.farm === farm)
    //   : [];

    const type = [
        { value: 'Income', label: 'Recieve' },
        { value: 'Expense', label: 'Send' }
    ];

    const method = [
        { value: 'Cash', name: 'Cash' },
        { value: 'MPesa', name: 'MPesa' },
        { value: 'Bank', name: 'Bank' },
        { value: 'Cheque', name: 'Cheque' }
    ];

    const options = [
        { value: 'supplier', label: 'Supplier' },
        { value: 'customer', label: 'Customer' },
        { value: 'employee', label: 'Employee' },
        { value: 'vetenary', label: 'Vetenary' }
    ];

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setTransactionDetails({ ...transactionDetails, [name]: value });
    };

    const handleSelectChange = (name, selectedOption) => {
        if (name === 'type') {
            setSelectedPaymentType(selectedOption.value);
            console.log(selectedOption.value);
        }
        setTransactionDetails({ ...transactionDetails, [name]: selectedOption.value, category: selectedType });
    };

    const handleSelectChanges = (name, selectedOption) => {
        setTransactionDetails({ ...transactionDetails, [name]: selectedOption.value, category: selectedType });
    };

    const accountOptions = accounts;
    // const accountOptions = selectedPaymentType === 'Income' ? incomeAccounts : [] && selectedPaymentType === 'Expense' ? expenseAccounts : [];

    return (
        <Modal show={showModal} onHide={handleCloseModal} centered size="lg">
            <Form onSubmit={handleSubmit} ref={formRef}>
                <Modal.Header closeButton>
                    <Modal.Title>Payments</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {success && (
                        <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                            {success}
                        </Alert>
                    )}
                    {error && (
                        <Alert variant="danger" onClose={() => setError(null)} dismissible>
                            {error}
                        </Alert>
                    )}
                    <ToastContainer />

                    <Row>
                        <Col md={6} sm={12} lg={6}>
                            <Form.Group>
                                <Form.Label>Farm:</Form.Label>
                                <Select
                                    // value={farmOptions.find(option => option.value === users.farm)}
                                    options={farmOptions}
                                    onChange={(selectedOption) => handleInpute('farm', selectedOption.value)}
                                    isSearchable
                                    name="farm"
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6} sm={2} lg={6}>
                            <Form.Group>
                                <Form.Label>Category:</Form.Label>
                                <Select
                                    options={options}
                                    value={options.find((option) => option.value === selectedType)}
                                    name="category"
                                    onChange={handleTypeChange}
                                />
                            </Form.Group>
                        </Col>

                        <Col md={6} sm={12} lg={6}>
                            <Form.Group>
                                <Form.Label>Beneficiary:</Form.Label>
                                <Select
                                    onChange={(selectedOption) => handleSelectChange('coInput', selectedOption)}
                                    options={availableOptions.map((option) => ({
                                        value: option.name || `${option.firstname} ${option.lastname}`,
                                        label: option.name || `${option.firstname} ${option.lastname}`
                                    }))}
                                    placeholder="Select a Beneficiary"
                                    required
                                />
                            </Form.Group>
                        </Col>

                        <Col md={6} sm={12} lg={6}>
                            <Form.Group>
                                <Form.Label>Send/Receive:</Form.Label>
                                <Select
                                    onChange={(selectedOption) => handleSelectChange('type', selectedOption)}
                                    options={type.map((option) => ({
                                        value: option.value,
                                        label: option.label
                                    }))}
                                    required
                                />
                            </Form.Group>
                        </Col>

                        <Col md={6} sm={12} lg={6}>
                            <Form.Group>
                                <Form.Label>Account:</Form.Label>
                                <Select
                                    onChange={(selectedOption) => handleSelectChange('bankAccount', selectedOption)}
                                    options={accountOptions.map((account) => ({
                                        value: account.description,
                                        label: (account.accountNumber, account.description)
                                    }))}
                                    placeholder="Select account"
                                    required
                                />
                            </Form.Group>
                        </Col>

                        <Col md={6} sm={12} lg={6}>
                            <Form.Group>
                                <Form.Label>Amount:</Form.Label>
                                <Form.Control type="number" name="amount" onChange={handleInputChange} placeholder="Amount" required />
                            </Form.Group>
                        </Col>

                        <Col md={6} sm={12} lg={6}>
                            <Form.Group>
                                <Form.Label>Payment Method:</Form.Label>
                                <Select
                                    onChange={(selectedOption) => handleSelectChange('method', selectedOption)}
                                    options={method.map((option) => ({
                                        value: option.name,
                                        label: option.name
                                    }))}
                                    placeholder="Method of Payment"
                                    required
                                />
                            </Form.Group>
                        </Col>

                        <Col md={6} sm={12} lg={6}>
                            <Form.Group>
                                <Form.Label>Transaction ID:</Form.Label>
                                <Form.Control type="text" name="transactionId" value={transactionDetails.transactionId} readOnly />
                            </Form.Group>
                        </Col>

                        <Col md={6} sm={12} lg={6}>
                            <Form.Group>
                                <Form.Label>Date:</Form.Label>
                                <Form.Control type="text" name="date" value={transactionDetails.date} readOnly />
                            </Form.Group>
                        </Col>

                        <Col md={12} sm={12} lg={12}>
                            <Form.Group>
                                <Form.Label>Notes:</Form.Label>
                                <Form.Control
                                    type="text"
                                    as="textarea"
                                    name="notes"
                                    onChange={handleInputChange}
                                    placeholder="Notes"
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <>
                        {createPermission && <Button type="submit">Transact</Button>}
                        <Button variant="secondary" onClick={() => setShowModal(false)}>
                            Close
                        </Button>
                    </>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default TransactionHistory;
