import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Table, Button, Pagination, Form, Card, Tab, Tabs } from 'react-bootstrap';
import Select from 'react-select';
import { CSVLink } from 'react-csv';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import axios from 'axios';
import config from '../../../config';
import { useParams, useHistory } from 'react-router-dom';
// import Card from '../../../components/Card/MainCard';
import DataTable from 'react-data-table-component';
import { usePermissions } from '../../../permissions';

const UserList = () => {
    const [broilers, setBroilers] = useState([]);
    const [Mo, setMo] = useState([]);
    const [layers, setLayers] = useState([]);
    const { id } = useParams();
    const history = useHistory();
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage, setUsersPerPage] = useState(10);
    const [totals, setTotals] = useState({ layers: 0, broilers: 0 });
    const [totalFeedLayers, setTotalFeedLayers] = useState(null);
    const [totalFeedBroilers, setTotalFeedBroilers] = useState(null);
    const [totalVaccineLayers, setTotalVaccineLayers] = useState(null);
    const [totalVaccineBroilers, setTotalVaccineBroilers] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [searchTexts, setSearchTexts] = useState('');
    const { createPermission, deletePermission, editPermission } = usePermissions();

    useEffect(() => {
        // Fetch the totals from the backend API
        fetch(`${apiUrl}/api/poultrytotals`) // Make sure this matches your backend route
            .then((response) => response.json())
            .then((data) => {
                setTotals(data);
            })
            .catch((error) => console.error('Error fetching totals:', error));
    }, [totals, apiUrl]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/poultry/feeds/monthly`);
                setMo(response.data[0]);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [Mo, apiUrl]);

    const memoizedApiUrl = useMemo(() => {
        // return `${apiUrl}/all-poultry`;
        return `${apiUrl}/poultry`;
    }, [apiUrl]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(memoizedApiUrl);
                const data = response.data;
                const broilerData = data.filter((item) => item.type === 'Broilers');
                const layerData = data.filter((item) => item.type === 'Layers');

                setBroilers(broilerData);
                setLayers(layerData);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [memoizedApiUrl]);

    const calculateAge = (birthDate) => {
        const today = new Date();
        const birth = new Date(birthDate);

        const todayUTC = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
        const birthUTC = Date.UTC(birth.getFullYear(), birth.getMonth(), birth.getDate());

        const ageDiffMillis = todayUTC - birthUTC;

        const yearDiff = Math.floor(ageDiffMillis / (1000 * 60 * 60 * 24 * 365));
        const monthDiff = Math.floor((ageDiffMillis % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30));
        const dayDiff = Math.floor((ageDiffMillis % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24));

        if (yearDiff > 0) {
            return yearDiff === 1 ? `${yearDiff} year ago` : `${yearDiff} years ago`;
        } else if (monthDiff > 0) {
            return monthDiff === 1 ? `${monthDiff} month ago` : `${monthDiff} months ago`;
        } else if (dayDiff > 0) {
            return dayDiff === 1 ? `${dayDiff} day ago` : `${dayDiff} days ago`;
        } else {
            return dayDiff === 0 ? `Today` : `${dayDiff} days ago`;
        }
    };

    useEffect(() => {
        axios
            .get(`${apiUrl}/layers_feeds/total`)
            .then((response) => {
                const { totalFeedLayers } = response.data;
                setTotalFeedLayers(totalFeedLayers);
            })
            .catch((error) => {
                console.error('Error fetching total feed:', error);
            });
    }, []);

    useEffect(() => {
        axios
            .get(`${apiUrl}/broilers_feeds/total`)
            .then((response) => {
                const { totalFeedBroilers } = response.data;
                setTotalFeedBroilers(totalFeedBroilers);
            })
            .catch((error) => {
                console.error('Error fetching total feed:', error);
            });
    }, []);

    useEffect(() => {
        axios
            .get(`${apiUrl}/layers_vaccines/total`)
            .then((response) => {
                const { totalVaccineLayers } = response.data;
                setTotalVaccineLayers(totalVaccineLayers);
            })
            .catch((error) => {
                console.error('Error fetching total Vaccine:', error);
            });
    }, []);

    useEffect(() => {
        axios
            .get(`${apiUrl}/broilers_vaccines/total`)
            .then((response) => {
                const { totalVaccineBroilers } = response.data;
                setTotalVaccineBroilers(totalVaccineBroilers);
            })
            .catch((error) => {
                console.error('Error fetching total Vaccine:', error);
            });
    }, []);

    const csvLinkRef = useRef();

    const exportToCSV = () => {
        const csvData = [
            ['Batch No.', 'Birds Type', 'No. of Birds', 'Arrival Date', 'Supplier', 'Farm'],
            ...filteredData.map((item) => [item.batch, item.type, item.quantity, item.createdAt, item.sup_info, item.farm])
        ];

        const csvContent = csvData.map((row) => row.join(',')).join('\n');
        const blob = new Blob(['\ufeff', csvContent], { type: 'text/csv;charset=utf-8;' });

        const csvUrl = URL.createObjectURL(blob);
        csvLinkRef.current.href = csvUrl;
        csvLinkRef.current.download = 'sfarms.csv';
        csvLinkRef.current.click();
    };

    const exportToPDF = () => {
        const doc = new jsPDF();
        doc.autoTable({
            head: [['Batch No.', 'Birds Type', 'Rem Birds', 'Arrival Date', 'Supplier', 'Farm']],
            body: filteredData.map((user) => [
                user.batch,
                user.type,
                user.quantity,
                new Intl.DateTimeFormat('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                }).format(new Date(user.createdAt)),
                user.sup_info,
                user.farm
            ])
        });
        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl);
    };

    const exportToCSVs = () => {
        const csvData = [
            ['Batch No.', 'Birds Type', 'Rem Birds', 'Arrival Date', 'Supplier', 'Farm'],
            ...filteredDatas.map((item) => [item.batch, item.type, item.quantity, item.createdAt, item.sup_info, item.farm])
        ];

        const csvContent = csvData.map((row) => row.join(',')).join('\n');
        const blob = new Blob(['\ufeff', csvContent], { type: 'text/csv;charset=utf-8;' });

        const csvUrl = URL.createObjectURL(blob);
        csvLinkRef.current.href = csvUrl;
        csvLinkRef.current.download = 'sfarms.csv';
        csvLinkRef.current.click();
    };

    const exportToPDFs = () => {
        const doc = new jsPDF();
        doc.autoTable({
            head: [['Batch No.', 'Birds Type', 'Rem Birds', 'Arrival Date', 'Supplier']],
            body: filteredDatas.map((user) => [
                user.batch,
                user.type,
                user.quantity,
                new Intl.DateTimeFormat('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                }).format(new Date(user.createdAt)),
                user.sup_info,
                user.farm
            ])
        });
        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl);
    };

    const columns = [
        {
            name: 'Batch No',
            selector: (row) => <Link to={`view-batch/${row.batch}`}># {row.batch}</Link>,
            sortable: true
        },
        {
            name: 'Birds Type',
            selector: (row) => row.type,
            sortable: true
        },
        {
            name: 'Rem Birds',
            selector: (row) => (row.quantity !== null ? row.quantity.toLocaleString(undefined) : ''),
            sortable: true
        },
        {
            name: 'Arrival Date',
            selector: (row) => calculateAge(row.createdAt),
            sortable: true
        },
        {
            name: 'Supplier',
            selector: (row) => row.sup_info,
            sortable: true
        },
        {
            name: 'Farm',
            selector: (row) => row.farm,
            sortable: true
        }
        // {
        //     name: 'Action',
        //     cell: (row) => (
        //         <div>
        //             <Button size="sm" onClick={() => handleUpdate(row.id)}>
        //                 <span className="feather icon-edit"></span> Edit
        //             </Button>{' '}
        //             <Button size="sm" variant="info" onClick={() => handleUpdatePass(row.id)}>
        //                 <span className="feather icon-eye"></span> View
        //             </Button>
        //             <Button variant="danger" size="sm" onClick={() => handleDelete(row.id, row.firstname, row.lastname)}>
        //                 <span className="feather icon-trash-2"></span> Delete
        //             </Button>
        //         </div>
        //     ),
        //     ignoreRowClick: true,
        //     allowOverflow: true
        //     // button: true,
        //     // right: true
        // }
    ];
    const column = [
        {
            name: 'Batch No',
            selector: (row) => <Link to={`view-batch/${row.batch}`}># {row.batch}</Link>,
            sortable: true
        },
        {
            name: 'Birds Type',
            selector: (row) => row.type,
            sortable: true
        },
        {
            name: 'Rem Birds',
            selector: (row) => (row.quantity !== null ? row.quantity.toLocaleString(undefined) : ''),
            sortable: true
        },
        {
            name: 'Arrival Date',
            selector: (row) => calculateAge(row.createdAt),
            sortable: true
        },
        {
            name: 'Supplier',
            selector: (row) => row.sup_info,
            sortable: true
        },
        {
            name: 'Farm',
            selector: (row) => row.farm,
            sortable: true
        }
        // {
        //     name: 'Action',
        //     cell: (row) => (
        //         <div>
        //             <Button size="sm" onClick={() => handleUpdate(row.id)}>
        //                 <span className="feather icon-edit"></span> Edit
        //             </Button>{' '}
        //             <Button size="sm" variant="info" onClick={() => handleUpdatePass(row.id)}>
        //                 <span className="feather icon-eye"></span> View
        //             </Button>
        //             <Button variant="danger" size="sm" onClick={() => handleDelete(row.id, row.firstname, row.lastname)}>
        //                 <span className="feather icon-trash-2"></span> Delete
        //             </Button>
        //         </div>
        //     ),
        //     ignoreRowClick: true,
        //     allowOverflow: true
        //     // button: true,
        //     // right: true
        // }
    ];

    const filteredData = searchText
        ? layers.filter((item) =>
              Object.values(item).some((value) => typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase()))
          )
        : layers;

    const filteredDatas = searchTexts
        ? broilers.filter((item) =>
              Object.values(item).some((value) => typeof value === 'string' && value.toLowerCase().includes(searchTexts.toLowerCase()))
          )
        : broilers;

    return (
        <React.Fragment>
            <Row>
                <Col md={4} xl={4}>
                    <Card style={{}}>
                        <Card.Body className="">
                            <div className="row d-flex align-items-center">
                                <div className="col-6">
                                    <h6 className="">Layers</h6>
                                </div>

                                <div className="col-6 text-right">{totals.layers !== null ? totals.layers.toLocaleString() : ''}</div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4} xl={4}>
                    <Card style={{}}>
                        <Link to="/poultry/feeds-consumption">
                            <Card.Body className="">
                                <div className="row d-flex align-items-center">
                                    <div className="col-6">
                                        <h6 className="">Feed</h6>
                                    </div>
                                    <div className="col-6 text-right">
                                        {totalFeedLayers !== null
                                            ? totalFeedLayers.toLocaleString(undefined, {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2
                                              })
                                            : ''}{' '}
                                        Kgs/Litres
                                    </div>
                                </div>
                            </Card.Body>
                        </Link>
                    </Card>
                </Col>
                <Col md={4} xl={4}>
                    <Card style={{}}>
                        <Link to="/poultry/vaccination">
                            <Card.Body className="">
                                <div className="row d-flex align-items-center">
                                    <div className="col-6">
                                        <h6 className="">Vaccine</h6>
                                    </div>

                                    <div className="col-6 text-right">
                                        {totalVaccineLayers !== null
                                            ? totalVaccineLayers.toLocaleString(undefined, {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2
                                              })
                                            : ''}{' '}
                                        Kgs/Litres
                                    </div>
                                </div>
                            </Card.Body>
                        </Link>
                    </Card>
                </Col>
                <Col md={4} xl={4}>
                    <Card style={{}}>
                        <Card.Body className="">
                            <div className="row d-flex align-items-center">
                                <div className="col-6">
                                    <h6 className="">Broilers</h6>
                                </div>
                                <div className="col-6 text-right">{totals.broilers !== null ? totals.broilers.toLocaleString() : ''}</div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4} xl={4}>
                    <Card style={{}}>
                        <Link to="/poultry/feeds-consumption">
                            <Card.Body className="">
                                <div className="row d-flex align-items-center">
                                    <div className="col-6">
                                        <h6 className="">Feed</h6>
                                    </div>

                                    <div className="col-6 text-right">
                                        {totalFeedBroilers !== null
                                            ? totalFeedBroilers.toLocaleString(undefined, {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2
                                              })
                                            : ''}{' '}
                                        Kgs/Litres
                                    </div>
                                </div>
                            </Card.Body>
                        </Link>
                    </Card>
                </Col>
                <Col md={4} xl={4}>
                    <Card style={{}}>
                        <Link to="/poultry/vaccination">
                            <Card.Body className="">
                                <div className="row d-flex align-items-center">
                                    <div className="col-6">
                                        <h6 className="">Vaccine</h6>
                                    </div>
                                    <div className="col-6 text-right">
                                        {totalVaccineBroilers !== null
                                            ? totalVaccineBroilers.toLocaleString(undefined, {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2
                                              })
                                            : ''}{' '}
                                        Kgs/Litres
                                    </div>
                                </div>
                            </Card.Body>
                        </Link>
                    </Card>
                </Col>
            </Row>
            <Row className="btn-page">
                <Col>
                    <div className="d-flex justify-content-between align-items-center m-b-10">
                        <div>
                            <span> </span>
                        </div>
                        {createPermission && (
                            <Link to="/poultry/new-poultry">
                                <Button variant="primary" className="">
                                    <span className="feather icon-plus-circle"></span> Add Poultry
                                </Button>
                            </Link>
                        )}
                    </div>

                    <Tabs defaultActiveKey="layers">
                        <Tab eventKey="layers" title="Layers" className="mt-4">
                            <Row>
                                <Col md="12" className="mb-4">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex justify-content-between">
                                            <text>Search</text>
                                            <Form.Control
                                                type="text"
                                                placeholder="Search..."
                                                value={searchText}
                                                onChange={(e) => setSearchText(e.target.value)}
                                            />
                                        </div>
                                        <div className="d-flex">
                                            <Button onClick={exportToCSV}>Export as CSV</Button>
                                            <a ref={csvLinkRef} style={{ display: 'none' }} />
                                            <a href="#" className="btn btn-primary" onClick={exportToPDF}>
                                                Export PDF
                                            </a>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <DataTable
                                columns={column}
                                data={filteredData}
                                pagination
                                highlightOnHover
                                striped={true}
                                // noHeader
                                bordered
                                paginationPerPage={10}
                                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                                paginationComponentOptions={{
                                    rowsPerPageText: 'Rows per page:'
                                }}
                                paginationResetDefaultPage={true}
                                paginationTotalRows={layers.length}
                                // expandableRows
                                // selectableRows
                                onSelectedRowsChange={(selectedRows) => console.log(selectedRows)}
                                customStyles={{
                                    headRow: {
                                        style: {
                                            backgroundColor: '#279e6c', // Green background
                                            color: '#fff', // White text
                                            textTransform: 'uppercase', // Uppercase text
                                            // fontSize: '10px', // Adjust font size for header row
                                            minHeight: '1.5rem',
                                            paddingTop: '8px',
                                            paddingBottom: '8px'
                                        }
                                    }
                                }}
                                dense={true}
                            />
                        </Tab>

                        <Tab eventKey="broilers" title="Broilers" className="mt-4">
                            <Row>
                                <Col md="12" className="mb-4">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex justify-content-between">
                                            <text>Search</text>
                                            <Form.Control
                                                type="text"
                                                placeholder="Search..."
                                                value={searchTexts}
                                                onChange={(e) => setSearchTexts(e.target.value)}
                                            />
                                        </div>
                                        <div className="d-flex">
                                            <Button onClick={exportToCSVs}>Export as CSV</Button>
                                            <a ref={csvLinkRef} style={{ display: 'none' }} />
                                            <a href="#" className="btn btn-primary" onClick={exportToPDFs}>
                                                Export PDF
                                            </a>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <DataTable
                                columns={columns}
                                data={filteredDatas}
                                pagination
                                highlightOnHover
                                striped={true}
                                // noHeader
                                bordered
                                paginationPerPage={10}
                                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                                paginationComponentOptions={{
                                    rowsPerPageText: 'Rows per page:'
                                }}
                                paginationResetDefaultPage={true}
                                paginationTotalRows={broilers.length}
                                // expandableRows
                                // selectableRows
                                onSelectedRowsChange={(selectedRows) => console.log(selectedRows)}
                                customStyles={{
                                    headRow: {
                                        style: {
                                            backgroundColor: '#279e6c', // Green background
                                            color: '#fff', // White text
                                            textTransform: 'uppercase', // Uppercase text
                                            // fontSize: '10px', // Adjust font size for header row
                                            minHeight: '1.5rem',
                                            paddingTop: '8px',
                                            paddingBottom: '8px'
                                        }
                                    }
                                }}
                                dense={true}
                            />
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default UserList;
