import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Button, Alert, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../../../config';
import Card from '../../../components/Card/MainCard';
import axios from 'axios';
import ConfirmationModal from './ConfirmationModal';
import { usePermissions } from '../../../permissions';

const SamplePage = ({ onCancel }) => {
    const account = useSelector((state) => state.account);
    const [data, setData] = useState({});
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [selectedFeed, setSelectedFeed] = useState(null);
    const [selectedBatch, setSelectedBatch] = useState(null);
    const [feedOptions, setFeedOptions] = useState([]);
    const [batches, setBatches] = useState([]);
    const [selectedType, setSelectedType] = useState('');
    const formRef = useRef(null);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [farmOptions, setFarmOptions] = useState([]);
    const [farm, setSelectedFarm] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const { createPermission, deletePermission, editPermission } = usePermissions();

    const memoizedApiUrl1 = useMemo(() => {
        return `${apiUrl}/farm`;
    }, [apiUrl]);

    const memoizedApiUrl2 = useMemo(() => {
        return `${apiUrl}/feeds`;
    }, [apiUrl]);

    const memoizedApiUrl3 = useMemo(() => {
        return `${apiUrl}/users`;
    }, [apiUrl]);

    useEffect(() => {
        const fetchFarms = async () => {
            try {
                const response = await axios.get(memoizedApiUrl1);
                const farmData = response.data;
                const options = farmData.map((farm) => ({
                    value: farm.name,
                    label: farm.name
                }));

                setFarmOptions(options);
            } catch (error) {
                console.error('Error fetching Farms:', error);
            }
        };

        fetchFarms();
    }, [memoizedApiUrl1]);

    useEffect(() => {
        const fetchFeeds = async () => {
            try {
                const response = await axios.get(memoizedApiUrl2);
                const data = response.data;
                // const filteredData = data.filter((tag) => tag.farm === farm);
                const options = data.map((feed) => ({
                    value: feed.feed_name,
                    label: feed.feed_name
                }));
                setFeedOptions(options);
            } catch (error) {
                console.error('Error fetching feeds:', error);
            }
        };

        fetchFeeds();
    }, [memoizedApiUrl2]);

    const handleInputs = (fieldName, value) => {
        if (fieldName === 'farm') {
            setSelectedFarm(value);
            setData((prevData) => ({ ...prevData, [fieldName]: value }));
        } else {
            setData((prevData) => ({ ...prevData, [fieldName]: value }));
        }
        console.log(data);
    };

    const handleInput = (fieldName, value) => {
        if (fieldName === 'batch') {
            setSelectedBatch(value);
            const batch = value ? value.map((option) => option.value) : [];
            setData((prevData) => ({
                ...prevData,
                [fieldName]: value,
                batch,
                type: selectedType.value,
                userId: account.user.id,
                userName: account.user.firstname
            }));
            console.log(data);
        } else {
            setData((prevData) => ({ ...prevData, [fieldName]: value, userId: account.user.id, userName: account.user.firstname }));
        }
        console.log(data);
    };

    const handleEggs = async (e) => {
        e.preventDefault();
        setShowConfirmationModal(true);
    };

    const handleConfirmation = async (e) => {
        if (!createPermission) {
            return;
        }
        axios
            .post(`${apiUrl}/poultry-feed-consumption`, data)
            .then((response) => {
                if (response.data) {
                    setSuccess(`${data.feed_name} consumed successfully`);
                    setTimeout(() => {
                        setSuccess(null);
                    }, 10000);
                    toast.success(`${data.feed_name} consumed successfully`);
                    formRef.current.reset();
                    setSelectedFeed(null);
                    setData({});
                    setShowConfirmationModal(false);
                    onCancel();
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    setError(`${error.response.data.message}`);
                    setShowConfirmationModal(false);
                } else {
                    toast.error(`Error consuming ${data.feed_name}`);
                    setError(`Error consuming ${data.feed_name}`);
                    setShowConfirmationModal(false);
                }
                console.log(error);
                setShowConfirmationModal(false);
            });
    };

    const poultryType = [
        { value: 'Layers', label: 'Layers' },
        { value: 'Broilers', label: 'Broilers' }
    ];

    useEffect(() => {
        // fetchSuppliers();
        fetchAllBatches();
    }, [farm, batches, apiUrl]);

    const fetchAllBatches = async () => {
        try {
            const response = await axios.get(`${apiUrl}/poultry`);
            const data = response.data;
            const filteredData = data.filter((tag) => tag.farm === farm);
            setBatches(filteredData);
        } catch (error) {
            console.error('Error fetching batches:', error);
        }
    };

    const handlePoultryTypeChange = (selectedOption) => {
        setSelectedType(selectedOption);
    };

    const filteredBatches = batches.filter((batch) => selectedType && batch.type === selectedType.value);

    const handleCancelConfirmation = () => {
        // Handle cancel action if needed
        setShowConfirmationModal(false);
    };

    return (
        <React.Fragment>
            <Modal show={true} onHide={onCancel} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Feed Poultry</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={12}>
                            {success && (
                                <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                                    {success}
                                </Alert>
                            )}
                            {error && (
                                <Alert variant="danger" onClose={() => setError(null)} dismissible>
                                    {error}
                                </Alert>
                            )}
                            <ToastContainer />
                        </Col>
                    </Row>
                    <Form onSubmit={handleEggs} ref={formRef}>
                        <Row>
                            <Col>
                                <Row>
                                    <Form.Group as={Col} md={6} sm={12} lg={6}>
                                        <Form.Label>Farm</Form.Label>
                                        <Select
                                            options={farmOptions}
                                            onChange={(selectedOption) => handleInputs('farm', selectedOption.value)}
                                            isSearchable
                                            name="farm"
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Name of Feed</Form.Label>
                                        <Select
                                            value={selectedFeed}
                                            onChange={(selectedOption) => setSelectedFeed(handleInput('feed_name', selectedOption.value))}
                                            options={feedOptions}
                                            isSearchable
                                            name="feed_name"
                                            placeholder="--Select feed--"
                                            required
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Bird Type</Form.Label>
                                        <Select
                                            value={selectedType}
                                            onChange={handlePoultryTypeChange}
                                            options={poultryType}
                                            isSearchable
                                            name="type"
                                            placeholder="--Select type-"
                                            required
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Batch No.</Form.Label>
                                        <Select
                                            onChange={(selectedOption) => setSelectedBatch(handleInput('batch', selectedOption))}
                                            options={filteredBatches.map((batch) => ({
                                                value: batch.batch,
                                                label: batch.batch
                                            }))}
                                            isSearchable
                                            isMulti
                                            type="text"
                                            name="batch"
                                            placeholder="-- Select Batch --"
                                            required
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Quantity in Kgs/Litres</Form.Label>
                                        <Form.Control
                                            size="sm"
                                            required
                                            onChange={(e) => handleInput('consumed', e.target.value)}
                                            type="number"
                                            name="consumed"
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Date</Form.Label>
                                        <Form.Control
                                            size="sm"
                                            required
                                            onChange={(e) => handleInput('recorded', e.target.value)}
                                            type="date"
                                            name="recorded"
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={12} sm={12} lg={12} controlId="formBasic">
                                        <Form.Label>Remarks/Notes</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            required
                                            onChange={(e) => handleInput('remarks', e.target.value)}
                                            type="text"
                                            name="remarks"
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md={12} sm={12} lg={12} controlId="formBasic">
                                        <Form.Text className="text-muted">
                                            **Type in <code>null</code> if there is no data to insert
                                        </Form.Text>
                                    </Form.Group>
                                </Row>
                            </Col>
                        </Row>
                        <Modal.Footer>
                            {createPermission && <Button type="submit">Submit</Button>}
                            <Button variant="secondary" onClick={onCancel}>
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
            </Modal>
            <ConfirmationModal show={showConfirmationModal} onHide={handleCancelConfirmation} onConfirm={handleConfirmation} />
        </React.Fragment>
    );
};

export default SamplePage;
