import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Row, Col, Form, Button, Alert, ProgressBar } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from './../../../config';
import axios from 'axios';
import zxcvbn from 'zxcvbn';

import Card from '../../../components/Card/MainCard';

const UpdateUser = () => {
    const account = useSelector((state) => state.account);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const user = account?.user;
    //   const [user, setUser] = useState({});
    const userName = account?.user?.firstname;
    const id = account?.user?.id;
    const history = useHistory();
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [message, setMessage] = useState('');
    
    const [formData, setFormData] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
        id: account?.user?.id, 
        email: account?.user?.email,
    });

    const [passwordStrength, setPasswordStrength] = useState(0);
    const [passwordMatch, setPasswordMatch] = useState(true);

    const handleInput = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value, id: id, email: account?.user?.email });

        if (name === 'newPassword') {
            const strength = zxcvbn(value).score;
            setPasswordStrength(strength);
        }

        if (name === 'confirmPassword' || name === 'newPassword') {
            setPasswordMatch(formData.newPassword === value);
        }
    };
    console.log(formData)

    const onSubmit = async (e) => {

        e.preventDefault();

        if (formData.newPassword !== formData.confirmPassword) {
            setPasswordMatch(false);
            setError('Passwords do not match');
            return;
        }
        try {
            const response = await axios.post(`${apiUrl}/user-password-change`, formData);
            setSuccess(response.data.message);
            toast.success(response.data.message);
            setFormData('');
        } catch (error) {
            // setError(error.message);
            setError(error.response.data.message);
            toast.error(error.response.data.message);
            setFormData('');
            console.log(error);
        }
    };

    const getProgressBarVariant = () => {
        switch (passwordStrength) {
            case 0:
            case 1:
                return 'danger';
            case 2:
                return 'warning';
            case 3:
            case 4:
                return 'success';
            default:
                return 'danger';
        }
    };

    return (
        <React.Fragment>
            <Row>
                <Col sm={12}>
                    {success && (
                        <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                            {success}
                        </Alert>
                    )}
                    {error && (
                        <Alert variant="danger" onClose={() => setError(null)} dismissible>
                            {error}
                        </Alert>
                    )}
                    <ToastContainer />
                </Col>
            </Row>

            <Row>
                <Col sm={12}>
                    <Card title={`Update Password - ${user.firstname} ${user.lastname}`} isOption>

                        <Form onSubmit={onSubmit}>
                            <Row>
                                <Col sm={12} md={12}>
                                    <div className="p-2">
                                        <label>USER ID:</label> {user.id}
                                    </div>
                                </Col>
                                <Col sm={12} md={12}>
                                    <div className="p-2">
                                        <Form.Label>Current Password:</Form.Label>
                                        <Form.Control type="password" size="sm" name="currentPassword" onChange={handleInput} required />
                                    </div>
                                </Col>
                                <Col sm={12} md={12}>
                                    <div className="p-2">
                                        <Form.Label>New Password:</Form.Label>
                                        <Form.Control type="password" size="sm" name="newPassword" onChange={handleInput} required />
                                        {formData.newPassword && (
                                            <>
                                                <ProgressBar
                                                    now={(passwordStrength + 1) * 20}
                                                    variant={getProgressBarVariant()}
                                                    className="mt-3"
                                                />
                                                <Form.Text>
                                                    {passwordStrength < 2 ? 'Weak' : passwordStrength < 3 ? 'Fair' : 'Strong'}
                                                </Form.Text>
                                            </>
                                        )}
                                    </div>
                                </Col>

                                <Col sm={12} md={12}>
                                    <div className="p-2">
                                        <Form.Label>Confirm Password:</Form.Label>
                                        <Form.Control type="password" size="sm" name="confirmPassword" onChange={handleInput} required />
                                        <Form.Control.Feedback type="invalid">Passwords do not match</Form.Control.Feedback>
                                    </div>
                                </Col>
                                <Col sm={12}>
                                    <div className="p-2">
                                        <Button type="submit">Submit</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default UpdateUser;
