import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Button, Alert, Modal } from 'react-bootstrap';
import { useHistory, useParams, Link } from 'react-router-dom';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../../config';
import Card from '../../components/Card/MainCard';
import axios from 'axios';
import ConfirmationModal from '../ui-elements/basic/ConfirmationModal';
import { usePermissions } from '../../permissions';

const SamplePage = ({ onCancel }) => {
    const account = useSelector((state) => state.account);
    const [data, setData] = useState({});
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [feedCost, setFeedCost] = useState(0);
    const [selectedFeed, setSelectedFeed] = useState(null);
    const [feedOptions, setFeedOptions] = useState([]);
    const formRef = useRef(null);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [accounts, setAccounts] = useState([]);
    const [supplierOptions, setSupplierOptions] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedSup, setSelectedSup] = useState([]);
    const generateTransactionId = () => {
        const timestamp = new Date().getTime();
        const random = Math.floor(Math.random() * 1000);
        return `SF-${random}`;
    };
    const [farmOptions, setFarmOptions] = useState([]);
    const [farm, setSelectedFarm] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const { createPermission, deletePermission, editPermission } = usePermissions();

    const memoizedApiUrl1 = useMemo(() => {
        return `${apiUrl}/farm`;
    }, [apiUrl]);

    const memoizedApiUrl2 = useMemo(() => {
        return `${apiUrl}/accounts`;
    }, [apiUrl]);

    const memoizedApiUrl3 = useMemo(() => {
        return `${apiUrl}/feeds`;
    }, [apiUrl]);

    const memoizedApiUrl4 = useMemo(() => {
        return `${apiUrl}/suppliers`;
    }, [apiUrl]);

    useEffect(() => {
        const fetchFarms = async () => {
            try {
                const response = await axios.get(memoizedApiUrl1);
                const farmData = response.data;
                const options = farmData.map((farm) => ({
                    value: farm.name,
                    label: farm.name
                }));

                setFarmOptions(options);
            } catch (error) {
                console.error('Error fetching Farms:', error);
            }
        };

        fetchFarms();
    }, [memoizedApiUrl1]);

    const handleInpute = (fieldName, value) => {
        if (fieldName === 'farm') {
            setSelectedFarm(value);
            setData((prevData) => ({ ...prevData, [fieldName]: value }));
        } else {
            setData((prevData) => ({ ...prevData, [fieldName]: value }));
        }
        console.log(data);
    };

    useEffect(() => {
        fetchAccounts();
    }, [memoizedApiUrl2]);

    const fetchAccounts = async () => {
        try {
            const response = await axios.get(memoizedApiUrl2);
            const accounts = response.data;

            // const filteredData = accounts.filter((tag) => tag.farm === farm);

            // const filteredAccounts = accounts.filter(account => account.accountName !== 'Income');

            setAccounts(accounts);
        } catch (error) {
            console.error('Error fetching accounts:', error);
        }
    };

    const handleSelectChange = (name, selectedOption) => {
        setData({ ...data, [name]: selectedOption.value, transactionId: generateTransactionId() });
    };

    useEffect(() => {
        const fetchFeeds = async () => {
            try {
                const response = await axios.get(memoizedApiUrl3);
                const data = response.data;
                // const filteredData = data.filter((tag) => tag.farm === farm);
                const options = data.map((feed) => ({
                    value: feed.feed_name,
                    label: feed.feed_name
                }));
                setFeedOptions(options);
            } catch (error) {
                console.error('Error fetching feeds:', error);
            }
        };

        fetchFeeds();
    }, [memoizedApiUrl3]);

    useEffect(() => {
        const calculateTotalCost = () => {
            const feedCost = data.feedQuantity * data.feedPer;
            setFeedCost(feedCost);
            setData((prevData) => ({ ...prevData, feedCost }));
        };

        calculateTotalCost();
    }, [data.feedQuantity, data.feedPer]);

    const handleInput = (fieldName, value) => {
        setData((prevData) => ({
            ...prevData,
            [fieldName]: value,
            sup_name: selectedSup,
            userName: account.user.firstname,
            userId: account.user.id
        }));
        console.log(data);
    };

    console.log(data);

    const formData = new FormData();
    formData.append('image', selectedImage);
    formData.append('sup_name', selectedSup);
    formData.append('feed_name', data.feed_name);
    formData.append('feedQuantity', data.feedQuantity);
    formData.append('feedPer', data.feedPer);
    formData.append('feedCost', data.feedCost);
    formData.append('bankAccount', data.bankAccount);
    formData.append('transactionId', data.transactionId);
    formData.append('recorded', data.recorded);
    formData.append('farm', null);
    formData.append('userName', account.user.firstname);
    formData.append('userId', account.user.id);

    const handleFeed = async (e) => {
        e.preventDefault();
        setShowConfirmationModal(true);
    };

    const handleConfirmation = async (e) => {
        if (!createPermission) {
            return;
        }
        axios
            .post(`${apiUrl}/feed-restock`, formData)
            .then((response) => {
                if (response.data) {
                    setSuccess(`${data.feed_name} restocked successfully`);
                    setTimeout(() => {
                        setSuccess(null);
                    }, 10000);
                    toast.success(`${data.feed_name} restocked successfully`);
                    formRef.current.reset();
                    setSelectedFeed(null);
                    setFeedCost(null);
                    setShowConfirmationModal(false);
                    onCancel();
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    setError(`${error.response.data.message}`);
                    toast.error(`${error.response.data.message}`);
                    setShowConfirmationModal(false);
                } else {
                    setError('An error occurred. Please try again later.');
                    setShowConfirmationModal(false);
                }
            });
    };

    useEffect(() => {
        const fetchSuppliers = async () => {
            try {
                const response = await axios.get(memoizedApiUrl4);
                const data = response.data;
                // const filteredData = data.filter((tag) => tag.farm === farm);
                const options = data.map((supplier) => ({
                    value: supplier.name,
                    label: supplier.name
                }));
                setSupplierOptions(options);
            } catch (error) {
                console.error('Error fetching suppliers:', error);
            }
        };

        fetchSuppliers();
    }, [memoizedApiUrl4]);

    const handleImageChange = (e) => {
        setSelectedImage(e.target.files[0]);
    };

    const handleCancelConfirmation = () => {
        // Handle cancel action if needed
        setShowConfirmationModal(false);
    };

    return (
        <React.Fragment>
            <Modal show={true} onHide={onCancel} size="xl" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Restock Feed</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={12}>
                            {success && (
                                <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                                    {success}
                                </Alert>
                            )}
                            {error && (
                                <Alert variant="danger" onClose={() => setError(null)} dismissible>
                                    {error}
                                </Alert>
                            )}
                            <ToastContainer />
                        </Col>
                    </Row>
                    <Form onSubmit={handleFeed} ref={formRef}>
                        <Row>
                            <Col>
                                <Row>
                                    {/* <Form.Group as={Col} md={6} sm={12} lg={6} >
                                <Form.Label>Farm</Form.Label>
                                <Select
                                options={farmOptions}
                                onChange={(selectedOption) => handleInpute('farm', selectedOption.value)}
                                isSearchable
                                name="farm"
                                required
                                />
                            </Form.Group> */}
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Supplier</Form.Label>
                                        <Select
                                            onChange={(selectedOption) => setSelectedSup(selectedOption.value)}
                                            options={supplierOptions}
                                            isSearchable
                                            type="text"
                                            name="sup_name"
                                            placeholder="-- Select Supplier --"
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Feed Name</Form.Label>
                                        <Select
                                            value={selectedFeed}
                                            onChange={(selectedOption) => setSelectedFeed(handleInput('feed_name', selectedOption.value))}
                                            options={feedOptions}
                                            isSearchable
                                            name="feed_name"
                                            placeholder="--Select feed--"
                                            required
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Stock Quantity in Kgs</Form.Label>
                                        <Form.Control
                                            size="sm"
                                            required
                                            onChange={(e) => handleInput('feedQuantity', e.target.value)}
                                            type="number"
                                            name="feedQuantity"
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Cost per Kg</Form.Label>
                                        <Form.Control
                                            size="sm"
                                            required
                                            onChange={(e) => handleInput('feedPer', e.target.value)}
                                            type="number"
                                            name="feedPer"
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Total Cost</Form.Label>
                                        <Form.Control
                                            size="sm"
                                            required
                                            onChange={(e) => handleInput('feedCost', e.target.value)}
                                            type="text"
                                            value={feedCost}
                                            name="feedCost"
                                            disabled
                                        />
                                    </Form.Group>
                                    <Col md={6} sm={12} lg={6}>
                                        <Form.Group>
                                            <Form.Label>Expense Account:</Form.Label>
                                            <Select
                                                onChange={(selectedOption) => handleSelectChange('bankAccount', selectedOption)}
                                                options={accounts.map((account) => ({
                                                    value: account.description,
                                                    label: (account.accountNumber, account.description)
                                                }))}
                                                placeholder="Select account"
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Form.Group as={Col} md={6} sm={12} lg={6}>
                                        <Form.Label>Supporting Document</Form.Label>
                                        <Form.Control type="file" onChange={handleImageChange} name="image" accept="image/*" required />
                                    </Form.Group>

                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Date</Form.Label>
                                        <Form.Control
                                            size="sm"
                                            required
                                            onChange={(e) => handleInput('recorded', e.target.value)}
                                            type="date"
                                            name="recorded"
                                        />
                                    </Form.Group>
                                </Row>
                                <Modal.Footer>
                                    {createPermission && <Button type="submit">Submit</Button>}
                                    <Button variant="secondary" onClick={onCancel}>
                                        Cancel
                                    </Button>
                                </Modal.Footer>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
            <ConfirmationModal show={showConfirmationModal} onHide={handleCancelConfirmation} onConfirm={handleConfirmation} />
        </React.Fragment>
    );
};

export default SamplePage;
