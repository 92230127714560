import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Button, Alert, FormText, Modal } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import config from '../../config';
import Card from '../../components/Card/MainCard';
import axios from 'axios';
import ConfirmationModal from '../ui-elements/basic/ConfirmationModal';
import { usePermissions } from '../../permissions';

const SamplePage = ({ onCancel }) => {
    const account = useSelector((state) => state.account);
    const [data, setData] = useState({});
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [cost, setCost] = useState(0);
    const [supplierOptions, setSupplierOptions] = useState([]);
    const [doneOptions, setDoneOptions] = useState([]);
    const [userOptions, setUserOptions] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [selectedPerson, setSelectedPerson] = useState(null);
    const formRef = useRef(null);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [accounts, setAccounts] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const generateTransactionId = () => {
        const timestamp = new Date().getTime();
        const random = Math.floor(Math.random() * 1000);
        return `SF-${random}`;
    };
    const [farmOptions, setFarmOptions] = useState([]);
    const [farm, setSelectedFarm] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const { createPermission, deletePermission, editPermission } = usePermissions();

    const memoizedApiUrl1 = useMemo(() => {
        return `${apiUrl}/farm`;
    }, [apiUrl]);

    const memoizedApiUrl2 = useMemo(() => {
        return `${apiUrl}/accounts`;
    }, [apiUrl]);

    const memoizedApiUrl4 = useMemo(() => {
        return `${apiUrl}/suppliers`;
    }, [apiUrl]);

    useEffect(() => {
        const fetchFarms = async () => {
            try {
                const response = await axios.get(memoizedApiUrl1);
                const farmData = response.data;
                const options = farmData.map((farm) => ({
                    value: farm.name,
                    label: farm.name
                }));

                setFarmOptions(options);
            } catch (error) {
                console.error('Error fetching Farms:', error);
            }
        };

        fetchFarms();
    }, [memoizedApiUrl1]);

    const handleInpute = (fieldName, value) => {
        if (fieldName === 'farm') {
            setSelectedFarm(value);
            setData((prevData) => ({ ...prevData, [fieldName]: value }));
        } else {
            setData((prevData) => ({ ...prevData, [fieldName]: value }));
        }
        console.log(data);
    };

    useEffect(() => {
        fetchAccounts();
    }, [memoizedApiUrl2]);

    const fetchAccounts = async () => {
        try {
            const response = await axios.get(memoizedApiUrl2);
            const accounts = response.data;

            // const filteredData = accounts.filter((tag) => tag.farm === farm);

            // const filteredAccounts = accounts.filter(account => account.accountName !== 'Income');

            setAccounts(accounts);
        } catch (error) {
            console.error('Error fetching accounts:', error);
        }
    };

    const handleSelectChange = (name, selectedOption) => {
        setData({ ...data, [name]: selectedOption.value, transactionId: generateTransactionId() });
    };

    const handleInput = (fieldName, value) => {
        if(userOptions === 'General'){
            setSelectedPerson(null);
            setData((prevData) => ({
                ...prevData,
                [fieldName]: value,
                sup_name: selectedSupplier,
                userName: account.user.firstname,
                userId: account.user.id
            }));
        }
        setData((prevData) => ({
            ...prevData,
            [fieldName]: value,
            sup_name: selectedSupplier,
            userName: account.user.firstname,
            userId: account.user.id
        }));
        console.log(data);
    };

    console.log(data);

    useEffect(() => {
        const fetchVaccines = async () => {
            try {
                const response = await axios.get(memoizedApiUrl4);
                const data = response.data;
                // const filteredData = data.filter((tag) => tag.farm === farm);
                const options = data.map((sup) => ({
                    value: sup.name,
                    label: sup.name
                }));
                console.log(options);
                setSupplierOptions(options);
            } catch (error) {
                console.error('Error fetching vaccines:', error);
            }
        };

        fetchVaccines();
    }, [memoizedApiUrl4]);

    const formData = new FormData();
    formData.append('image', selectedImage || null);
    formData.append('sup_name', selectedSupplier);
    formData.append('water_user', userOptions);
    formData.append('capacity', data.capacity);
    formData.append('costper', data.costper);
    formData.append('cost', data.cost);
    formData.append('bankAccount', data.bankAccount);
    formData.append('transactionId', data.transactionId);
    formData.append('recorded', data.recorded);
    formData.append('farm', data.farm || null);
    formData.append('remarks', data.remarks);
    formData.append('userId', data.userId);
    formData.append('userName', data.userName);
    formData.append('person', selectedPerson || null);

    const handleMilk = async (e) => {
        e.preventDefault();
        setShowConfirmationModal(true);
    };

    const handleConfirmation = async (e) => {
        if (!createPermission) {
            return;
        }
        try {
            const response = await axios.post(`${apiUrl}/add-general-expense`, formData);

            if (response.data.message) {
                setSuccess(response.data.message);
                setTimeout(() => {
                    setSuccess(null);
                }, 10000);
                toast.success(response.data.message);
                formRef.current.reset();
                setCost(null);
                setSelectedSupplier(null);
                setShowConfirmationModal(false);
                onCancel();
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                setError(`${error.response.data.message}`);
                toast.error(`${error.response.data.message}`);
                setShowConfirmationModal(false);
                onCancel();
            } else {
                setError('An error occurred. Please try again later.');
                setShowConfirmationModal(false);
                onCancel();
            }
        }
    };

    const handleImageChange = (e) => {
        setSelectedImage(e.target.files[0]);
    };

    const handleCancelConfirmation = () => {
        // Handle cancel action if needed
        setShowConfirmationModal(false);
    };

    const selectOptions = [
        { value: 'General', label: 'General' },
        { value: 'Employees', label: 'Employees' },
        { value: 'Poultry', label: 'Poultry' },
        { value: 'Pigs', label: 'Pigs' },
        { value: 'Goats', label: 'Goats' },
        { value: 'Customers', label: 'Customers' },
        { value: 'Suppliers', label: 'Suppliers' },
        { value: 'Vetenary', label: 'Vetenary' },
    ];

    useEffect(() => {
        if (userOptions) {
            fetchSupplierOptions(userOptions);
            setSelectedPerson((prevSelectedPerson) => ({
                ...prevSelectedPerson,
                value: null,
              }));
            
        }
    }, [userOptions]);

    //   const fetchSupplierOptions = async (userOptions) => {
    //     try {
    //       let url;
    //       if (userOptions === 'Pigs') {
    //         url = `${apiUrl}/pigs`;
    //       } else if (userOptions === 'Poultry') {
    //         url = `${apiUrl}/poultry`;
    //       } else if (userOptions === 'Goats') {
    //         url = `${apiUrl}/goats`;
    //       } else if (userOptions === 'Vetenary') {
    //         url = `${apiUrl}/vetenary`;
    //       } else if (userOptions === 'Customers') {
    //         url = `${apiUrl}/customer`;
    //       } else if (userOptions === 'Suppliers') {
    //         url = `${apiUrl}/suppliers`;
    //       } else {
    //         url = `${apiUrl}/users`;
    //       }
    //       const response = await axios.get(url);
    //       const data = await response.json();
    //       const options = data.map((supplier) => ({
    //         label: supplier.name,
    //         value: supplier.id,
    //       }));
    //       setSupplierOptions(options);
    //     } catch (error) {
    //       console.error('Error fetching suppliers:', error);
    //     }
    //   };

    const fetchSupplierOptions = async (userOptions) => {
        try {
            let url;
            let labelKey;
            let valueKey;

            switch (userOptions) {
                case 'Poultry':
                    url = `${apiUrl}/${userOptions.toLowerCase()}`;
                    labelKey = 'batch';
                    valueKey = 'batch';
                    break;
                case 'Pigs':
                case 'Goats':
                    url = `${apiUrl}/${userOptions.toLowerCase()}`;
                    labelKey = 'tagNumber';
                    valueKey = 'tagNumber';
                    break;
                case 'Vetenary':
                    url = `${apiUrl}/vetenary`;
                    labelKey = 'name';
                    valueKey = 'name';
                    break;
                case 'Customers':
                    url = `${apiUrl}/customer`;
                    labelKey = 'name';
                    valueKey = 'name';
                    break;
                case 'Suppliers':
                    url = `${apiUrl}/suppliers`;
                    labelKey = 'name';
                    valueKey = 'name';
                    break;
                case 'Employees':
                    url = `${apiUrl}/users`;
                    labelKey = `firstname`;
                    valueKey = `firstname`;
                    break;
                default:
                    url = null;
            }

            const response = await axios.get(url);
            const data = response.data;
            const options = data.map((item) => ({
                label: item[labelKey],
                value: item[valueKey]
            }));
            setDoneOptions(options);
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };

    return (
        <React.Fragment>
            <Modal show={true} onHide={onCancel} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Record Expense</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={12}>
                            {success && (
                                <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                                    {success}
                                </Alert>
                            )}
                            {error && (
                                <Alert variant="danger" onClose={() => setError(null)} dismissible>
                                    {error}
                                </Alert>
                            )}
                            <ToastContainer />
                        </Col>
                    </Row>
                    <Form onSubmit={handleMilk} ref={formRef}>
                        <Row>
                            <Col>
                                <Row>
                                    <Form.Group as={Col} md={6} sm={12} lg={6}>
                                        <Form.Label>
                                            Farm <code>Optional</code>
                                        </Form.Label>
                                        <Select
                                            options={farmOptions}
                                            onChange={(selectedOption) => handleInpute('farm', selectedOption.value)}
                                            isSearchable
                                            name="farm"
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Supplier</Form.Label>
                                        <CreatableSelect
                                            onChange={(selectedOption) => setSelectedSupplier(selectedOption.value)}
                                            options={supplierOptions}
                                            isSearchable
                                            name="sup_name"
                                            placeholder="-- Select or Type Supplier --"
                                            required
                                        />
                                        <FormText>Type name, click Create / hit Enter to insert added name</FormText>
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Purchase for</Form.Label>
                                        <CreatableSelect
                                            onChange={(selectedOption) => setUserOptions(selectedOption.value)}
                                            options={selectOptions}
                                            isSearchable
                                            name="water_user"
                                            placeholder="-- Select --"
                                            required
                                        />
                                        {/* <FormText>Type name, click Create / hit Enter to insert added name</FormText> */}
                                    </Form.Group>
                                    {userOptions && userOptions !== 'General' && (
                                        <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                            <Form.Label>
                                            Beneficiary <code>Optional</code>
                                            </Form.Label>
                                            <CreatableSelect
                                                onChange={(selectedOption) => setSelectedPerson(selectedOption.value)}
                                                options={doneOptions}
                                                value={selectedPerson?.value}
                                                isSearchable
                                                name="person"
                                                placeholder="-- Select --"
                                                // required
                                            />
                                        </Form.Group>
                                    )}
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Total Cost</Form.Label>
                                        <Form.Control
                                            size="sm"
                                            required
                                            onChange={(e) => handleInput('cost', e.target.value)}
                                            // value={cost}
                                            type="number"
                                            name="cost"
                                            // disabled
                                        />
                                    </Form.Group>

                                    <Col Col md={6} sm={12} lg={6}>
                                        <Form.Group>
                                            <Form.Label>Expense Account:</Form.Label>
                                            <Select
                                                onChange={(selectedOption) => handleSelectChange('bankAccount', selectedOption)}
                                                options={accounts.map((account) => ({
                                                    value: account.description,
                                                    label: (account.accountNumber, account.description)
                                                }))}
                                                placeholder="Select account"
                                                required
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Date</Form.Label>
                                        <Form.Control
                                            size="sm"
                                            required
                                            onChange={(e) => handleInput('recorded', e.target.value)}
                                            type="date"
                                            name="recorded"
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} sm={12} lg={6}>
                                        <Form.Label>Supporting Document <code>Optional</code></Form.Label>
                                        <Form.Control
                                            type="file"
                                            onChange={handleImageChange}
                                            name="image"
                                            accept="image/*"
                                            // required
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md={12} sm={12} lg={12} controlId="formBasic">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            required
                                            onChange={(e) => handleInput('remarks', e.target.value)}
                                            type="text"
                                            name="remarks"
                                        />
                                    </Form.Group>
                                </Row>
                                <Modal.Footer>
                                    {createPermission && <Button type="submit">Submit</Button>}
                                    <Button variant="secondary" onClick={onCancel}>
                                        Cancel
                                    </Button>
                                </Modal.Footer>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>

            <ConfirmationModal show={showConfirmationModal} onHide={handleCancelConfirmation} onConfirm={handleConfirmation} />
        </React.Fragment>
    );
};

export default SamplePage;
