import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { useParams, useHistory, Link  } from 'react-router-dom';
import { Row, Col, Form, Button, Table, Alert, Pagination} from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../../../components/Card/MainCard';
import config from '../../../config';

const TransactionHistory = ({resetTypeInput}) => {
  const { selectedFarm } = useParams();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [transactionData, setTransactionData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(10);
  const history = useHistory();
  const [selectedType, setSelectedType] = useState([]);
  const [transactionDetails, setTransactionDetails] = useState({type: '',});
  const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
  const apiUrl = config[environment].apiUrl;

  useEffect(() => {
    fetchTrialBalance();
  }, [selectedType, selectedFarm]);

  const fetchTrialBalance = async () => {
    try {
      const response = await axios.get(`${apiUrl}/transactions?farm=${selectedFarm}`);
      setTransactionData(response.data);
      
    } catch (error) {
      console.error(error);
    }
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currenttransactionData = transactionData.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePerPageChange = (selectedOption) => {
    setUsersPerPage(selectedOption.value);
    setCurrentPage(1); 
  };

 const options = [
   { value: 5, label: '5' },
   { value: 10, label: '10' },
   { value: 25, label: '25' },
   { value: 50, label: '50' },
   { value: 150, label: '150' },
   { value: 200, label: '200' },
   { value: 500, label: '500' },
 ];

 const totalPages = Math.ceil(transactionData.length / usersPerPage);
 
  const type = [
    { value: '', name: '' },
    { value: 'Payments', name: 'Payments' },
    { value: 'Loans', name: 'Loans' },
    // { value: 'Purchases', name: 'Purchases' },
    { value: 'Accounts Transfer', name: 'Accounts Transfer' },
  ];

  const handleSelectChange = (name, selectedOption,event) => {
    setSelectedType(selectedOption.value);
    setTransactionDetails({ ...transactionDetails, [name]: selectedOption.value });
  };


  const getLabelColorClass = (type) => {
  switch (type) {
    case 'Goats Sales':
      return 'label-orange';
    case 'Income':
      return 'label-blue';
    case '':
      return 'label-till';
    case 'Expense':
      return 'label-brown';
    default:
      return '';
  }
};

const handleView = (transactionId) => {
  const transactionToUpdate = transactionData.find((transaction) => transaction.id === transactionId);
  history.push(`view-transaction/${transactionId}`, { transaction: transactionToUpdate });
};

const handleDelete = async (id, transactionId) => {
  try {
    const confirmed = window.confirm(`Are you sure you want to delete this transaction ${transactionId} ?`);
    if (confirmed) {
      await axios.delete(`${apiUrl}/transaction/${id}`);
      alert('Transaction deleted successfully!');
      window.location.reload();
    }
  } catch (error) {
    alert(error.message);
  }
};

const resetSelectedType = () => {
  setSelectedType(null);
};

  return (
    <React.Fragment>
      <Row>
          <Col sm={12}>
          {success && <Alert variant="success" onClose={() => setSuccess(null)} dismissible >{success}</Alert>}
          {error && <Alert variant="danger" onClose={() => setError(null)} dismissible >{error}</Alert>}
          <ToastContainer />
          </Col>
      </Row>
      <div className='pb-3'>                        
            <Link to="/farms/manage">
                            <Button variant="primary" className="">
                                <span className='feather icon-arrow-left'></span>  Go Back
                            </Button>
                            </Link>
            </div>

      <Row>
        <Col>
          <Card title="Transactions" isOption>
            <Row className='table-accounts'>
            <Table striped bordered hover>
              <thead className="bg-c-blue border-bottom text-uppercase">
                <tr> 
                  <th>Date</th>
                  <th>Transaction ID</th>
                  <th>Paid To</th>
                  <th>Amount</th>
                  <th>Payment Method</th>
                  <th>Type</th>
                  <th colSpan={2}>Action</th>
                </tr>
              </thead>
              <tbody>
                {currenttransactionData.map((transaction) => (
                  <tr key={transaction.id}>
                    <td>{new Date(transaction.date).toLocaleDateString()}</td>
                    <td>{transaction.transactionId}</td>
                    <td>{transaction.coInput}</td>
                    <td>Ksh {(transaction.amount).toLocaleString()}</td>
                    <td>{transaction.method}</td>
                    <td>
                      <span className={getLabelColorClass(transaction.type)}>
                        {transaction.type}
                      </span>
                      </td>
                    <td>
                      <Button onClick={() => handleView(transaction.id)}>
                        <i className="feather icon-eye" /> View
                      </Button>
                      {/* <Button>
                        <i className="feather icon-printer" />
                      </Button> */}
                      <Button variant="danger" onClick={() => handleDelete(transaction.id, transaction.transactionId)}>
                        <i className="feather icon-trash" />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
          <div className="d-flex">
              <Pagination>
                <Pagination.Prev
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                />
                {Array.from({ length: totalPages }).map((_, index) => (
                  <Pagination.Item
                    key={index + 1}
                    active={index + 1 === currentPage}
                    onClick={() => paginate(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage === totalPages}
                />
              </Pagination>
            <Select
              options={options}
              value={{ value: usersPerPage, label: `${usersPerPage}` }}
              onChange={handlePerPageChange}
            />
            
          </div>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default TransactionHistory;
