import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import { Row, Col, Form, Button, Alert, Modal } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../../config';
import { usePermissions } from '../../permissions';

const TransactionHistory = ({resetSelectedType}) => {
  const [error, setError] = useState(null);
  const formRef = useRef(null);
  const [success, setSuccess] = useState(null);
  const [transactionData, setTransactionData] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
  const apiUrl = config[environment].apiUrl;
  const history = useHistory();
  const [accounts, setAccounts] = useState([]);
  const [showModal, setShowModal] = useState(true); // Show modal by default
  const { createPermission, deletePermission, editPermission } = usePermissions();

  const generateTransactionId = () => {
    const timestamp = new Date().getTime();
    const random = Math.floor(Math.random() * 1000);
    return `SF-${random}`;
  };

  const [transactionDetails, setTransactionDetails] = useState({
    bankAccountFrom: '',
    bankAccountTo: '',
    amount: '',
    transactionId: generateTransactionId(),
    type: 'Accounts Transfer',
    bankAccountFrom_balance: '',
    bankAccountTo_balance: '',
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
if(!createPermission){
  return;
}
    try {
      const response = await axios.post(`${apiUrl}/api/account_transfer_transaction`, transactionDetails);
      if (response.data.success) {
        toast.success('Transaction submitted successfully.');
        setSuccess('Transaction submitted successfully.');
        setShowModal(false);
        setTransactionDetails({
          bankAccountFrom: '',
          bankAccountTo: '',
          amount: '',
          transactionId: generateTransactionId(),
          type: 'Accounts Transfer',
          bankAccountFrom_balance: '',
          bankAccountTo_balance: '',
        });
      } else {
        toast.error(response.data.error);
        setError(response.data.error);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setError(error.response.data.error);
        toast.error(error.response.data.error);
      } else {
        toast.error('Error submitting transaction.');
        setError('Error submitting transaction.');
        console.error('Error submitting transaction:', error);
      }
    }
  };

  useEffect(() => {
    fetchAccounts();
  }, []);

  const fetchAccounts = async () => {
    try {
      const response = await axios.get(`${apiUrl}/accounts`);
      setAccounts(response.data);
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTransactionDetails({ ...transactionDetails, [name]: value });
  };

  const handleSelectChange = async (name, selectedOption) => {
    const selectedAccount = selectedOption.value;

    try {
      const response = await axios.get(`${apiUrl}/accounts/${selectedAccount}`);
      const accountDetails = response.data[0];

      const updatedTransactionDetails = { ...transactionDetails };
      updatedTransactionDetails[name] = selectedAccount;
      updatedTransactionDetails[`${name}_balance`] = accountDetails.accountBalance;

      setTransactionDetails(updatedTransactionDetails);
    } catch (error) {
      console.error('Error fetching account details:', error);
    }
  };

  const filterToAccounts = () => {
    return accounts.filter((account) => account.value !== transactionDetails.bankAccountFrom);
  };

  const filterFromAccounts = () => {
    return accounts.filter((account) => account.value !== transactionDetails.bankAccountTo);
  };

  return (
    <React.Fragment>
      <Row>
        <Col sm={12}>
          {success && <Alert variant="success" onClose={() => setSuccess(null)} dismissible>{success}</Alert>}
          {error && <Alert variant="danger" onClose={() => setError(null)} dismissible>{error}</Alert>}
          <ToastContainer />
        </Col>
      </Row>
      
      
      <Modal show={showModal} onHide={() => {
        setShowModal(false); 
        resetSelectedType();
        }} 
        centered
        size='lg'
        >
          <Form onSubmit={handleSubmit} ref={formRef}>
        <Modal.Header closeButton>
          <Modal.Title>Transfer Funds</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
              <Col md={4} sm={6} lg={4}>
                <Form.Group>
                  <Form.Label>From:</Form.Label>
                </Form.Group>
              </Col>
              <Col md={8} sm={6} lg={8}>
                <Form.Group>
                  <Select
                    onChange={(selectedOption) => handleSelectChange('bankAccountFrom', selectedOption)}
                    options={filterFromAccounts().map((account) => ({
                      value: account.description,
                      label: account.description,
                    }))}
                    placeholder="Select account"
                    required
                  />
                  <Form.Text className="text-muted text-uppercase font-weight-bold">Balance: Kshs {(transactionDetails.bankAccountFrom_balance)!== null ? transactionDetails.bankAccountFrom_balance.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''}</Form.Text>
                </Form.Group>
              </Col>

              <Col md={4} sm={6} lg={4}>
                <Form.Group>
                  <Form.Label>To:</Form.Label>
                </Form.Group>
              </Col>
              <Col md={8} sm={6} lg={8}>
                <Form.Group>
                  <Select
                    onChange={(selectedOption) => handleSelectChange('bankAccountTo', selectedOption)}
                    options={filterToAccounts().map((account) => ({
                      value: account.description,
                      label: account.description,
                    }))}
                    placeholder="Select account"
                    required
                  />
                  <Form.Text className="text-muted text-uppercase font-weight-bold">Balance: Ksh {(transactionDetails.bankAccountTo_balance)!== null ? transactionDetails.bankAccountTo_balance.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''}</Form.Text>
                </Form.Group>
              </Col>

              <Col md={4} sm={6} lg={4}>
                <Form.Group>
                  <Form.Label>Amount:</Form.Label>
                </Form.Group>
              </Col>
              <Col md={8} sm={6} lg={8}>
                <Form.Group>
                  <Form.Control
                    type="number"
                    name="amount"
                    onChange={handleInputChange}
                    placeholder="Amount"
                    required
                  />
                </Form.Group>
              </Col>

              <Col md={4} sm={6} lg={4}>
                <Form.Group>
                  <Form.Label>Transaction ID:</Form.Label>
                </Form.Group>
              </Col>
              <Col md={8} sm={6} lg={8}>
                <Form.Group>
                  <Form.Control
                    type="text"
                    name="transactionId"
                    value={transactionDetails.transactionId}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>          
        </Modal.Body>
        <Modal.Footer>
          <>

          {createPermission && (<Button type="submit">
            Transfer
          </Button>)}
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button></>
        </Modal.Footer>
        </Form>
      </Modal>
      
    </React.Fragment>
  );
};

export default TransactionHistory;
