import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Button, Alert, Tabs, Tab } from 'react-bootstrap';
import { useHistory, useParams, Link } from 'react-router-dom';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../../../components/Card/MainCard';
import axios from 'axios';
import ConfirmationModal from '../../ui-elements/basic/ConfirmationModal';
import { usePermissions } from '../../../permissions';
import config from '../../../config';
import WeanersGroup from './BasicCollapse09';
import WeanersHouse from './BasicCollapse10.js';

const SamplePage = () => {
    const account = useSelector((state) => state.account);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const formRef = useRef(null);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [supplierOptions, setSupplierOptions] = useState([]);
    const [selectedSup, setSelectedSup] = useState([]);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const { createPermission, deletePermission, editPermission } = usePermissions();
    // const [numberOfHouses, setNumberOfHouses] = useState(0);
    const [formType, setFormType] = useState('houses'); // "houses" or "groups"
    const [numberOfItems, setNumberOfItems] = useState('');
    const [message, setMessage] = useState('');

    const handleFormTypeChange = (type) => {
        setFormType(type);
    };

    // const handleInputChange = (e) => {
    //     setNumberOfHouses(e.target.value);
    // };

    const handleConfirmation = async () => {
        if (!createPermission) {
            return;
        }
        const endpoint = formType === 'houses' ? 'create-houses' : 'create-groups';
        const payload =
            formType === 'houses'
                ? { numberOfHouses: numberOfItems, userName: account.user.firstname, userId: account.user.id }
                : { numberOfGroups: numberOfItems, userName: account.user.firstname, userId: account.user.id };

        try {
            const response = await axios.post(`${apiUrl}/${endpoint}`, payload);
            setSuccess(response.data.message);
            toast.success(response.data.message);
            setTimeout(() => {
                setSuccess(null);
            }, 10000);
            formRef.current.reset();
            setShowConfirmationModal(false);
            // setNumberOfHouses('');
            setNumberOfItems('');
        } catch (error) {
            if (error.response && error.response.status === 400) {
                setError(`${error.response.data.message}`);
                toast.error(`${error.response.data.message}`);
                setShowConfirmationModal(false);
            } else {
                setError('An error occurred. Please try again later.');
                setShowConfirmationModal(false);
            }
        }
    };

    const handleFeed = async (e) => {
        e.preventDefault();
        setShowConfirmationModal(true);
    };

    const handleCancelConfirmation = () => {
        setShowConfirmationModal(false);
    };

    return (
        <React.Fragment>
            <Row>
                <Col sm={12}>
                    {success && (
                        <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                            {success}
                        </Alert>
                    )}
                    {error && (
                        <Alert variant="danger" onClose={() => setError(null)} dismissible>
                            {error}
                        </Alert>
                    )}
                    <ToastContainer />
                </Col>
            </Row>

            <Row>
                <Col>
                    {/* <Card title="Add Weaners Group or Weaners Houses" isOption> */}
                    <Form onSubmit={handleFeed} ref={formRef}>
                        <Row>
                            <Col md="12" className="mb-2">
                                {' '}
                                {createPermission && (
                                    <div>
                                        <Button onClick={() => handleFormTypeChange('houses')}>Houses</Button>
                                        <Button onClick={() => handleFormTypeChange('groups')}>Groups</Button>
                                    </div>
                                )}
                            </Col>

                            <Col md="12">
                                <Form.Group controlId="formBasicHouse">
                                    <Form.Label>{`Number of ${formType === 'houses' ? 'Houses' : 'Groups'}`}</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        required
                                        // type="text"
                                        // value={numberOfHouses}
                                        type="number"
                                        value={numberOfItems}
                                        onChange={(e) => setNumberOfItems(e.target.value)}
                                        placeholder={`Number of ${formType === 'houses' ? 'Houses' : 'Groups'}`}
                                    />
                                </Form.Group>

                                {createPermission && (
                                    <Form.Group controlId="formBasic">
                                        <Button type="submit"> {formType === 'houses' ? 'Create Houses' : 'Create Groups'}</Button>
                                    </Form.Group>
                                )}
                            </Col>
                        </Row>
                    </Form>
                    {/* </Card> */}
                </Col>
            </Row>
            <Tabs defaultActiveKey="feeds">
                <Tab eventKey="feeds" title="Groups" className="mt-4">
                    <WeanersGroup />
                </Tab>
                <Tab eventKey="food" title="Houses" className="mt-4">
                    <WeanersHouse />
                </Tab>
            </Tabs>
            <ConfirmationModal show={showConfirmationModal} onHide={handleCancelConfirmation} onConfirm={handleConfirmation} />
        </React.Fragment>
    );
};

export default SamplePage;
