import React, {useState, useEffect, useRef} from 'react';
import { Row, Col, Form, Table, Button, Alert } from 'react-bootstrap';
import config from '../../../config';
import axios from 'axios';
import Select from 'react-select';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import Card from '../../../components/Card/MainCard';
import { Link, useParams } from 'react-router-dom';

const SamplePage = () => {
  const { selectedFarm } = useParams();
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [totalDebits, setTotalDebits] = useState(0);
    const [totalCredits, setTotalCredits] = useState(0);
    const [trialBalance, setTrialBalance] = useState([]);  
    const tableRef = useRef(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [ filteredData, setFilteredData ] = useState([]);
    
    const csvLinkRef = useRef();

    const handleExportCSV = () => {
      const csvData = [
        ['Category', 'Debit', 'Credit'],
        ...trialBalance.map((item) => [item.category, item.expenses, item.sales]),
        ['Total', totalDebits, totalCredits],
      ];
  
      const csvContent = csvData.map((row) => row.join(',')).join('\n');
      const blob = new Blob(['\ufeff', csvContent], { type: 'text/csv;charset=utf-8;' });
  
      const csvUrl = URL.createObjectURL(blob);
      csvLinkRef.current.href = csvUrl;
      csvLinkRef.current.download = 'trial_balance.csv';
      csvLinkRef.current.click();
    };   

      const handleExportPDF = () => {
        const doc = new jsPDF();
        doc.autoTable({
          head: [['Category', 'Debit', 'Credit']],
          body: trialBalance.map((user) => [
            user.category.toLocaleString(),
            user.expenses.toLocaleString(),
            user.sales.toLocaleString(),           
          ]),
        });
        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl);
      };
      
    useEffect(() => {
        fetchTrialBalance();
      }, [selectedFarm]);
    
      const fetchTrialBalance = async () => {
        try {
          const response = await axios.get(`${apiUrl}/api/trial-balance?farm=${selectedFarm}`);
          setTrialBalance(response.data);
          calculateTotalDebitsAndCredits(response.data);
        } catch (error) {
          console.error(error);
        }
      };
    
      const calculateTotalDebitsAndCredits = (data) => {
        let debitsTotal = 0;
        let creditsTotal = 0;
    
        data.forEach((item) => {
          debitsTotal += item.expenses;
          creditsTotal += item.sales;
        });
    
        setTotalDebits(debitsTotal);
        setTotalCredits(creditsTotal);
      };

      const handleFilter = () => {
        const startDateObj = new Date(startDate);
        const endDateObj = new Date(endDate);
    
        const filteredData = trialBalance.filter((item) => {
          const itemDate = new Date(item.date);
          return itemDate >= startDateObj && itemDate <= endDateObj;
        });
    
        setFilteredData(filteredData);
      };

    return (
        <React.Fragment>
            <Row>
                <Col sm={12}>
                {success && <Alert variant="success" onClose={() => setSuccess(null)} dismissible >{success}</Alert>}
                {error && <Alert variant="danger" onClose={() => setError(null)} dismissible >{error}</Alert>}
                </Col>
            </Row>
            {/* <Row>
                <Col>
                <Card title="Filter" isOption>
                        <Row>
                            <Form.Group as={Col} md={4} sm={12} lg={4} controlId="formBasic">
                              <Form.Label>FROM:</Form.Label>
                              <Form.Control
                                  type="date" 
                                  value={startDate} 
                                  onChange={(e) => setStartDate(e.target.value)}
                              />
                            </Form.Group>
                            
                            <Form.Group as={Col} md={4} sm={12} lg={4}> 
                              <Form.Label>TO:</Form.Label>
                              <Form.Control
                                  type="date" 
                                  value={endDate} 
                                  onChange={(e) => setEndDate(e.target.value)}
                              />
                            </Form.Group>
                            <Form.Group as={Col} md={12} sm={12} lg={12}>
                            <Button onClick={handleFilter}>Submit</Button>
                            </Form.Group>
                        </Row>
                      
                    </Card>
                </Col>
            </Row> */}
            <Row>                    
                    <Col >
                    <div className='pb-3'>                        
            <Link to="/farms/manage">
                            <Button variant="primary" className="">
                                <span className='feather icon-arrow-left'></span>  Go Back
                            </Button>
                            </Link>
            </div>
                        <Card title="Monthly Trial & balance" isOption>
                            <Row className='table-accounts'>
                            <Button onClick={handleExportCSV}>Export as CSV</Button>
                                <a ref={csvLinkRef} style={{ display: 'none' }} />

                                {/* <button onClick={handleExportExcel}>Export as Excel</button> */}
                                <Button onClick={handleExportPDF}>Export as PDF</Button>

                                <Table id="table-to-export table-to-xls" striped bordered hover ref={tableRef}>
                                    <thead className="bg-c-blue border-bottom text-uppercase">
                                    <tr>
                                        <th>Category</th> 
                                        <th>Debit</th>
                                        <th>Credit</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {trialBalance.map((item, index) => (
                                            <tr key={index}>
                                            <td>{item.category}</td>
                                            <td>{item.expenses.toLocaleString()}</td>
                                            <td>{item.sales.toLocaleString()}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot>
                                        <tr className="border-bottom text-uppercase font-weight-bold">
                                        <td>Total</td>
                                        <td><span style={{ backgroundColor: '#b30000', color: 'white', padding: '5px' }}>Ksh. {totalDebits.toLocaleString()}</span></td>
                                        <td><span style={{ backgroundColor: 'green', color: 'white', padding: '5px' }}>Ksh. {totalCredits.toLocaleString()}</span></td>
                                        </tr>
                                    </tfoot>
                                </Table>
                            </Row>
                        </Card>
                    </Col>
            </Row>
        </React.Fragment>
    );
};

export default SamplePage;
