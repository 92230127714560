import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Button, Alert, Tabs, Tab } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { usePermissions } from '../../../permissions';
import config from '../../../config';
import DataTable from 'react-data-table-component';

const SamplePage = () => {
    const account = useSelector((state) => state.account);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const { createPermission, deletePermission, editPermission } = usePermissions();
    const [searchText, setSearchText] = useState('');
    const [houseMoveHistory, setHouseMoveHistory] = useState([]);

    useEffect(() => {
        axios
            .get(`${apiUrl}/house-move-history`)
            .then((response) => {
                setHouseMoveHistory(response.data);
            })
            .catch((error) => {
                console.error('Error fetching house move history:', error);
            });
    }, [apiUrl]);

    const columns = [
        {
            name: 'Timestamp',
            selector: (row) => new Date(row?.timestamp).toLocaleString(),
            // selector: (row) => new Date(row.recorded).toDateString(),
            sortable: true,
            wrap: true
        },
        {
            name: 'Recorded At',
            selector: (row) => new Date(row.recorded).toDateString(),
            sortable: true,
            wrap: true
        },
        {
            name: 'from House',
            selector: (row) => row.fromHouse,
            sortable: true
        },
        {
            name: 'to House',
            selector: (row) => row.toHouse,
            sortable: true,
            wrap: true
        },
        {
            name: 'Weaners Group',
            selector: (row) => row.weanersGroup,
            sortable: true,
            wrap: true
        }
    ];

    const filteredData = searchText
        ? houseMoveHistory.filter((item) =>
              Object.values(item).some((value) => typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase()))
          )
        : houseMoveHistory;

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Row>
                        <Form.Group as={Col} md={1} sm={12} lg={1} controlId="formBasicName">
                            <Form.Label>Search</Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} md={3} sm={12} lg={3} controlId="formBasicName">
                            <Form.Control
                                type="text"
                                placeholder="Search..."
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                        </Form.Group>
                    </Row>
                    <DataTable
                        columns={columns}
                        data={filteredData}
                        pagination
                        highlightOnHover
                        striped
                        // noHeader
                        dense
                        bordered
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                        paginationComponentOptions={{
                            rowsPerPageText: 'Rows per page:'
                        }}
                        paginationResetDefaultPage={true}
                        paginationTotalRows={houseMoveHistory.length}
                        // expandableRows
                        selectableRows
                        onSelectedRowsChange={(selectedRows) => console.log(selectedRows)}
                        customStyles={{
                            headRow: {
                                style: {
                                    backgroundColor: '#279e6c', // Green background
                                    color: '#fff', // White text
                                    textTransform: 'uppercase', // Uppercase text
                                    // fontSize: '10px', // Adjust font size for header row
                                    minHeight: '1.5rem',
                                    paddingTop: '8px',
                                    paddingBottom: '8px'
                                }
                            }
                        }}
                    />
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default SamplePage;
