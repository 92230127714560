import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Row, Col, Form, Button, Alert } from 'react-bootstrap';
import config from './../../../config';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Card from '../../../components/Card/MainCard';

const UpdateFarm = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [farm, setFarm] = useState({});
  const { id } = useParams();
  const history = useHistory();
  const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
  const apiUrl = config[environment].apiUrl;

  useEffect(() => {
    const fetchFarm = async () => {
      try {
        const response = await axios.get(`${apiUrl}/farm/${id}`);
        setFarm(response.data[0]);
      } catch (error) {
        console.log(error);
      }
    };
    fetchFarm();
  }, [id]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFarm((prevFarm) => ({
      ...prevFarm,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`${apiUrl}/farm/${id}`, farm);
      
      if (response.data.success) {
        setSuccess(response.data.message || "Farms updated successfully!");
        setTimeout(() => {
          setSuccess(null);
          history.push('/farms')
        }, 10000);
        toast.success(response.data.message || "Farm updated successfully!");
      } else {
        setError(response.data.error || 'An error occurred while updating the farm.');
        console.error('Error updating farm:', response.data.error);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        // If there are multiple errors in the response
        const errorMessages = Object.values(error.response.data.errors).flat();
        setError(errorMessages.join(' '));
      } else {
        // If there is a single error or no specific error message
        setError(error.response?.data?.error || 'An error occurred while updating the farm.');
      }
      console.error('Error updating farm:', error);
    }
  };
  

  return (
    <React.Fragment>
      <Row>
        <Col sm={12}>
          {success && (
            <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
              {success}
            </Alert>
          )}
          {error && (
            <Alert variant="danger" onClose={() => setError(null)} dismissible>
              {error}
            </Alert>
          )}
          <ToastContainer />
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <Link to="/farms">
            <Button variant="primary" className="">
              <span className='feather icon-arrow-left'></span>  Go Back
            </Button>
          </Link>
          <Card title="Update Farm" isOption>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col sm={12} md={6}>
                  <div className="p-2">
                    <Form.Label>Farm Name:</Form.Label>
                    <Form.Control type="text" name="name" value={farm.name || ''} onChange={handleInput} />
                  </div>
                </Col>
                
                <Col sm={12}>
                  <div className="p-2">
                    <Button type="submit">Update</Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UpdateFarm;
