import React, {useEffect, useState} from 'react';
import { useParams, useNavigate, Link } from "react-router-dom";
import { Row, Col, Card, ListGroup, Table, Button } from 'react-bootstrap';
import ModuleNotification from '../../../components/Widgets/Statistic/Notification';
import config from '../../../config';

// import avatar1 from '../../../../assets/images/user/avatar-1.jpg';
import avatar2 from './../../../assets/images/user/avatar-2.jpg';
// import avatar3 from '../../../../assets/images/user/avatar-3.jpg';
// import avatar4 from '../../../../assets/images/user/avatar-4.jpg';


import axios from 'axios';

const DetailsUser = () => {
    const { id } = useParams();
    const [userData, setUserData] = useState({});
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;

    useEffect(() => {
        const getUser = async () => {
          try {
            const requestDoc = await axios.get(`${apiUrl}/admin/${id}`);
              setUserData(requestDoc.data[0]);
            //   console.log(requestDoc.data);
          } catch (error) {
            console.log('Error getting document:', error);
          }
        };
        getUser();
      }, [id]);

      const calculateAge = (birthDate) => {
        const today = new Date();
        const birth = new Date(birthDate);
      
        // Get the UTC values for year, month, and day
        const todayUTC = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
        const birthUTC = Date.UTC(birth.getFullYear(), birth.getMonth(), birth.getDate());
      
        // Calculate the age difference in milliseconds
        const ageDiffMillis = todayUTC - birthUTC;
      
        // Convert milliseconds to years, months, and days
        const yearDiff = Math.floor(ageDiffMillis / (1000 * 60 * 60 * 24 * 365));
        const monthDiff = Math.floor((ageDiffMillis % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30));
        const dayDiff = Math.floor((ageDiffMillis % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24));
      
        if (yearDiff > 0) {
          return yearDiff === 1 ? `${yearDiff} year ago` : `${yearDiff} years ago`;
        } else if (monthDiff > 0) {
          return monthDiff === 1 ? `${monthDiff} month ago` : `${monthDiff} months ago`;
        } else {
          return dayDiff === 1 ? `${dayDiff} day ago` : `${dayDiff} days ago`;
        }
      };
    

      if (!userData) {
        return <div>Loading...</div>;
      }

      const {
        firstname,
        lastname,
        email,
        id_number,
        phone_number,
        mpesa_number,
        nssf,
        nhif,
        county,
        address,
        salary,
        leave,
        payment_rate,
        emp_status,
        is_admin,
        is_active,
        nok_firstname,
        nok_lastname,
        nok_email,
        nok_phone_number,
        nok_address,
        createdAt,
        farm,
        updatedAt
      } = userData;

    return (
        <React.Fragment>
            
            <Row>
                <Col md={6} lg={4}>
                    <Card >
                        <Link to="/admins">
                            <Button variant="primary" className="mb-3 mt-2">
                            <span className='feather icon-arrow-left'></span>  Go Back
                            </Button>
                        </Link>
                        <Card.Header>
                            <Card.Title as="h5">User Profile</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <div className="pro-head d-flex align-items-center justify-content-between border-bottom">
                                <img src={avatar2} className="img-radius" alt="User Profile" />
                                <span>{ firstname} {lastname}</span>
                                <Link to="#" className="dud-logout ml-5" title="Logout">
                                    <span className="feather icon-log-out"></span> {is_active}
                                </Link>
                            </div>
                        <Card.Text as="h6">
                           
                        </Card.Text>
                        {/* <ListGroup className="list-group-flush border-bottom">
                            <div className="row d-flex align-items-center m-t-5 m-b-5">
                                <div className="col-6">
                                    <p className='m-1'>PHONE NUMBER:</p>
                                    <p className='m-1'>ID NUMBER:</p>
                                </div>

                                <div className="col-6 text-right">
                                    <p className='m-1'>{phone_number}</p>
                                    <p className='m-1'>{id_number}</p>
                                </div>
                            </div>
                        </ListGroup> */}
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={6} lg={8}>
                    <Card>
                        <Card.Header>
                            <Card.Title as="h5">User Details</Card.Title>
                        </Card.Header>
                        <Card.Body>
                        <Table responsive hover striped>
                            <tbody>
                                <tr>
                                    <td>Phone Number:</td>
                                    <td>{phone_number}</td>
                                 </tr>   
                                <tr>
                                    <td>ID Number:</td>
                                    <td>{id_number}</td>
                                 </tr>   
                                <tr>
                                    <td>Email:</td>
                                    <td>{email}</td>
                                 </tr>   
                                <tr>
                                    <td>Address:</td>
                                    <td>{address}</td>
                                 </tr>   
                                <tr>
                                    <td>Role:</td>
                                    <td>{is_admin}</td>
                                 </tr>    
                                <tr>
                                    <td>Created:</td>
                                    <td>{new Date(createdAt).toLocaleString()}</td>
                                 </tr>  
                                 <tr>
                                        <td>Updated:</td>
                                        <td>{new Date(updatedAt).toDateString()}</td>
                                    </tr>  
                            </tbody>
                        </Table>
                        </Card.Body>
                    </Card>
                   
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default DetailsUser ;
