import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, Form, Button, Alert, Table, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModuleNotification from '../../../components/Widgets/Statistic/Notification';
import config from '../../../config';
import axios from 'axios';
import Calendar from './Calendar';
import EventForm from './EventForm';
import { usePermissions } from '../../../permissions';

const BasicBreadcrumbPagination = () => {
    const account = useSelector((state) => state.account);
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const formRef = useRef(null);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const { createPermission, deletePermission, editPermission } = usePermissions();
    const [showEventForm, setShowEventForm] = useState(false);
    const userId = account.user.id;

    // useEffect(() => {
    //     axios
    //         .get(`${apiUrl}/events`)
    //         .then((response) => {
    //             setEvents(response.data);
    //             // console.log(data);
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //         });
    // }, [events, apiUrl]);

    //     useEffect(() => {
    //         const fetchEvents = async () => {
    //           try {
    //             let url = `${apiUrl}/events`;

    //             // Construct query parameters based on userId and event type
    //             const params = {
    //               userId: userId
    //             };

    //             // Append query parameters to the URL
    //             url += `?${new URLSearchParams(params).toString()}`;

    //             // const response = await axios.get(url);
    //             const response = await axios.get(url);
    // console.log('API Response:', response.data); // Log API response for debugging

    //             const filteredEvents = response.data.filter((event) => {
    //               if (event.eventType === 'event' && event.alerts === true) {
    //                 return true; // Include events of type 'event' with alerts
    //               } else if (event.eventType === 'task' && event.employeeSelectOption) {
    //                 // Check if the event's employeeSelectOption contains userId
    //                 return event.employeeSelectOption.includes(userId);
    //               }
    //               return false;
    //             });

    //             setEvents(filteredEvents);
    //             console.log(filteredEvents)
    //           } catch (error) {
    //             console.error('Error fetching events:', error);
    //           }
    //         };

    //         fetchEvents();
    //     }, [userId, apiUrl]);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await axios.get(apiUrl + '/events');
                console.log('response Events:', response.data);
                const filteredEvents = response.data.filter((event) => {
                    console.log('Processing event:', event);
                    if (event.eventType === 'event' && event.alerts === 1) {
                        console.log('Included: eventType is event and alerts are true');
                        return true;
                    } else if (event.eventType === 'task' && event.employeeSelectOption && event.employeeSelectOption.includes(userId)) {
                        console.log('Included: eventType is task and userId is in employeeSelectOption');
                        return true;
                    } else if (event.userId === userId) {
                        console.log('Included: eventType is task and userId is in employeeSelectOption');
                        return true;
                    }
                    console.log('Excluded event:', event);
                    return false;
                });

                console.log('Filtered Events:', filteredEvents);

                // Set the filtered events in state
                setEvents(filteredEvents);
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };

        fetchEvents(); // Fetch events when component mounts

        // Specify dependencies in the array to re-run effect on changes
    }, [userId, apiUrl]);

    const addOrEditEvent = async (event) => {
        try {
            if (event.id) {
                if (!editPermission) {
                    return;
                }

                await axios.put(`${apiUrl}/events/${event.id}`, event);
            } else {
                if (!createPermission) {
                    return;
                }
                await axios.post(`${apiUrl}/events/new`, event);
            }
            const updatedEvents = [...events];
            const existingEventIndex = events.findIndex((e) => e.id === event.id);
            if (existingEventIndex !== -1) {
                updatedEvents[existingEventIndex] = event;
            } else {
                updatedEvents.push(event);
            }

            setSuccess('Event added/edited successfully');
            setTimeout(() => {
                setSuccess(null);
            }, 10000);
            toast.success('Event added/edited successfully');
            setShowEventForm(false);
        } catch (error) {
            setError(error.response.data.message);
            toast.error('Failed');
            setTimeout(() => {
                setError(null);
            }, 10000);
        }
    };

    const deleteEvent = async (eventId) => {
        try {
            const confirmed = window.confirm(`Are you sure you want to delete this event ${eventId}?`);
            if (confirmed) {
                await axios.delete(`${apiUrl}/events/${eventId}`);
                const updatedEvents = events.filter((event) => event.id !== eventId);
                setEvents(updatedEvents);
                setSuccess('Event deleted successfully');
                setTimeout(() => {
                    setSuccess(null);
                }, 10000);
                toast.success('Event deleted successfully');
                setShowEventForm(false);
            }
        } catch (error) {
            setError(error.message);
        }
    };

    const handleShowEventForm = () => {
        setShowEventForm(true);
    };

    const handleCloseEventForm = () => {
        setShowEventForm(false);
        setSelectedEvent(null);
    };

    const handleEventClick = (item) => {
        console.log('Before setting selectedEvent:', selectedEvent);
        setSelectedEvent(item);
        console.log('After setting selectedEvent:', selectedEvent);
        setShowEventForm(true);
    };

    const currentDate = new Date();

    return (
        <React.Fragment>
            <Row>
                <Col sm={12}>
                    {success && (
                        <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                            {success}
                        </Alert>
                    )}
                    {error && (
                        <Alert variant="danger" onClose={() => setError(null)} dismissible>
                            {error}
                        </Alert>
                    )}
                    <ToastContainer />
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <Col sm={12} lg={12} className="mb-2">
                        {createPermission && (
                            <Button disabled={!createPermission} variant="primary" onClick={() => setShowEventForm(true)}>
                                <span className="feather icon-plus"></span> Add Event
                            </Button>
                        )}
                        {showEventForm && (
                            <EventForm
                                event={selectedEvent}
                                onAddOrEditEvent={addOrEditEvent}
                                onDeleteEvent={deleteEvent}
                                onClose={handleCloseEventForm}
                            />
                        )}
                    </Col>
                    <Card>
                        <Card.Header>
                            <Card.Title as="h5">Calendar</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Calendar events={events} onEventClick={handleEventClick} />
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>

                {/* <Col sm={6}>
                    <Card>
                        <Card.Header>
                            <Card.Title as="h5">Recent Events</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col sm={12} lg={12} className="mb-2">
                                    <Button disabled={!createPermission} variant="primary" onClick={() => setShowEventForm(true)}>
                                        <span className="feather icon-plus"></span> Add Event
                                    </Button>
                                    {showEventForm && (
                                        <EventForm
                                            event={selectedEvent}
                                            onAddOrEditEvent={addOrEditEvent}
                                            onDeleteEvent={deleteEvent}
                                            onClose={handleCloseEventForm}
                                        />
                                    )}
                                </Col>

                                {events &&
                                    events.slice(0, 10).map((item) => {
                                        const fromDate = new Date(item.fromDate); // Event start date and time
                                        const toDate = new Date(item.toDate); // Event end date and time

                                        const isUpcoming = fromDate > currentDate;
                                        const isOngoing = fromDate <= currentDate && toDate >= currentDate;
                                        const isEnded = toDate < currentDate;

                                        const statusColor = isUpcoming ? 'primary' : isOngoing ? 'success' : 'secondary';
                                        const statusText = isUpcoming ? 'Upcoming' : isOngoing ? 'Ongoing' : 'Ended';

                                        return (
                                            <React.Fragment key={item.id}>
                                                <div className="col-12 border-top d-flex align-items-center">
                                                    <div className="col-4">
                                                        <p className="m-1 font-weight-bold">
                                                            {item.title}
                                                            <sup>
                                                                <Badge variant={item.eventType === 'task' ? 'warning' : 'primary'}>
                                                                    {item.eventType}
                                                                </Badge>
                                                            </sup>
                                                        </p>
                                                        <p className="m-1 pr description">{item.description}</p>
                                                    </div>
                                                    <div className="col-3 text-center">
                                                        <p className="m-1 pr">
                                                            {new Date(item.fromDate).toLocaleDateString('en-US', {
                                                                weekday: 'short',
                                                                year: 'numeric',
                                                                month: 'short',
                                                                day: 'numeric'
                                                            })}
                                                        </p>
                                                        <p className="m-1 pr">
                                                            {new Date(item.toDate).toLocaleDateString('en-US', {
                                                                weekday: 'short',
                                                                year: 'numeric',
                                                                month: 'short',
                                                                day: 'numeric'
                                                            })}
                                                        </p>
                                                    </div>
                                                    <div className="col-2 text-center">
                                                        <p className="m-1 font-weight-bold">
                                                            <Badge variant={`${statusColor}`}>{statusText}</Badge>
                                                        </p>
                                                    </div>
                                                    <div className="col-3 text-right">
                                                        {editPermission && (
                                                            <Button
                                                                disabled={!editPermission}
                                                                spanvariant="success"
                                                                onClick={() => handleEventClick(item)}
                                                            >
                                                                <span className="feather icon-edit"></span>
                                                            </Button>
                                                        )}
                                                        {deletePermission && (
                                                            <Button
                                                                disabled={!deletePermission}
                                                                variant="danger"
                                                                onClick={() => deleteEvent(item.id)}
                                                            >
                                                                <span className="feather icon-trash"></span>
                                                            </Button>
                                                        )}
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        );
                                    })}
                            </Row>
                        </Card.Body>
                    </Card>
                </Col> */}
            </Row>
        </React.Fragment>
    );
};

export default BasicBreadcrumbPagination;
