import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, Form, Button, Alert, Container, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.css';
import ModuleNotification from '../../../components/Widgets/Statistic/Notification';
import config from '../../../config';
import axios from 'axios';
import { usePermissions } from '../../../permissions';

const EventForm = ({ event, onAddOrEditEvent, onDeleteEvent, onClose }) => {
    const account = useSelector((state) => state.account);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [employees, setEmployees] = useState([]);
    const [formData, setFormData] = useState({
        // id: '',
        title: '',
        description: '',
        toDate: '',
        fromDate: '',
        eventType: 'event',
        employeeSelectOption: null,
        userId: account.user.id,
        userName: `${account.user.firstname} ${account.user.lastname}`,
        alerts: false
    });
    const { createPermission, deletePermission, editPermission } = usePermissions();

    const formatDatetimeForInput = (datetime) => {
        if (!datetime) return ''; // Return empty string if datetime is null or undefined

        const formattedDatetime = new Date(datetime).toISOString().slice(0, 16);
        return formattedDatetime;
    };

    // Set form data based on the event prop when it changes
    useEffect(() => {
        if (event) {
            const formattedFromDate = event.start
                ? formatDatetimeForInput(event.start)
                : event.fromDate
                ? formatDatetimeForInput(event.fromDate)
                : null;

            const formattedToDate = event.end
                ? formatDatetimeForInput(event.end)
                : event.toDate
                ? formatDatetimeForInput(event.toDate)
                : null;

            setFormData({
                id: event.id || '',
                title: event.title || '',
                description: event.description || '',
                fromDate: formattedFromDate,
                toDate: formattedToDate,
                eventType: event.eventType || 'event',
                employeeSelectOption: event.employeeSelectOption || null,
                userId: account.user.id,
                userName: account.user.firstname,
                alerts: event.alerts
            });
        }
    }, [event]);

    const handleChange = (name, value) => {
        if (formData.eventType === 'event') {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
                userName: account.user.firstname,
                userId: account.user.id,
                employeeSelectOption: null // Set employeeSelectOption to null
            }));
        } else {
            // eventType is not 'event', update other fields normally
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
                userName: account.user.firstname,
                userId: account.user.id
            }));
        }
    };

    const handleEmployeeSelectChange = (fieldName, value) => {
        if (fieldName === 'employeeSelectOption') {
            setSelectedEmployee(value);
            const employeeSelectOption = value ? value.map((option) => option.value) : [];
            setFormData((prevData) => ({ ...prevData, [fieldName]: value, employeeSelectOption }));
        } else {
            setFormData((prevData) => ({ ...prevData, [fieldName]: value }));
        }
        console.log(formData);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onAddOrEditEvent({
            id: event ? event.id : null,
            title: formData.title,
            description: formData.description,
            fromDate: formData.fromDate,
            toDate: formData.toDate,
            eventType: formData.eventType,
            employeeSelectOption: formData.employeeSelectOption,
            userId: account.user.id,
            userName: account.user.firstname,
            alerts: formData.alerts || false
        });
        setFormData({
            title: '',
            description: '',
            toDate: '',
            fromDate: '',
            eventType: 'event',
            employeeSelectOption: null,
            userId: account.user.id,
            userName: account.user.firstname,
            alerts: null
        });
    };

    useEffect(() => {
        console.log(formData);
        console.log(event);
    }, [formData.eventType, formData.employeeSelectOption, formData.description, formData.alerts]);

    const handleDelete = (eventId) => {
        if (event) {
            onDeleteEvent(event.id);
        }
        onClose();
        setFormData({
            title: '',
            description: '',
            date: '',
            eventType: 'event',
            employeeSelectOption: null
        });
    };

    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const response = await axios.get(`${apiUrl}/users`);
                const data = response.data;
                const options = data.map((employee) => ({
                    value: employee.id,
                    label: `${employee.firstname} ${employee.lastname}`
                }));
                setEmployees(options);
                console.log(employees);
            } catch (error) {
                console.error('Error fetching employees:', error);
            }
        };

        fetchEmployees();
    }, []);

    // Utility function to format date for input type 'datetime-local'
    const formatDateForInput = (dateTimeString) => {
        // Ensure the input string is in ISO 8601 format
        const isoDateTimeString = new Date(dateTimeString).toISOString();

        // Extract date and time parts and combine them for input value
        const datePart = isoDateTimeString.split('T')[0]; // Date part (YYYY-MM-DD)
        const timePart = isoDateTimeString.split('T')[1].slice(0, 5); // Time part (HH:mm)

        return `${datePart}T${timePart}`; // Combined date and time string for input
    };

    return (
        <Modal show={true} onHide={onClose} centered size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>
                    {event ? 'Edit' : 'Add New'} {formData.eventType === 'task' ? 'Task' : 'Event'}
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit}>
                <Modal.Body>
                    <Container className="event-form">
                        <Form.Group controlId="title">
                            <Form.Label>Title:</Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                value={formData.title}
                                onChange={(e) => handleChange('title', e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="description">
                            <Form.Label>Description:</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="description"
                                value={formData.description}
                                onChange={(e) => handleChange('description', e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="fromDate">
                            <Form.Label>From Date:</Form.Label>
                            <Form.Control
                                type="datetime-local"
                                name="fromDate"
                                value={formData.fromDate}
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="toDate">
                            <Form.Label>To Date:</Form.Label>
                            <Form.Control
                                type="datetime-local"
                                name="toDate"
                                value={formData.toDate}
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="eventType">
                            <Form.Label>Type:</Form.Label>
                            <Form.Control
                                as="select"
                                name="eventType"
                                value={formData.eventType}
                                onChange={(e) => handleChange('eventType', e.target.value)}
                            >
                                <option value="event">Event</option>
                                <option value="task">Task</option>
                            </Form.Control>
                        </Form.Group>

                        {formData.eventType !== 'task' && (
                            <Form.Group controlId="alerts">
                                <Form.Check
                                    type="checkbox"
                                    label="Alert All"
                                    name="alerts"
                                    value={formData.alerts}
                                    checked={formData.alerts}
                                    onChange={(e) => handleChange('alerts', e.target.checked)}
                                />
                            </Form.Group>
                        )}

                        {formData.eventType === 'task' && (
                            <Form.Group controlId="employeeSelectOption">
                                <Form.Label>Task Employee(s):</Form.Label>
                                <Select
                                    name="employeeSelectOption"
                                    value={formData.employeeSelectOption ? { value: formData.employeeSelectOption.value, label: formData.employeeSelectOption || formData.employeeSelectOption.selectedOption} : null}
                                    onChange={(selectedOption) => handleEmployeeSelectChange('employeeSelectOption', selectedOption)}
                                    isMulti
                                    isClearable
                                    isSearchable
                                    options={employees}
                                />
                            </Form.Group>
                        )}
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" disabled={!createPermission || !editPermission}>
                        {event ? 'Save Changes' : 'Create'} {formData.eventType === 'task' ? 'Task' : 'Event'}
                    </Button>
                    {event && deletePermission && (
                        <Button
                            disabled={!deletePermission}
                            variant="danger"
                            type="button"
                            onClick={handleDelete}
                            className="delete-button"
                        >
                            Delete {formData.eventType === 'task' ? 'Task' : 'Event'}
                        </Button>
                    )}
                    <Button variant="primary" onClick={onClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default EventForm;
