import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import { Form, Button, Container, Col, Row, Alert, Modal } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import config from './../../../config';

const SalaryCalculator = ({onCancel}) => {
  const [selectedUser, setSelectedUser] = useState('');
  const [salaryAmount, setSalaryAmount] = useState('');
  const [nhifDeduction, setNhifDeduction] = useState('');
  const [nssfDeduction, setNssfDeduction] = useState('');
  const [otherDeduction, setOtherDeduction] = useState(0);
  const [payableSalary, setPayableSalary] = useState('');
  const [userOptions, setUserOptions] = useState([]);
  const [employeesFarm, setEmployeesFarm] = useState([]);
  const [calculatedUsers, setCalculatedUsers] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const formRef = useRef(null);
  const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
  const apiUrl = config[environment].apiUrl;

  useEffect(() => {
    axios.get(`${apiUrl}/users`)
      .then((response) => {
        const usersData = response.data;
        const formattedUsers = usersData.map((user) => ({
          value: `${user.firstname} ${user.lastname}`,
          label: `${user.firstname} ${user.lastname}`,
        }));
        setUserOptions(formattedUsers);

        // const farm = usersData.map((employee) => ({
        //   value: employee.farm, 
        //   label: employee.farm, 
        // }));
        // setEmployeesFarm(farm);
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
      });

    axios.get(`${apiUrl}/employees_salary`)
      .then((response) => {
        const calculatedUsersData = response.data;
        setCalculatedUsers(calculatedUsersData);
      })
      .catch((error) => {
        console.error('Error fetching calculated users data:', error);
      });
  }, []);

  // Function to calculate payable salary whenever any input field changes
  const calculatePayableSalary = () => {
    const totalNhfifDeduction = parseFloat(nhifDeduction) || 0;
    const totalNssfDeduction = parseFloat(nssfDeduction) || 0;
    const totalOtherDeduction = parseFloat(otherDeduction) || 0;
    const totalDeductions = totalNhfifDeduction + totalNssfDeduction + totalOtherDeduction;
    const salary = parseFloat(salaryAmount);
    const payable = salary - totalDeductions;
    setPayableSalary(payable.toFixed(2));
  };

  // Add an onChange handler for NHIF Deductions
  const handleNhifDeductionChange = (e) => {
    setNhifDeduction(e.target.value);
    calculatePayableSalary();
  };

  // Add an onChange handler for NSSF Deductions
  const handleNssfDeductionChange = (e) => {
    setNssfDeduction(e.target.value);
    calculatePayableSalary();
  };

  // Add an onChange handler for Other Deductions
  const handleOtherDeductionChange = (e) => {
    setOtherDeduction(e.target.value);
    calculatePayableSalary();
  };

  const handleAmountChange = (e) => {
    setSalaryAmount(e.target.value);
    calculatePayableSalary();
  };

  const handleUserChange = (selectedOption) => {
    setSelectedUser(selectedOption);
    setError('');
    setPayableSalary('');
    calculatePayableSalary();
  };

  useEffect(() => {
    calculatePayableSalary();
    // handleUserChange();
    // // handleAmountChange();
    // handleOtherDeductionChange();
    // handleNssfDeductionChange()
    // handleNhifDeductionChange()
  }, [apiUrl,selectedUser,salaryAmount,nhifDeduction,nssfDeduction,otherDeduction,payableSalary,userOptions,calculatedUsers])

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!selectedUser) {
      setError('Please select a user.');
      return;
    }

    const isUserCalculated = calculatedUsers.some((user) => user.employee === selectedUser.value);

    if (isUserCalculated) {
      setError('Salary for this user has already been calculated.');
      toast.error('Salary for this user has already been calculated.');
      return;
    }

    const requestData = {
      employee: selectedUser.value,
      salaryAmount: parseFloat(salaryAmount),
      nhifDeduction: parseFloat(nhifDeduction),
      nssfDeduction: parseFloat(nssfDeduction),
      otherDeduction: parseFloat(otherDeduction),
      payableSalary: parseFloat(payableSalary),
      payment_status: 'Pending',
      // farm: employeesFarm,
    };

    axios.post(`${apiUrl}/salary_calculations`, requestData)
      .then((response) => {
        const calculatedSalary = response.data;
        setSuccess(response.data.message)
        setTimeout(() => {
          setSuccess(null);
        }, 10000);
        formRef.current.reset();
        setPayableSalary('')
        setOtherDeduction('')
        setNssfDeduction('')
        setNhifDeduction('')
        setSalaryAmount('')
        setSelectedUser('')
        onCancel();
        toast.success(response.data.message);
        axios.get(`${apiUrl}/employees_salary`)
          .then((response) => {
            const calculatedUsersData = response.data;
            setCalculatedUsers(calculatedUsersData);
            console.log(calculatedUsers)
          })
          .catch((error) => {
            console.error('Error fetching calculated users data:', error);
            setError('Error fetching calculated users data:', error);
          });
      })
      .catch((error) => {
        console.error('Error calculating salary:', error);
        setError('Error calculating salary:', error);
      });
  };

  const usersToDisplay = userOptions.filter((user) => !calculatedUsers.some((calculatedUser) => calculatedUser.employee === user.employee));

  return (
  <Container>
    <Modal show={true} onHide={onCancel} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Salary & Deductions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Row>
        <Col sm={12}>
          {success && <Alert variant="success" onClose={() => setSuccess(null)} dismissible >{success}</Alert>}
          {error && <Alert variant="danger" onClose={() => setError(null)} dismissible >{error}</Alert>}
          <ToastContainer />
        </Col>
      </Row>
      <Form onSubmit={handleSubmit} className="mt-4" ref={formRef}>
        <Form.Group as={Row}>
          <Form.Label column sm={4}>
            Select User:
          </Form.Label>
          <Col sm={8}>
            <Select
              options={usersToDisplay}
              value={selectedUser}
              onChange={handleUserChange}
              placeholder="Select user..."
              required
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={4} className=''>
            Salary Amount:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              type="number"
              value={salaryAmount}
              onChange={handleAmountChange}
              required
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={4} className=''>
            NHIF Deduction:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              type="number"
              value={nhifDeduction}
              onChange={handleNhifDeductionChange}
              required
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={4} className=''>
            NSSF Deduction:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              type="number"
              value={nssfDeduction}
              onChange={handleNssfDeductionChange}
              required
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={4} className=''>
            Other Deduction:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              type="number"
              value={otherDeduction}
              onChange={handleOtherDeductionChange}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={4} className=''>
            Payable Salary:
          </Form.Label>
          <Col sm={8}>
            <Form.Control type="text" readOnly value={payableSalary.toLocaleString()} required />
          </Col>
        </Form.Group>
        <input type="hidden" name="payableSalary" value={payableSalary.toLocaleString()} />
        <Modal.Footer>
        <Button variant="primary" type="submit">
          Calculate Payable Salary
        </Button>
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button></Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
    </Container>
  );
};

export default SalaryCalculator;
