import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Alert, Button, Modal } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import axios from 'axios';
import config from '../../../config';
import ConfirmationModal from './ConfirmationModal';
import { usePermissions } from '../../../permissions';

const FormsElements = ({ onCancel }) => {
    const account = useSelector((state) => state.account);
    const [data, setData] = useState({});
    const [datas, setDatas] = useState({});
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [selectedTagM, setSelectedTagM] = useState(null);
    const [selectedHouse, setSelectedHouse] = useState(null);
    const [tagMOptions, setTagMOptions] = useState([]);
    const [houseOptions, setHouseOptions] = useState([]);
    const [tagOptions, setTagOptions] = useState([]);
    const [filteredHouseOptions, setFilteredHouseptions] = useState([]);
    const [weanersOptions, setWeanersOptions] = useState([]);
    const [selectedHouseOptions, setSelectedHouseOptions] = useState([]);
    const formRef = useRef(null);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const { createPermission, deletePermission, editPermission } = usePermissions();
    const [selectedForm, setSelectedForm] = useState('weanersMove');

    const memoizedApiUrl2 = useMemo(() => {
        return `${apiUrl}/weaners`;
    }, [apiUrl]);

    const memoizedApiUrl3 = useMemo(() => {
        return `${apiUrl}/weaners_group_list`;
    }, [apiUrl]);

    const handleInput = (fieldName, value) => {
        const updatedData = {
            ...data,
            [fieldName]: value,
            fromWeanersGroup: selectedTagM?.value,
            userName: account.user.firstname
        };
        setData(updatedData);
        if (fieldName === 'fromWeanersGroup') {
            setSelectedTagM(value);
        }
        console.log(data);
    };

    const handleInputs = (fieldName, value) => {
        const updatedData = {
            ...datas,
            [fieldName]: value,
            fromHouse: selectedHouse?.value,
            userId: account.user.id
        };
        setDatas(updatedData);
        if (fieldName === 'fromHouse') {
            setSelectedHouse(value);
        }
        console.log(datas);
    };

    const handlePiglets = async (e) => {
        e.preventDefault();
        setShowConfirmationModal(true);
    };

    const handleConfirmation = async (e) => {
        if (!createPermission) {
            return;
        }

        if (selectedForm === 'weanersMove' && data.toWeanersGroup === data.fromWeanersGroup) {
            setError('To Weaners Group and From Weaners Group cannot be the same.');
            toast.error('To Weaners Group and From Weaners Group cannot be the same.');
            setShowConfirmationModal(false);
            return;
        }

        let formattedUrl;
        let requestData;

        if (selectedForm === 'weanersMove') {
            formattedUrl = `${apiUrl}/group-move`;
            requestData = data;
        } else if (selectedForm === 'houseMove') {
            formattedUrl = `${apiUrl}/house-move`;
            requestData = datas;
        }

        try {
            const response = await axios.post(formattedUrl, requestData);
            if (response.data) {
                setSuccess(response.data.message);
                setTimeout(() => {
                    setSuccess(null);
                }, 10000);
                toast.success(response.data.message);
                onCancel();
                setData({});
                formRef.current.reset();
                setSelectedTagM(null);
                setShowConfirmationModal(false);
            }
            console.log(response);
        } catch (error) {
            if (error.response && error.response.status === 400) {
                setError(`${error.response.data.message}`);
                toast.error(`${error.response.data.message}`);
            } else {
                setError('An error occurred. Please try again later.');
            }
            setShowConfirmationModal(false);
            onCancel();
            console.log(error);
        }
    };

    useEffect(() => {
        const fetchTags = async () => {
            try {
                const response = await axios.get(`${apiUrl}/weaners`);
                const data = response.data;
                const filteredData = data.filter((tag) => tag.capacity > 0 && tag.archive !== 1);
                const options = filteredData.map((tag) => ({
                    value: tag.weanersGroup,
                    label: tag.weanersGroup
                }));
                setTagMOptions(options);
            } catch (error) {
                console.error('Error fetching Tags:', error);
            }
        };

        fetchTags();
    }, [apiUrl, tagMOptions]);

    useEffect(() => {
        if (selectedTagM) {
            const fetchWeaners = async () => {
                try {
                    const response = await axios.get(memoizedApiUrl2);
                    const data = response.data;
                    const filteredData = data.filter((tag) => tag.weanersGroup === selectedTagM.value);
                    setWeanersOptions(filteredData);
                    getToWeanersGroupOptions();
                    console.log('DOB options:', filteredData); // Debugging log
                } catch (error) {
                    console.error('Error fetching Weaners data:', error);
                }
            };

            fetchWeaners();
        }
    }, [selectedTagM, memoizedApiUrl2]);

    useEffect(() => {
        const fetchTags = async () => {
            try {
                const response = await axios.get(`${apiUrl}/houses`);
                const data = response.data;
                const filteredData = data;
                const options = filteredData.map((tag) => ({
                    value: tag.houseNumber,
                    label: tag.houseNumber
                }));
                setHouseOptions(options);
            } catch (error) {
                console.error('Error fetching Tags:', error);
            }
        };

        fetchTags();
    }, [apiUrl, tagMOptions]);

    useEffect(() => {
        if (selectedHouse) {
            const fetchWeaners = async () => {
                try {
                    const response = await axios.get(memoizedApiUrl3);
                    const data = response.data;
                    const filteredData = data.filter((tag) => tag.houseNumber === selectedHouse.value);
                    const options = filteredData.map((tag) => ({
                        value: tag.id,
                        label: `${new Date(tag.dob)?.toLocaleDateString()} - ${tag.weanersGroup}`
                    }));
                    setSelectedHouseOptions(options);
                    getToHouseOptions();
                    console.log('DOB options:', filteredData); // Debugging log
                } catch (error) {
                    console.error('Error fetching Weaners data:', error);
                }
            };

            fetchWeaners();
        }
    }, [selectedHouse, memoizedApiUrl3]);

    const handleCancelConfirmation = () => {
        setShowConfirmationModal(false);
    };

    const handleFormChange = (e) => {
        setSelectedForm(e.target.value);
    };

    const getToWeanersGroupOptions = () => {
        const options = tagMOptions.filter((option) => option.value !== selectedTagM?.value);
        setTagOptions(options);
    };

    const getToHouseOptions = () => {
        const option = houseOptions.filter((option) => option.value !== selectedHouse?.value);
        setFilteredHouseptions(option);
    };

    return (
        <React.Fragment>
            <Modal show={true} onHide={onCancel} size="xl" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Move Weaners</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Row>
                        <Col sm={12}>
                            {success && (
                                <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                                    {success}
                                </Alert>
                            )}
                            {error && (
                                <Alert variant="danger" onClose={() => setError(null)} dismissible>
                                    {error}
                                </Alert>
                            )}
                            <ToastContainer />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <Form>
                                <Form.Check
                                    type="radio"
                                    label="Group Move"
                                    name="formSelector"
                                    value="weanersMove"
                                    checked={selectedForm === 'weanersMove'}
                                    onChange={handleFormChange}
                                />
                                <Form.Check
                                    type="radio"
                                    label="House Move"
                                    name="formSelector"
                                    value="houseMove"
                                    checked={selectedForm === 'houseMove'}
                                    onChange={handleFormChange}
                                />
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        {selectedForm === 'weanersMove' && (
                            <Col>
                                <Form onSubmit={handlePiglets} ref={formRef}>
                                    <Row>
                                        <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                            <Form.Label>From Group</Form.Label>
                                            <Select
                                                value={selectedTagM}
                                                onChange={(selectedOption) => {
                                                    handleInput('fromWeanersGroup', selectedOption);
                                                }}
                                                options={tagMOptions}
                                                isSearchable
                                                name="fromWeanersGroup"
                                                placeholder="-- Select Weaners Group --"
                                                required
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                            <Form.Label>Capacity</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                required
                                                type="number"
                                                placeholder="Weaners to be moved"
                                                onChange={(e) => handleInput('capacity', e.target.value)}
                                                name="capacity"
                                            />
                                            <Form.Text>
                                                Current weaners <code>{weanersOptions[0]?.capacity}</code>
                                            </Form.Text>
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                            <Form.Label>To Group</Form.Label>
                                            <Select
                                                onChange={(selectedOption) => {
                                                    handleInput('toWeanersGroup', selectedOption.value);
                                                }}
                                                options={tagOptions}
                                                isSearchable
                                                name="toWeanersGroup"
                                                placeholder="-- Select Weaners Group --"
                                                required
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                            <Form.Label>Date</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                required
                                                onChange={(e) => handleInput('recorded', e.target.value)}
                                                type="date"
                                                name="recorded"
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} md={12} sm={12} lg={12} controlId="formBasic">
                                            <Form.Label>Remarks/Notes</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                required
                                                onChange={(e) => handleInput('remarks', e.target.value)}
                                                type="text"
                                                name="remarks"
                                            />
                                        </Form.Group>
                                    </Row>
                                    <Modal.Footer>
                                        {createPermission && <Button type="submit">Submit</Button>}
                                        <Button variant="secondary" onClick={onCancel}>
                                            Cancel
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            </Col>
                        )}
                        {selectedForm === 'houseMove' && (
                            <Col>
                                <Form onSubmit={handlePiglets} ref={formRef}>
                                    <Row>
                                        <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                            <Form.Label>From House</Form.Label>
                                            <Select
                                                value={selectedHouse}
                                                onChange={(selectedOption) => {
                                                    handleInputs('fromHouse', selectedOption);
                                                }}
                                                options={houseOptions}
                                                isSearchable
                                                name="fromHouse"
                                                placeholder="-- Select house --"
                                                required
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                            <Form.Label>Group</Form.Label>
                                            <Select
                                                onChange={(selectedOption) => {
                                                    handleInputs('weanersGroupId', selectedOption.value);
                                                }}
                                                options={selectedHouseOptions}
                                                isSearchable
                                                name="weanersGroupId"
                                                placeholder="-- Select Weaners Group --"
                                                required
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                            <Form.Label>To House</Form.Label>
                                            <Select
                                                onChange={(selectedOption) => {
                                                    handleInputs('toHouse', selectedOption.value);
                                                }}
                                                options={filteredHouseOptions}
                                                isSearchable
                                                name="toHouse"
                                                placeholder="-- Select house --"
                                                required
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                            <Form.Label>Date</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                required
                                                onChange={(e) => handleInputs('recorded', e.target.value)}
                                                type="date"
                                                name="recorded"
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} md={12} sm={12} lg={12} controlId="formBasic">
                                            <Form.Label>Remarks/Notes</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                required
                                                onChange={(e) => handleInputs('remarks', e.target.value)}
                                                type="text"
                                                name="remarks"
                                            />
                                        </Form.Group>
                                    </Row>
                                    <Modal.Footer>
                                        {createPermission && <Button type="submit">Submit</Button>}
                                        <Button variant="secondary" onClick={onCancel}>
                                            Cancel
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            </Col>
                        )}
                    </Row>
                </Modal.Body>
            </Modal>

            <ConfirmationModal show={showConfirmationModal} onHide={handleCancelConfirmation} onConfirm={handleConfirmation} />
        </React.Fragment>
    );
};

export default FormsElements;
