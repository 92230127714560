import React, {useState, useEffect} from 'react';
import NVD3Chart from 'react-nvd3';
import { Bar, Doughnut, Line, Scatter } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { VictoryBar, VictoryLine, VictoryChart, VictoryAxis } from 'victory';
import config from '../../../../config';
import axios from 'axios';

const MultiBarChart = () => {
  const [totalQuantity, setTotalQuantity] = useState(0);
const [totalEggsSold, setTotalEggsSold] = useState(0);
const [totalEggsCollected,  setTotalEggsCollected] = useState(0);
const [totalBirdsSold, setTotalBirdsSold] = useState(0);
const [totalSpoilt, setTotalSpoilt] = useState(0);
const [totalDead, setTotalDead] = useState(0);
const [totalpigsSired, setPigsSired] = useState(0);
const [totalFeedQuantity, setTotalFeedQuantity] = useState(0);
const [totalFeedRestock, setTotalFeedRestock] = useState(0);
  const [totalPoultry, setTotalPoultry] = useState(0);
  const [totalCapacity, setTotalCapacity] = useState(0);
  const [totalRefill, setTotalRefill] = useState(0);
  const [labels, setLabels] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalPigs, setTotalPigs] = useState(0);
  const [totalpigsSold, setTotalPigsSold] = useState(0);
  const [totalGoatsSold, setTotalGoatsSold] = useState(0);
  const [totalPigsDead, setTotalPigsDead] = useState(0);
  const [totalGoats, setTotalGoats] = useState(0);
  const [totalGoatsDead, setTotalGoatsDead] = useState(0);
  const [totalSired, setTotalGoatsSired] = useState(0);
  const [earnings, setEarnings] = useState({ totalEarnings: 0 });
  const [dead, setDead] = useState({ totalDead: 0 });
  const [water, setWater] = useState({ totalWater: 0 });
  const [feed, setFeed] = useState({ totalFeed: 0 });
  const [expenses, setExpenses] = useState({ totalExpenses: 0 });
  const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
  const apiUrl = config[environment].apiUrl;

 

  useEffect(() => {
    axios.get(`${apiUrl}/poultry/total`)
      .then((response) => {
        setTotalPoultry(response.data.totalQuantity);
        // console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axios.get(`${apiUrl}/water/total`)
      .then((response) => {
        setTotalCapacity(response.data.totalCapacity);
        // console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  useEffect(() => {
    axios.get(`${apiUrl}/feeds/total`)
      .then((response) => {
        setTotalFeedQuantity(response.data.totalFeedQuantity);
        // console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  useEffect(() => {
    axios.get(`${apiUrl}/water/refill`)
      .then((response) => {
        setTotalRefill(response.data.totalRefill);
        // console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axios.get(`${apiUrl}/goats/total`)
      .then((response) => {
        setTotalGoats(response.data.totalGoats);
        // console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axios.get(`${apiUrl}/total/pigs`)
      .then((response) => {
        setTotalPigs(response.data.totalPigs);
        // console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axios.get(`${apiUrl}/users/total`)
      .then((response) => {
        setTotalUsers(response.data.totalUsers);
        // console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
        axios.get(`${apiUrl}/poultry/total`)
        .then((response) => {
            setTotalQuantity(response.data.totalQuantity);
            // console.log(response);
        })
        .catch(error => {
            console.error(error);
        });
    }, []);

    useEffect(() => {
        axios.get(`${apiUrl}/eggsSold/total`)
        .then((response) => {
            setTotalEggsSold(response.data.totalEggsSold);
            // console.log(response);
        })
        .catch(error => {
            console.error(error);
        });
    }, []);

    useEffect(() => {
        axios.get(`${apiUrl}/eggsCollected/total`)
        .then((response) => {
            setTotalEggsCollected(response.data.totalCollected);
            // console.log(response);
        })
        .catch(error => {
            console.error(error);
        });
    }, []);

    useEffect(() => {
        axios.get(`${apiUrl}/totalBirdsSold`)
        .then((response) => {
            setTotalBirdsSold(response.data.totalBirdsSold);
            // console.log(response);
        })
        .catch(error => {
            console.error(error);
        });
    }, []);
    useEffect(() => {
        axios.get(`${apiUrl}/goatsSold/total`)
        .then((response) => {
            setTotalGoatsSold(response.data.totalGoatsSold);
            // console.log(response);
        })
        .catch(error => {
            console.error(error);
        });
    }, []);
    useEffect(() => {
        axios.get(`${apiUrl}/totalGoatsDead`)
        .then((response) => {
            setTotalGoatsDead(response.data.totalGoatsDead);
            // console.log(response);
        })
        .catch(error => {
            console.error(error);
        });
    }, []);
    useEffect(() => {
        axios.get(`${apiUrl}/goatsSired/total`)
        .then((response) => {
            setTotalGoatsSired(response.data.totalSired);
            // console.log(response);
        })
        .catch(error => {
            console.error(error);
        });
    }, []);

    useEffect(() => {
        axios.get(`${apiUrl}/pigsSold/total`)
        .then((response) => {
            setTotalPigsSold(response.data.totalpigsSold);
            // console.log(response);
        })
        .catch(error => {
            console.error(error);
        });
    }, []);
    useEffect(() => {
        axios.get(`${apiUrl}/pigsDead/total`)
        .then((response) => {
            setTotalPigsDead(response.data.totalPigsDead);
            // console.log(response);
        })
        .catch(error => {
            console.error(error);
        });
    }, []);

    useEffect(() => {
        axios.get(`${apiUrl}/eggsBroken/total`)
        .then((response) => {
            setTotalSpoilt(response.data.totalSpoilt);
            // console.log(response);
        })
        .catch(error => {
            console.error(error);
        });
    }, []);
    useEffect(() => {
        axios.get(`${apiUrl}/dead/total`)
        .then((response) => {
            setTotalDead(response.data.totalDead);
            // console.log(response);
        })
        .catch(error => {
            console.error(error);
        });
    }, []);
    useEffect(() => {
        axios.get(`${apiUrl}/pigsSired/total`)
        .then((response) => {
            setPigsSired(response.data.totalpigsSired);
            // console.log(response);
        })
        .catch(error => {
            console.error(error);
        });
    }, []);
    useEffect(() => {
        axios.get(`${apiUrl}/feeds/restock`)
        .then((response) => {
            setTotalFeedRestock(response.data.totalFeedRestock);
            // console.log(response);
        })
        .catch(error => {
            console.error(error);
        });
    }, []);

    const formatPercentage = (value) => {
      return `${value}%`; // Adjust the formatting as needed
    };
    const total = totalPoultry + totalFeedQuantity + totalCapacity + totalRefill + totalPigs + totalGoats + totalEggsCollected + totalFeedRestock;
    const poultryPercentage = ((totalPoultry / total) * 100).toFixed(2);
    const feedPercentage = ((totalFeedQuantity / total) * 100).toFixed(2);
    const capacityPercentage = ((totalCapacity / total) * 100).toFixed(2);
    const refillPercentage = ((totalRefill / total) * 100).toFixed(2);
    const totalPigsPer = ((totalPigs / total) * 100).toFixed(2);
    const totalGoatsPer = ((totalGoats / total) * 100).toFixed(2);
    const totalEggsCollectedPer = ((totalEggsCollected / total) * 100).toFixed(2);
    const totalFeedPer = ((totalFeedRestock / total) * 100).toFixed(2);

    // const chartData = {
    //   labels: ['Poultry', 'Capacity', 'Feed Quantity', 'Refill', 'Pigs', 'Goats', 'Eggs'],
    //   datasets: [
    //     {
    //       label: 'Poultry',
    //       data: [poultryPercentage],
    //       backgroundColor: 'rgba(75, 192, 192, 0.6)', // Customize the bar color
    //     },
    //     {
    //       label: 'Capacity',
    //       data: [capacityPercentage],
    //       backgroundColor: 'rgba(255, 99, 132, 0.6)', // Customize the bar color
    //     },
    //     {
    //       label: 'Feed Quantity',
    //       data: [feedPercentage],
    //       backgroundColor: 'rgba(54, 162, 235, 0.6)', // Customize the bar color
    //     },
    //     {
    //       label: 'Refill',
    //       data: [refillPercentage],
    //       backgroundColor: 'rgba(255, 206, 86, 0.6)', // Customize the bar color
    //     },
    //     {
    //       label: 'Pigs',
    //       data: [totalPigsPer ],
    //       backgroundColor: 'rgba(55, 29, 32, 0.6)', // Customize the bar color
    //     },
    //     {
    //       label: 'Goats',
    //       data: [totalGoatsPer],
    //       backgroundColor: 'rgba(54, 62, 235, 0.6)', // Customize the bar color
    //     },
    //     {
    //       label: 'Eggs',
    //       data: [totalEggsCollectedPer],
    //       backgroundColor: 'rgba(255, 106, 86, 0.6)', // Customize the bar color
    //     },
    //   ],
    // };
    useEffect(() => {
      setLabels(['Poultry', 'Capacity', 'Feed Quantity', 'Refill']);
    }, []);

    const chartData = {
      labels: ['Poultry', 'Water','Water Refill', 'Feed', 'Feed Restock', 'Pigs','Goats','Eggs'],
      datasets: [
        {
          label: 'Percentage',
          data: [poultryPercentage, capacityPercentage, refillPercentage, feedPercentage, totalFeedPer,totalPigsPer,totalGoatsPer,totalEggsCollectedPer],
          backgroundColor: [
            'rgba(75, 192, 192, 0.6)',
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
            'rgba(54, 62, 235, 0.6)',
            'rgba(55, 29, 32, 0.6)',
            'rgba(255, 29, 32, 0.6)',
            'rgba(65, 192, 182, 0.6)',
          ], // Customize the bar colors
        },
      ],
    };
  
    const chartOptions = {
      indexAxis: 'x',
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            callback: (value) => `${value}%`, // Format x-axis ticks as percentages
          },
        },
      },
    };
  
    useEffect(() => {
      if (typeof window.Chart !== 'undefined') {
        Chart.register({ // Register the Bar controller directly
          id: 'bar',
          beforeDraw: (chart) => {
            const ctx = chart.ctx;
            const xAxis = chart.scales['x'];
            const yAxis = chart.scales['y'];
            const datasets = chart.data.datasets;
            const dataset = datasets[0];
            const meta = chart.getDatasetMeta(0);
            const barThickness = xAxis.width / datasets.length;
  
            ctx.save();
            for (let i = 0; i < dataset.data.length; i++) {
              const value = dataset.data[i];
              const x = xAxis.left + barThickness * i + barThickness / 2;
              const y = yAxis.getPixelForValue(value);
  
              ctx.fillText(`${value}%`, x, y - 10);
            }
            ctx.restore();
          }
        });
      }
    }, []);
  
    return (
      <div>
        <Bar data={chartData} options={chartOptions} />
        {/* <Scatter data={chartData} options={chartOptions} />
        <Doughnut data={chartData} options={chartOptions} /> */}
      </div>
    );
};

export default MultiBarChart;
