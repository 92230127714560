import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Table, Button, Pagination, Form } from 'react-bootstrap';
import Select from 'react-select';
import { CSVLink } from 'react-csv';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import axios from 'axios';
import config from '../../../config';
import { useParams, useHistory, Link } from 'react-router-dom';
import Card from '../../../components/Card/MainCard';
import DataTable from 'react-data-table-component';
import Sup from './AddRole';
import { usePermissions } from '../../../permissions';

const UserList = () => {
    const [users, setUsers] = useState([]);
    const { id } = useParams();
    const history = useHistory();
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [searchText, setSearchText] = useState('');
    const [isPopupVisible, setPopupVisible] = useState(false);
    const { createPermission, deletePermission, editPermission, viewPermission } = usePermissions();

    const hidePopup = () => {
        setPopupVisible(false);
    };

    const showPopup = () => {
        setPopupVisible(true);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/roles`);
                setUsers(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    console.log(users);

    const handleDelete = async (userId, name) => {
        try {
            const confirmed = window.confirm(`Are you sure you want to delete ${name}?`);
            if (confirmed) {
                await axios.put(`${apiUrl}/admin/${userId}/archive`);
                alert('Admin archived successfully!');
                window.location.reload();
            }
        } catch (error) {
            alert(error.message);
        }
    };

    const handleUpdate = (userId) => {
        const userToUpdate = users.find((user) => user.id === userId);
        history.push(`roles/${userId}/edit`, { user: userToUpdate });
    };

    const handleUpdatePass = (userId) => {
        const userToUpdate = users.find((user) => user.id === userId);
        history.push(`roles/view/${userId}`, { user: userToUpdate });

        console.log(userId);
    };

    const calculateAge = (updated_at) => {
        const today = new Date();
        const birth = new Date(updated_at);

        // Get the UTC values for year, month, and day
        const todayUTC = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
        const birthUTC = Date.UTC(birth.getFullYear(), birth.getMonth(), birth.getDate());

        // Calculate the age difference in milliseconds
        const ageDiffMillis = todayUTC - birthUTC;

        // Convert milliseconds to years, months, and days
        const yearDiff = Math.floor(ageDiffMillis / (1000 * 60 * 60 * 24 * 365));
        const monthDiff = Math.floor((ageDiffMillis % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30));
        const dayDiff = Math.floor((ageDiffMillis % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24));

        if (yearDiff > 0) {
            return yearDiff === 1 ? `${yearDiff} year ago` : `${yearDiff} years ago`;
        } else if (monthDiff > 0) {
            return monthDiff === 1 ? `${monthDiff} month ago` : `${monthDiff} months ago`;
        } else {
            return dayDiff === 1 ? `${dayDiff} day ago` : `${dayDiff} days ago`;
        }
    };

    const csvLinkRef = useRef();

    const exportToCSV = () => {
        const csvData = [
            ['UserId', 'First Name', 'Last Name', 'Email', 'Phone No.', 'Date', 'Address'],
            ...users.map((item) => [item.id, item.firstname, item.lastname, item.email, item.phone_number, item.createdAt, item.address])
        ];

        const csvContent = csvData.map((row) => row.join(',')).join('\n');
        const blob = new Blob(['\ufeff', csvContent], { type: 'text/csv;charset=utf-8;' });

        const csvUrl = URL.createObjectURL(blob);
        csvLinkRef.current.href = csvUrl;
        csvLinkRef.current.download = 'sfarms.csv';
        csvLinkRef.current.click();
    };

    const exportToPDF = () => {
        const doc = new jsPDF();
        doc.autoTable({
            head: [['UserId', 'First Name', 'Last Name', 'Email', 'Phone No.', 'Date', 'Address']],
            body: users.map((user) => [
                user.id,
                user.firstname,
                user.lastname,
                user.email,
                user.phone_number,
                new Intl.DateTimeFormat('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                }).format(new Date(user.createdAt)),
                user.address
            ])
        });
        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl);
    };

    const columns = [
        {
            name: 'Space Name',
            selector: (row) => row.name,
            sortable: true
        },
        {
            name: 'Updated At',
            selector: (row) => new Date(row.updated_at).toLocaleString(),
            sortable: true
        },
        {
            name: 'Permission Actions',
            cell: (row) => (
                <div>
                    {editPermission && (
                        <Button disabled={!editPermission} size="sm" onClick={() => handleUpdate(row.id)}>
                            <span className="feather icon-edit"></span> Edit 
                        </Button>
                    )}{' '}
                    {viewPermission && (
                        <Button disabled={!viewPermission} size="sm" variant="info" onClick={() => handleUpdatePass(row.id)}>
                            <span className="feather icon-eye"></span> View 
                        </Button>
                    )}
                    {deletePermission && (
                        <Button disabled={!deletePermission} variant="danger" size="sm" onClick={() => handleDelete(row.id, row.name)}>
                            <span className="feather icon-trash-2"></span> Delete
                        </Button>
                    )}
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true
            // button: true,
            // right: true
        }
    ];

    const filteredData = searchText
        ? users.filter((item) =>
              Object.values(item).some((value) => typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase()))
          )
        : users;

    return (
        <React.Fragment>
            <Row className="btn-page">
                <Col>
                    <div className="d-flex justify-content-between align-center m-b-10">
                        <div>
                            <span> </span>
                        </div>
                        {createPermission && (
                            <Button disabled={!createPermission} variant="primary" className="" onClick={showPopup}>
                                <span className="feather icon-user-plus"></span> Add Roles
                            </Button>
                        )}
                        {isPopupVisible && <Sup onCancel={hidePopup} />}
                    </div>
                    <Card title="User Roles" isOption>
                        <div>
                            <Row>
                                <Form.Group as={Col} md={1} sm={12} lg={1} controlId="formBasicName">
                                    <Form.Label>Search</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md={3} sm={12} lg={3} controlId="formBasicName">
                                    <Form.Control
                                        type="text"
                                        placeholder="Search..."
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                    />
                                </Form.Group>
                            </Row>
                        </div>
                        <DataTable
                            columns={columns}
                            data={filteredData}
                            pagination
                            highlightOnHover
                            striped
                            noHeader
                            // bordered
                            paginationPerPage={10}
                            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                            paginationComponentOptions={{
                                rowsPerPageText: 'Rows per page:'
                            }}
                            paginationResetDefaultPage={true}
                            paginationTotalRows={users.length}
                            // expandableRows
                            selectableRows
                            onSelectedRowsChange={(selectedRows) => console.log(selectedRows)}
                            customStyles={{
                                headRow: {
                                    style: {
                                        backgroundColor: '#279e6c', // Green background
                                        color: '#fff', // White text
                                        textTransform: 'uppercase' // Uppercase text
                                    }
                                }
                            }}
                        />
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default UserList;
