import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Form, Table, Button, Alert, Modal, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import config from '../../config';
import Account from './SamplePage120';
import { CSVLink } from 'react-csv';
import axios from 'axios';
import Select from 'react-select';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
// import Card from '../../components/Card/MainCard';
import { usePermissions } from '../../permissions';
import DataTable from 'react-data-table-component';
import LineChart from './../charts/nvd3-chart/chart/LineChart';
import AccountDashboard from './SamplePage100';
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

const SamplePage = () => {
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [accounts, setAccounts] = useState([]);
    const [newAccount, setNewAccount] = useState({
        accountName: null,
        accountBalance: '',
        description: '',
        type: null,
        accountNumber: '',
        farm: ''
    });
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [totalDebit, setTotalDebit] = useState(0);
    const [totalCredit, setTotalCredit] = useState(0);
    const [totalDebits, setTotalDebits] = useState(0);
    const [totalCredits, setTotalCredits] = useState(0);
    const [expenses, setExpenses] = useState({ totalExpenses: 0 });
    const [earnings, setEarnings] = useState({ totalEarnings: 0 });
    const [data, setData] = useState([]);
    const [trialBalance, setTrialBalance] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [selectedAccountDetails, setSelectedAccountDetails] = useState([]);
    const tableRef = useRef(null);
    const [dailyEarnings, setDailyEarnings] = useState(0);
    const [weeklyEarnings, setWeeklyEarnings] = useState(0);
    const [monthlyEarnings, setMonthlyEarnings] = useState(0);
    const [farmOptions, setFarmOptions] = useState([]);
    const { createPermission, deletePermission, editPermission } = usePermissions();
    const [searchText, setSearchText] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [mostUsedAccounts, setMostUsedAccounts] = useState([]);
    const [dateRange, setDateRange] = useState([null, null]);

    useEffect(() => {
        const fetchFarms = async () => {
            try {
                const response = await axios.get(`${apiUrl}/farm`);
                const farmData = response?.data;
                const options = farmData?.map((farm) => ({
                    value: farm.name,
                    label: farm.name
                }));

                setFarmOptions(options);
            } catch (error) {
                console.error('Error fetching Farms:', error);
            }
        };

        fetchFarms();
    }, [apiUrl, farmOptions]);

    useEffect(() => {
        // Fetch daily earnings
        axios
            .get(`${apiUrl}/earnings/stats?period=daily`)
            .then((response) => {
                setDailyEarnings(response?.data?.totalEarnings);
            })
            .catch((error) => {
                console.error('Error fetching daily earnings:', error);
            });

        // Fetch weekly earnings
        axios
            .get(`${apiUrl}/earnings/stats?period=weekly`)
            .then((response) => {
                setWeeklyEarnings(response?.data?.totalEarnings);
            })
            .catch((error) => {
                console.error('Error fetching weekly earnings:', error);
            });

        // Fetch monthly earnings
        axios
            .get(`${apiUrl}/earnings/stats?period=monthly`)
            .then((response) => {
                setMonthlyEarnings(response?.data?.totalEarnings);
            })
            .catch((error) => {
                console.error('Error fetching monthly earnings:', error);
            });
    }, []);

    useEffect(() => {
        const start = dateRange && dateRange[0] ? dateRange[0].toISOString().split('T')[0] : '1970-01-01';
        const end = dateRange && dateRange[1] ? dateRange[1].toISOString().split('T')[0] : new Date().toISOString().split('T')[0];

        axios
            .get(`${apiUrl}/api/most-used-account?start=${start}&end=${end}`)
            .then((response) => setMostUsedAccounts(response?.data))
            .catch((error) => console.error('Error fetching most used accounts:', error));
    }, [dateRange]);

    const csvLinkRef = useRef();

    const handleExportsCSV = () => {
        const csvData = [
            ['Account Name', 'Description', 'Type', 'Balance'],
            ...filteredData.map((item) => [item.accountName, item.description, item.type, item.accountBalance]),
            ['Total', totalDebit, totalCredit]
        ];

        const csvContent = csvData.map((row) => row.join(',')).join('\n');
        const blob = new Blob(['\ufeff', csvContent], { type: 'text/csv;charset=utf-8;' });

        const csvUrl = URL.createObjectURL(blob);
        csvLinkRef.current.href = csvUrl;
        csvLinkRef.current.download = 'accounts.csv';
        csvLinkRef.current.click();
    };

    const handleExportPDF = () => {
        const table = document.getElementById('table-to-export');

        html2canvas(table).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save('trial_balance.pdf');
        });
    };

    const hideIt = () => {
        setSelectedAccount(null);
    };

    const hidePopup = () => {
        setPopupVisible(false);
    };

    const showPopup = () => {
        setPopupVisible(true);
    };

    useEffect(() => {
        fetchTrialBalance();
    }, [farmOptions, apiUrl]);

    const fetchTrialBalance = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/trial-balance`);
            setTrialBalance(response?.data);
            calculateTotalDebitsAndCredits(response?.data);
        } catch (error) {
            console.error(error);
        }
    };

    const calculateTotalDebitsAndCredits = (data) => {
        let debitsTotal = 0;
        let creditsTotal = 0;

        data.forEach((item) => {
            debitsTotal += item.expenses;
            creditsTotal += item.sales;
        });

        setTotalDebits(debitsTotal);
        setTotalCredits(creditsTotal);
    };

    useEffect(() => {
        fetch(`${apiUrl}/expenses`)
            .then((response) => response?.json())
            .then((expenses) => setExpenses(expenses))
            .catch((error) => console.error('Error:', error));
    }, []);

    useEffect(() => {
        fetch(`${apiUrl}/earnings`)
            .then((response) => response?.json())
            .then((data) => setEarnings(data))
            .catch((error) => console.error('Error:', error));
    }, []);

    useEffect(() => {
        fetch(`${apiUrl}/accounts`)
            .then((response) => response?.json())
            .then((data) => {
                setAccounts(data);
                calculateTotals(data);
            })
            .catch((error) => console.error('Error fetching accounts: ', error));
    }, [apiUrl, accounts]);

    const calculateTotals = (accounts) => {
        let Totals = 0;
        let creditTotal = 0;

        accounts.forEach((account) => {
            Totals += Number(account.accountBalance);
        });

        setTotalDebit(Totals);
        // setTotalCredit(creditTotal);
    };

    const profitLoss = totalDebit - totalCredit;

    const profitLossF = earnings.totalEarnings - expenses.totalExpenses;

    const handleInput = (event) => {
        setNewAccount((prevAccount) => ({
            ...prevAccount,
            [event.target.name]: event.target.value
        }));
    };

    const handleCreateAccount = (e) => {
        e.preventDefault();

        if (selectedAccount) {
            if (!editPermission) {
                return;
            }
            // Update existing account
            axios
                .put(`${apiUrl}/api/accounts/${selectedAccount.id}`, newAccount)
                .then((response) => {
                    setSuccess(`${newAccount.description} account successfully updated`);
                    setSelectedAccount(null);
                    setNewAccount({
                        accountName: null,
                        accountNumber: '',
                        accountBalance: '',
                        description: '',
                        type: null,
                        farm: null
                    });
                })
                .catch((error) => {
                    setError(error?.response?.data?.error);
                    console.error('Error updating account: ', error);
                });
        } else {
            if (!createPermission) {
                return;
            }
            // Create new account
            axios
                .post(`${apiUrl}/accounts`, newAccount)
                .then((response) => {
                    setSuccess('Account successfully created');
                    setNewAccount({
                        accountName: null,
                        accountNumber: '',
                        accountBalance: '',
                        description: '',
                        type: null,
                        farm: null
                    });
                })
                .catch((error) => {
                    setError(error.response?.data?.error);
                    console.error('Error creating account: ', error);
                });
        }
    };

    const handleEditAccount = (account) => {
        setSelectedAccount(account);
        setNewAccount({
            accountName: account.accountName,
            accountNumber: account.accountNumber,
            accountBalance: account.accountBalance,
            description: account.description,
            type: account.type,
            farm: account.farm
        });
    };

    const handleDeleteAccount = (accountId) => {
        if (window.confirm('Are you sure you want to archive this account?')) {
            axios
                .put(`${apiUrl}/api/accounts/${accountId}/archive`)
                .then((response) => {
                    setSuccess('Account successfully archived');
                    // Remove the deleted account from the accounts list
                    setAccounts((prevAccounts) => prevAccounts?.filter((account) => account.id !== accountId));
                })
                .catch((error) => {
                    setError(error.response.data.error);
                    console.error('Error deleting account: ', error);
                });
        }
    };

    const handleRowClick = (account) => {
        setSelectedAccountDetails(account);
        setModalVisible(true);
        // fetchAccountDetails(account.bankAccount);
    };

    const hideVisible = () => {
        setModalVisible(false);
    };

    const columns = [
        {
            name: 'Id',
            selector: (row) => row?.id,
            sortable: true,
            wrap: true,
            minWidth: '100px',
            maxWidth: '100px'
        },

        {
            name: 'Account Name',
            selector: (row) => row?.description,
            sortable: true,
            wrap: true,
            minWidth: '180px'
        },

        {
            name: 'Account Number',
            selector: (row) => row.accountNumber,
            sortable: true,
            wrap: true,
            minWidth: '150px'
        },

        {
            name: 'Balance',
            selector: (row) =>
                row.accountBalance !== null
                    ? row.accountBalance.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                      })
                    : '',
            sortable: true,
            wrap: true,
            minWidth: '150px'
        },
        {
            name: 'Created',
            selector: (row) => new Date(row.timeStamp).toLocaleString(),
            sortable: true,
            wrap: true,
            minWidth: '200px'
        },
        {
            name: 'action',
            selector: (row) => row?.action,
            cell: (row) => (
                <div>
                    {editPermission ? (
                        <Button size="sm" onClick={() => handleEditAccount(row)}>
                            <span className="feather icon-edit"></span> Edit
                        </Button>
                    ) : (
                        '-'
                    )}
                    {deletePermission ? (
                        <Button variant="danger" size="sm" onClick={() => handleDeleteAccount(row.id)}>
                            <span className="feather icon-trash"></span> Delete
                        </Button>
                    ) : (
                        '-'
                    )}
                    <Button size="sm" onClick={() => handleRowClick(row)}>
                        <span className="feather icon-eye"></span> Details
                    </Button>
                </div>
            ),
            sortable: true,
            wrap: true,
            minWidth: '180px'
        }
    ];

    const filteredData = searchText
        ? accounts.filter((item) =>
              Object.values(item).some((value) => typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase()))
          )
        : accounts;

    const handleDateChange = (dates) => {
        setDateRange(dates);
    };

    return (
        <React.Fragment>
            <Row>
                <Col sm={12}>
                    {success && (
                        <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                            {success}
                        </Alert>
                    )}
                    {error && (
                        <Alert variant="danger" onClose={() => setError(null)} dismissible>
                            {error}
                        </Alert>
                    )}
                </Col>
            </Row>
            <Row>
                {modalVisible && (
                    <AccountDashboard selectedAccountDetails={selectedAccountDetails} dateRange={dateRange} onCancel={hideVisible} />
                )}
                <Col md={12} xl={12} xs={12}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="pb-2">
                            {createPermission && (
                                <Button onClick={showPopup} className="mb-10">
                                    <span className="feather icon-plus-circle"></span> New Account
                                </Button>
                            )}
                            {isPopupVisible && <Account onCancel={hidePopup} />}
                            {createPermission && (
                                <Link to="/accounts/new-transaction">
                                    <Button variant="primary" className="">
                                        <span className="feather icon-plus-circle"></span> Make Transaction
                                    </Button>
                                </Link>
                            )}
                        </div>
                    </div>
                    <div className="pb-3">
                        <RangePicker onChange={handleDateChange} />
                    </div>

                    <Card style={{ borderRadius: '10px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', marginBottom: '20px' }}>
                        <Card.Body style={{ padding: '20px' }}>
                            {/* <h6 className="mb-4">Bank Account Usage</h6> */}
                            <div className="row align-items-center">
                                {mostUsedAccounts &&
                                    mostUsedAccounts.length > 0 &&
                                    mostUsedAccounts.map((item, index) => (
                                        <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                                            <div key={index} style={{ padding: '10px' }}>
                                                <div className="row align-items-center">
                                                    <div className="col-8">
                                                        <h6 className="font-weight-bold m-0">{item.bankAccount}</h6>
                                                        <p className="m-0">Usage Count: {item.usage_count}</p>
                                                    </div>
                                                    <div className="col-4 text-right f-16">
                                                        {/* <img
                                                            src="/assets/goat.png" // Replace with your actual image path
                                                            alt="Icon"
                                                            style={{
                                                                width: '30px',
                                                                height: '30px',
                                                                borderRadius: '50%',
                                                                objectFit: 'cover'
                                                            }}
                                                        /> */}
                                                        {item.percentage}%
                                                    </div>
                                                </div>
                                                <div className="progress mt-3" style={{ height: '7px' }}>
                                                    <div
                                                        className="progress-bar bg-warning"
                                                        role="progressbar"
                                                        style={{ width: `${item.percentage}%` }}
                                                        aria-valuenow={item.percentage}
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    ))}
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm="12" md="12" lg="12">
                    {selectedAccount && editPermission && (
                        <Modal show={true} onHide={hideIt} centered size="lg">
                            <Modal.Header closeButton>
                                <Modal.Title>Update Account</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form onSubmit={handleCreateAccount}>
                                    <Row>
                                        <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                            <Form.Label>Account Name</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                required
                                                onChange={handleInput}
                                                name="description"
                                                type="text"
                                                value={newAccount.description}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                            <Form.Label>Account Number</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                required
                                                onChange={handleInput}
                                                name="accountNumber"
                                                type="number"
                                                value={newAccount.accountNumber}
                                            />
                                        </Form.Group>
                                        {/* <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                            <Form.Label>Account Category</Form.Label>
                                            <Select
                                                options={accountNameOptions}
                                                value={accountNameOptions.find((option) => option.value === newAccount.accountName)}
                                                onChange={handleAccountNameSelect}
                                                required
                                            />
                                            </Form.Group> */}
                                        {/* <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                            <Form.Label>Account Type</Form.Label>
                                            <Select
                                                options={typeOptions}
                                                value={typeOptions.find((option) => option.value === newAccount.type)}
                                                onChange={handleTypeSelect}
                                                required
                                                isDisabled
                                            />
                                            </Form.Group> */}
                                        <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                            <Form.Label>Initial Balance</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                required
                                                onChange={handleInput}
                                                name="accountBalance"
                                                type="number"
                                                value={newAccount.accountBalance}
                                            />
                                        </Form.Group>
                                        {/* <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                            <Form.Label>Farm</Form.Label>
                                            <Select
                                                options={farmOptions}
                                                value={farmOptions.find((option) => option.value === newAccount.farm)}
                                                onChange={handleFarmSelect}
                                                required
                                            />
                                            </Form.Group> */}
                                    </Row>
                                    <Modal.Footer>
                                        {editPermission && (
                                            <>
                                                <Button type="submit">{selectedAccount ? 'Update' : 'Create'}</Button>
                                            </>
                                        )}
                                        <Button variant="secondary" onClick={hideIt}>
                                            Cancel
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            </Modal.Body>
                        </Modal>
                    )}

                    {data && data.length > 0 && (
                        <Row>
                            <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasicName">
                                <Form.Control
                                    type="text"
                                    placeholder="Search..."
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                />
                            </Form.Group>
                        </Row>
                    )}

                    <DataTable
                        columns={columns}
                        data={filteredData}
                        pagination
                        highlightOnHover
                        striped
                        bordered
                        dense
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                        paginationComponentOptions={{
                            rowsPerPageText: 'Rows per page:'
                        }}
                        paginationResetDefaultPage={true}
                        paginationTotalRows={accounts.length}
                        selectableRows
                        customStyles={{
                            headRow: {
                                style: {
                                    backgroundColor: '#279e6c', // Green background
                                    color: '#fff', // White text
                                    textTransform: 'uppercase',
                                    minHeight: '1.5rem',
                                    paddingTop: '8px',
                                    paddingBottom: '8px'
                                }
                            }
                        }}
                        subHeader
                        subHeaderComponent={
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="pr-2">
                                    {/* Total Cost: {totalCost.toLocaleString('en-US', { minimumFractionDigits: 2 })} */}
                                    <strong className="text-c-blue">Total KES</strong>{' '}
                                    <strong className="f-20">{totalDebit.toLocaleString('en-US', { minimumFractionDigits: 2 })}</strong>
                                </div>
                                <div>
                                    <Button onClick={handleExportsCSV}>
                                        <span className="feather icon-file"></span> CSV
                                    </Button>
                                    <a ref={csvLinkRef} style={{ display: 'none' }} />
                                    <a href="#" className="btn btn-primary" onClick={handleExportPDF}>
                                        <span className="feather icon-file-text"></span> PDF
                                    </a>
                                </div>
                            </div>
                        }
                        // subFooter={<div>Total Cost: {totalCost.toLocaleString('en-US', { minimumFractionDigits: 2 })}</div>}
                    />
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default SamplePage;
