import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Tabs, Tab, Alert, Form, Button, Modal } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import ModuleNotification from '../../../components/Widgets/Statistic/Notification';
import config from '../../../config';
import Card from '../../../components/Card/MainCard';
import axios from 'axios';
import Receipt from './Receipt01';
import ConfirmationModal from './ConfirmationModal';
import { usePermissions } from '../../../permissions';

const BasicTabsPills = () => {
    const account = useSelector((state) => state.account);
    const dispatcher = useDispatch();
    const [transactionId, setTransactionId] = useState(null);
    const [data, setData] = useState({});
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [totalCost, setTotalCost] = useState(0);
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [selectedDelivery, setSelectedDelivery] = useState(null);
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [supplierOptions, setSupplierOptions] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [showReceipt, setShowReceipt] = useState(false);
    const [showReceiptModal, setShowReceiptModal] = useState(false);
    const formRef = useRef(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [accounts, setAccounts] = useState([]);
    const [farmOptions, setFarmOptions] = useState([]);
    const [farm, setSelectedFarm] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const { createPermission, deletePermission, editPermission } = usePermissions();

    const memoizedApiUrl1 = useMemo(() => {
        return `${apiUrl}/farm`;
    }, [apiUrl]);

    const memoizedApiUrl2 = useMemo(() => {
        return `${apiUrl}/accounts`;
    }, [apiUrl]);

    const memoizedApiUrl3 = useMemo(() => {
        return `${apiUrl}/users`;
    }, [apiUrl]);

    useEffect(() => {
        const fetchFarms = async () => {
            try {
                const response = await axios.get(memoizedApiUrl1);
                const farmData = response.data;
                const options = farmData.map((farm) => ({
                    value: farm.name,
                    label: farm.name
                }));

                setFarmOptions(options);
            } catch (error) {
                console.error('Error fetching Farms:', error);
            }
        };

        fetchFarms();
    }, [memoizedApiUrl1]);

    const handleInpute = (fieldName, value) => {
        if (fieldName === 'farm') {
            setSelectedFarm(value);
            setData((prevData) => ({ ...prevData, [fieldName]: value }));
        } else {
            setData((prevData) => ({ ...prevData, [fieldName]: value }));
        }
        console.log(data);
    };

    useEffect(() => {
        fetchAccounts();
    }, [memoizedApiUrl2]);

    const fetchAccounts = async () => {
        try {
            const response = await axios.get(memoizedApiUrl2);
            const accounts = response.data;

            // const filteredAccounts = accounts.filter(account => account.accountName === 'Income');

            setAccounts(accounts);
        } catch (error) {
            console.error('Error fetching accounts:', error);
        }
    };

    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const response = await axios.get(memoizedApiUrl3);
                const data = response.data;
                // const filteredData = data.filter((tag) => tag.farm === farm);
                const options = data.map((employee) => ({
                    value: employee.firstname,
                    label: [employee.firstname, employee.lastname]
                }));
                setEmployees(options);
                // console.log(employees)
            } catch (error) {
                console.error('Error fetching employees:', error);
            }
        };

        fetchEmployees();
    }, [memoizedApiUrl3]);

    const handleSelectChange = (name, selectedOption) => {
        setData({ ...data, [name]: selectedOption.value });
    };

    useEffect(() => {
        const calculateTotalCost = () => {
            const totalCost = data.eggs * data.price;
            setTotalCost(totalCost);
            setData((prevData) => ({ ...prevData, totalCost }));
        };

        calculateTotalCost();
    }, [data.eggs, data.price]);

    const handleInput = (fieldName, value) => {
        if (fieldName === 'delivery' && value === 'Pick-Up') {
            setSelectedEmployee(null);
        }
        setData((prevData) => ({
            ...prevData,
            [fieldName]: value,
            totalCost,
            servedBy: account.user.firstname,
            userId: account.user.id,
            userName: account.user.firstname
        }));
        console.log(data);
    };
    const handleImageChange = (e) => {
        setSelectedImage(e.target.files[0]);
    };

    const formData = new FormData();
    formData.append('image', selectedImage);
    formData.append('cus_name', data.cus_name);
    formData.append('eggs', data.eggs);
    formData.append('price', data.price);
    formData.append('pay_method', data.pay_method);
    formData.append('delivery', data.delivery);
    formData.append('del_guy', data.del_guy || '');
    formData.append('totalCost', data.totalCost);
    formData.append('pay_reference', data.pay_reference);
    formData.append('servedBy', data.servedBy);
    formData.append('bankAccount', data.bankAccount);
    formData.append('farm', data.farm);
    formData.append('recorded', data.recorded);
    formData.append('userId', data.userId);
    formData.append('userName', data.userName);

    const handleEggs = async (e) => {
        e.preventDefault();
        setShowConfirmationModal(true);
    };

    const handleConfirmation = async (e) => {
        if(!createPermission){
            return;
        }
        axios
            .post(`${apiUrl}/broken-eggs/sell`, formData)
            .then((response) => {
                if (response.data) {
                    setSuccess(response.data.message);
                    setTimeout(() => {
                        setSuccess(null);
                    }, 10000);
                    toast.success(response.data.message);
                    // window.location.href = window.location.pathname + "?success=true";
                    const transactionId = response.data.transactionId;
                    setTransactionId(transactionId);
                    setShowReceipt(true);
                    setShowReceiptModal(true);
                    formRef.current.reset();
                    setSelectedPayment(null);
                    setSelectedDelivery(null);
                    setSelectedEmployee(null);
                    setSelectedSupplier(null);
                    setTotalCost(null);
                    setShowConfirmationModal(false);
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    setError(`${error.response.data.message}`);
                    toast.error(`${error.response.data.message}`);
                    setShowConfirmationModal(false);
                } else {
                    setError('An error occurred. Please try again later.');
                    setShowConfirmationModal(false);
                }
                console.log(error);
                setShowConfirmationModal(false);
            });
    };

    const memoizedApiUrl4 = useMemo(() => {
        return `${apiUrl}/customer`;
    }, [apiUrl]);

    useEffect(() => {
        const fetchSuppliers = async () => {
            try {
                const response = await axios.get(memoizedApiUrl4);
                const data = response.data;
                // const filteredData = data.filter((tag) => tag.farm === farm);
                const options = data.map((supplier) => ({
                    value: supplier.name,
                    label: supplier.name
                }));
                setSupplierOptions(options);
            } catch (error) {
                console.error('Error fetching suppliers:', error);
            }
        };

        fetchSuppliers();
    }, [memoizedApiUrl4]);

    const deliveryOptions = [
        { value: 'Delivery', label: 'Delivery' },
        { value: 'Pick-Up', label: 'Pick-Up' }
    ];

    const paymentOptions = [
        { value: 'Mpesa', label: 'Mpesa' },
        { value: 'Cash', label: 'Cash' },
        { value: 'Bank', label: 'Bank' },
        { value: 'Cheque', label: 'Cheque' }
    ];

    const handleCloseReceiptModal = () => {
        setShowReceiptModal(false);
        window.location.reload();
    };

    const handlePrint = () => {
        window.print();
    };

    const handleCancelConfirmation = () => {
        // Handle cancel action if needed
        setShowConfirmationModal(false);
    };

    return (
        <React.Fragment>
            <Row>
                <Col sm={12}>
                    {success && (
                        <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                            {success}
                        </Alert>
                    )}
                    {error && (
                        <Alert variant="danger" onClose={() => setError(null)} dismissible>
                            {error}
                        </Alert>
                    )}
                    <ToastContainer />
                </Col>
            </Row>
            <Row>
                <Col>
                    <hr />
                    <Tabs defaultActiveKey="home">
                        <Tab eventKey="home" title="SELL BROKEN EGGS" className="mt-4">
                            <Form onSubmit={handleEggs} ref={formRef}>
                                <Row>
                                    <Col>
                                        <Card title="Sales Information" isOption>
                                            <Row>
                                                <Form.Group as={Col} md={6} sm={12} lg={6}>
                                                    <Form.Label>Farm</Form.Label>
                                                    <Select
                                                        options={farmOptions}
                                                        onChange={(selectedOption) => handleInpute('farm', selectedOption.value)}
                                                        isSearchable
                                                        name="farm"
                                                    />
                                                    {/* <Form.Text className="text-muted">We'll never share your email with anyone else.</Form.Text> */}
                                                </Form.Group>
                                                <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasicName">
                                                    <Form.Label>Customer Name</Form.Label>
                                                    <Select
                                                        value={selectedSupplier}
                                                        onChange={(selectedOption) =>
                                                            setSelectedSupplier(handleInput('cus_name', selectedOption.value))
                                                        }
                                                        options={supplierOptions}
                                                        isSearchable
                                                        name="cus_name"
                                                        placeholder="--Select customer--"
                                                        required
                                                    />
                                                </Form.Group>

                                                <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                                    <Form.Label>Number of Broken Eggs</Form.Label>
                                                    <Form.Control
                                                        size="sm"
                                                        required
                                                        onChange={(e) => handleInput('eggs', e.target.value)}
                                                        type="number"
                                                        name="eggs"
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                                    <Form.Label>Price/Cost Per Egg</Form.Label>
                                                    <Form.Control
                                                        size="sm"
                                                        required
                                                        onChange={(e) => handleInput('price', e.target.value)}
                                                        type="number"
                                                        name="price"
                                                    />
                                                </Form.Group>

                                                <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                                    <Form.Label>Payment Method</Form.Label>
                                                    <Select
                                                        value={selectedPayment}
                                                        onChange={(selectedOption) =>
                                                            setSelectedPayment(handleInput('pay_method', selectedOption.value))
                                                        }
                                                        options={paymentOptions}
                                                        isSearchable
                                                        type="text"
                                                        name="pay_method"
                                                        placeholder="-- Select Payment Method --"
                                                        required
                                                    />
                                                </Form.Group>

                                                <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                                    <Form.Label>Delivery/Pickup Method</Form.Label>
                                                    <Select
                                                        value={selectedDelivery}
                                                        onChange={(selectedOption) =>
                                                            setSelectedDelivery(handleInput('delivery', selectedOption.value))
                                                        }
                                                        options={deliveryOptions}
                                                        isSearchable
                                                        type="text"
                                                        name="delivery"
                                                        placeholder="-- Select Delivery Method --"
                                                        required
                                                    />
                                                </Form.Group>

                                                {data.delivery === 'Delivery' && (
                                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                                        <Form.Label>Select Employee for Delivery</Form.Label>
                                                        <Select
                                                            value={selectedEmployee}
                                                            onChange={(selectedOption) =>
                                                                setSelectedEmployee(handleInput('del_guy', selectedOption.value))
                                                            }
                                                            options={employees}
                                                            isSearchable
                                                            name="del_guy"
                                                            placeholder="-- Select employee --"
                                                            required
                                                        />
                                                    </Form.Group>
                                                )}

                                                <Form.Group as={Col} md={6} sm={12} lg={6}>
                                                    <Form.Label>Total Cost</Form.Label>
                                                    <Form.Control
                                                        size="sm"
                                                        required
                                                        type="text"
                                                        onChange={(e) => handleInput('totalCost', e.target.value)}
                                                        value={totalCost}
                                                        disabled
                                                        name="totalCost"
                                                    />
                                                </Form.Group>

                                                <Col md={6} sm={12} lg={6}>
                                                    <Form.Group>
                                                        <Form.Label>Income Account:</Form.Label>
                                                        <Select
                                                            onChange={(selectedOption) => handleSelectChange('bankAccount', selectedOption)}
                                                            options={accounts.map((account) => ({
                                                                value: account.description,
                                                                label: (account.accountNumber, account.description)
                                                            }))}
                                                            placeholder="Select account"
                                                            required
                                                        />
                                                    </Form.Group>
                                                </Col>

                                                <Form.Group as={Col} md={6} sm={12} lg={6}>
                                                    <Form.Label>Supporting Document</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        onChange={handleImageChange}
                                                        name="image"
                                                        accept="image/*"
                                                        required
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                                    <Form.Label>Date</Form.Label>
                                                    <Form.Control
                                                        size="sm"
                                                        required
                                                        onChange={(e) => handleInput('recorded', e.target.value)}
                                                        type="date"
                                                        name="recorded"
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md={12} sm={12} lg={12} controlId="formBasic">
                                                    <Form.Label>Payment Reference</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        required
                                                        onChange={(e) => handleInput('pay_reference', e.target.value)}
                                                        type="text"
                                                        name="pay_reference"
                                                    />
                                                </Form.Group>
                                                {createPermission && (
                                                <Form.Group as={Col} md={12} sm={12} lg={12} controlId="formBasic">
                                                    <Button type="submit">Submit</Button>
                                                </Form.Group>
                                            )}
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                            </Form>
                        </Tab>
                    </Tabs>
                    {showReceipt && (
                        <Modal show={showReceiptModal} onHide={handleCloseReceiptModal} size="lg" centered>
                            <Modal.Header closeButton>
                                <Modal.Title>Receipt</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Receipt transactionId={transactionId} />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="success" onClick={handlePrint}>
                                    Print
                                </Button>
                                <Button variant="secondary" onClick={handleCloseReceiptModal}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    )}

                    {/* {showReceipt && <Receipt transactionId={transactionId} />} */}
                </Col>
            </Row>
            <ConfirmationModal show={showConfirmationModal} onHide={handleCancelConfirmation} onConfirm={handleConfirmation} />
        </React.Fragment>
    );
};

export default BasicTabsPills;
