import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListGroup, Dropdown, Media, Badge } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import axios from 'axios';
import ChatList from './ChatList';
import { API_SERVER } from '../../../../config/constant';
import { LOGOUT } from './../../../../store/actions';
import avatar1 from '../../../../assets/images/user/avatar-1.jpg';
import avatar2 from '../../../../assets/images/user/avatar-2.jpg';
import avatar3 from '../../../../assets/images/user/avatar-3.jpg';
import avatar4 from '../../../../assets/images/user/avatar-4.jpg';
import config from '../../../../config';
import socketIOClient from 'socket.io-client';

const NavRight = () => {
    const account = useSelector((state) => state.account);
    const dispatcher = useDispatch();
    const [listOpen, setListOpen] = useState(false);
    const history = useHistory();
    const [notifications, setNotifications] = useState([]); // State to store notifications
    const [unreadCount, setUnreadCount] = useState(0);
    const userId = account?.user?.id; 
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const ENDPOINT = `${apiUrl}`;
    const socket = socketIOClient(ENDPOINT);

    useEffect(() => {
        fetch(`${apiUrl}/api/checkItemQuantities`)
            .then((response) => response.json())
            .then((data) => {
                setNotifications(data);
                setUnreadCount(data.filter((notification) => !notification.read).length);
            })
            .catch((error) => console.error('Error fetching notifications:', error));
    }, []);

    const handleLogout = async () => {
        try {
            socket.emit('logout', userId);
            await dispatcher({ type: 'LOGOUT' });
            history.push('/auth/signin');
        } catch (error) {
            console.log(error);
        }
    };

    const handlePass = async () => {
        history.push('/auth/password-change');
    };

    const generateGreeting = () => {
        const currentTime = new Date();
        const currentHour = currentTime.getHours();

        let greeting = '';

        if (currentHour < 12) {
            greeting = 'Good morning';
        } else if (currentHour >= 12 && currentHour < 18) {
            greeting = 'Good afternoon';
        } else {
            greeting = 'Good evening';
        }

        const userName = `${account.user.firstname} ${account.user.lastname}`; // Replace with the actual user's name

        return (
            <span>
                {greeting},{' '}
                <span className="text-success" style={{ fontWeight: 'bold' }}>
                    {userName}
                </span>
            </span>
        );
    };

    return (
        <React.Fragment>
            <ListGroup as="ul" bsPrefix=" " className="navbar-nav ml-auto" id="navbar-right">
                <ListGroup.Item as="li" bsPrefix=" ">
                    {/* <span>
                  Hi,  {account.user.firstname} {account.user.lastname}
                </span> */}
                    {/* <Dropdown>
                        <Dropdown.Toggle as={Link} variant="link" to="#" id="dropdown-basic">
                        <i className="feather icon-bell icon" />
                        {unreadCount > 0 && (
                            <Badge variant="danger">{unreadCount}</Badge> // Display a badge with unread count
                        )}
                        </Dropdown.Toggle>
                        <Dropdown.Menu alignRight className="notification notification-scroll">
                        <div className="noti-head">
                            <h6 className="d-inline-block m-b-0">Notifications</h6>
                            <div className="float-right">
                            <Link to="#" className="m-r-10">
                                Mark as read
                            </Link>
                            <Link to="#">Clear all</Link>
                            </div>
                        </div>
                        <PerfectScrollbar>
                            <ListGroup as="ul" bsPrefix=" " variant="flush" className="noti-body">
                            {notifications.map((notification, index) => (
                                <ListGroup.Item
                                as="li"
                                bsPrefix=" "
                                className={`notification ${notification.read ? 'read' : 'unread'}`}
                                key={index}
                                >
                                <Media>
                                    <img className="img-radius" src={notification.avatar} alt="Generic placeholder" />
                                    <Media.Body>
                                    <p>
                                        <strong>{notification.sender}</strong>
                                        <span className="n-time text-muted">
                                        <i className="icon feather icon-clock m-r-10" />
                                        {notification.timestamp}
                                        </span>
                                    </p>
                                    <p>{notification.message}</p>
                                    </Media.Body>
                                </Media>
                                </ListGroup.Item>
                            ))}
                            </ListGroup>
                        </PerfectScrollbar>
                        <div className="noti-footer">
                            <Link to="#">Show all</Link>
                        </div>
                        </Dropdown.Menu>
                    </Dropdown> */}
                </ListGroup.Item>
                <ListGroup.Item as="li" bsPrefix=" ">
                    <span>{generateGreeting()}</span>
                    {/* <Dropdown>
                        <Dropdown.Toggle as={Link} variant="link" to="#" className="displayChatbox" onClick={() => setListOpen(true)}>
                            <i className="icon feather icon-mail" />
                        </Dropdown.Toggle>
                    </Dropdown> */}
                </ListGroup.Item>
                <ListGroup.Item as="li" bsPrefix=" ">
                    <Dropdown className="drp-user">
                        <Dropdown.Toggle as={Link} variant="link" to="#" id="dropdown-basic">
                            <i className="icon feather icon-settings" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu alignRight className="profile-notification">
                            <div className="pro-head">
                                {account.user.passport !== undefined ? (
                                    <img
                                        src={`${apiUrl}/uploads/${account.user.passport}`}
                                        className="img-radius"
                                        alt="User Passport"
                                        style={{
                                            width: '50px', // Adjust the width as needed
                                            height: '50px',
                                            borderRadius: '50%',
                                            objectFit: 'cover'
                                        }}
                                    />
                                ) : (
                                    <img src={avatar1} className="img-radius" alt="User Profile" />
                                )}
                                <span>
                                    {account.user.firstname} {account.user.lastname}
                                </span>
                                
                                <Link to="#" className="dud-logout" onClick={handleLogout} title="Logout">
                                    <i className="feather icon-log-out" />
                                </Link>
                            </div>
                            <ListGroup as="ul" bsPrefix=" " variant="flush" className="pro-body">
                                {/* <ListGroup.Item as="li" bsPrefix=" ">
                                    <Link to="#" className="dropdown-item">
                                        <i className="feather icon-settings" /> Settings
                                    </Link>
                                </ListGroup.Item> */}
                                <ListGroup.Item as="li" bsPrefix=" ">
                                    <Link to="#" className="dropdown-item text-bg-dark">
                                        <i className="feather icon-activity" /> {account.user.is_admin} Role
                                    </Link>
                                </ListGroup.Item>
                                {/* <ListGroup.Item as="li" bsPrefix=" ">
                                    <Link to="#" className="dropdown-item">
                                        <i className="feather icon-user" /> Profile
                                    </Link>
                                </ListGroup.Item> */}
                                {/* <ListGroup.Item as="li" bsPrefix=" ">
                                    <Link to="#" className="dropdown-item">
                                        <i className="feather icon-mail" /> My Messages
                                    </Link>
                                </ListGroup.Item> */}
                                {/* <ListGroup.Item as="li" bsPrefix=" ">
                                    <Link to="#" className="dropdown-item">
                                        <i className="feather icon-lock" /> Lock Screen
                                    </Link>
                                </ListGroup.Item> */}
                                <ListGroup.Item as="li" bsPrefix=" ">
                                    <Link to="#" className="dropdown-item" onClick={handlePass}>
                                        <i className="feather icon-unlock" /> Change Password
                                    </Link>
                                </ListGroup.Item>
                                <ListGroup.Item as="li" bsPrefix=" ">
                                    <Link to="#" className="dropdown-item" onClick={handleLogout}>
                                        <i className="feather icon-log-out" /> Logout
                                    </Link>
                                </ListGroup.Item>
                            </ListGroup>
                        </Dropdown.Menu>
                    </Dropdown>
                </ListGroup.Item>
            </ListGroup>
            <ChatList listOpen={listOpen} closed={() => setListOpen(false)} />
        </React.Fragment>
    );
};

export default NavRight;
