import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Button, Alert, Tabs, Tab } from 'react-bootstrap';
import { useHistory, useParams, Link } from 'react-router-dom';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../../../components/Card/MainCard';
import axios from 'axios';
import ConfirmationModal from '../../ui-elements/basic/ConfirmationModal';
import { usePermissions } from '../../../permissions';
import config from '../../../config';
import DataTable from 'react-data-table-component';
import PiVac from './BasicCollapse15.js';
import GroupMove from './BasicCollapse16.js';
import HouseMove from './BasicCollapse17.js';

const SamplePage = () => {
    const account = useSelector((state) => state.account);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const { createPermission, deletePermission, editPermission } = usePermissions();
    const [isPopupVisible, setPopupVisible] = useState(false);    

    const hidePopup = () => {
        setPopupVisible(false);
    };

    const showPopup = () => {
        setPopupVisible(true);
    };

    return (
        <React.Fragment>
            <Row className="btn-page">
                <Col>
                    <div className="d-flex justify-content-between align-center m-b-10">
                        <div>
                            <span> </span>
                        </div>
                        {createPermission && (
                            <Button onClick={showPopup} className="mb-10">
                                <span className="feather icon-plus-circle"></span> Move Weaners
                            </Button>
                        )}
                        {isPopupVisible && <PiVac onCancel={hidePopup} />}
                    </div>
                </Col>
            </Row>
            <Tabs defaultActiveKey="raw">
                <Tab eventKey="feeds" title="House Move" className="mt-4">
                    <HouseMove />
                </Tab>
                <Tab eventKey="raw" title="Group Move" className="mt-4">
                    <GroupMove />
                </Tab>
            </Tabs>
            
        </React.Fragment>
    );
};

export default SamplePage;
