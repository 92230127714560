import React from 'react';
import { Alert, Row, Col } from 'react-bootstrap';

const Notification = (props) => {
    return (
        <React.Fragment>
            <Alert variant='warning'>
                <Row>
                    <Col className='font-weight-bold' md={12} sm={12} lg={12} xl={6}>{props.message}</Col>
                    <Col className='' md={12} sm={12} lg={12} xl={6}>{props.count}</Col>
                </Row>
            </Alert>
        </React.Fragment>
    );
};

export default Notification;