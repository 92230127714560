import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Table, Button, Pagination, Form, Card } from 'react-bootstrap';
import Select from 'react-select';
import Pvac from './../ui-elements/basic/BasicTabsPills2';
import { CSVLink } from 'react-csv';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import axios from 'axios';
import config from '../../config';
import { useParams, useHistory } from 'react-router-dom';
import { usePermissions } from '../../permissions';
import DataTable from 'react-data-table-component';
// import Card from '../../components/Card/MainCard';

const UserList = () => {
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [users, setUsers] = useState([]);
    const [Da, setDa] = useState([]);
    const [We, setWe] = useState([]);
    const [Mo, setMo] = useState([]);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const { createPermission, deletePermission, editPermission } = usePermissions();
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/poultry_vaccine_consumption`);
                setUsers(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [users, apiUrl]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/poultry/vaccines/daily`);
                setDa(response.data[0]);
                // console.log(response.data)
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [users, apiUrl]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/poultry/vaccines/weekly`);
                setWe(response.data[0]);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [users, apiUrl]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/poultry/vaccines/monthly`);
                setMo(response.data[0]);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [users, apiUrl]);

    const calculateAge = (birthDate) => {
        const today = new Date();
        const birth = new Date(birthDate);

        // Get the UTC values for year, month, and day
        const todayUTC = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
        const birthUTC = Date.UTC(birth.getFullYear(), birth.getMonth(), birth.getDate());

        // Calculate the age difference in milliseconds
        const ageDiffMillis = todayUTC - birthUTC;

        // Convert milliseconds to years, months, and days
        const yearDiff = Math.floor(ageDiffMillis / (1000 * 60 * 60 * 24 * 365));
        const monthDiff = Math.floor((ageDiffMillis % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30));
        const dayDiff = Math.floor((ageDiffMillis % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24));

        if (yearDiff > 0) {
            return yearDiff === 1 ? `${yearDiff} year` : `${yearDiff} years`;
        } else if (monthDiff > 0) {
            return monthDiff === 1 ? `${monthDiff} month` : `${monthDiff} months`;
        } else {
            return dayDiff === 1 ? `${dayDiff} day` : `${dayDiff} days`;
        }
    };

    const hidePopup = () => {
        setPopupVisible(false);
    };

    const showPopup = () => {
        setPopupVisible(true);
    };

    const csvLinkRef = useRef();

    const exportToCSV = () => {
        const csvData = [
            ['Vaccine Name', 'Type', 'Quantity', 'Batch No.', 'Served By', 'Date', 'Farm'],
            ...filteredData.map((item) => [
                item.vaccineName,
                item.type,
                item.vaccineQuantity,
                `${item.batchVaccinated}`,
                item.sup_info,
                item.servedBy,
                item.createdAt,
                item.farm
            ])
        ];

        const csvContent = csvData.map((row) => row.join(',')).join('\n');
        const blob = new Blob(['\ufeff', csvContent], { type: 'text/csv;charset=utf-8;' });

        const csvUrl = URL.createObjectURL(blob);
        csvLinkRef.current.href = csvUrl;
        csvLinkRef.current.download = 'sfarms.csv';
        csvLinkRef.current.click();
    };

    const exportToPDF = () => {
        const doc = new jsPDF();
        doc.autoTable({
            head: [['Vaccine Name', 'Type', 'Quantity', 'Batch No.', 'Served By', 'Date', 'Farm']],
            body: filteredData.map((user) => [
                user.vaccineName,
                user.type,
                user.vaccineQuantity,
                `${user.batchVaccinated}`,
                user.servedBy,
                new Intl.DateTimeFormat('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                }).format(new Date(user.createdAt)),
                user.farm
            ])
        });
        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl);
    };

    const columns = [
        {
            name: 'Vaccine Name',
            selector: (row) => row.vaccineName,
            sortable: true
        },
        {
            name: 'Type',
            selector: (row) => row.type,
            sortable: true,
            wrap: true
        },

        {
            name: 'Quantity',
            selector: (row) => row.vaccineQuantity,
            sortable: true
        },
        {
            name: 'Batch No',
            selector: (row) => row.batchVaccinated,
            sortable: true,
            wrap: true
        },
        {
            name: 'Served By',
            selector: (row) => row.servedBy,
            sortable: true,
            wrap: true
        },
        {
            name: 'farm',
            selector: (row) => row.farm,
            sortable: true,
            wrap: true
        },

        {
            name: 'Timestamp',
            selector: (row) => new Date(row?.createdAt).toLocaleString(),
            sortable: true,
            wrap: true
        }
        // {
        //     name: 'DOB',
        //     selector: (row) => new Date(row.dob).toLocaleDateString(),
        //     sortable: true,
        //     wrap: true
        // }
    ];

    const filteredData = searchText
        ? users.filter((item) =>
              Object.values(item).some((value) => typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase()))
          )
        : users;

    return (
        <React.Fragment>
            <Row>
                <Col md={4} xl={4}>
                    <Card style={{}}>
                        <Card.Body className="">
                            <div className="row d-flex align-items-center">
                                <div className="col-6">
                                    <h6 className="">Daily</h6>
                                </div>
                                <div className="col-6 text-right">{Da && <span>{Da.total_consumed} Kgs/Litres</span>}</div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4} xl={4}>
                    <Card style={{}}>
                        <Card.Body className="">
                            <div className="row d-flex align-items-center">
                                <div className="col-6">
                                    <h6 className="">Weekly</h6>
                                </div>
                                <div className="col-6 text-right">{We && <span>{We.total_consumed} Kgs/Litres</span>}</div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4} xl={4}>
                    <Card style={{}}>
                        <Card.Body className="">
                            <div className="row d-flex align-items-center">
                                <div className="col-6">
                                    <h6 className="">Monthly </h6>
                                </div>

                                <div className="col-6 text-right">{Mo && <span>{Mo.total_consumed} Kgs/Litres</span>}</div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <div className="d-flex justify-content-between align-center m-b-10">
                {createPermission && (
                    <Button onClick={showPopup} className="mb-10">
                        <span className="feather icon-plus-circle"></span> Vaccinate
                    </Button>
                )}
                {isPopupVisible && <Pvac onCancel={hidePopup} />}
            </div>
            <Row className="btn-page">
                <Col>
                    {filteredData && filteredData.length > 0 && (
                        <Row>
                            <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasicName">
                                <Form.Control
                                    type="text"
                                    placeholder="Search..."
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasicName">
                                <div className="d-flex">
                                    <Button onClick={exportToCSV}>Export as CSV</Button>
                                    <a ref={csvLinkRef} style={{ display: 'none' }} />
                                    <a href="#" className="btn btn-primary" onClick={exportToPDF}>
                                        Export PDF
                                    </a>
                                </div>
                            </Form.Group>
                        </Row>
                    )}
                    <Row>
                        <Col>
                            <DataTable
                                columns={columns}
                                data={filteredData}
                                pagination
                                highlightOnHover
                                striped
                                // noHeader
                                dense
                                bordered
                                paginationPerPage={10}
                                paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                                paginationComponentOptions={{
                                    rowsPerPageText: 'Rows per page:'
                                }}
                                paginationResetDefaultPage={true}
                                paginationTotalRows={users.length}
                                // expandableRows
                                selectableRows
                                onSelectedRowsChange={(selectedRows) => console.log(selectedRows)}
                                customStyles={{
                                    headRow: {
                                        style: {
                                            backgroundColor: '#279e6c', // Green background
                                            color: '#fff', // White text
                                            textTransform: 'uppercase', // Uppercase text
                                            // fontSize: '10px', // Adjust font size for header row
                                            minHeight: '1.5rem',
                                            paddingTop: '8px',
                                            paddingBottom: '8px'
                                        }
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default UserList;
