import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Table, Button, Pagination, Form } from 'react-bootstrap';
import Select from 'react-select';
import { CSVLink } from 'react-csv';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import axios from 'axios';
import config from '../../config';
import { useParams, useHistory } from 'react-router-dom';
import Card from '../../components/Card/MainCard';
import { usePermissions } from '../../permissions';
import DataTable from 'react-data-table-component';

const UserList = () => {
    const [users, setUsers] = useState([]);
    const { id } = useParams();
    const history = useHistory();
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const { createPermission, deletePermission, editPermission } = usePermissions();
    const [searchText, setSearchText] = useState('');
    const [isPopupVisible, setPopupVisible] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/vaccines`);
                setUsers(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [users, apiUrl]);

    const hidePopup = () => {
        setPopupVisible(false);
    };

    const showPopup = () => {
        setPopupVisible(true);
    };

    const calculateAge = (birthDate) => {
        const today = new Date();
        const birth = new Date(birthDate);

        // Get the UTC values for year, month, and day
        const todayUTC = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
        const birthUTC = Date.UTC(birth.getFullYear(), birth.getMonth(), birth.getDate());

        // Calculate the age difference in milliseconds
        const ageDiffMillis = todayUTC - birthUTC;

        // Convert milliseconds to years, months, and days
        const yearDiff = Math.floor(ageDiffMillis / (1000 * 60 * 60 * 24 * 365));
        const monthDiff = Math.floor((ageDiffMillis % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30));
        const dayDiff = Math.floor((ageDiffMillis % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24));

        if (yearDiff > 0) {
            return yearDiff === 1 ? `${yearDiff} year ago` : `${yearDiff} years ago`;
        } else if (monthDiff > 0) {
            return monthDiff === 1 ? `${monthDiff} month ago` : `${monthDiff} months ago`;
        } else if (dayDiff > 0) {
            return dayDiff === 1 ? `${dayDiff} day ago` : `${dayDiff} days ago`;
        } else {
            return dayDiff === 0 ? `Today` : `${dayDiff} days ago`;
        }
    };

    const csvLinkRef = useRef();

    const exportToCSV = () => {
        const csvData = [
            ['Supplier Name', 'Vaccine Name', 'Quanity', 'Cost', 'Date'],
            ...users.map((item) => [item.sup_name, item.vaccine, item.quantity, item.cost, item.createdAt])
        ];

        const csvContent = csvData.map((row) => row.join(',')).join('\n');
        const blob = new Blob(['\ufeff', csvContent], { type: 'text/csv;charset=utf-8;' });

        const csvUrl = URL.createObjectURL(blob);
        csvLinkRef.current.href = csvUrl;
        csvLinkRef.current.download = 'sfarms.csv';
        csvLinkRef.current.click();
    };

    const exportToPDF = () => {
        const doc = new jsPDF();
        doc.autoTable({
            head: [['Supplier Name', 'Vaccine Name', 'Quanity', 'Cost', 'Date']],
            body: users.map((user) => [
                user.sup_name,
                user.vaccine,
                user.quantity,
                user.cost.toLocaleString(),
                new Intl.DateTimeFormat('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                }).format(new Date(user.createdAt))
            ])
        });
        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl);
    };

    const columns = [
        {
            name: 'Supplier Name',
            selector: (row) => row.sup_name,
            sortable: true
        },
        {
            name: 'vaccine Name',
            selector: (row) => row.vaccine,
            sortable: true
        },
        {
            name: 'Quantity',
            selector: (row) =>
                row?.quantity !== null
                    ? row.quantity?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                      })
                    : '-',
            sortable: true,
            wrap: true
        },
        {
            name: 'initial cost',
            selector: (row) =>
                row.cost !== null
                    ? row.cost?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                      })
                    : '',
            sortable: true,
            wrap: true
        },

        {
            name: 'Date',
            selector: (row) => new Date(row.recorded).toDateString(),
            sortable: true
        },

        {
            name: 'Updated Date',
            selector: (row) => calculateAge(row.createdAt),
            sortable: true,
            wrap: true
        }
    ];

    const filteredData = searchText
        ? users.filter((item) =>
              Object.values(item).some((value) => typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase()))
          )
        : users;

    return (
        <React.Fragment>
            <Row className="btn-page">
                <Col>
                    <div className="d-flex justify-content-between align-items-center m-b-10">
                        {createPermission && (
                            <Link to="/vaccine/add-vaccine">
                                <Button variant="primary" className="">
                                    <span className="feather icon-plus-circle"></span> Add Vaccine
                                </Button>
                            </Link>
                        )}
                    </div>

                    <Row className="btn-page">
                        <Col>
                            <Row className="btn-page">
                                <Col>
                                    {filteredData && filteredData.length > 0 && (
                                        <Row>
                                            <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasicName">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Search..."
                                                    value={searchText}
                                                    onChange={(e) => setSearchText(e.target.value)}
                                                />
                                            </Form.Group>

                                            <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasicName">
                                                <div className="d-flex">
                                                    <Button onClick={exportToCSV}>
                                                        <span className="feather icon-file"></span> CSV
                                                    </Button>
                                                    <a ref={csvLinkRef} style={{ display: 'none' }} />
                                                    <a href="#" className="btn btn-primary" onClick={exportToPDF}>
                                                        <span className="feather icon-file-text"></span> PDF
                                                    </a>
                                                </div>
                                            </Form.Group>
                                        </Row>
                                    )}
                                    <Row>
                                        <Col>
                                            <DataTable
                                                columns={columns}
                                                data={filteredData}
                                                pagination
                                                highlightOnHover
                                                striped
                                                // noHeader
                                                dense
                                                bordered
                                                paginationPerPage={10}
                                                paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                                                paginationComponentOptions={{
                                                    rowsPerPageText: 'Rows per page:'
                                                }}
                                                paginationResetDefaultPage={true}
                                                paginationTotalRows={users.length}
                                                // expandableRows
                                                selectableRows
                                                onSelectedRowsChange={(selectedRows) => console.log(selectedRows)}
                                                customStyles={{
                                                    headRow: {
                                                        style: {
                                                            backgroundColor: '#279e6c', // Green background
                                                            color: '#fff', // White text
                                                            textTransform: 'uppercase', // Uppercase text
                                                            // fontSize: '10px', // Adjust font size for header row
                                                            minHeight: '1.5rem',
                                                            paddingTop: '8px',
                                                            paddingBottom: '8px'
                                                        }
                                                    }
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default UserList;
