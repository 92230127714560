import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, PDFViewer, Font, Image } from '@react-pdf/renderer';
import config from './../../../config';
import axios from 'axios';
import Card from '../../../components/Card/MainCard';
import MontserratRegular from './../../../fonts/static/Montserrat-Regular.ttf';
import MontserratBold from './../../../fonts/static/Montserrat-Bold.ttf';

Font.register({
    family: 'Montserrat',
    fonts: [
        {
            src: MontserratRegular,
            fontWeight: 'normal'
        },
        {
            src: MontserratBold,
            fontWeight: 'bold'
        }
        // Register other font weights if needed
    ]
});

const UpdateUser = () => {
    const [user, setUser] = useState({});
    const [users, setUsers] = useState({});
    const [mot, setMot] = useState({});
    const [vac, setVac] = useState({});
    const [sel, setSel] = useState({});
    const [fee, setFee] = useState({});
    const [sir, setSir] = useState({});
    const { tagNumber } = useParams();
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [pdfBlob, setPdfBlob] = useState(null);
    const [pdfData, setPdfData] = useState([]);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await axios.get(`${apiUrl}/pigs/tagNumber/${tagNumber}`);
                setUser(response.data);
                setUsers(response.data[0]);
                console.log(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchUser();
    }, [apiUrl]);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await axios.get(`${apiUrl}/vaccinated_tagNumber/${tagNumber}`);
                setVac(response.data);
                // console.log(response.data)
            } catch (error) {
                console.log(error);
            }
        };
        fetchUser();
    }, [apiUrl]);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await axios.get(`${apiUrl}/mortality/tagNumber/${tagNumber}`);
                setMot(response.data);
                // console.log(response.data)
            } catch (error) {
                console.log(error);
            }
        };
        fetchUser();
    }, [apiUrl]);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await axios.get(`${apiUrl}/sold_tagNumber/${tagNumber}`);
                setSel(response.data);
                // console.log(response.data[0])
            } catch (error) {
                console.log(error);
            }
        };
        fetchUser();
    }, [tagNumber]);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await axios.get(`${apiUrl}/sired_tagNumber/${tagNumber}`);
                setSir(response.data);
                // console.log(response.data[0])
            } catch (error) {
                console.log(error);
            }
        };
        fetchUser();
    }, [tagNumber]);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await axios.get(`${apiUrl}/feed_tagNumber/${tagNumber}`);
                setFee(response.data);
                // console.log(response.data[0])
            } catch (error) {
                console.log(error);
            }
        };
        fetchUser();
    }, [tagNumber]);

    // useEffect(() => {
    //   const fetchUser = async () => {
    //     try {
    //       const response = await axios.get(`${apiUrl}/expense_tagNumber/${tagNumber}`);
    //       setExp(response.data);
    //       // console.log(response.data[0])
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   };
    //   fetchUser();
    // }, [tagNumber]);

    const generatePdf = () => {
        const combinedData = {
            user: Array.isArray(user) ? user : [user],
            fee: Array.isArray(fee) ? fee : [fee],
            vac: Array.isArray(vac) ? vac : [vac],
            mot: Array.isArray(mot) ? mot : [mot],
            sir: Array.isArray(sir) ? sir : [sir],
            sel: Array.isArray(sel) ? sel : [sel],
            // exp: Array.isArray(exp) ? exp : [exp],
            pdfData: pdfData
        };

        setPdfData(combinedData);

        const blob = new Blob([<PDFContent {...combinedData} tagNumber={tagNumber} />], {
            type: 'application/pdf'
        });
        setPdfBlob(blob);
    };

    let selTotal = 0;
    let vacTotal = 0;
    let sirTotal = 0;
    let motTotal = 0;
    let userTotal = 0;
    let feeTotal = 0;
    let feeCost = 0;
    let individualFeeTotal = 0;
    let individualFeeCost = 0;

    if (Array.isArray(sel)) {
        selTotal = sel.reduce((acc, item) => acc + item.totalCost, 0);
    } else if (sel) {
        selTotal = sel.totalCost;
    }

    if (Array.isArray(vac)) {
        vacTotal = vac.reduce((acc, item) => acc + item.vaccineQuantity, 0);
    } else if (vac) {
        vacTotal = vac.vaccineQuantity;
    }

    if (Array.isArray(sir)) {
        sirTotal = sir.reduce((acc, item) => acc + item.pigletsSired, 0);
    } else if (sir) {
        sirTotal = sir.pigletsSired;
    }

    if (Array.isArray(mot)) {
        motTotal = mot.reduce((acc, item) => acc + item.pigletMortality, 0);
    } else if (mot) {
        motTotal = mot.pigletMortality;
    }

    if (Array.isArray(user)) {
        userTotal = user.reduce((acc, item) => acc + item.cost, 0);
    } else if (user) {
        userTotal = user.cost;
    }

    if (Array.isArray(fee)) {
        feeTotal = fee?.reduce((acc, item) => acc + item?.totalQuantity, 0);
        feeCost = fee?.reduce((acc, item) => acc + item?.totalCost, 0);

        const feeTotals = fee?.reduce((acc, item) => acc + item.totalQuantity, 0);
        const feeCosts = fee?.reduce((acc, item) => acc + item.totalCost, 0);

        const totalSiloCount = fee?.reduce((acc, item) => {
            const siloNumbers = Array.isArray(item.siloNumber) ? item.siloNumber : item.siloNumber?.split(',');
            return acc + siloNumbers.length;
        }, 0);

        individualFeeTotal = feeTotals / totalSiloCount;
        individualFeeCost = feeCosts / totalSiloCount;
    } else if (fee) {
        feeTotal = fee?.totalQuantity;
        feeCost = fee?.totalCost;

        const feeTotals = fee?.totalQuantity;
        const feeCosts = fee?.totalCost;

        const siloNumbers = Array.isArray(fee?.siloNumber) ? fee?.siloNumber : fee?.siloNumber?.split(',');
        const totalSiloCount = siloNumbers?.length;

        individualFeeTotal = feeTotals / totalSiloCount;
        individualFeeCost = feeCosts / totalSiloCount;
    }

    return (
        <React.Fragment>
            <Row>
                <Col sm={12}>
                    <Link to="/pigs/pigs">
                        <Button variant="primary" className="mb-3 mt-2">
                            <span className="feather icon-arrow-left"></span> Go Back
                        </Button>
                    </Link>
                    <Card title="Tag Number history" isOption>
                        <Row>
                            <Col sm={12} md={12} className="border-bottom">
                                <div className="row d-flex align-items-center m-b-10">
                                    <div className="col-12">
                                        <div className="pdf-button-container">
                                            <Button onClick={generatePdf} className="no-pdf">
                                                <i className="feather icon-printer f-40 text-c-white" />
                                            </Button>
                                            {pdfBlob && (
                                                <PDFDownloadLink
                                                    document={
                                                        <PDFContent
                                                            tagNumber={tagNumber}
                                                            user={user}
                                                            fee={fee}
                                                            sir={sir}
                                                            vac={vac}
                                                            mot={mot}
                                                            sel={sel}
                                                            pdfData={pdfData}
                                                            motTotal={motTotal}
                                                            sirTotal={sirTotal}
                                                            vacTotal={vacTotal}
                                                            feeTotal={feeTotal}
                                                            userTotal={userTotal}
                                                            selTotal={selTotal}
                                                            feeCost={feeCost}
                                                        />
                                                    }
                                                    fileName={`pig_tagNumber_${tagNumber}.pdf`}
                                                >
                                                    {({ blob, url, loading, error }) => (loading ? 'Loading...' : 'Print PDF')}
                                                </PDFDownloadLink>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col sm={12} md={12} className="border-bottom">
                                <div className="row d-flex align-items-center m-b-10">
                                    <div className="col-4">
                                        <img
                                            src={avatarImage}
                                            alt="Comapny Logo"
                                            style={{ width: '100px', height: 'auto', align: 'center' }}
                                        />
                                    </div>
                                    <div className="col-4"></div>
                                    <div className="col-4">
                                        {/* <div>SFARMS.CO.KE</div> */}
                                        <div>P.O BOX 800-222</div>
                                        <div>Naivasha</div>
                                        <div>Phone : 254-787-689-760</div>
                                        <div>Email : info@sfarms.co.ke</div>
                                    </div>
                                </div>
                            </Col>

                            <Col sm={12} md={12} className="border-bottom color">
                                <div className="row d-flex align-items-center m-t-20 m-b-20">
                                    <div className="col-4">
                                        <h6 className="d-block text-uppercase m-1 color">Summary</h6>
                                        <p className="m-1">
                                            <strong className="font-weight-bold">Total Income:</strong> KS{' '}
                                            {selTotal !== null && selTotal !== undefined
                                                ? selTotal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                : ''}
                                        </p>
                                        <p className="m-1">
                                            <strong className="font-weight-bold">Total Expense:</strong>
                                            {users.archived === 0 ? (
                                                // Display the total when user is not archived
                                                <span>
                                                    KS{' '}
                                                    {userTotal !== null && userTotal !== undefined
                                                        ? userTotal.toLocaleString(undefined, {
                                                              minimumFractionDigits: 2,
                                                              maximumFractionDigits: 2
                                                          })
                                                        : ''}
                                                </span>
                                            ) : (
                                                // Display "Archived" when user is archived
                                                <span>Archived</span>
                                            )}
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <p className="m-1">
                                            <strong className="font-weight-bold">Feed Quantity:</strong>{' '}
                                            {feeTotal !== null && feeTotal !== undefined
                                                ? individualFeeTotal.toLocaleString(undefined, {
                                                      minimumFractionDigits: 2,
                                                      maximumFractionDigits: 2
                                                  })
                                                : ''}{' '}
                                            Kgs/lts
                                        </p>
                                        <p className="m-1">
                                            <strong className="font-weight-bold">Feed Cost:</strong> KS{' '}
                                            {feeCost !== null && feeCost !== undefined
                                                ? individualFeeCost.toLocaleString(undefined, {
                                                      minimumFractionDigits: 2,
                                                      maximumFractionDigits: 2
                                                  })
                                                : ''}
                                        </p>
                                        <p className="m-1">
                                            <strong className="font-weight-bold">Vaccine Quantity:</strong>{' '}
                                            {vacTotal !== null && vacTotal !== undefined
                                                ? vacTotal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                : ''}{' '}
                                            Kgs/lts
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <p className="m-1">
                                            <strong className="font-weight-bold">Piglets Sired:</strong> {sirTotal}
                                        </p>
                                        <p className="m-1">
                                            <strong className="font-weight-bold">Total Mortality:</strong> {motTotal}
                                        </p>
                                    </div>
                                </div>
                            </Col>

                            {Array.isArray(user) ? (
                                user.map((item, index) => (
                                    <Col key={index} sm={12} md={12} className="border-bottom">
                                        <div className="row d-flex align-items-center m-t-20 m-b-20">
                                            <div className="col-4">
                                                <h6 className="d-block text-uppercase m-1">Bought</h6>
                                                <p className="m-1">
                                                    <strong className="font-weight-bold">Supplier:</strong> {item.sup_name}
                                                </p>
                                                <p className="m-1">
                                                    <strong className="font-weight-bold">Quantity:</strong> {item.quantity}
                                                </p>
                                                <p className="m-1">
                                                    <strong className="font-weight-bold">D.O.B:</strong>{' '}
                                                    {new Date(item.birth).toLocaleDateString()}
                                                </p>
                                                <p className="m-1">
                                                    <strong className="font-weight-bold">Health:</strong> {item.health}
                                                </p>
                                            </div>
                                            <div className="col-4">
                                                <p className="m-1">
                                                    <strong className="font-weight-bold">Pig Type:</strong> {item.type}
                                                </p>
                                                <p className="m-1">
                                                    <strong className="font-weight-bold">Total Cost:</strong> Ksh {item.cost}
                                                </p>
                                                <p className="m-1">
                                                    <strong className="font-weight-bold">Silo Number:</strong> {item.siloNumber}
                                                </p>
                                                <p className="m-1">
                                                    <strong className="font-weight-bold">Age:</strong> {item.age}
                                                </p>
                                            </div>
                                            <div className="col-4">
                                                <p className="m-1">
                                                    <strong className="font-weight-bold">Date:</strong>{' '}
                                                    {new Date(item.createdAt).toLocaleString()}
                                                </p>
                                                <p className="m-1">
                                                    <strong className="font-weight-bold">Weight:</strong> {item.weight} Kgs
                                                </p>
                                                <p className="m-1">
                                                    <strong className="font-weight-bold">Remarks:</strong> {item.remarks}
                                                </p>
                                                <p className="m-1">
                                                    <strong className="font-weight-bold">Status:</strong>{' '}
                                                    {item.archived === 0 ? 'Not Archived' : 'Archived'}
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                ))
                            ) : user && user.tagNumber ? (
                                <Col sm={12} md={12} className="border-bottom">
                                    <div className="row d-flex align-items-center m-t-20 m-b-20">
                                        <div className="col-4">
                                            <h6 className="d-block text-uppercase m-1">Bought</h6>
                                            <p className="m-1">
                                                <strong className="font-weight-bold">Supplier:</strong> {user.sup_name}
                                            </p>
                                            <p className="m-1">
                                                <strong className="font-weight-bold">Quantity:</strong> {user.quantity}
                                            </p>
                                            <p className="m-1">
                                                <strong className="font-weight-bold">D.O.B:</strong>{' '}
                                                {new Date(user.birth).toLocaleDateString()}
                                            </p>
                                            <p className="m-1">
                                                <strong className="font-weight-bold">Health:</strong> {user.health}
                                            </p>
                                        </div>
                                        <div className="col-4">
                                            <p className="m-1">
                                                <strong className="font-weight-bold">Pig Type:</strong> {user.type}
                                            </p>
                                            <p className="m-1">
                                                <strong className="font-weight-bold">Total Cost:</strong> Ksh {user.cost}
                                            </p>
                                            <p className="m-1">
                                                <strong className="font-weight-bold">Silo Number:</strong> {user.siloNumber}
                                            </p>
                                            <p className="m-1">
                                                <strong className="font-weight-bold">Age:</strong> {user.age}
                                            </p>
                                        </div>
                                        <div className="col-4">
                                            <p className="m-1">
                                                <strong className="font-weight-bold">Date:</strong>{' '}
                                                {new Date(user.createdAt).toLocaleString()}
                                            </p>
                                            <p className="m-1">
                                                <strong className="font-weight-bold">Weight:</strong> {user.weight} Kgs
                                            </p>
                                            <p className="m-1">
                                                <strong className="font-weight-bold">Remarks:</strong> {user.remarks}
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            ) : null}

                            {Array.isArray(fee)
                                ? fee.map((item, index) => (
                                      <Col sm={12} md={12} className="border-bottom">
                                          <div className="row d-flex align-items-center m-t-20 m-b-20">
                                              <div className="col-4">
                                                  <h6 className="d-block text-uppercase m-1">Fed</h6>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Feed Name:</strong> {item.feed_name},{' '}
                                                      {item?.feed_name1}, {item?.feed_name2}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Quantity:</strong> {item.totalQuantity}{' '}
                                                      Kgs/Litres
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Silo No. Fed:</strong> {item.siloNumber}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Individual Quantity:</strong>{' '}
                                                      {item?.individualValues?.join(', ')}
                                                  </p>
                                              </div>
                                              <div className="col-4">
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Pig Type:</strong> {item.type}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Tag Number:</strong> {item.tagNumber}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Remarks:</strong> {item.remarks}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Individual Cost:</strong>{' '}
                                                      {item?.individualCosts?.join(', ')}
                                                  </p>
                                              </div>
                                              <div className="col-4">
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Date:</strong>{' '}
                                                      {new Date(item.createdAt).toLocaleString()}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">ID:</strong> {item.id}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Total Cost:</strong> {item.totalCost}
                                                  </p>
                                              </div>
                                          </div>
                                      </Col>
                                  ))
                                : fee &&
                                  fee.tagNumber && (
                                      <Col sm={12} md={12} className="border-bottom">
                                          <div className="row d-flex align-items-center m-t-20 m-b-20">
                                              <div className="col-4">
                                                  <h6 className="d-block text-uppercase m-1">Fed</h6>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Feed Name:</strong> {fee.feed_name},{' '}
                                                      {fee?.feed_name1}, {fee?.feed_name2}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Quantity:</strong> {fee.totalQuantity} Kgs/Litres
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Silo No. Fed:</strong> {fee.siloNumber}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Individual Quantity:</strong>{' '}
                                                      {fee?.individualValues?.join(', ')}
                                                  </p>
                                              </div>
                                              <div className="col-4">
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Pig Type:</strong> {fee.type}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Tag Number:</strong> {fee.tagNumber}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Remarks:</strong> {fee.remarks}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Individual Cost:</strong>{' '}
                                                      {fee?.individualCosts?.join(', ')}
                                                  </p>
                                              </div>
                                              <div className="col-4">
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Date:</strong>{' '}
                                                      {new Date(fee.createdAt).toLocaleString()}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">ID:</strong> {fee.id}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Total Cost:</strong> {fee.totalCost}
                                                  </p>
                                              </div>
                                          </div>
                                      </Col>
                                  )}

                            {Array.isArray(sir)
                                ? sir.map((item, index) => (
                                      <Col key={index} sm={12} md={12} className="border-bottom">
                                          <div className="row d-flex align-items-center m-t-20 m-b-20">
                                              <div className="col-4">
                                                  <h6 className="d-block text-uppercase m-1">Sired</h6>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Piglets Sired:</strong> {item.pigletsSired}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Silo No.:</strong> {item.siloNumber} Kgs/Litres
                                                  </p>
                                              </div>
                                              <div className="col-4">
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Date Sired:</strong>{' '}
                                                      {new Date(item.dateSired).toLocaleDateString()}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Tag Number:</strong> {item.tagNumber}
                                                  </p>
                                              </div>
                                              <div className="col-4">
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Recorded Date:</strong>{' '}
                                                      {new Date(item.createdAt).toLocaleString()}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">ID:</strong> {item.id}
                                                  </p>
                                              </div>
                                          </div>
                                      </Col>
                                  ))
                                : sir &&
                                  sir.tagNumber && (
                                      <Col sm={12} md={12} className="border-bottom">
                                          <div className="row d-flex align-items-center m-t-20 m-b-20">
                                              <div className="col-4">
                                                  <h6 className="d-block text-uppercase m-1">Sired</h6>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Piglets Sired:</strong> {sir.pigletsSired}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Silo No.:</strong> {sir.siloNumber}
                                                  </p>
                                              </div>
                                              <div className="col-4">
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Date Sired:</strong>{' '}
                                                      {new Date(sir.dateSired).toLocaleDateString()}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Tag Number:</strong> {sir.tagNumber}
                                                  </p>
                                              </div>
                                              <div className="col-4">
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Recorded Date:</strong>{' '}
                                                      {new Date(sir.createdAt).toLocaleString()}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">ID:</strong> {sir.id}
                                                  </p>
                                              </div>
                                          </div>
                                      </Col>
                                  )}

                            {Array.isArray(vac)
                                ? vac.map((item, index) => (
                                      <Col key={index} sm={12} md={12} className="border-bottom">
                                          <div className="row d-flex align-items-center m-t-20 m-b-20">
                                              <div className="col-4">
                                                  <h6 className="d-block text-uppercase m-1">vaccinated</h6>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Vaccine Name:</strong> {item.vaccineName}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Quantity:</strong> {item.vaccineQuantity}
                                                  </p>
                                              </div>
                                              <div className="col-4">
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Vaccinated By:</strong> {item.servedBy}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Tag Number:</strong> {item.tagNumber}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Silo No. Vaccinated:</strong>{' '}
                                                      {item.siloVaccinated}
                                                  </p>
                                              </div>
                                              <div className="col-4">
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Date:</strong>{' '}
                                                      {new Date(item.createdAt).toLocaleString()}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">ID:</strong> {item.id}
                                                  </p>
                                              </div>
                                          </div>
                                      </Col>
                                  ))
                                : vac &&
                                  vac.tagNumber && (
                                      <Col sm={12} md={12} className="border-bottom">
                                          <div className="row d-flex align-items-center m-t-20 m-b-20">
                                              <div className="col-4">
                                                  <h6 className="d-block text-uppercase m-1">vaccinated</h6>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Vaccine Name:</strong> {vac.vaccineName}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Quantity:</strong> {vac.vaccineQuantity}{' '}
                                                      Kgs/Litres
                                                  </p>
                                              </div>
                                              <div className="col-4">
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Vaccinated By:</strong> {vac.servedBy}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Tag Number::</strong> {vac.tagNumber}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Silo Number::</strong> {vac.siloNumber}
                                                  </p>
                                              </div>
                                              <div className="col-4">
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Date:</strong>{' '}
                                                      {new Date(vac.createdAt).toLocaleString()}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">ID:</strong> {vac.id}
                                                  </p>
                                              </div>
                                          </div>
                                      </Col>
                                  )}

                            {Array.isArray(mot)
                                ? mot.map((item, index) => (
                                      <Col key={index} sm={12} md={12} className="border-bottom">
                                          <div className="row d-flex align-items-center m-t-20 m-b-20">
                                              <div className="col-4">
                                                  <h6 className="d-block text-uppercase m-1">Mortality</h6>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Piglet Mortality:</strong> {item.pigletMortality}
                                                  </p>
                                              </div>
                                              <div className="col-4">
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Tag Number:</strong> {item.tagNumber}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Remarks:</strong> {item.remarks}
                                                  </p>
                                              </div>
                                              <div className="col-4">
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Date:</strong>{' '}
                                                      {new Date(item.createdAt).toLocaleString()}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">ID:</strong> {item.id}
                                                  </p>
                                              </div>
                                          </div>
                                      </Col>
                                  ))
                                : mot &&
                                  mot.tagNumber && (
                                      <Col sm={12} md={12} className="border-bottom">
                                          <div className="row d-flex align-items-center m-t-20 m-b-20">
                                              <div className="col-4">
                                                  <h6 className="d-block text-uppercase m-1">Mortality</h6>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Piglet Mortality:</strong> {mot.pigletMortality}
                                                  </p>
                                              </div>
                                              <div className="col-4">
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Tag No:</strong> {mot.tagNumber}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Remarks:</strong> {mot.remarks}
                                                  </p>
                                              </div>
                                              <div className="col-4">
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Date:</strong>{' '}
                                                      {new Date(mot.createdAt).toLocaleString()}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">ID:</strong> {mot.id}
                                                  </p>
                                              </div>
                                          </div>
                                      </Col>
                                  )}

                            {Array.isArray(sel)
                                ? sel.map((item, index) => (
                                      <Col sm={12} md={12} className="border-bottom">
                                          <div className="row d-flex align-items-center m-t-20 m-b-20">
                                              <div className="col-4">
                                                  <h6 className="d-block text-uppercase m-1">Sold</h6>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Customer:</strong> {item.cus_name}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Delivery Guy:</strong> {item.del_guy}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Payment Method:</strong> {item.pay_method}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Delivery:</strong> {item.delivery}
                                                  </p>
                                              </div>
                                              <div className="col-4">
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Pig Type:</strong> {item.type}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Price Per Kgs:</strong> {item.price}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Total Cost:</strong> {item.totalCost}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">No. of Pigs:</strong> {item.pigs}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Payment Reference:</strong> {item.pay_reference}
                                                  </p>
                                              </div>
                                              <div className="col-4">
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Date:</strong>{' '}
                                                      {new Date(item.createdAt).toLocaleString()}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Transaction ID:</strong> {item.transactionId}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Served By:</strong> {item.servedBy}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Tag No.:</strong> {item.tagNumber}
                                                  </p>
                                              </div>
                                          </div>
                                      </Col>
                                  ))
                                : sel &&
                                  sel.tagNumber && (
                                      <Col sm={12} md={12} className="border-bottom">
                                          <div className="row d-flex align-items-center m-t-20 m-b-20">
                                              <div className="col-4">
                                                  <h6 className="d-block text-uppercase m-1">Sold</h6>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Customer:</strong> {sel.cus_name}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Delivery Guy:</strong> {sel.del_guy}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Payment Method:</strong> {sel.pay_method}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Delivery:</strong> {sel.delivery}
                                                  </p>
                                              </div>
                                              <div className="col-4">
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Pig Type:</strong> {sel.type}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Price Per Kgs:</strong> {sel.price}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Total Cost:</strong> {sel.totalCost}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">No. of Pigs:</strong> {sel.pigs}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Payment Reference:</strong> {sel.pay_reference}
                                                  </p>
                                              </div>
                                              <div className="col-4">
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Date:</strong>{' '}
                                                      {new Date(sel.createdAt).toLocaleString()}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Transaction ID:</strong> {sel.transactionId}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Served By:</strong> {sel.servedBy}
                                                  </p>
                                                  <p className="m-1">
                                                      <strong className="font-weight-bold">Tag No.:</strong> {sel.tagNumber}
                                                  </p>
                                              </div>
                                          </div>
                                      </Col>
                                  )}
                        </Row>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

const avatarImage = '/assets/farm-logo.jpg';

const PDFContent = ({
    tagNumber,
    user,
    sir,
    fee,
    vac,
    mot,
    sel,
    pdfData,
    selTotal,
    userTotal,
    feeTotal,
    vacTotal,
    sirTotal,
    motTotal,
    feeCost
}) => (
    <Document>
        <Page style={styles.page} size="A4">
            <View style={styles.section}>
                <View style={styles.row}>
                    <View style={styles.header} fixed>
                        <View style={styles.rows}>
                            <View style={styles.column}>
                                <Image source={avatarImage} style={styles.letterheadLogo} />
                            </View>

                            <View style={styles.column}></View>
                            <View style={styles.column}>
                                <Text>SFARMS.CO.KE</Text>
                                <Text>P.O BOX 800-222</Text>
                                <Text>Naivasha</Text>
                                <Text>Phone : 254-787-689-760</Text>
                                <Text>Email : info@sfarms.co.ke</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <Text style={styles.ft}>SFARMS TAG NUMBER HISTORY</Text>
                <Text style={styles.transactionId}>Tag Number Details: TAG NO #{tagNumber}</Text>
                <View>
                    <View style={styles.row}>
                        <View style={styles.column}>
                            <Text style={styles.tile}>Summary</Text>
                            <Text>Total Income: KS {selTotal}</Text>
                            <Text>Total Expense: KS {userTotal}</Text>
                        </View>
                        <View style={styles.column}>
                            <Text>Feed Quantity: {feeTotal} Kgs/lts</Text>
                            <Text>Vaccine Quantity: {vacTotal} Kgs/lts</Text>
                            <Text>Feed Cost: KS {feeCost} </Text>
                        </View>
                        <View style={styles.column}>
                            <Text>Piglets Sired: {sirTotal}</Text>
                            <Text>Total Mortality: {motTotal}</Text>
                        </View>
                    </View>

                    {Array.isArray(user)
                        ? user.map((item, index) => (
                              <View key={index} style={styles.row}>
                                  <View style={styles.column}>
                                      <Text style={styles.tile}>Bought</Text>
                                      <Text>Supplier: {item.sup_name}</Text>
                                      <Text>Quantity: {item.quantity}</Text>
                                      <Text>D.O.B: {new Date(item.birth).toLocaleDateString()}</Text>
                                      <Text>Health: {item.health}</Text>
                                  </View>
                                  <View style={styles.column}>
                                      <Text>Pig Type: {item.type}</Text>
                                      <Text>Total Cost: Ksh {item.cost}</Text>
                                      <Text>Silo Number: {item.siloNumber}</Text>
                                      <Text>Age: {item.age}</Text>
                                  </View>
                                  <View style={styles.column}>
                                      <Text>Date: {new Date(item.createdAt).toLocaleString()}</Text>
                                      <Text>Weight: {item.weight} Kgs</Text>
                                      <Text>Remarks: {item.remarks}</Text>
                                  </View>
                              </View>
                          ))
                        : user &&
                          user.tagNumber && (
                              <View style={styles.row}>
                                  <View style={styles.column}>
                                      <Text style={styles.tile}>Bought</Text>
                                      <Text>Supplier: {user.sup_name}</Text>
                                      <Text>Quantity: {user.quantity}</Text>
                                      <Text>D.O.B: {new Date(user.birth).toLocaleDateString()}</Text>
                                      <Text>Health: {user.health}</Text>
                                  </View>
                                  <View style={styles.column}>
                                      <Text>Pig Type: {user.type}</Text>
                                      <Text>Total Cost: Ksh {user.cost}</Text>
                                      <Text>Silo Number: {user.siloNumber}</Text>
                                      <Text>Age: {user.age}</Text>
                                  </View>
                                  <View style={styles.column}>
                                      <Text>Date: {new Date(user.createdAt).toLocaleString()}</Text>
                                      <Text>Weight: {user.weight} Kgs</Text>
                                      <Text>Remarks: {user.remarks}</Text>
                                  </View>
                              </View>
                          )}

                    {/* Render Fed data */}
                    {Array.isArray(fee)
                        ? fee.map((item, index) => (
                              <View key={index} style={styles.row}>
                                  <View style={styles.column}>
                                      <Text style={styles.tile}>FED</Text>
                                      <Text>Feed Name: {item.feed_name}</Text>
                                      <Text>Quantity: {item.consumed} Kgs/Litres</Text>
                                      <Text>Silo Fed: {item.siloNumber} Kgs/Litres</Text>
                                  </View>
                                  <View style={styles.column}>
                                      <Text>Pig Type: {item.type}</Text>
                                      <Text>Tag No.: {item.tagNumber}</Text>
                                      <Text>Remarks: {item.remarks}</Text>
                                  </View>
                                  <View style={styles.column}>
                                      <Text>Date: {new Date(item.createdAt).toLocaleString()}</Text>
                                      <Text>ID: {item.id}</Text>
                                      <Text>Total Cost: {item.totalCost}</Text>
                                  </View>
                              </View>
                          ))
                        : fee &&
                          fee.tagNumber && (
                              <View style={styles.row}>
                                  <View style={styles.column}>
                                      <Text style={styles.tile}>FED</Text>
                                      <Text>Feed Name: {fee.feed_name}</Text>
                                      <Text>Quantity: {fee.consumed} Kgs/Litres</Text>
                                      <Text>Silo Fed: {fee.siloNumber} Kgs/Litres</Text>
                                  </View>
                                  <View style={styles.column}>
                                      <Text>Pig Type: {fee.type}</Text>
                                      <Text>Tag No: {fee.tagNumber}</Text>
                                      <Text>Remarks: {fee.remarks}</Text>
                                  </View>
                                  <View style={styles.column}>
                                      <Text>Date: {new Date(fee.createdAt).toLocaleString()}</Text>
                                      <Text>ID: {fee.id}</Text>
                                      <Text>Total Cost: {fee.totalCost}</Text>
                                  </View>
                              </View>
                          )}

                    {/* Render Vaccinated data */}
                    {Array.isArray(vac)
                        ? vac.map((item, index) => (
                              <View key={index} style={styles.row}>
                                  <View style={styles.column}>
                                      <Text style={styles.tile}>Vaccinated</Text>
                                      <Text>Vaccine Name: {item.vaccineName}</Text>
                                      <Text>Quantity: {item.vaccineQuantity} Kgs/Litres</Text>
                                  </View>
                                  <View style={styles.column}>
                                      <Text>Vaccinated By: {item.servedBy}</Text>
                                      <Text>Tag No: {item.tagNumber}</Text>
                                      <Text>Silo No: {item.siloVaccinated}</Text>
                                  </View>
                                  <View style={styles.column}>
                                      <Text>Date: {new Date(item.createdAt).toLocaleString()}</Text>
                                      <Text>ID: {item.id}</Text>
                                  </View>
                              </View>
                          ))
                        : vac &&
                          vac.tagNumber && (
                              <View style={styles.row}>
                                  <View style={styles.column}>
                                      <Text style={styles.tile}>Vaccinated</Text>
                                      <Text>Vaccine Name: {vac.vaccineName}</Text>
                                      <Text>Quantity: {vac.vaccineQuantity} Kgs/Litres</Text>
                                  </View>
                                  <View style={styles.column}>
                                      <Text>Vaccinated By: {vac.servedBy}</Text>
                                      <Text>Tag No: {vac.tagNumber}</Text>
                                      <Text>Silo No: {vac.siloVaccinated}</Text>
                                  </View>
                                  <View style={styles.column}>
                                      <Text>Date: {new Date(vac.createdAt).toLocaleString()}</Text>
                                      <Text>ID: {vac.id}</Text>
                                  </View>
                              </View>
                          )}

                    {Array.isArray(sir)
                        ? sir.map((item, index) => (
                              <View key={index} style={styles.row}>
                                  <View style={styles.column}>
                                      <Text style={styles.tile}>Sired</Text>
                                      <Text>Piglets Sired: {item.pigletsSired}</Text>
                                      <Text>Silo No.: {item.siloNumber}</Text>
                                  </View>
                                  <View style={styles.column}>
                                      <Text>Date Sired: {new Date(item.dateSired).toLocaleDateString()}</Text>
                                      <Text>Tag No: {item.tagNumber}</Text>
                                  </View>
                                  <View style={styles.column}>
                                      <Text>Recorded Date: {new Date(item.createdAt).toLocaleString()}</Text>
                                      <Text>ID: {item.id}</Text>
                                  </View>
                              </View>
                          ))
                        : sir &&
                          sir.tagNumber && (
                              <View style={styles.row}>
                                  <View style={styles.column}>
                                      <Text style={styles.tile}>Sired</Text>
                                      <Text>Piglets Sired: {sir.pigletsSired}</Text>
                                      <Text>Silo No.: {sir.siloNumber}</Text>
                                  </View>
                                  <View style={styles.column}>
                                      <Text>Date Sired: {new Date(sir.dateSired).toLocaleDateString()}</Text>
                                      <Text>Tag No: {sir.tagNumber}</Text>
                                  </View>
                                  <View style={styles.column}>
                                      <Text>Recorded Date: {new Date(sir.createdAt).toLocaleString()}</Text>
                                      <Text>ID: {sir.id}</Text>
                                  </View>
                              </View>
                          )}

                    {/* Render Mortality data */}
                    {Array.isArray(mot)
                        ? mot.map((item, index) => (
                              <View key={index} style={styles.row}>
                                  <View style={styles.column}>
                                      <Text style={styles.tile}>Mortality</Text>
                                      <Text>Mortality: {item.mortality}</Text>
                                      <Text>Health: {item.health}</Text>
                                  </View>
                                  <View style={styles.column}>
                                      <Text>Tag No: {item.tagNumber}</Text>
                                      <Text>Remarks: {item.remarks}</Text>
                                  </View>
                                  <View style={styles.column}>
                                      <Text>Date: {new Date(item.createdAt).toLocaleString()}</Text>
                                      <Text>ID: {item.id}</Text>
                                  </View>
                              </View>
                          ))
                        : mot &&
                          mot.tagNumber && (
                              <View style={styles.row}>
                                  <View style={styles.column}>
                                      <Text style={styles.tile}>Mortality</Text>
                                      <Text>Mortality: {mot.mortality}</Text>
                                      <Text>Health: {mot.health}</Text>
                                  </View>
                                  <View style={styles.column}>
                                      <Text>Tag No: {mot.tagNumber}</Text>
                                      <Text>Remarks: {mot.remarks}</Text>
                                  </View>
                                  <View style={styles.column}>
                                      <Text>Date: {new Date(mot.createdAt).toLocaleString()}</Text>
                                      <Text>ID: {mot.id}</Text>
                                  </View>
                              </View>
                          )}

                    {/* Render Sold data */}
                    {Array.isArray(sel)
                        ? sel.map((item, index) => (
                              <View key={index} style={styles.row}>
                                  <View style={styles.column}>
                                      <Text style={styles.tile}>Sold</Text>
                                      <Text>Customer: {item.cus_name}</Text>
                                      <Text>Delivery Guy: {item.del_guy}</Text>
                                      <Text>Payment Method: {item.pay_method}</Text>
                                      <Text>Delivery: {item.delivery}</Text>
                                  </View>
                                  <View style={styles.column}>
                                      <Text>Pig Type: {item.type}</Text>
                                      <Text>
                                          Price Per Kg:{' '}
                                          {item.price !== null
                                              ? item.price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                              : ''}
                                      </Text>
                                      <Text>
                                          Total Cost:{' '}
                                          {item.totalCost !== null
                                              ? item.totalCost.toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                })
                                              : ''}
                                      </Text>
                                      <Text>No. of Pigs: {item.pigs}</Text>
                                      <Text>Payment Reference: {item.pay_reference}</Text>
                                  </View>
                                  <View style={styles.column}>
                                      <Text>Date: {new Date(item.createdAt).toLocaleString()}</Text>
                                      <Text>Transaction ID: {item.transactionId}</Text>
                                      <Text>Served By: {item.servedBy}</Text>
                                      <Text>Tag No.: {item.tagNumber}</Text>
                                  </View>
                              </View>
                          ))
                        : sel &&
                          sel.tagNumber && (
                              <View style={styles.row}>
                                  <View style={styles.column}>
                                      <Text style={styles.tile}>Sold</Text>
                                      <Text>Customer: {sel.cus_name}</Text>
                                      <Text>Delivery Guy: {sel.del_guy}</Text>
                                      <Text>Payment Method: {sel.pay_method}</Text>
                                      <Text>Delivery: {sel.delivery}</Text>
                                  </View>
                                  <View style={styles.column}>
                                      <Text>Pig Type: {sel.type}</Text>
                                      <Text>
                                          Price Per Kg:{' '}
                                          {sel.price !== null
                                              ? sel.price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                              : ''}
                                      </Text>
                                      <Text>
                                          Total Cost:{' '}
                                          {sel.totalCost !== null
                                              ? sel.totalCost.toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                })
                                              : ''}
                                      </Text>
                                      <Text>No. of Pigs: {sel.pigs}</Text>
                                      <Text>Payment Reference: {sel.pay_reference}</Text>
                                  </View>
                                  <View style={styles.column}>
                                      <Text>Date: {new Date(sel.createdAt).toLocaleString()}</Text>
                                      <Text>Transaction ID: {sel.transactionId}</Text>
                                      <Text>Served By: {sel.servedBy}</Text>
                                      <Text>Tag No.: {sel.tagNumber}</Text>
                                  </View>
                              </View>
                          )}
                </View>
            </View>
            <Footer />
        </Page>
    </Document>
);

const Footer = ({ tagNumber, totalPages }) => (
    <View style={styles.footer} fixed>
        <Text style={styles.footerText}>
            <Text style={styles.ft}>#SFARMS PIG TAG NUMBER {tagNumber} HISTORY</Text>
        </Text>
    </View>
);

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Montserrat',
        fontSize: 8.5,
        flexDirection: 'row',
        backgroundColor: '#ffffff',
        width: '100%',
        height: '20px'
        // padding: 40,
    },
    pageBreak: {
        marginBottom: 20,
        pageBreakBefore: 'always'
    },
    section: {
        marginLeft: 50,
        marginRight: 50,
        padding: 10,
        flexGrow: 1
    },
    header: {
        width: '100%',
        alignContent: 'center'
    },
    letterheadLogo: {
        width: 100,
        height: 100
    },
    rows: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 10
    },
    tableRowEven: {
        backgroundColor: '#f2f2f2' // Light grey for even rows
    },
    tableRowOdd: {
        backgroundColor: '#ffffff' // White for odd rows
    },
    transactionId: {
        fontSize: 12,
        fontWeight: 'bold',
        marginBottom: 10,
        color: '#ffffff',
        borderBottom: '0.5px solid grey',
        backgroundColor: '#279e6c'
    },
    tile: {
        fontSize: 8,
        fontWeight: 'bold',
        color: '#279e6c',
        textTransform: 'uppercase',
        marginBottom: 5
    },
    ft: {
        fontSize: 8,
        fontWeight: 'bold',
        color: '#279e6c',
        textTransform: 'uppercase',
        textAlign: 'center',
        margin: 5
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10,
        marginTop: 5,
        borderBottom: '0.5px solid grey'
    },
    column: {
        width: '50%',
        marginBottom: 10
    },
    notes: {
        marginTop: 20,
        fontSize: 10
    },
    footer: {
        position: 'absolute',
        bottom: 20,
        left: 40,
        right: 40,
        textAlign: 'center',
        fontSize: 8,
        borderTop: '1px solid #000',
        paddingTop: 5
    },
    footerText: {
        color: '#000'
    }
});

export default UpdateUser;
