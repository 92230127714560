import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Table, Button, Alert, Modal } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../../config';
import axios from 'axios';
import Select from 'react-select';
import Card from '../../components/Card/MainCard';
import { usePermissions } from '../../permissions';

const SamplePage = ({ onCancel }) => {
    const account = useSelector((state) => state.account);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [newAccount, setNewAccount] = useState({
        accountName: null,
        accountBalance: '',
        description: '',
        type: null,
        accountNumber: '',
        farm: null
    });
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [farmOptions, setFarmOptions] = useState([]);
    const [selectedFarm, setSelectedFarm] = useState(null);
    const { createPermission, deletePermission, editPermission } = usePermissions();

    useEffect(() => {
        const fetchFarms = async () => {
            try {
                const response = await axios.get(`${apiUrl}/farm`);
                const farmData = response.data;
                const options = farmData.map((farm) => ({
                    value: farm.name,
                    label: farm.name
                }));

                setFarmOptions(options);
            } catch (error) {
                console.error('Error fetching Farms:', error);
            }
        };

        fetchFarms();
    }, [apiUrl, farmOptions]);

    const accountNameOptions = [
        { value: 'Income', label: 'Income' },
        { value: 'COGs', label: 'COGs' },
        { value: 'Expenses', label: 'Expenses' },
        { value: 'Assets', label: 'Assets' }
    ];

    const typeOptions = [
        { value: 'Credit', label: 'Credit' },
        { value: 'Debit', label: 'Debit' }
    ];

    // const farm = [
    //   { value: 'Farm 1', label: 'Farm 1' },
    //   { value: 'Farm 2', label: 'Farm 2' },
    // ];

    const handleInput = (event) => {
        setNewAccount((prevAccount) => ({
            ...prevAccount,
            [event.target.name]: event.target.value,
            userName: account.user.firstname,
            userId: account.user.id
        }));
    };

    const handleAccountNameSelect = (selectedOption) => {
        let defaultAccountType = 'Debit';

        if (selectedOption.value === 'Income') {
            defaultAccountType = 'Credit';
        }

        setNewAccount((prevAccount) => ({
            ...prevAccount,
            accountName: selectedOption.value,
            type: defaultAccountType
        }));
    };

    const handleTypeSelect = (selectedOption) => {
        setNewAccount((prevAccount) => ({
            ...prevAccount,
            type: selectedOption.value
        }));
    };
    const handleFarm = (selectedOption) => {
        setNewAccount((prevAccount) => ({
            ...prevAccount,
            farm: selectedOption.value
        }));
    };

    const handleCreateAccount = (e) => {
        e.preventDefault();
        if (!createPermission) {
            return;
        }
        axios
            .post(`${apiUrl}/accounts`, newAccount)
            .then((response) => {
                setSuccess(`${newAccount.description} Account successfully created`);
                toast.success(`${newAccount.description} Account successfully created`);
                onCancel();
                setNewAccount({
                    accountName: null,
                    accountNumber: '',
                    accountBalance: '',
                    description: '',
                    type: null
                });
            })
            .catch((error) => {
                setError(error.response.data.error);
                console.error('Error creating account: ', error);
            });
    };

    return (
        <React.Fragment>
            <Modal show={true} onHide={onCancel} size='lg' centered>
                <Modal.Header closeButton>
                    <Modal.Title>Create Account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={12}>
                            {success && (
                                <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                                    {success}
                                </Alert>
                            )}
                            {error && (
                                <Alert variant="danger" onClose={() => setError(null)} dismissible>
                                    {error}
                                </Alert>
                            )}
                            <ToastContainer />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form onSubmit={handleCreateAccount}>
                                <Row>
                                    <Form.Group as={Col} md={4} sm={12} lg={4} controlId="formBasic">
                                        <Form.Label>Account Name</Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Col} md={8} sm={12} lg={8} controlId="formBasic">
                                        <Form.Control
                                            required
                                            onChange={handleInput}
                                            name="description"
                                            type="text"
                                            value={newAccount.description}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={4} sm={12} lg={4}>
                                        <Form.Label>Account Number</Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Col} md={8} sm={12} lg={8}>
                                        <Form.Control
                                            required
                                            onChange={handleInput}
                                            name="accountNumber"
                                            type="number"
                                            value={newAccount.accountNumber}
                                        />
                                    </Form.Group>
                                    {/* <Form.Group as={Col} md={4} sm={12} lg={4} controlId="formBasic">
                            <Form.Label>Account Category</Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} md={8} sm={12} lg={8} controlId="formBasic">
                            <Select
                                options={accountNameOptions}
                                value={accountNameOptions.find((option) => option.value === newAccount.accountName)}
                                onChange={handleAccountNameSelect}
                                required
                            />
                            </Form.Group>
                            <Form.Group as={Col} md={4} sm={12} lg={4} controlId="formBasic">
                            <Form.Label>Account Type</Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} md={8} sm={12} lg={8} controlId="formBasic">
                            <Select
                                options={typeOptions}
                                value={typeOptions.find((option) => option.value === newAccount.type)}
                                onChange={handleTypeSelect}
                                required
                                isDisabled
                            />
                            </Form.Group> */}
                                    <Form.Group as={Col} md={4} sm={12} lg={4} controlId="formBasic">
                                        <Form.Label>Initial Balance</Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Col} md={8} sm={12} lg={8} controlId="formBasic">
                                        <Form.Control
                                            required
                                            onChange={handleInput}
                                            name="accountBalance"
                                            type="number"
                                            value={newAccount.accountBalance}
                                        />
                                    </Form.Group>

                                    {/* <Form.Group as={Col} md={4} sm={12} lg={4} >
                                <Form.Label>Farm</Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} md={8} sm={12} lg={8} >
                            <Select
                                options={farmOptions}
                                value={farmOptions.find((option) => option.value === newAccount.type)}
                                onChange={handleFarm}
                                required
                            />
                            </Form.Group> */}
                                </Row>
                                <Modal.Footer>
                                    {createPermission && <Button type="submit">Submit</Button>}
                                    <Button variant="secondary" onClick={onCancel}>
                                        Cancel
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};

export default SamplePage;
