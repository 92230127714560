import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import { useSelector } from 'react-redux';
import navigation from '../menu-items';

const PermissionsContext = createContext([]);

export const usePermissions = () => useContext(PermissionsContext);

const fetchRoleId = async (apiUrl, space) => {
    try {
        const rolesResponse = await axios.get(`${apiUrl}/roles`);
        const role = rolesResponse.data.find((role) => role.name === space);
        return role?.id;
    } catch (error) {
        console.error('Error fetching roles:', error);
        return null;
    }
};

const fetchPermissions = async (apiUrl, roleId) => {
    try {
        const response = await axios.get(`${apiUrl}/roles/${roleId}/permissions`);
        return response.data;
    } catch (error) {
        console.error('Error fetching permissions:', error);
        return [];
    }
};

const applyPermissionsRecursively = (item, permissionsData, parentPath = '') => {
    const permissions = permissionsData.find((permission) => permission.name === item.title) || {};

    const itemPermissions = {
        create_permission: permissions.create_permission === 1,
        delete_permission: permissions.delete_permission === 1,
        edit_permission: permissions.edit_permission === 1,
        view_permission: permissions.view_permission === 1,
    };

    const alwaysVisibleTitles = ['Home', 'Dashboard'];
    const isVisible = alwaysVisibleTitles.includes(item.title) || itemPermissions.view_permission;

    let visibleChildren = [];

    if (item.children && item.children.length > 0) {
        visibleChildren = item.children
            .map((child) => applyPermissionsRecursively(child, permissionsData, `${parentPath}${item.path || ''}`))
            .filter((child) => child.isVisible);
    }

    return {
        ...item,
        path: `${parentPath}${item.path || ''}`,
        permissions: itemPermissions,
        isVisible: isVisible || visibleChildren.length > 0,
        children: visibleChildren,
    };
};

export const PermissionsProvider = ({ children }) => {
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const account = useSelector((state) => state.account?.user?.is_admin);
    const space = account;
    const [permissionsData, setPermissionsData] = useState([]);
    const location = useLocation();
    const currentPath = location.pathname;
    

    useEffect(() => {
        const loadPermissions = async () => {
            const roleId = await fetchRoleId(apiUrl, space);
            if (roleId) {
                const permissions = await fetchPermissions(apiUrl, roleId);
                setPermissionsData(permissions);
            }
        };

        loadPermissions();
    }, [space, apiUrl]);

    const filteredNavigation = useMemo(() => {
        return navigation.items
            .map((item) => applyPermissionsRecursively(item, permissionsData))
            .filter((item) => item.isVisible);
    }, [permissionsData]);

    console.log(filteredNavigation)

    const flattenRoutes = (items, parentPath = '') => {
        return items.reduce((acc, item) => {
            const fullPath = `${parentPath}${item.path || ''}`;
            if (item.children) {
                acc.push({ ...item, path: fullPath });
                acc.push(...flattenRoutes(item.children, fullPath));
            } else {
                acc.push({ ...item, path: fullPath });
            }
            return acc;
        }, []);
    };


    const allRoutes = flattenRoutes(filteredNavigation);

    const currentRoute = allRoutes.find((route) => route.url === currentPath);

    const defaultPermissions = {
        view_permission: true,
        create_permission: true,
        edit_permission: true,
        delete_permission: true
    };

    const viewPermission = currentRoute ? currentRoute.permissions.view_permission : false;
    const createPermission = currentRoute ? currentRoute.permissions.create_permission : false;
    const editPermission = currentRoute ? currentRoute.permissions.edit_permission : false;
    const deletePermission = currentRoute ? currentRoute.permissions.delete_permission : false;

    console.log('Props in PermissionProvider:', { viewPermission, createPermission, editPermission, deletePermission });


    return (
        <PermissionsContext.Provider value={{ createPermission, deletePermission, editPermission, viewPermission, filteredNavigation }}>
            {children}
        </PermissionsContext.Provider>
    );
};

export default PermissionsProvider;
