import React, { useState, useRef } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { CSVLink } from 'react-csv';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { Row, Col, Form, Button, Table, Alert, Tabs, Tab, Modal, Pagination} from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Font, PDFViewer, pdf  } from '@react-pdf/renderer';
import Card from '../../components/Card/MainCard';
import config from '../../config';
import Receipt from './GeneralReceipt1.js';


const TransactionHistory = () => {
  const [error, setError] = useState(null);
  const formRef = useRef(null);
  const [success, setSuccess] = useState(null);
  const [selectedTable, setSelectedTable] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [transactionData, setTransactionData] = useState([]);
  const [showReceipt, setShowReceipt] = useState(false);
  const [showReceiptModal, setShowReceiptModal] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(10);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
  const apiUrl = config[environment].apiUrl;
  const [receiptData, setReceiptData] = useState(null);
  const [pdfBlob, setPdfBlob] = useState(null);

  const handleTableChange = (selectedOption) => {
    setSelectedTable(selectedOption);
  };
  
  const handleModal = async (transactionId) => {
    console.log("View Button Clicked with Transaction ID:", transactionId);
    setSelectedTransaction(transactionId);
    setShowReceiptModal(true);
    setShowReceipt(true);
    setTransactionId(transactionId);
    try {
      const response = await axios.get(`${apiUrl}/summary-receipts/${selectedTable.value}/${transactionId}`);
      setReceiptData(response.data.receiptData);
    } catch (error) {
      console.error('Error retrieving receipt data:', error);
    }
  };
  
  const generatePdf = async () => {
    try {
      const response = await axios.get(`${apiUrl}/summary-receipts/${selectedTable.value}/${transactionId}`);
      setReceiptData(response.data.receiptData);
  
      // Convert the ReceiptPDF component into a Blob
      const blob = await pdf(<ReceiptPDF transactionId={transactionId} receiptData={response.data.receiptData} />).toBlob();
      setPdfBlob(blob);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  const handleFetchData = async () => {
    // e.preventDefault();
    try {
      if (!selectedTable) {
        return; // If no table is selected, do not fetch data
      }

      const response = await axios.get(`${apiUrl}/api/transaction-history`, {
        params: {
          table: selectedTable.value,
          startDate: startDate,
          endDate: endDate,
        },
      });
      setTransactionData(response.data);
    console.log(response)
      if (response.data) {
        toast.success('Transaction history fetched successfully.');
        setSuccess('Transaction history fetched successfully.');
        setTimeout(() => {
            setSuccess(null);
          }, 10000);
        }

    } catch (error) {
      toast.error(error);
      console.error('Error fetching transaction history:', error);
    }
  };

  const tableOptions = [
    { value: 'goats_sell', label: 'Goat Sales' },
    { value: 'eggs_sell', label: 'Egg Sales' },
    { value: 'pigs_sell', label: 'Pig Sales' },
    { value: 'poultry_sell', label: 'Poultry Sales' },
    // { value: 'emergency_vaccination', label: 'Emergency Vaccination' },
    { value: 'broken_eggs_sell', label: 'Broken Eggs Sales' },
  ];

  const handleCloseReceiptModal = () => {
    setShowReceiptModal(false);
  };

  const handlePrint = () => {
    window.print();
  };

  // Calculate index of the last user on the current page
  const indexOfLastUser = currentPage * usersPerPage;
  // Calculate index of the first user on the current page
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  // Get the current users to display on the current page
  const currenttransactionData = transactionData.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePerPageChange = (selectedOption) => {
    setUsersPerPage(selectedOption.value);
    setCurrentPage(1); // Reset to the first page when changing the number of users per page
  };

 const options = [
   { value: 5, label: '5' },
   { value: 10, label: '10' },
   { value: 25, label: '25' },
   { value: 50, label: '50' },
   { value: 150, label: '150' },
   { value: 200, label: '200' },
   { value: 500, label: '500' },
 ];

 const totalPages = Math.ceil(transactionData.length / usersPerPage);

 const handleViewFile = (image) => {
  // Open the file in a new tab
  window.open(`${apiUrl}/uploads/${image}`, '_blank');
};

const csvLinkRef = useRef();

  const exportToCSV = () => {
    const csvData = [
        ['TransactionID', 'Customer', 'Amount', 'Payment Mode', 'Reference', 'Date/Time', 'Generated By', 'Farm'],
        ...transactionData.map((item) => [item.transactionId, item.cus_name, item.amount, item.pay_method, item.pay_reference, item.createdAt, item.servedBy,item.farm]),
      ];
  
      const csvContent = csvData.map((row) => row.join(',')).join('\n');
      const blob = new Blob(['\ufeff', csvContent], { type: 'text/csv;charset=utf-8;' });
  
      const csvUrl = URL.createObjectURL(blob);
      csvLinkRef.current.href = csvUrl;
      csvLinkRef.current.download = 'sfarms.csv';
      csvLinkRef.current.click();
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [['TransactionID', 'Customer', 'Amount', 'Payment Mode', 'Reference', 'Date/Time', 'Generated By', 'Farm']],
      body: transactionData.map((user) => [
        user.transactionId,
        user.cus_name,
        user.amount.toLocaleString(), 
        user.pay_method, 
        user.pay_reference,
        new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "short",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }).format(new Date(user.createdAt)),
        user.servedBy,
        user.farm,
      ]),
    });
    const pdfBlob = doc.output('blob');
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl);
  };
  


  return (
    <React.Fragment>
      <Row>
          <Col sm={12}>
          {success && <Alert variant="success" onClose={() => setSuccess(null)} dismissible >{success}</Alert>}
          {error && <Alert variant="danger" onClose={() => setError(null)} dismissible >{error}</Alert>}
          <ToastContainer />
          </Col>
      </Row>

      <Row>
        <Col>
          <Card title="Sales Summarry" isOption>
            {/* <Form onSubmit={handleFetchData} ref={formRef}> */}
            <Row>
              <Col md={4} sm={12} lg={4}>
                <Form.Group>
                <Form.Label>Select:</Form.Label>
                <Select
                  value={selectedTable}
                  onChange={(selectedOption) => handleTableChange(selectedOption)}
                  options={tableOptions}
                  placeholder="Select sales"
                />

                </Form.Group>
              </Col>
              <Col md={4} sm={12} lg={4}>
                <Form.Group>
                  <Form.Label>From:</Form.Label>
                  <Form.Control
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={4} sm={12} lg={4}>
                <Form.Group>
                  <Form.Label>To:</Form.Label>
                  <Form.Control
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={12} sm={12} lg={12}>
                <Form.Group>
                <Button onClick={handleFetchData}>Fetch Data</Button>
                </Form.Group>
              </Col>
              
            </Row>
            {/* </Form> */}
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card title="Receipts Summarry" isOption>
            <Row className='table-accounts'>
            <Table striped bordered hover responsive>
              <thead className="bg-c-blue border-bottom text-uppercase">
                <tr>
                  <th>Transaction ID</th>
                  <th>Customer</th>
                  <th>Amount</th>
                  <th>Payment Mode</th>
                  <th>Payment Reference</th>
                  <th>Date / Time</th>
                  <th>Generated By</th>
                  <th>Farm</th>
                  <th colSpan={2}>Receipt</th>
                </tr>
              </thead>
              <tbody>
                {currenttransactionData.map((transaction) => (
                  <tr key={transaction.createdAt}>
                    <td>{transaction.transactionId}</td>
                    <td>{transaction.cus_name}</td>
                    <td>Ksh {(transaction.amount).toLocaleString()}</td>
                    <td>{transaction.pay_method}</td>
                    <td>{transaction.pay_reference}</td>
                    <td>{new Date(transaction.createdAt).toLocaleString()}</td>
                    <td>{transaction.servedBy}</td>
                    <td>{transaction.farm}</td>
                    <td>
                      <Button onClick={() => handleModal(transaction.transactionId)}>
                        <i className="feather icon-eye" />
                      </Button>
                      {transaction.image && (
                        <Button onClick={() => handleViewFile(transaction.image)}>
                          <i className="feather icon-download-cloud" />File
                        </Button>
                       )}

                      </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
          <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex">
              <Pagination>
                <Pagination.Prev
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                />
                {Array.from({ length: totalPages }).map((_, index) => (
                  <Pagination.Item
                    key={index + 1}
                    active={index + 1 === currentPage}
                    onClick={() => paginate(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage === totalPages}
                />
              </Pagination>
            <Select
              options={options}
              value={{ value: usersPerPage, label: `${usersPerPage}` }}
              onChange={handlePerPageChange}
            />
            
          </div>
          <div className="d-flex" >

            <Button onClick={exportToCSV}>Export as CSV</Button>
            <a ref={csvLinkRef} style={{ display: 'none' }} />
              <a
                href="#"
                className="btn btn-primary"
                onClick={exportToPDF}
              >
                Export PDF
              </a>
              </div>
            </div>
          </Card>
          {showReceipt && (
            <Modal show={showReceiptModal} onHide={handleCloseReceiptModal} size='lg' centered>
              <Modal.Header closeButton>
                <Modal.Title>Receipt</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Receipt transactionId={transactionId} selectedTable={selectedTable} />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="success" onClick={handlePrint}>
                  Print
                </Button>
                <Button variant="secondary" onClick={handleCloseReceiptModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

const ReceiptPDF = ({ transactionId, receiptData }) => {
  return (
    <PDFViewer style={{ width: '100%', height: '100vh' }}>
      <Document>
        <Page style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.heading}>SFARMS SALES RECEIPT</Text>
            <Text style={styles.heading}>RECEIPT: #{receiptData.transactionId}</Text>
            <Text style={styles.heading}>DATE/TIME: {new Date(receiptData.createdAt).toLocaleString()}</Text>
            {receiptData.cus_name && (
              <View>
                <Text style={styles.label}>Customer:</Text>
                <Text style={styles.value}>{receiptData.cus_name}</Text>
              </View>
            )}
            {receiptData.birds && (
              <View>
                <Text style={styles.label}>No. of Birds:</Text>
                <Text style={styles.value}>{receiptData.birds}</Text>
              </View>
            )}
            {receiptData.tagNumber && (
              <View>
                <Text style={styles.label}>Tag No.:</Text>
                <Text style={styles.value}>{receiptData.tagNumber}</Text>
              </View>
            )}
            {receiptData.goats && (
              <View>
                <Text style={styles.label}>No. of Goats:</Text>
                <Text style={styles.value}>{receiptData.goats}</Text>
              </View>
            )}
            {receiptData.pigs && (
              <View>
                <Text style={styles.label}>No. of Pigs:</Text>
                <Text style={styles.value}>{receiptData.pigs}</Text>
              </View>
            )}
            {receiptData.trays && (
              <View>
                <Text style={styles.label}>No. of Trays:</Text>
                <Text style={styles.value}>{receiptData.trays}</Text>
              </View>
            )}
            {receiptData.eggs && (
              <View>
                <Text style={styles.label}>No. of Eggs:</Text>
                <Text style={styles.value}>{receiptData.eggs}</Text>
              </View>
            )}
            {receiptData.price && (
              <View>
                <Text style={styles.label}>Price:</Text>
                <Text style={styles.value}>Ksh. {receiptData.price.toLocaleString()}</Text>
              </View>
            )}
            {receiptData.pay_method && (
              <View>
                <Text style={styles.label}>Payment Mode:</Text>
                <Text style={styles.value}>{receiptData.pay_method}</Text>
              </View>
            )}
            {receiptData.delivery && (
              <View>
                <Text style={styles.label}>Delivery Mode:</Text>
                <Text style={styles.value}>{receiptData.delivery}</Text>
              </View>
            )}
            {receiptData.del_guy && (
              <View>
                <Text style={styles.label}>Delivery Guy:</Text>
                <Text style={styles.value}>{receiptData.del_guy}</Text>
              </View>
            )}
            {receiptData.pay_reference && (
              <View>
                <Text style={styles.label}>Payment Reference:</Text>
                <Text style={styles.value}>{receiptData.pay_reference}</Text>
              </View>
            )}
            {receiptData.transactionId && (
              <View>
                <Text style={styles.label}>Transaction ID :</Text>
                <Text style={styles.value}>{receiptData.transactionId}</Text>
              </View>
            )}
            {receiptData.createdAt && (
              <View>
                <Text style={styles.label}>Date / Time :</Text>
                <Text style={styles.value}>{new Date(receiptData.createdAt).toLocaleString()}</Text>
              </View>
            )}
            <View style={styles.footer}>
              <Text>Welcome Again</Text>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

// Register a custom font (optional)
Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
});

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Roboto',
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  heading: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  label: {
    fontSize: 12,
    marginBottom: 3,
  },
  value: {
    fontSize: 12,
  },
  footer: {
    fontSize: 12,
    marginTop: 10,
    textAlign: 'center',
  },
});
export default TransactionHistory;
