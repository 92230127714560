const config = {
    development: {
      apiUrl: 'http://localhost:5001',
    },
    production: {
      apiUrl: 'https://sfarms.co.ke:5000',
      enableConsoleLog: false,
    },
  };
  
  export default config;

  if (process.env.NODE_ENV === 'production' && config.production.apiUrl){
    console.log = function () {};
    console.error = function () {};
    console.warn = function () {};
    console.info = function () {};
    console.debug = function () {};

    const originalWarn = console.warn;
    console.warn = function (...args) {
      if (!args.some((arg) => typeof arg === 'string' && arg.includes('Mixed Content'))) {
        originalWarn.apply(console, args);
      }
    };

  };







