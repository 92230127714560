import React, {useState, useEffect} from 'react';
import NVD3Chart from 'react-nvd3';
import { Bar, Bubble, Doughnut, Line, Pie, PolarArea, Radar, Scatter } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { VictoryBar, VictoryLine, VictoryChart, VictoryAxis } from 'victory';
import config from '../../../../config';
import axios from 'axios';

const BarDiscreteChart= () => {
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalEggsSold, setTotalEggsSold] = useState(0);
  const [totalEggsCollected,  setTotalEggsCollected] = useState(0);
  const [totalBirdsSold, setTotalBirdsSold] = useState(0);
  const [totalSpoilt, setTotalSpoilt] = useState(0);
  const [totalDead, setTotalDead] = useState(0);
  const [totalpigsSired, setPigsSired] = useState(0);
  const [totalFeedQuantity, setTotalFeedQuantity] = useState(0);
  const [totalPoultry, setTotalPoultry] = useState(0);
  const [totalCapacity, setTotalCapacity] = useState(0);
  const [totalRefill, setTotalRefill] = useState(0);
  const [labels, setLabels] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalPigs, setTotalPigs] = useState(0);
  const [totalpigsSold, setTotalPigsSold] = useState(0);
  const [totalGoatsSold, setTotalGoatsSold] = useState(0);
  const [totalPigsDead, setTotalPigsDead] = useState(0);
  const [totalGoats, setTotalGoats] = useState(0);
  const [totalGoatsDead, setTotalGoatsDead] = useState(0);
  const [totalSired, setTotalGoatsSired] = useState(0);
  const [earnings, setEarnings] = useState({ totalEarnings: 0 });
  const [dead, setDead] = useState({ totalDead: 0 });
  const [water, setWater] = useState({ totalWater: 0 });
  const [feed, setFeed] = useState({ totalFeed: 0 });
  const [expenses, setExpenses] = useState({ totalExpenses: 0 });
  const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
  const apiUrl = config[environment].apiUrl;
 
    useEffect(() => {
        axios.get(`${apiUrl}/eggsSold/total`)
        .then((response) => {
            setTotalEggsSold(response.data.totalEggsSold);
            // console.log(response);
        })
        .catch(error => {
            console.error(error);
        });
    }, []);

    useEffect(() => {
        axios.get(`${apiUrl}/totalBirdsSold`)
        .then((response) => {
            setTotalBirdsSold(response.data.totalBirdsSold);
            // console.log(response);
        })
        .catch(error => {
            console.error(error);
        });
    }, []);
    useEffect(() => {
        axios.get(`${apiUrl}/goatsSold/total`)
        .then((response) => {
            setTotalGoatsSold(response.data.totalGoatsSold);
            // console.log(response);
        })
        .catch(error => {
            console.error(error);
        });
    }, []);
    

    useEffect(() => {
        axios.get(`${apiUrl}/pigsSold/total`)
        .then((response) => {
            setTotalPigsSold(response.data.totalpigsSold);
            // console.log(response);
        })
        .catch(error => {
            console.error(error);
        });
    }, []);
    
   

    const formatPercentage = (value) => {
      return `${value}%`; // Adjust the formatting as needed
    };
    const total = totalBirdsSold + totalpigsSold + totalGoatsSold + totalEggsSold;
    const bird = ((totalBirdsSold / total) * 100).toFixed(2);
    const pig = ((totalpigsSold / total) * 100).toFixed(2);
    const goat = ((totalGoatsSold / total) * 100).toFixed(2);
    const egg = ((totalEggsSold / total) * 100).toFixed(2);

  
    useEffect(() => {
      setLabels(['Poultry', 'Capacity', 'Feed Quantity', 'Refill']);
    }, []);

    const chartData = {
     
      labels: ['Poultry', 'Pigs', 'Goats', 'Eggs'],
      datasets: [
        {
          label: 'Sales Percentage',
          data: [bird, pig, goat, egg],
          backgroundColor: [
            'rgba(75, 192, 192, 0.6)',
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
          ], // Customize the bar 
          borderColor: [
            'rgba(75, 192, 192, 0.6)',
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
          ],
          borderWidth: 1
        },
      ],
    };
  
    // const chartOptions = {
    //   indexAxis: 'x',
    //   scales: {
    //     y: {
    //       beginAtZero: true,
    //       ticks: {
    //         callback: (value) => `${value}%`,
    //       },
    //     },
    //   },
    // };
  
    // useEffect(() => {
    //   if (typeof window.Chart !== 'undefined') {
    //     Chart.register({ // Register the Bar controller directly
    //       id: 'line',
    //       beforeDraw: (chart) => {
    //         const ctx = chart.ctx;
    //         // const xAxis = chart.scales['x'];
    //         // const yAxis = chart.scales['y'];
    //         const datasets = chart.data.datasets;
    //         const dataset = datasets[0];
    //         const meta = chart.getDatasetMeta(0);
    //         // const barThickness = xAxis.width / datasets.length;
  
    //         ctx.save();
    //         for (let i = 0; i < dataset.data.length; i++) {
    //           const value = dataset.data[i];
    //           // const x = xAxis.left + barThickness * i + barThickness / 2;
    //           // const y = yAxis.getPixelForValue(value);
  
    //           // ctx.fillText(`${value}%`, x, y - 10);
    //         }
    //         ctx.restore();
    //       }
    //     });
    //   }
    // }, []);
  
    return (
      <div className='w-50 align-content-center align-items-center'>
        {/* <Scatter data={chartData} /> */}
        {/* <Radar data={chartData} /> */}
        <Doughnut data={chartData} labelType="percent" />
        {/* <PolarArea data={chartData} /> */}
        {/* <Pie data={chartData}/> */}
      </div>
    );
};

export default BarDiscreteChart;
