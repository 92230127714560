import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Table, Button, Pagination, Form, Card, Tabs, Tab } from 'react-bootstrap';
import Select from 'react-select';
import { CSVLink } from 'react-csv';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import axios from 'axios';
import config from '../../../config';
import { useParams, useHistory } from 'react-router-dom';
import { usePermissions } from '../../../permissions';
// import Card from '../../components/Card/MainCard';
import ReadyFeed from './BasicTabsPills401';
import RawFeed from './BasicTabsPills402';
import SamplePage9 from './BasicCollapse071.js';
import DataTable from 'react-data-table-component';

const UserList = () => {
    const [users, setUsers] = useState([]);
    const [Da, setDa] = useState(0);
    const [We, setWe] = useState(0);
    const [Mo, setMo] = useState(0);
    const [DaC, setDaC] = useState(0);
    const [WeC, setWeC] = useState(0);
    const [MoC, setMoC] = useState(0);
    const [ready, setReady] = useState([]);
    const [raw, setRaw] = useState([]);
    const { id } = useParams();
    const history = useHistory();
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage, setUsersPerPage] = useState(5);
    const [currentPages, setCurrentPages] = useState(1);
    const [usersPerPages, setUsersPerPages] = useState(5);
    const { createPermission, deletePermission, editPermission } = usePermissions();
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [searchText, setSearchText] = useState('');

    const hidePopup = () => {
        setPopupVisible(false);
    };

    const showPopup = () => {
        setPopupVisible(true);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/weaners-to-adults`);
                const data = response.data;
                setRaw(data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [apiUrl, raw]);

    const calculateAge = (birthDate) => {
        const today = new Date();
        const birth = new Date(birthDate);

        // Get the UTC values for year, month, and day
        const todayUTC = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
        const birthUTC = Date.UTC(birth.getFullYear(), birth.getMonth(), birth.getDate());

        // Calculate the age difference in milliseconds
        const ageDiffMillis = todayUTC - birthUTC;

        // Convert milliseconds to years, months, and days
        const yearDiff = Math.floor(ageDiffMillis / (1000 * 60 * 60 * 24 * 365));
        const monthDiff = Math.floor((ageDiffMillis % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30));
        const dayDiff = Math.floor((ageDiffMillis % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24));

        if (yearDiff > 0) {
            return yearDiff === 1 ? `${yearDiff} year` : `${yearDiff} years`;
        } else if (monthDiff > 0) {
            return monthDiff === 1 ? `${monthDiff} month` : `${monthDiff} months`;
        } else {
            return dayDiff === 1 ? `${dayDiff} day` : `${dayDiff} days`;
        }
    };

    const csvLinkRef = useRef();

    const exportToCSV = () => {
        const csvData = [
            ['Type', 'Feed', 'Total Qty', 'Total Cost', 'Silo No.', 'Remarks', 'Date', 'Farm'],
            ...filteredData.map((item) => [
                item.feed_type,
                item.feed_name,
                item.totalQuantity,
                item.totalCost,
                item.siloNumber,
                item.remarks,
                item.createdAt,
                item.farm
            ])
        ];

        const csvContent = csvData.map((row) => row.join(',')).join('\n');
        const blob = new Blob(['\ufeff', csvContent], { type: 'text/csv;charset=utf-8;' });

        const csvUrl = URL.createObjectURL(blob);
        csvLinkRef.current.href = csvUrl;
        csvLinkRef.current.download = 'sfarms.csv';
        csvLinkRef.current.click();
    };

    const exportToPDF = () => {
        const doc = new jsPDF();
        doc.autoTable({
            head: [['Type', 'Feed', 'Total Qty', 'Total Cost', 'Silo No.', 'Date', 'Farm']],
            body: filteredData.map((user) => [
                user.feed_type,
                user.feed_name,
                user.totalQuantity,
                user.totalCost,
                user.siloNumber,
                new Intl.DateTimeFormat('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                }).format(new Date(user.createdAt)),
                user.farm
            ])
        });
        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl);
    };

    const columns = [
        {
            name: 'Id',
            selector: (row) => row.id,
            sortable: true
        },
        {
            name: 'Tag No.',
            selector: (row) => row.tagNumber,
            cell: (row) => <Link to={`/pigs/view-tagNumber/${row.tagNumber}`}>{row.tagNumber}</Link>,
            sortable: true,
            wrap: true
        },
        {
            name: 'Silo No.',
            selector: (row) => row.siloNumber,
            // selector: (row) => new Date(row.recorded).toDateString(),
            sortable: true,
            wrap: true
        },
        {
            name: 'Weaners Group',
            selector: (row) => row.weanersGroup,
            cell: (row) => <Link to={`/weaners/view/${row.weanersGroup}`}>{row.weanersGroup}</Link>,
            sortable: true,
            wrap: true
        },
        {
            name: 'Mother Tag',
            selector: (row) => row.motherTag,
            cell: (row) => <Link to={`view-tagNumber/${row.motherTag}`}>{row.motherTag}</Link>,
            sortable: true,
            wrap: true
        },
        {
            name: 'Weight',
            selector: (row) => row.weight,
            sortable: true,
            wrap: true
        },       
        {
            name: 'DATE',
            selector: (row) => new Date(row.recorded).toLocaleDateString(),
            sortable: true,
            wrap: true
        },
        {
            name: 'updated',
            selector: (row) => new Date(row.createdAt).toLocaleString(),
            sortable: true,
            wrap: true
        },
    ];

    const ExpandableRow = ({ data }) => {
        return (
            <div style={{ padding: '10px 20px' }}>
                <p className="m-0">
                    <strong className="text-c-blue">Farm:</strong> {data?.farm}
                </p>
                <p className="m-0">
                    <strong className="text-c-blue">Cost:</strong> {data?.cost}
                </p>

                <p className="m-0">
                    <strong className="text-c-blue">D.O.B:</strong> {new Date(data?.birth).toDateString()}
                </p>
                <p className="m-0">
                    <strong className="text-c-blue">Age:</strong> {calculateAge(data?.birth)}
                </p>
                <p className="m-0">
                    <strong className="text-c-blue">Remarks:</strong> {data?.remarks}
                </p>
            </div>
        );
    };

    const filteredData = searchText
        ? raw.filter((item) =>
              Object.values(item).some((value) => typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase()))
          )
        : raw;

    return (
        <React.Fragment>
            {' '}
            <Col>
                <div className="d-flex justify-content-between align-center m-b-10">
                    <div>
                        <span> </span>
                    </div>
                    {createPermission && (
                        <Button onClick={showPopup} className="mb-10">
                            <span className="feather icon-plus-circle"></span> Grow-Finish
                        </Button>
                    )}
                    {isPopupVisible && <SamplePage9 onCancel={hidePopup} />}
                </div>
            </Col>
            <Row className="btn-page">
                <Col>
                    {/* <Card title="Pigs Table" isOption> */}
                    <Row>
                        <Form.Group as={Col} md={1} sm={12} lg={1} controlId="formBasicName">
                            <Form.Label>Search</Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} md={3} sm={12} lg={3} controlId="formBasicName">
                            <Form.Control
                                type="text"
                                placeholder="Search..."
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                        </Form.Group>
                        {/* <Form.Group as={Col} md={3} sm={12} lg={3} controlId="formBasicName">
                            <Form.Control
                                type="text"
                                placeholder="Search..."
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                        </Form.Group> */}
                    </Row>
                    <DataTable
                        columns={columns}
                        data={filteredData}
                        pagination
                        highlightOnHover
                        striped
                        // noHeader
                        dense
                        bordered
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                        paginationComponentOptions={{
                            rowsPerPageText: 'Rows per page:'
                        }}
                        paginationResetDefaultPage={true}
                        paginationTotalRows={raw.length}
                        expandableRows
                        expandableRowsComponent={ExpandableRow}
                        // selectableRows
                        onSelectedRowsChange={(selectedRows) => console.log(selectedRows)}
                        customStyles={{
                            headRow: {
                                style: {
                                    backgroundColor: '#279e6c', // Green background
                                    color: '#fff', // White text
                                    textTransform: 'uppercase', // Uppercase text
                                    // fontSize: '10px', // Adjust font size for header row
                                    minHeight: '1.5rem',
                                    paddingTop: '8px',
                                    paddingBottom: '8px'
                                }
                            }
                        }}
                    />
                    {filteredData && filteredData.length > 0 && (
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex"></div>
                            <div className="d-flex">
                                <Button onClick={exportToCSV}>Export as CSV</Button>
                                <a ref={csvLinkRef} style={{ display: 'none' }} />
                                <a href="#" className="btn btn-primary" onClick={exportToPDF}>
                                    Export PDF
                                </a>
                            </div>
                        </div>
                    )}
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default UserList;
