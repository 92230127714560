import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Bar, Line, Pie } from 'react-chartjs-2';
import config from '../../../config';
import { usePermissions } from '../../../permissions';
import { DatePicker, Input, Form, Card } from 'antd';
import { Button, Col, Row } from 'react-bootstrap';
import DynamicReport from './BasicTabsPills102';

const PoultryReport = () => {
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const { createPermission, deletePermission, editPermission } = usePermissions();
    const [reportData, setReportData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filters, setFilters] = useState({ startDate: '', endDate: '', batchNumber: '' });
    const [dataForRevenue, setDataForRevenue] = useState({});
    const [dataForFeeds, setDataForFeeds] = useState({});
    const [dataForFeedsMonthly, setDataForFeedsMonthly] = useState({});
    const [dataForVaccines, setDataForVaccines] = useState({});
    const [dataForMortalities, setDataForMortalities] = useState({});
    const [dataForEggs, setDataForEggs] = useState({});
    const [dataForEggsBroken, setDataForEggsBroken] = useState({});
    const [dataForEggsCollected, setDataForEggsCollected] = useState({});

    useEffect(() => {
        if (reportData) {
            const revenueExpenseLabels = reportData?.monthly_revenue_expense?.map((item) => item.month);
            const revenueData = reportData?.monthly_revenue_expense?.map((item) => item.monthly_total_revenue);
            const expenseData = reportData?.monthly_revenue_expense?.map((item) => item.monthly_total_expense);

            setDataForRevenue({
                labels: revenueExpenseLabels,
                datasets: [
                    {
                        label: 'Revenue',
                        data: revenueData,
                        backgroundColor: 'rgba(75, 192, 192, 0.6)',
                        borderColor: 'rgba(75, 192, 192, 1)',
                        borderWidth: 1
                    },
                    {
                        label: 'Expense',
                        data: expenseData,
                        backgroundColor: 'rgba(255, 99, 132, 0.6)',
                        borderColor: 'rgba(255, 99, 132, 1)',
                        borderWidth: 1
                    }
                ]
            });

            // const feedAggregation = reportData?.monthly_feeds_consumed?.reduce((acc, item) => {
            //     if (!acc[item?.month]) {
            //       acc[item?.month] = 0;
            //     }
            //     acc[item?.month] += item?.monthly_feeds_consumed;
            //     return acc;
            //   }, {});

            //   const feedLabels = Object?.keys(feedAggregation);
            //   const feedData = Object?.values(feedAggregation);

            //   setDataForFeeds({
            //     labels: feedLabels,
            //     datasets: [
            //       {
            //         label: 'Feed Consumption',
            //         data: feedData,
            //         backgroundColor: 'rgba(54, 162, 235, 0.6)',
            //         borderColor: 'rgba(54, 162, 235, 1)',
            //         borderWidth: 1
            //       }
            //     ]
            //   });

            // Feeds Consumption Chart
            const uniqueMonths = [...new Set(reportData?.monthly_feeds_consumed?.map((item) => item?.month))];

            // Extract unique feed names
            const uniqueFeedNames = [...new Set(reportData?.monthly_feeds_consumed?.map((item) => item?.feed_name))];

            // Create datasets for each feed name
            const datasets = uniqueFeedNames?.map((feedName) => {
                return {
                    label: feedName,
                    data: uniqueMonths?.map((month) => {
                        const feedData = reportData?.monthly_feeds_consumed?.find(
                            (item) => item.month === month && item.feed_name === feedName
                        );
                        return feedData ? feedData?.monthly_feeds_consumed : 0;
                    }),
                    backgroundColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(
                        Math.random() * 255
                    )}, 0.6)`,
                    // borderColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(
                    //     Math.random() * 255
                    // )}, 1)`,
                    borderWidth: 1
                };
            });

            setDataForFeedsMonthly({
                labels: uniqueMonths,
                datasets: datasets
            });

            const uniqueVaccineMonths = [...new Set(reportData?.monthly_vaccines_consumed?.map((item) => item?.month))];

            // Extract unique feed names
            const uniqueVaccineNames = [...new Set(reportData?.monthly_vaccines_consumed?.map((item) => item?.vaccineName))];

            // Create datasets for each feed name
            const datasetsVaccine = uniqueVaccineNames?.map((vaccineName) => {
                return {
                    label: vaccineName,
                    data: uniqueVaccineMonths?.map((month) => {
                        const vaccineData = reportData?.monthly_vaccines_consumed?.find(
                            (item) => item.month === month && item.vaccineName === vaccineName
                        );
                        return vaccineData ? vaccineData?.monthly_vaccines_consumed : 0;
                    }),
                    backgroundColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(
                        Math.random() * 255
                    )}, 0.6)`,
                    // borderColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(
                    //     Math.random() * 255
                    // )}, 1)`,
                    borderWidth: 1
                };
            });

            setDataForVaccines({
                labels: uniqueVaccineMonths,
                datasets: datasetsVaccine
            });

            // Vaccines Consumption Chart
            // const vaccineLabels = reportData?.monthly_vaccines_consumed?.map((item) => item.vaccineName);
            // const vaccineData = reportData?.monthly_vaccines_consumed?.map((item) => item.monthly_vaccines_consumed);

            // setDataForVaccines({
            //     labels: vaccineLabels,
            //     datasets: [
            //         {
            //             label: 'Total Vaccines Consumed',
            //             data: vaccineData,
            //             backgroundColor: [
            //                 'rgba(255, 99, 132, 0.6)',
            //                 'rgba(54, 162, 235, 0.6)',
            //                 'rgba(255, 206, 86, 0.6)',
            //                 'rgba(75, 192, 192, 0.6)',
            //                 'rgba(153, 102, 255, 0.6)',
            //                 'rgba(255, 159, 64, 0.6)'
            //             ],
            //             borderColor: [
            //                 'rgba(255, 99, 132, 1)',
            //                 'rgba(54, 162, 235, 1)',
            //                 'rgba(255, 206, 86, 1)',
            //                 'rgba(75, 192, 192, 1)',
            //                 'rgba(153, 102, 255, 1)',
            //                 'rgba(255, 159, 64, 1)'
            //             ],
            //             borderWidth: 1
            //         }
            //     ]
            // });

            // Mortalities Chart
            const mortalityLabels = reportData?.monthly_mortalities?.map((item) => item.month);
            const mortalityData = reportData?.monthly_mortalities?.map((item) => item.monthly_mortalities);

            setDataForMortalities({
                labels: mortalityLabels,
                datasets: [
                    {
                        label: 'Total Mortalities',
                        data: mortalityData,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.6)',
                            'rgba(54, 162, 235, 0.6)',
                            'rgba(255, 206, 86, 0.6)',
                            'rgba(75, 192, 192, 0.6)',
                            'rgba(153, 102, 255, 0.6)',
                            'rgba(255, 159, 64, 0.6)'
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }
                ]
            });

            // Eggs Data
            const eggLabels = reportData?.combined_eggs_sales?.map((item) => item.month);
            const eggSalesData = reportData?.combined_eggs_sales?.map((item) => item.monthly_eggs_sales);
            const eggBrokenData = reportData?.combined_eggs_sales?.map((item) => item.monthly_eggs_broken_sales);

            setDataForEggs({
                labels: eggLabels,
                datasets: [
                    {
                        label: 'Eggs Sales',
                        data: eggSalesData,
                        backgroundColor: 'rgba(75, 192, 192, 0.6)',
                        borderColor: 'rgba(75, 192, 192, 1)',
                        borderWidth: 1
                    },
                    {
                        label: 'Broken Eggs Sales',
                        data: eggBrokenData,
                        backgroundColor: 'rgba(255, 99, 132, 0.6)',
                        borderColor: 'rgba(255, 99, 132, 1)',
                        borderWidth: 1
                    }
                ]
            });

            // Eggs Broken Chart
            const eggBrokenLabels = reportData?.monthly_eggs_spoilt?.map((item) => item.month);
            const eggBrokenCounts = reportData?.monthly_eggs_spoilt?.map((item) => item.monthly_eggs_spoilt);

            setDataForEggsBroken({
                labels: eggBrokenLabels,
                datasets: [
                    {
                        label: 'Monthly Eggs Broken',
                        data: eggBrokenCounts,
                        backgroundColor: 'rgba(255, 99, 132, 0.6)',
                        borderColor: 'rgba(255, 99, 132, 1)',
                        borderWidth: 1
                    }
                ]
            });

            // Eggs Collected Chart
            const eggCollectedLabels = reportData?.monthly_eggs_collected?.map((item) => item.month);
            const eggCollectedCounts = reportData?.monthly_eggs_collected?.map((item) => item.monthly_eggs_collected);

            setDataForEggsCollected({
                labels: eggCollectedLabels,
                datasets: [
                    {
                        label: 'Total Eggs Collected',
                        data: eggCollectedCounts,
                        backgroundColor: 'rgba(54, 162, 235, 0.6)',
                        borderColor: 'rgba(54, 162, 235, 1)',
                        borderWidth: 1
                    }
                ]
            });
        }
    }, [reportData]);

    useEffect(() => {
        fetchReportData();
    }, [filters]);

    const fetchReportData = () => {
        const { startDate, endDate, batchNumber } = filters;

        axios
            .get(`${apiUrl}/poultry-combined-report`, {
                params: { startDate, endDate, batchNumber }
            })
            .then((response) => {
                setReportData(response?.data);
                setLoading(false);
            })
            .catch((err) => {
                setError(err?.message);
                setLoading(false);
            });
    };

    console.log('Report Data:', reportData);

    const handleDateChange = (dates, dateStrings) => {
        setFilters({ ...filters, startDate: dateStrings[0], endDate: dateStrings[1] });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    const handleResetFilters = () => {
        setFilters({ startDate: '', endDate: '', batchNumber: '' });
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <React.Fragment>
            <Row className="mb-3">
                <Col>
                    <Card>
                        <Form layout="inline">
                            <Form.Item label="Date Range">
                                <DatePicker.RangePicker onChange={handleDateChange} />
                            </Form.Item>
                            <Form.Item label="Batch Number">
                                <Input type="text" name="batchNumber" value={filters?.batchNumber} onChange={handleInputChange} />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" onClick={fetchReportData}>
                                    Filter
                                </Button>
                                <Button onClick={handleResetFilters} style={{ marginLeft: '10px' }}>
                                    Reset
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xl={8}>
                    <Card title={`Report from ${filters?.startDate} - ${filters?.endDate} for Batch ${filters?.batchNumber}`}>
                        <Row>
                            <Col xl={12} md={12} lg={12} sm={12}>
                                <div className="row bg-white shadow-md pb-4 rounded-lg border-bottom-0">
                                    <div className="col rounded-md border text-align-center grid grid-cols-2 gap-4">
                                        <p>
                                            <p className="m-1 border-bottom">Most Used Account: </p>
                                            <p className="m-1">
                                                Name:{' '}
                                                <span className="font-weight-bold f-14">
                                                    {reportData?.most_used_bank_account?.bankAccount}
                                                </span>
                                            </p>
                                            <p className="m-1">
                                                Count:{' '}
                                                <span className="font-weight-bold f-14">
                                                    {reportData?.most_used_bank_account?.usage_count?.toLocaleString()}
                                                </span>
                                            </p>
                                        </p>
                                    </div>
                                    <div className="col rounded-md border text-align-center grid grid-cols-2 gap-4">
                                        <p>
                                            <p className="m-1 border-bottom">Most User transaction: </p>
                                            <p className="m-1">
                                                Name:{' '}
                                                <span className="font-weight-bold f-14">{reportData?.most_frequent_server?.servedBy}</span>
                                            </p>
                                            <p className="m-1">
                                                Count:{' '}
                                                <span className="font-weight-bold f-14">
                                                    {reportData?.most_frequent_server?.transaction_count?.toLocaleString()}
                                                </span>
                                            </p>
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col xl={12} lg={12} md={12} sm={12}>
                                <div className="bg-white shadow-md pb-4 rounded-lg border-bottom-0">
                                    <h6 className="text-lg font-bold mb-4">Poultry Report</h6>
                                    <Row>
                                        <Col>
                                            <div className="grid grid-cols-2 gap-4">
                                                <div className="rounded-md border-bottom text-align-center">
                                                    <p>
                                                        Purchase Expense:{' '}
                                                        <span className="font-weight-bold f-14">KS {reportData?.total_expense?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                                    </p>
                                                </div>
                                                <div className="rounded-md border-bottom text-align-center">
                                                    <p>
                                                        Sales Revenue:{' '}
                                                        <span className="font-weight-bold f-14">KS {reportData?.total_revenue?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                                    </p>
                                                </div>
                                                <div className="rounded-md border-bottom text-align-center">
                                                    <p>
                                                        Bought Poultry:{' '}
                                                        <span className="font-weight-bold f-14"> {reportData?.total_poultry_bought?.toLocaleString()}</span>
                                                    </p>
                                                </div>
                                                <div className="rounded-md border-bottom text-align-center">
                                                    <p>
                                                        Available Poultry:{' '}
                                                        <span className="font-weight-bold f-14">
                                                            {' '}
                                                            {reportData?.total_poultry_available?.toLocaleString()}
                                                        </span>
                                                    </p>
                                                </div>
                                                {/* <div className="rounded-md border-bottom text-align-center">
                                                    <p>
                                                        Total Feeds Consumed:{' '}
                                                        <span className="font-weight-bold f-14">{reportData?.total_consumption}</span>
                                                    </p>
                                                </div> */}
                                                <div className="rounded-md border-bottom text-align-center">
                                                    <p>
                                                        Total Vaccines Consumed:{' '}
                                                        <span className="font-weight-bold f-14">{reportData?.total_vaccines_consumed?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>{' '}
                                                    </p>
                                                </div>
                                                <div className="rounded-md border-bottom text-align-center">
                                                    <p>
                                                        Total Birds Sold:{' '}
                                                        <span className="font-weight-bold f-14">{reportData?.total_sold?.toLocaleString()}</span>
                                                    </p>
                                                </div>
                                                <div className="rounded-md border-bottom text-align-center">
                                                    <p>
                                                        Total Mortalities:{' '}
                                                        <span className="font-weight-bold f-14">{reportData?.total_mortalities?.toLocaleString()}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="grid grid-cols-2 gap-4">
                                                <div className="rounded-md border-bottom text-align-center">
                                                    <p>
                                                        Highest Income: - Batch:{' '}
                                                        <span className="font-weight-bold f-14">
                                                            {reportData?.highest_income_batch?.batch}
                                                        </span>
                                                        , Amount:{' '}
                                                        <span className="font-weight-bold f-14 text-success">
                                                            {reportData?.highest_income_batch?.highest_income_batch?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                        </span>
                                                    </p>
                                                </div>
                                                <div className="rounded-md border-bottom text-align-center">
                                                    <p>
                                                        Highest Expense: - Batch:{' '}
                                                        <span className="font-weight-bold f-14">
                                                            {reportData?.highest_expense_batch?.batch}
                                                        </span>
                                                        , Amount:{' '}
                                                        <span className="font-weight-bold f-14 text-danger">
                                                            {reportData?.highest_expense_batch?.highest_expense_batch?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                        </span>
                                                    </p>
                                                </div>
                                                <div className="rounded-md border text-align-center">
                                                    <p>
                                                        <p className="m-1 border-bottom">Most Consumed Vaccine: </p>
                                                        <p className="m-1">
                                                            Name:{' '}
                                                            <span className="font-weight-bold f-14">
                                                                {reportData?.most_consumed_vaccine?.vaccineName}
                                                            </span>
                                                        </p>
                                                        <p className="m-1">
                                                            Percentage:{' '}
                                                            <span className="font-weight-bold f-14">
                                                                {reportData?.most_consumed_vaccine?.percentage}
                                                            </span>
                                                            %
                                                        </p>
                                                        <p className="m-1">
                                                            Quantity:{' '}
                                                            <span className="font-weight-bold f-14">
                                                                {reportData?.most_consumed_vaccine?.most_consumed_vaccine?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} KG/LTS
                                                            </span>
                                                        </p>
                                                    </p>
                                                </div>
                                                <div className="rounded-md border text-align-center">
                                                    <p>
                                                        <p className="m-1 border-bottom">Most Consumed Feed: </p>
                                                        <p className="m-1">
                                                            Name:{' '}
                                                            <span className="font-weight-bold f-14">
                                                                {reportData?.most_consumed_feed?.feed_name}
                                                            </span>
                                                        </p>
                                                        <p className="m-1">
                                                            Percentage:{' '}
                                                            <span className="font-weight-bold f-14">
                                                                {reportData?.most_consumed_feed?.percentage?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                            </span>
                                                            %
                                                        </p>
                                                        <p className="m-1">
                                                            Quantity:{' '}
                                                            <span className="font-weight-bold f-14">
                                                            {reportData?.most_consumed_feed?.most_consumed_feed?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} KG/LTS
                                                            </span>
                                                        </p>
                                                    </p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col className="border" />
                            <Col xl={12} lg={12} md={12} sm={12}>
                                <div className="bg-white shadow-md pt-4 rounded-lg border-bottom-0">
                                    <h6 className="text-lg font-bold mb-4 border-info">Eggs Report</h6>
                                    <div className="grid grid-cols-2 gap-4">
                                        <div className="rounded-md border-bottom text-align-center">
                                            <p>
                                                Eggs Broken:{' '}
                                                <span className="font-weight-bold f-14"> {reportData?.total_eggs_broken?.toLocaleString()}</span>
                                            </p>
                                        </div>
                                        <div className="rounded-md border-bottom text-align-center">
                                            <p>
                                                Eggs Broken During Sales:{' '}
                                                <span className="font-weight-bold f-14"> {reportData?.eggs_broken_in_sales?.toLocaleString()}</span>
                                            </p>
                                        </div>
                                        <div className="rounded-md border-bottom text-align-center">
                                            <p>
                                                Total Eggs Collected:{' '}
                                                <span className="font-weight-bold f-14"> {reportData?.total_eggs_collected?.toLocaleString()}</span>
                                            </p>
                                        </div>
                                        <div className="rounded-md border-bottom text-align-center">
                                            <p>
                                                Total Sold Trays:{' '}
                                                <span className="font-weight-bold f-14"> {reportData?.total_trays_sales?.toLocaleString()}</span>
                                            </p>
                                        </div>
                                        <div className="rounded-md border-bottom text-align-center">
                                            <p>
                                                Broken Eggs Revenue:
                                                <span className="font-weight-bold f-14"> {reportData?.total_eggs_broken_sales?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>{' '}
                                            </p>
                                        </div>
                                        <div className="rounded-md border-bottom text-align-center">
                                            <p>
                                                Eggs Revenue:
                                                <span className="font-weight-bold f-14"> {reportData?.total_eggs_sales?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col xl={4}>
                    <Card>
                        <Row className="graph-container">
                            <Col xl={12} lg={12} md={6} sm={6} className="border-bottom mb-4">
                                <h6>Revenue and Expenses</h6>
                                <Bar data={dataForRevenue} />
                            </Col>
                            {/* <Col xl={12} lg={12} md={6} sm={6} className='border-bottom mb-4'>
                                <h6>Total Monthly Feeds Consumed</h6>
                                <Bar data={dataForFeeds} />
                            </Col> */}
                            <Col xl={12} lg={12} md={6} sm={6} className="border-bottom mb-4">
                                <h6>Monthly Feeds Consumed</h6>
                                <Bar data={dataForFeedsMonthly} />
                            </Col>
                            <Col xl={12} lg={12} md={6} sm={6} className="border-bottom mb-4">
                                <h6>Monthly Vaccines Consumption</h6>
                                <Bar data={dataForVaccines} />
                            </Col>
                            <Col xl={12} lg={12} md={6} sm={6} className="border-bottom mb-4">
                                <h6>Monthly Mortalities</h6>
                                {/* <div className="w-50"> */}
                                    <Pie data={dataForMortalities} />
                                {/* </div> */}
                            </Col>
                            <Col xl={12} lg={12} md={6} sm={6} className="border-bottom mb-4">
                                <h6>Monthly Eggs Broken</h6>
                                <Bar data={dataForEggsBroken} />
                            </Col>
                            <Col xl={12} lg={12} md={6} sm={6} className="border-bottom mb-4">
                                <h6>Monthly Eggs Collected</h6>
                                <Bar data={dataForEggsCollected} />
                            </Col>
                            <Col xl={12} lg={12} md={6} sm={6} className="border-bottom mb-4">
                                <h6>Monthly Eggs Sales</h6>
                                <Bar data={dataForEggs} />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default PoultryReport;
