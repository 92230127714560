import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Row, Col, Form, Button, Alert } from 'react-bootstrap';
import Select from 'react-select';
import config from './../../../config';
import axios from 'axios';

import Card from '../../../components/Card/MainCard';
import { usePermissions } from '../../../permissions';

const UpdateUser = () => {
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [user, setUser] = useState({});
    const { id } = useParams();
    const history = useHistory();
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [farmOptions, setFarmOptions] = useState([]);
    const [farm, setSelectedFarm] = useState(null);
    const { createPermission, deletePermission, editPermission } = usePermissions();
    const [userRoles, setUserRoles] = useState({});

    useEffect(() => {
        const fetchFarms = async () => {
            try {
                const response = await axios.get(`${apiUrl}/farm`);
                const farmData = response.data;
                const options = farmData.map((farm) => ({
                    value: farm.name,
                    label: farm.name
                }));

                setFarmOptions(options);
            } catch (error) {
                console.error('Error fetching Farms:', error);
            }
        };

        fetchFarms();
    }, [farm, apiUrl, farmOptions]);

    useEffect(() => {
        const fetchUserRoles = async () => {
            try {
                const response = await axios.get(`${apiUrl}/roles`);
                setUserRoles(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchUserRoles();
    }, []);

    const handleInpute = (fieldName, value) => {
        if (fieldName === 'farm') {
            setSelectedFarm(value);
            setUser((prevUser) => ({ ...prevUser, [fieldName]: value }));
        } else {
            setUser((prevUser) => ({ ...prevUser, [fieldName]: value }));
        }
        console.log(user);
    };

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await axios.get(`${apiUrl}/auth/${id}`);
                setUser(response.data[0]);
            } catch (error) {
                console.log(error);
            }
        };
        fetchUser();
    }, [id]);

    const handleInput = (e) => {
        const { name, value } = e.target;
        setUser((prevUser) => ({
            ...prevUser,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!editPermission) {
            return;
        }
        try {
            await axios.put(`${apiUrl}/user/${id}`, user);
            setSuccess('User updated successfully!');
            //   history.push('/'); // Redirect to the home page after successful update
        } catch (error) {
            setError(error.message);
            console.log(error);
        }
    };

    return (
        <React.Fragment>
            <Row>
                <Col sm={12}>
                    {success && (
                        <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                            {success}
                        </Alert>
                    )}
                    {error && (
                        <Alert variant="danger" onClose={() => setError(null)} dismissible>
                            {error}
                        </Alert>
                    )}
                </Col>
            </Row>

            <Row>
                <Col sm={12}>
                    <Link to="/users/employees">
                        <Button variant="primary" className="mb-3 mt-2">
                            <span className="feather icon-arrow-left"></span> Go Back
                        </Button>
                    </Link>
                    <Card title="Update User" isOption>
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col sm={12} md={6}>
                                    <div className="p-2">
                                        <Form.Label>First Name:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            size="sm"
                                            name="firstname"
                                            value={user.firstname || ''}
                                            onChange={handleInput}
                                        />
                                    </div>
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className="p-2">
                                        <Form.Label>Last Name:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            size="sm"
                                            name="lastname"
                                            value={user.lastname || ''}
                                            onChange={handleInput}
                                        />
                                    </div>
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className="p-2">
                                        <Form.Label>Email:</Form.Label>
                                        <Form.Control type="email" size="sm" name="email" value={user.email || ''} onChange={handleInput} />
                                    </div>
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className="p-2">
                                        <Form.Label>Phone Number:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            size="sm"
                                            name="phone_number"
                                            value={user.phone_number || ''}
                                            onChange={handleInput}
                                        />
                                    </div>
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className="p-2">
                                        <Form.Label>Mpesa Number:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            size="sm"
                                            name="mpesa_number"
                                            value={user.mpesa_number || ''}
                                            onChange={handleInput}
                                        />
                                    </div>
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className="p-2">
                                        <Form.Label>ID Number:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            size="sm"
                                            name="id_number"
                                            value={user.id_number || ''}
                                            onChange={handleInput}
                                        />
                                    </div>
                                </Col>
                                {/* <Col sm={12} md={6}>
                                    <div className="p-2">
                                        <Form.Label>User Space:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            as="select"
                                            size="sm"
                                            name="is_admin"
                                            value={user.is_admin || ''}
                                            onChange={handleInput}
                                        >
                                            <option value="Admin">Admin</option>
                                            <option value="User">User</option>
                                        </Form.Control>
                                    </div>
                                </Col> */}
                                <Col sm={12} md={6}>
                                    <div className="p-2">
                                        <Form.Label>User Role:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            as="select"
                                            size="sm"
                                            name="is_admin"
                                            value={user.is_admin || ''}
                                            onChange={handleInput}
                                        >
                                            <option value={user.is_admin}>{user.is_admin}</option>

                                            {Array.isArray(userRoles) &&
                                                userRoles.map((role) => (
                                                    <option key={role.id} value={role.name}>
                                                        {role.name}
                                                    </option>
                                                ))}
                                        </Form.Control>
                                    </div>
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className="p-2">
                                        <Form.Label>Status:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            as="select"
                                            size="sm"
                                            name="is_active"
                                            value={user.is_active || ''}
                                            onChange={handleInput}
                                        >
                                            <option value="Active">Active</option>
                                            <option value="Inactive">Inactive</option>
                                        </Form.Control>
                                    </div>
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className="p-2">
                                        <Form.Label>Employment Status:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            as="select"
                                            size="sm"
                                            name="emp_status"
                                            value={user.emp_status || ''}
                                            onChange={handleInput}
                                        >
                                            <option value="Permanent">Permanent</option>
                                            <option value="Contract">Contract</option>
                                            <option value="Temporary">Temporary</option>
                                        </Form.Control>
                                    </div>
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className="p-2">
                                        <Form.Label>Payment Rate:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            as="select"
                                            size="sm"
                                            name="payment_rate"
                                            value={user.payment_rate || ''}
                                            onChange={handleInput}
                                        >
                                            <option value="Per Day">Per Day</option>
                                            <option value="Per Month">Per Month</option>
                                            <option value="Per Week">Per Week</option>
                                        </Form.Control>
                                    </div>
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className="p-2">
                                        <Form.Label>NSSF:</Form.Label>
                                        <Form.Control type="text" size="sm" name="nssf" value={user.nssf || ''} onChange={handleInput} />
                                    </div>
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className="p-2">
                                        <Form.Label>NHIF:</Form.Label>
                                        <Form.Control type="text" size="sm" name="nhif" value={user.nhif || ''} onChange={handleInput} />
                                    </div>
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className="p-2">
                                        <Form.Label>Leave Days:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            size="sm"
                                            name="leave_days"
                                            value={user.leave_days || ''}
                                            onChange={handleInput}
                                        />
                                    </div>
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className="p-2">
                                        <Form.Label>Address:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            size="sm"
                                            name="address"
                                            value={user.address || ''}
                                            onChange={handleInput}
                                        />
                                    </div>
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className="p-2">
                                        <Form.Label>County:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            size="sm"
                                            name="county"
                                            value={user.county || ''}
                                            onChange={handleInput}
                                        />
                                    </div>
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className="p-2">
                                        <Form.Label>Farm:</Form.Label>
                                        <Select
                                            value={farmOptions.find((option) => option.value === user.farm)}
                                            options={farmOptions}
                                            onChange={(selectedOption) => handleInpute('farm', selectedOption.value)}
                                            isSearchable
                                            name="farm"
                                        />
                                    </div>
                                </Col>
                                <Col sm={12}>
                                    <div className="p-2">{editPermission && <Button type="submit">Update User</Button>}</div>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default UpdateUser;
