import React, { useState, useEffect, useRef } from 'react';
import config from '../../../config';
import Card from '../../../components/Card/MainCard';
import { Col, Row, Alert } from 'react-bootstrap';
import axios from 'axios';

const Receipt = ({ transactionId }) => {
    const [receiptData, setReceiptData] = useState(null);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const printContentRef = useRef(null);

    const handlePrint = () => {
        const printContent = printContentRef.current.innerHTML;
        const originalContent = document.body.innerHTML;

        document.body.innerHTML = printContent;
        window.print();
        document.body.innerHTML = originalContent;
    };

    useEffect(() => {
        const fetchReceiptData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/goat-receipt/${transactionId}`);
                const { receiptData } = response.data;
                setReceiptData(receiptData);
                console.log(receiptData);
            } catch (error) {
                console.error('Error retrieving receipt data:', error);
            }
        };

        fetchReceiptData();
    }, [transactionId]);

    if (!receiptData) {
        return <div>Loading receipt...</div>;
    }

    const avatarImage = '/assets/farm-logo.jpg';

    return (
        <div className="receipt" id="receipt-section" ref={printContentRef}>
            <Col sm={12} md={12} className="border-bottom">
                <div className="row d-flex align-items-center m-b-10">
                    <div className="col-4">
                        <img src={avatarImage} alt="Comapny Logo" style={{ width: '100px', height: 'auto', align: 'center' }} />
                    </div>
                    <div className="col-4"></div>
                    <div className="col-4">
                        {/* <div>SFARMS.CO.KE</div> */}
                        <div>P.O BOX 800-222</div>
                        <div>Naivasha</div>
                        <div>Phone : 254 729 588 892</div>
                        <div>Email : info@sfarms.co.ke</div>
                    </div>
                </div>
            </Col>
            <h4 className="receipt-heading">
                <Alert variant="success">SFARMS GOATS SALE</Alert>
            </h4>
            <h4 className="receipt-heading">RECIEPT: #{receiptData.transactionId}</h4>
            <p className="receipt-heading">TIMESTAMP: {new Date(receiptData.createdAt).toLocaleString()}</p>
            <div className="receipt-item">
                <span className="receipt-label">Customer :</span>
                <span className="receipt-value">{receiptData.cus_name}</span>
            </div>
            {receiptData.goats && (
                <div className="receipt-item">
                    <span className="receipt-label">No. of Goats :</span>
                    <span className="receipt-value">{receiptData.goats}</span>
                </div>
            )}
            {receiptData.tagNumber && (
                <div className="receipt-item">
                    <span className="receipt-label">Goats Tag No. :</span>
                    <span className="receipt-value">{receiptData.tagNumber}</span>
                </div>
            )}
            <div className="receipt-item">
                <span className="receipt-label">Price Per Kg :</span>
                <span className="receipt-value">Ksh. {receiptData.price.toLocaleString()}</span>
            </div>
            <div className="receipt-item">
                <span className="receipt-label">Total Kg :</span>
                <span className="receipt-value">{receiptData.totalKgs.toLocaleString()} Kgs</span>
            </div>
            <div className="receipt-item">
                <span className="receipt-label">Payment Mode :</span>
                <span className="receipt-value">{receiptData.pay_method}</span>
            </div>
            <div className="receipt-item">
                <span className="receipt-label">Delivery Mode :</span>
                <span className="receipt-value">{receiptData.delivery}</span>
            </div>
            {receiptData.del_guy && (
                <div className="receipt-item">
                    <span className="receipt-label">Delivery Guy :</span>
                    <span className="receipt-value">{receiptData.del_guy}</span>
                </div>
            )}
            <div className="receipt-item">
                <span className="receipt-label">Payment Reference :</span>
                <span className="receipt-value">{receiptData.pay_reference}</span>
            </div>
            <div className="receipt-item">
                <span className="receipt-label">Transaction ID :</span>
                <span className="receipt-value">{receiptData.transactionId}</span>
            </div>
            <div className="receipt-item">
                <span className="receipt-label">DATE :</span>
                <span className="receipt-value">{new Date(receiptData.recorded).toLocaleDateString()}</span>
            </div>
            <hr />
            <div className="receipt-footer">
                <span className="receipt-label">Total Cost: </span>
                <span className="receipt-value">Ksh. {receiptData.totalCost.toLocaleString()}</span>
            </div>
            <div className="receipt-footer">
                <span className="receipt-label">Served By: </span>
                <span className="receipt-value">{receiptData.servedBy}</span>
            </div>
            <div className="receipt-footer">
                <h4>Welcome Again</h4>
            </div>
        </div>
    );
};

export default Receipt;
