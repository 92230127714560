import React, {useEffect, useState, useRef} from 'react';
import Select from 'react-select';
import { Row, Col, Form, Tab, Tabs, Alert, Button, Modal, Badge, Card  } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../../../config';
import ModuleNotification from '../../../components/Widgets/Statistic/Notification1';
// import Card from '../../../components/Card/MainCard';
import axios from 'axios';
import { Link } from 'react-router-dom';

const SamplePage = () => {
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [totalEggsSoldFarm1, setTotalEggsSoldFarm1] = useState(0);
    const [totalEggsCollectedFarm1,  setTotalEggsCollectedFarm1] = useState(0);
    const [totalTraysCollectedFarm1,  setTotalTraysCollectedFarm1] = useState(0);
    const [totalSpoiltFarm1, setTotalSpoiltFarm1] = useState(0);
    const [totalDeadFarm1, settotalDeadFarm1] = useState(0);
    const [totalpigsSiredFarm1, setPigsSiredFarm1] = useState(0);
    const [totalFeedQuantityFarm1, setTotalFeedQuantityFarm1] = useState(0);
    const [totalCapacityFarm1, setTotalCapacityFarm1] = useState(0);
    const [totalRefillFarm1, setTotalRefillFarm1] = useState(0);
    const [totalpigsSoldFarm1, setTotalPigsSoldFarm1] = useState(0);
    const [totalGoatsSoldFarm1, setTotalGoatsSoldFarm1] = useState(0);
    const [totalPigsDeadFarm1, setTotalPigsDeadFarm1] = useState(0);
    const [totalGoatsDeadFarm1, setTotalGoatsDeadFarm1] = useState(0);
    const [totalSiredFarm1, setTotalGoatsSiredFarm1] = useState(0);
    const [totalPoultryFarm1, setTotalPoultryFarm1] = useState(0);
    const [totalGoatsFarm1, setTotalGoatsFarm1] = useState(0);
    const [totalPigsFarm1, setTotalPigsFarm1] = useState(0);
    const [totalUsersFarm1, setTotalUsersFarm1] = useState(0);
    const [birdsSoldFarm1, setSoldBirdsFarm1] = useState(0);
    const [totalsFarm1, setTotalsFarm1] = useState({ layers: 0, broilers: 0 });
    const [feed1, setFeedFarm1] = useState({ totalFeed: 0 });
    const [farmOptions, setFarmOptions] = useState([]);
    const [selectedFarm, setSelectedFarm] = useState(null);

    useEffect(() => {
        const fetchFarms = async () => {
          try {
            const response = await axios.get(`${apiUrl}/farm`);
            const farmData = response.data;
            const options = farmData.map((farm) => ({
              value: farm.name, 
              label: farm.name, 
            }));
      
            setFarmOptions(options);
          } catch (error) {
            console.error('Error fetching Farms:', error);
          }
        };
      
        fetchFarms();
      }, [apiUrl, farmOptions]);

      const handleInpute = (fieldName, value) => {
        if (fieldName === 'farm') {
          setSelectedFarm(value);
        } 
        console.log(selectedFarm)
      };

    useEffect(() => {
        axios.get(`${apiUrl}/poultry/total?farm=${selectedFarm}`)
          .then((response) => {
            setTotalPoultryFarm1(response.data.totalQuantity);
          })
          .catch((error) => {
            console.error(error);
          });
      }, [selectedFarm]);

      useEffect(() => {
        axios.get(`${apiUrl}/goats/total?farm=${selectedFarm}`)
          .then((response) => {
            setTotalGoatsFarm1(response.data.totalGoats);
          })
          .catch((error) => {
            console.error(error);
          });
      }, [selectedFarm]);

      useEffect(() => {
        axios.get(`${apiUrl}/total/pigs?farm=${selectedFarm}`)
          .then((response) => {
            setTotalPigsFarm1(response.data.totalPigs);
          })
          .catch((error) => {
            console.error(error);
          });
      }, [selectedFarm]);

      useEffect(() => {
        axios.get(`${apiUrl}/users/total?farm=${selectedFarm}`)
          .then((response) => {
            setTotalUsersFarm1(response.data.totalUsers);
          })
          .catch((error) => {
            console.error(error);
          });
      }, [selectedFarm]);

      useEffect(() => {
        axios.get(`${apiUrl}/totalBirdsSold?farm=${selectedFarm}`)
          .then((response) => {
            setSoldBirdsFarm1(response.data.totalBirdsSold);
          })
          .catch((error) => {
            console.error(error);
          });
      }, [selectedFarm]);

      useEffect(() => {
        axios.get(`${apiUrl}/dead/total?farm=${selectedFarm}`)
          .then((response) => {
            settotalDeadFarm1(response.data.totalDead);
          })
          .catch((error) => {
            console.error(error);
          });
      }, [selectedFarm]);
    
      useEffect(() => {
        axios.get(`${apiUrl}/water/total?farm=${selectedFarm}`)
          .then((response) => {
            setTotalCapacityFarm1(response.data.totalCapacity);
          })
          .catch((error) => {
            console.error(error);
          });
      }, [selectedFarm]);

      useEffect(() => {
        axios.get(`${apiUrl}/feeds/total?farm=${selectedFarm}`)
          .then((response) => {
            setTotalFeedQuantityFarm1(response.data.totalFeedQuantity);
            // console.log(response);
          })
          .catch((error) => {
            console.error(error);
          });
      }, [selectedFarm]);

      useEffect(() => {
        axios.get(`${apiUrl}/water/refill?farm=${selectedFarm}`)
          .then((response) => {
            setTotalRefillFarm1(response.data.totalRefill);
            // console.log(response);
          })
          .catch((error) => {
            console.error(error);
          });
      }, [selectedFarm]);
    
        useEffect(() => {
            axios.get(`${apiUrl}/eggsSold/total?farm=${selectedFarm}`)
            .then((response) => {
                setTotalEggsSoldFarm1(response.data.totalEggsSold);
                // console.log(response);
            })
            .catch(error => {
                console.error(error);
            });
        }, [selectedFarm]);
    
        useEffect(() => {
            axios.get(`${apiUrl}/eggsCollected/total?farm=${selectedFarm}`)
            .then((response) => {
                setTotalEggsCollectedFarm1(response.data.totalCollected);
                // console.log(response);
            })
            .catch(error => {
                console.error(error);
            });
        }, [selectedFarm]);
    
        useEffect(() => {
            axios.get(`${apiUrl}/traysCollected/total?farm=${selectedFarm}`)
            .then((response) => {
                setTotalTraysCollectedFarm1(response.data.totalTrays);
                // console.log(response);
            })
            .catch(error => {
                console.error(error);
            });
        }, [selectedFarm]);

        useEffect(() => {
            axios.get(`${apiUrl}/goatsSold/total?farm=${selectedFarm}`)
            .then((response) => {
                setTotalGoatsSoldFarm1(response.data.totalGoatsSold);
                // console.log(response);
            })
            .catch(error => {
                console.error(error);
            });
        }, [selectedFarm]);

        useEffect(() => {
            axios.get(`${apiUrl}/totalGoatsDead?farm=${selectedFarm}`)
            .then((response) => {
                setTotalGoatsDeadFarm1(response.data.totalGoatsDead);
                // console.log(response);
            })
            .catch(error => {
                console.error(error);
            });
        }, [selectedFarm]);

        useEffect(() => {
            axios.get(`${apiUrl}/goatsSired/total?farm=${selectedFarm}`)
            .then((response) => {
                setTotalGoatsSiredFarm1(response.data.totalSired);
                // console.log(response);
            })
            .catch(error => {
                console.error(error);
            });
        }, [selectedFarm]);
    
        useEffect(() => {
            axios.get(`${apiUrl}/pigsSold/total?farm=${selectedFarm}`)
            .then((response) => {
                setTotalPigsSoldFarm1(response.data.totalpigsSold);
                // console.log(response);
            })
            .catch(error => {
                console.error(error);
            });
        }, [selectedFarm]);

        useEffect(() => {
            axios.get(`${apiUrl}/pigsDead/total?farm=${selectedFarm}`)
            .then((response) => {
                setTotalPigsDeadFarm1(response.data.totalPigsDead);
                // console.log(response);
            })
            .catch(error => {
                console.error(error);
            });
        }, [selectedFarm]);
    
        useEffect(() => {
            axios.get(`${apiUrl}/eggsBroken/total?farm=${selectedFarm}`)
            .then((response) => {
                setTotalSpoiltFarm1(response.data.totalSpoilt);
                // console.log(response);
            })
            .catch(error => {
                console.error(error);
            });
        }, [selectedFarm]);
        
        useEffect(() => {
            axios.get(`${apiUrl}/pigsSired/total?farm=${selectedFarm}`)
            .then((response) => {
                setPigsSiredFarm1(response.data.totalpigsSired);
                // console.log(response);
            })
            .catch(error => {
                console.error(error);
            });
        }, [selectedFarm]);

        useEffect(() => {
            axios.get(`${apiUrl}/api/poultrytotals?farm=${selectedFarm}`)
            .then((response) => {
                setTotalsFarm1(response.data);
                // console.log(response);
            })
            .catch(error => {
                console.error(error);
            });
        }, [selectedFarm]);

        useEffect(() => {
            fetch(`${apiUrl}/feedConsumption?farm=${selectedFarm}`)
              .then((response) => response.json())
              .then((data) => setFeedFarm1(data))
              .catch((error) => console.error('Error:', error));
        }, [selectedFarm]);

        useEffect(() => {
            axios.get(`${apiUrl}/water/total?farm=${selectedFarm}`)
              .then((response) => {
                setTotalCapacityFarm1(response.data.totalCapacity);
                // console.log(response);
              })
              .catch((error) => {
                console.error(error);
              });
        }, [selectedFarm]);

        useEffect(() => {
            axios.get(`${apiUrl}/feeds/total?farm=${selectedFarm}`)
              .then((response) => {
                setTotalFeedQuantityFarm1(response.data.totalFeedQuantity);
                // console.log(response);
              })
              .catch((error) => {
                console.error(error);
              });
        }, [selectedFarm]);

        useEffect(() => {
            axios.get(`${apiUrl}/water/refill?farm=${selectedFarm}`)
              .then((response) => {
                setTotalRefillFarm1(response.data.totalRefill);
                // console.log(response);
              })
              .catch((error) => {
                console.error(error);
              });
        }, [selectedFarm]);

    return (
        <React.Fragment>
            <Row>
                <Col md={12} xl={12} >
                    <Card style={{ }} >
                        <Card.Body className=''>
                            <div className="row d-flex align-items-center">
                                <div className="col-12">
                                    <Col md={4} sm={12} lg={4}>
                                        <h6 className="mb-4 ">Select Farm</h6>
                                        <Form.Group>
                                            <Select
                                                value={farmOptions.find(option => option.value === selectedFarm)}
                                                options={farmOptions}
                                                onChange={(selectedOption) => handleInpute('farm', selectedOption.value)}
                                                isSearchable
                                                name="farm"
                                            />
                                        </Form.Group>
                                    </Col>
                                </div>
                            </div>
                            <div className="progress m-t-30" style={{ height: '7px' }}>
                                
                            </div>
                        </Card.Body>
                    </Card>
                </Col>

                {apiUrl &&(
                <Col md={6} xl={6} >
                    <Card style={{ }} >
                        <Card.Body className=''>
                            <h6 className="mb-4 ">{selectedFarm} - Animal Totals</h6>
                            <div className="row d-flex align-items-center">
                                <div className="col-6">
                                        <p className='m-t-10 m-b-10'><img src='/assets/chicken (2).png' alt='poultry' style={{ width: '22px' }}/> <span  className='pl-2 font-weight-bold text-uppercase'> Poultry:</span> <span className=''>{totalPoultryFarm1 && (totalPoultryFarm1.toLocaleString())}</span></p>
                                        <p className='m-t-10 m-b-10'><img src='/assets/goat (2).png' alt='goats' style={{ width: '22px' }}/> <span className='pl-2 font-weight-bold text-uppercase'> Goats:</span> <span className=''>{totalGoatsFarm1 && (totalGoatsFarm1.toLocaleString())}</span></p> 
                                        <p className='m-t-10 m-b-10'><img src='/assets/chicken (3).png' alt='goats' style={{ width: '22px' }}/> <span className='pl-2 font-weight-bold text-uppercase'> bROILERS:</span> <span className=''>{totalsFarm1.broilers && (totalsFarm1.broilers.toLocaleString())}</span></p> 
                                        <p className='m-t-10 m-b-10'>.. </p> 
                                    
                                </div>

                                <div className="col-6">
                                    <p className='m-t-10 m-b-10'><img src='/assets/animal.png' alt='pigs' style={{ width: '22px' }}/> <span className='pl-2 font-weight-bold text-uppercase'>Pigs: </span> <span className=''>{totalPigsFarm1 &&(totalPigsFarm1.toLocaleString())}</span></p>
                                    <p className='m-t-10 m-b-10'><img src='/assets/farmer.png' alt='person' style={{ width: '22px' }}/> <span className='pl-2 font-weight-bold text-uppercase'>Employees: </span> <span className=''>{totalUsersFarm1 &&(totalUsersFarm1.toLocaleString())}</span></p>
                                    <p className='m-t-10 m-b-10'><img src='/assets/chicken (4).png' alt='person' style={{ width: '22px' }}/> <span className='pl-2 font-weight-bold text-uppercase'>Layers: </span> <span className=''>{totalsFarm1.layers &&(totalsFarm1.layers.toLocaleString())}</span></p>
                                    <p className='m-t-10 m-b-10'>.. </p> 
                                </div>
                            </div>
                            <div className="progress m-t-30" style={{ height: '7px' }}>
                                
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                )}
                {apiUrl &&(
                <Col md={6} xl={6} >
                    <Card style={{ }} >
                        <Card.Body className=''>
                            <h6 className="mb-4 ">{selectedFarm} - Accounting & Finance</h6>
                            <div className="row d-flex justify-content-between">
                                <div className="col-6">
                                {selectedFarm ? (
                                    <>
                                        <Link to={`/income/${selectedFarm}`}><p className='m-t-10 m-b-10'><img src='/assets/profit.png' alt='poultry' style={{ width: '20px' }}/> <span  className='pl-2 font-weight-bold text-uppercase'> Income</span> </p></Link>
                                        <Link to={`/expense/${selectedFarm}`}><p className='m-t-10 m-b-10'><img src='/assets/accounting.png' alt='goats' style={{ width: '20px' }}/> <span className='pl-2 font-weight-bold text-uppercase'> Expenditure</span> </p> </Link>
                                        <Link to={`/sales/${selectedFarm}`}><p className='m-t-10 m-b-10'><img src='/assets/business.png' alt='goats' style={{ width: '20px' }}/> <span className='pl-2 font-weight-bold text-uppercase'> Sales</span></p> </Link>
                                        <Link to={`/loans/${selectedFarm}`}><p className='m-t-10 m-b-10'><img src='/assets/signing.png' alt='pigs' style={{ width: '20px' }}/> <span className='pl-2 font-weight-bold text-uppercase'>Loans</span></p></Link>
                                    </>
                                    ) : (
                                        <span>Select a farm to view</span>
                                    )}
                                </div>

                                <div className="col-6">
                                {selectedFarm ? (
                                    <>
                                    <Link to={`/acquisition/${selectedFarm}`}><p className='m-t-10 m-b-10'><img src='/assets/cash.png' alt='goats' style={{ width: '20px' }}/> <span className='pl-2 font-weight-bold text-uppercase'> Animal acquisition</span></p> </Link>
                                    <Link to={`/payments/${selectedFarm}`}><p className='m-t-10 m-b-10'><img src='/assets/cashless-payment.png' alt='person' style={{ width: '20px' }}/> <span className='pl-2 font-weight-bold text-uppercase'>payments</span></p></Link>
                                    <Link to={`/balancesheet/${selectedFarm}`}><p className='m-t-10 m-b-10'><img src='/assets/balance-sheet.png' alt='person' style={{ width: '20px' }}/> <span className='pl-2 font-weight-bold text-uppercase'>Balance Sheet</span></p></Link>
                                    </>
                                    ) : (
                                        <span>Select a farm to view</span>
                                    )}
                                </div>
                            </div>
                            <div className="progress m-t-30" style={{ height: '7px' }}>
                                
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                 )}
                {apiUrl &&(
                <Col sm={12} md={12}>
                    <Card>
                    <Card.Header >
                            <Card.Title as="h5" >
                            {selectedFarm} - Animal Management
                            </Card.Title>
                        </Card.Header>
                    
                        <Card.Body>
                            <Row>
                             {/* Poultry */}
                                <Col sm={6} md={3}>
                                <p className=''>Poultry</p>
                                {apiUrl &&(
                                    <ModuleNotification
                                        message={'Total Birds'}
                                        className="feather icon-trending-up"
                                        
                                        count={totalPoultryFarm1}
                                        
                                    />)}
                                {apiUrl &&(
                                    <ModuleNotification
                                        message="Birds Sold"
                                        
                                        count={birdsSoldFarm1}
                                        
                                    />)}
                                {apiUrl &&(
                                    <ModuleNotification
                                        message="Birds Mortality"
                                        
                                        count={totalDeadFarm1}
                                        
                                    />)}
                                </Col>

                                 {/* Pigs */}
                                <Col sm={6} md={3}>
                                <p className=''>Total Eggs - {totalEggsCollectedFarm1}</p>
                                 {apiUrl &&(
                                    <ModuleNotification
                                        message="Trays Available"
                                       
                                        count={totalTraysCollectedFarm1}
                                        
                                    />)}
                                {apiUrl &&(
                                    <ModuleNotification
                                        message="Trays Sold"
                                        
                                        count={totalEggsSoldFarm1}
                                        
                                    />)}
                                    {apiUrl &&(
                                    <ModuleNotification
                                        message={'Broken Eggs'}
                                        className="feather icon-trending-up"
                                        
                                        count={totalSpoiltFarm1}
                                        
                                    />)}
                                </Col>

                                {/* Goats */}
                                <Col sm={6} md={3}>
                                <p className=''>Goats</p>{apiUrl &&(
                                    <ModuleNotification
                                        message="Goats Sold"
                                        
                                        count={totalGoatsSoldFarm1}
                                        
                                    />)}
                                {apiUrl &&(
                                    <ModuleNotification
                                        message="Kids Sired"
                                        
                                        count={totalSiredFarm1}
                                        
                                    />)}
                                {apiUrl &&(
                                    <ModuleNotification
                                        message={'Goats Mortality'}
                                        className="feather icon-trending-up"
                                        
                                        count={totalGoatsDeadFarm1}
                                    />)}
                                </Col>

                                 {/* Something els */}
                                <Col sm={6} md={3}>
                                    <p className=''>Pigs</p>
                                    {apiUrl &&(
                                    <ModuleNotification
                                        message="Pigs Sold"
                                        
                                        count={totalpigsSoldFarm1}
                                        
                                    />)}
                                {apiUrl &&(
                                    <ModuleNotification
                                        message="Piglets Sired"
                                        
                                        count={totalpigsSiredFarm1}
                                        
                                    />)}
                                {apiUrl &&(
                                    <ModuleNotification
                                        message="Pigs Mortality"
                                        
                                    count={totalPigsDeadFarm1}
                                        
                                    />)}
                                </Col>
                            </Row>
                        </Card.Body>
                 </Card>
                </Col>
                 )}
                {apiUrl &&(
                <Col md={12} xl={12} sm={12}>
                    <Card>
                        <Card.Header >
                            <Card.Title as="h5" >
                            {selectedFarm} - Levels
                            </Card.Title>
                        </Card.Header>
                        <Link to="/water">
                        <Card.Body className='border-bottom'>
                        <div className="row d-flex align-items-center">
                                <div className="col-9">
                                    <h5 className="f-w-300 d-flex align-items-center m-b-0 ">
                                    {/* <i class="fa fa-tint f-24 text-c-grey" aria-hidden="true"></i> */}
                                    <img src='/assets/water.png' alt='water' style={{ width: '35px' }} />
                                    </h5>
                                    <h6 className="d-block text-uppercase">available water</h6>
                                </div>
                                {apiUrl &&(

                                <div className="col-3">
                                    <h6>{totalCapacityFarm1.toLocaleString()} Litres</h6>
                                </div>)}
                            </div>
                        </Card.Body>
                        </Link>
                        <Link to="/water/water-refill">
                        <Card.Body className='border-bottom'>
                            <div className="row d-flex align-items-center">
                                <div className="col-9">
                                    <h5 className="f-w-300 d-flex align-items-center m-b-0 ">
                                      {/* <i class="fa fa-tint f-24 text-c-grey" aria-hidden="true"></i> */}
                                      <img src='/assets/water.png' alt='water' style={{ width: '35px' }} />
                                    </h5>
                                    <h6 className="d-block text-uppercase">water refilled</h6>
                                </div>
                                {apiUrl && (

                                <div className="col-3">
                                    <h6>{totalRefillFarm1.toLocaleString()} Litres</h6>
                                </div>
                                )}
                            </div>
                        </Card.Body>
                        </Link>
                        <Link to="/feeds">
                        <Card.Body className='border-bottom'>
                        <div className="row d-flex align-items-center">
                                <div className="col-9">
                                    <h5 className="f-w-300 d-flex align-items-center m-b-0 ">
                                      <img src='/assets/feed.png' alt='Chicken' style={{ width: '35px' }} />
                                        {/* <i class="fa fa-rss f-24 text-c-grey" aria-hidden="true"></i> */}
                                    </h5>
                                    <h6 className="d-block text-uppercase">available feeds</h6>
                                </div>
                                {apiUrl && (
                                <div className="col-3">
                                    <h6>{totalFeedQuantityFarm1.toLocaleString()} Kgs</h6>
                                </div>)}
                            </div>
                        </Card.Body>
                        </Link>
                        <Link to="/feeds/consumed-feeds">
                        <Card.Body className='border-bottom'>
                            <div className="row d-flex align-items-center">
                                <div className="col-9">
                                    <h5 className="f-w-300 d-flex align-items-center m-b-0 ">
                                        {/* <i class="fa fa-rss f-24 text-c-grey" aria-hidden="true"></i> */}
                                        <img src='/assets/feed.png' alt='Chicken' style={{ width: '35px' }} />
                                    </h5>
                                    <h6 className="d-block text-uppercase">Feeds consumed</h6>
                                </div>

                                <div className="col-3">
                                    <h6>{feed1.totalFeed.toLocaleString()} Kgs</h6>
                                </div>
                            </div>
                        </Card.Body>
                        </Link>
                    </Card>
                    
                </Col>
                 )}
              
            </Row>
        </React.Fragment>
    );
};

export default SamplePage;
