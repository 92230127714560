import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Table, Button, Pagination, Form, Card } from 'react-bootstrap';
import Select from 'react-select';
import { CSVLink } from 'react-csv';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import axios from 'axios';
import config from '../../config';
import { useParams, useHistory } from 'react-router-dom';
import { usePermissions } from '../../permissions';
// import Card from '../../components/Card/MainCard';
import DataTable from 'react-data-table-component';

const UserList = () => {
    const [users, setUsers] = useState([]);
    const [Mo, setMo] = useState([]);
    const [MoV, setMoV] = useState([]);
    const { id } = useParams();
    const history = useHistory();
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage, setUsersPerPage] = useState(10);
    const { createPermission, deletePermission, editPermission } = usePermissions();
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/all-pigs`);
                setUsers(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [users, apiUrl]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/pigs/feeds/monthly`);
                setMo(response.data[0]);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [users, apiUrl]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/pigs/vaccines/monthly`);
                setMoV(response.data[0]);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [users, apiUrl]);

    const calculateAge = (birthDate) => {
        const today = new Date();
        const birth = new Date(birthDate);

        // Get the UTC values for year, month, and day
        const todayUTC = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
        const birthUTC = Date.UTC(birth.getFullYear(), birth.getMonth(), birth.getDate());

        // Calculate the age difference in milliseconds
        const ageDiffMillis = todayUTC - birthUTC;

        // Convert milliseconds to years, months, and days
        const yearDiff = Math.floor(ageDiffMillis / (1000 * 60 * 60 * 24 * 365));
        const monthDiff = Math.floor((ageDiffMillis % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30));
        const dayDiff = Math.floor((ageDiffMillis % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24));

        if (yearDiff > 0) {
            return yearDiff === 1 ? `${yearDiff} year` : `${yearDiff} years`;
        } else if (monthDiff > 0) {
            return monthDiff === 1 ? `${monthDiff} month` : `${monthDiff} months`;
        } else {
            return dayDiff === 1 ? `${dayDiff} day` : `${dayDiff} days`;
        }
    };

    const csvLinkRef = useRef();

    const exportToCSV = () => {
        const csvData = [
            ['Tag No', 'Type', 'D.O.B', 'Silo No', 'Supplier', 'Date', 'Farm'],
            ...filteredData.map((item) => [
                item.tagNumber,
                item.type,
                item.birth,
                item.siloNumber,
                item.sup_name,
                item.createdAt,
                item.farm
            ])
        ];

        const csvContent = csvData.map((row) => row.join(',')).join('\n');
        const blob = new Blob(['\ufeff', csvContent], { type: 'text/csv;charset=utf-8;' });

        const csvUrl = URL.createObjectURL(blob);
        csvLinkRef.current.href = csvUrl;
        csvLinkRef.current.download = 'sfarms.csv';
        csvLinkRef.current.click();
    };

    const exportToPDF = () => {
        const doc = new jsPDF();
        doc.autoTable({
            head: [['Tag No', 'Type', 'D.O.B', 'Silo No', 'Supplier', 'Date', 'Farm']],
            body: filteredData.map((user) => [
                user.tagNumber,
                user.type,
                user.birth,
                `${user.siloNumber}`,
                user.sup_name,
                new Intl.DateTimeFormat('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                }).format(new Date(user.createdAt)),
                user.farm
            ])
        });
        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl);
    };

    const columns = [
        {
            name: 'Tag No',
            selector: (row) => row.tagNumber,
            cell: (row) => <Link to={`view-tagNumber/${row.tagNumber}`}>{row.tagNumber}</Link>,
            sortable: true
        },
        {
            name: 'Type',
            selector: (row) => row?.type,
            sortable: true,
            wrap: true
        },

        {
            name: 'Birth',
            selector: (row) => new Date(row.birth).toLocaleDateString(),
            sortable: true
        },
        {
            name: 'Age',
            selector: (row) => calculateAge(row.birth),
            sortable: true
        },
        {
            name: 'Silo No',
            selector: (row) => row.siloNumber,
            sortable: true,
            wrap: true
        },
        {
            name: 'Supplier',
            selector: (row) => row.sup_name,
            sortable: true,
            wrap: true
        },
        {
            name: 'farm',
            selector: (row) => row.farm,
            sortable: true,
            wrap: true
        },

        {
            name: 'Arrival',
            selector: (row) => new Date(row?.recorded).toLocaleDateString(),
            sortable: true,
            wrap: true
        }
    ];

    const ExpandableRow = ({ data }) => {
        return (
            <div style={{ padding: '10px 20px' }}>
                <p className="m-0">
                    <strong className="text-c-blue">Cost:</strong>{' '}
                    {data?.cost !== null && data.cost !== undefined
                        ? data.cost.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                          })
                        : '-'}
                </p>
                <p className="m-0">
                    <strong className="text-c-blue">Weight:</strong> {data?.weight || '-'}
                </p>
                <p className="m-0">
                    <strong className="text-c-blue">Health:</strong> {data?.health || '-'}
                </p>
                <p className="m-0">
                    <strong className="text-c-blue">Remarks:</strong> {data?.remarks || '-'}
                </p>
            </div>
        );
    };

    const filteredData = searchText
        ? users.filter((item) =>
              Object.values(item).some((value) => typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase()))
          )
        : users;

    return (
        <React.Fragment>
            <Row>
                <Col md={4} xl={4}>
                    <Card style={{}}>
                        <Card.Body className="">
                            <div className="row d-flex align-items-center">
                                <div className="col-6">
                                    <h6 className="">Pigs Monthly Consumption</h6>
                                </div>

                                <div className="col-6 text-right">{/* {totals.layers} */}</div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4} xl={4}>
                    <Card style={{}}>
                        <Link to="/pigs/feeds-consumption">
                            <Card.Body className="">
                                <div className="row d-flex align-items-center">
                                    <div className="col-6">
                                        <h6 className="">Feeds</h6>
                                    </div>
                                    <div className="col-6 text-right">{Mo && <span>{Mo.total_consumed} Kgs/Litres</span>}</div>
                                </div>
                            </Card.Body>
                        </Link>
                    </Card>
                </Col>
                <Col md={4} xl={4}>
                    <Card style={{}}>
                        <Link to="/pigs/vaccination">
                            <Card.Body className="">
                                <div className="row d-flex align-items-center">
                                    <div className="col-6">
                                        <h6 className="">Vaccines</h6>
                                    </div>
                                    <div className="col-6 text-right">{MoV && <span>{MoV.total_consumed} Kgs/Litres</span>}</div>
                                </div>
                            </Card.Body>
                        </Link>
                    </Card>
                </Col>
            </Row>
            <Row className="btn-page">
                <Col>
                    <div className="d-flex justify-content-between align-center m-b-10">
                        <div>
                            <span> </span>
                        </div>
                        {createPermission && (
                            <Link to="/pigs/add-pig">
                                <Button variant="primary" className="">
                                    <span className="feather icon-plus-circle"></span> Add Pigs
                                </Button>
                            </Link>
                        )}
                    </div>
                    <Row className="btn-page">
                        <Col>
                            {filteredData && filteredData.length > 0 && (
                                <Row>
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasicName">
                                        <Form.Control
                                            type="text"
                                            placeholder="Search..."
                                            value={searchText}
                                            onChange={(e) => setSearchText(e.target.value)}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasicName">
                                        <div className="d-flex">
                                            <Button onClick={exportToCSV}>Export as CSV</Button>
                                            <a ref={csvLinkRef} style={{ display: 'none' }} />
                                            <a href="#" className="btn btn-primary" onClick={exportToPDF}>
                                                Export PDF
                                            </a>
                                        </div>
                                    </Form.Group>
                                </Row>
                            )}
                            <Row>
                                <Col>
                                    <DataTable
                                        columns={columns}
                                        data={filteredData}
                                        pagination
                                        highlightOnHover
                                        striped
                                        // noHeader
                                        dense
                                        bordered
                                        paginationPerPage={10}
                                        paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                                        paginationComponentOptions={{
                                            rowsPerPageText: 'Rows per page:'
                                        }}
                                        paginationResetDefaultPage={true}
                                        paginationTotalRows={users.length}
                                        expandableRows
                                        expandableRowsComponent={ExpandableRow}
                                        selectableRows
                                        onSelectedRowsChange={(selectedRows) => console.log(selectedRows)}
                                        customStyles={{
                                            headRow: {
                                                style: {
                                                    backgroundColor: '#279e6c', // Green background
                                                    color: '#fff', // White text
                                                    textTransform: 'uppercase', // Uppercase text
                                                    // fontSize: '10px', // Adjust font size for header row
                                                    minHeight: '1.5rem',
                                                    paddingTop: '8px',
                                                    paddingBottom: '8px'
                                                }
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default UserList;
