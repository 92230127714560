import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from './../../../config';
import axios from 'axios';

import Card from '../../../components/Card/MainCard';

const UpdateUser = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [user, setUser] = useState({});
  const { id } = useParams();
  const history = useHistory();
  const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
  const apiUrl = config[environment].apiUrl;

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${apiUrl}/auth/${id}`);
        setUser(response.data[0]);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, [id]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${apiUrl}/password/${id}`, user);
      setSuccess("User Password updated successfully!");
      toast.success("User Password updated successfully!");
    } catch (error) {
      setError(error.message);
      console.log(error)
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col sm={12}>
          {success && (
            <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
              {success}
            </Alert>
          )}
          {error && (
            <Alert variant="danger" onClose={() => setError(null)} dismissible>
              {error}
            </Alert>
          )}
          <ToastContainer />
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
        <Link to="/users/employees">
            <Button variant="primary" className="mb-3 mt-2">
              <span className='feather icon-arrow-left'></span>  Go Back
            </Button>
          </Link>
          <Card title={`Update Password - ${user.firstname} ${user.lastname}`} isOption>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col sm={12} md={12}>
                  <div className="p-2">
                    <label>USER ID:</label> {user.id}
                  </div>
                </Col>
                <Col sm={12} md={6}>
                  <div className="p-2">
                    <Form.Label>Enter Password:</Form.Label>
                    <Form.Control type="text" size='sm' name="password" onChange={handleInput} required />
                  </div>
                </Col>
                <Col sm={12}>
                  <div className="p-2">
                    <Button type="submit">Update</Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UpdateUser;
