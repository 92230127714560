import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Row, Col, Card, ListGroup, Table, Button } from 'react-bootstrap';
import ModuleNotification from '../../../components/Widgets/Statistic/Notification';
import config from '../../../config';

// import avatar1 from '../../../../assets/images/user/avatar-1.jpg';
import avatar2 from './../../../assets/images/user/avatar-2.jpg';
// import avatar3 from '../../../../assets/images/user/avatar-3.jpg';
// import avatar4 from '../../../../assets/images/user/avatar-4.jpg';

import axios from 'axios';

const DetailsUser = () => {
    const { id } = useParams();
    const [userData, setUserData] = useState({});
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;

    useEffect(() => {
        const getUser = async () => {
            try {
                const requestDoc = await axios.get(`${apiUrl}/auth/${id}`);
                setUserData(requestDoc.data[0]);
                //   console.log(requestDoc.data);
            } catch (error) {
                console.log('Error getting document:', error);
            }
        };
        getUser();
    }, [id]);

    const calculateAge = (birthDate) => {
        const today = new Date();
        const birth = new Date(birthDate);

        // Get the UTC values for year, month, and day
        const todayUTC = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
        const birthUTC = Date.UTC(birth.getFullYear(), birth.getMonth(), birth.getDate());

        // Calculate the age difference in milliseconds
        const ageDiffMillis = todayUTC - birthUTC;

        // Convert milliseconds to years, months, and days
        const yearDiff = Math.floor(ageDiffMillis / (1000 * 60 * 60 * 24 * 365));
        const monthDiff = Math.floor((ageDiffMillis % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30));
        const dayDiff = Math.floor((ageDiffMillis % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24));

        if (yearDiff > 0) {
            return yearDiff === 1 ? `${yearDiff} year ago` : `${yearDiff} years ago`;
        } else if (monthDiff > 0) {
            return monthDiff === 1 ? `${monthDiff} month ago` : `${monthDiff} months ago`;
        } else {
            return dayDiff === 1 ? `${dayDiff} day ago` : `${dayDiff} days ago`;
        }
    };

    if (!userData) {
        return <div>Loading...</div>;
    }

    const {
        firstname,
        lastname,
        email,
        id_number,
        phone_number,
        mpesa_number,
        nssf,
        nhif,
        county,
        address,
        salary,
        leave_days,
        payment_rate,
        emp_status,
        is_admin,
        is_active,
        nok_firstname,
        nok_lastname,
        nok_email,
        nok_phone_number,
        nok_address,
        createdAt,
        farm,
        id_card,
        passport,
        nssf_card,
        nhif_card,
        updatedAt
    } = userData;

    const viewImage = (file) => {
        if (file) {
            window.open(`${apiUrl}/uploads/${file}`, '_blank');
        }
    };

    return (
        <React.Fragment>
            <Row>
                <Col md={6} lg={4}>
                    <Card>
                        <Link to="/users/employees">
                            <Button variant="primary" className="mb-3 mt-2">
                                <span className="feather icon-arrow-left"></span> Go Back
                            </Button>
                        </Link>
                        <Card.Header>
                            <Card.Title as="h5">User Profile</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <div className="row pro-head d-flex align-items-center justify-content-between border-bottom">
                                <div className="col-12">
                                    {passport ? (
                                        <img
                                            src={`${apiUrl}/uploads/${passport}`}
                                            className="img-radius"
                                            alt="User Passport"
                                            style={{
                                                width: '100px',
                                                height: '100px',
                                                borderRadius: '50%',
                                                marginBottom: '1.2rem',
                                                objectFit: 'cover'
                                            }}
                                        />
                                    ) : (
                                        <img src={avatar2} className="img-radius" alt="User Profile" />
                                    )}
                                </div>
                                <div className="col pb-3">
                                    <span>
                                        {firstname} {lastname}
                                    </span>
                                </div>
                                <div className="col pb-3">
                                    <Link to="#" className="dud-logout ml-5" title="Logout">
                                        <span className="feather icon-log-out"></span> {is_active}
                                    </Link>
                                </div>
                            </div>
                            <Card.Text as="h6"></Card.Text>
                            <ListGroup className="list-group-flush border-bottom">
                                <div className="row d-flex align-items-center m-t-5 m-b-5">
                                    <div className="col-6">
                                        <p className="m-1">PHONE NUMBER:</p>
                                        <p className="m-1">ID NUMBER:</p>
                                        <p className="m-1">EMAIL:</p>
                                    </div>

                                    <div className="col-6 text-right">
                                        <p className="m-1">{phone_number}</p>
                                        <p className="m-1">{id_number}</p>
                                        <p className="m-1">{email}</p>
                                    </div>
                                </div>
                                {/* <ListGroup.Item variant="success"> <strong>PHONE NUMBER: </strong> {phone_number}</ListGroup.Item>
                            <ListGroup.Item variant="success"> <strong>ID NUMBER: </strong> {id_number}</ListGroup.Item>
                            <ListGroup.Item variant="success"> <strong>EMAIL: </strong> {email}</ListGroup.Item> */}
                            </ListGroup>
                        </Card.Body>
                    </Card>

                    <Card>
                        <Card.Header>
                            <Card.Title as="h5">Next of Kin</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Table responsive hover striped>
                                <tbody>
                                    <tr>
                                        <td>Next Of Kin Name:</td>
                                        <td>
                                            {nok_firstname} {nok_lastname}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Next Of Kin Email:</td>
                                        <td>{nok_email}</td>
                                    </tr>
                                    <tr>
                                        <td>Next Of Kin Phone:</td>
                                        <td>{nok_phone_number}</td>
                                    </tr>
                                    <tr>
                                        <td>Next Of Kin Address:</td>
                                        <td>{nok_address}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={6} lg={8}>
                    <Card>
                        <Card.Header>
                            <Card.Title as="h5">User Details</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Table responsive hover striped>
                                {/* <thead>
                                <tr>
                                    <th>ID</th>
                                    
                                    <th>Last Name</th>
                                    <th>Email</th>
                                    <th>Phone Number</th>
                                    <th>Action</th>
                                </tr>
                            </thead> */}
                                <tbody>
                                    <tr>
                                        <td>Mpesa Number:</td>
                                        <td>{mpesa_number}</td>
                                    </tr>
                                    <tr>
                                        <td>NSSF:</td>
                                        <td>{nssf}</td>
                                    </tr>
                                    <tr>
                                        <td>NHIF:</td>
                                        <td>{nhif}</td>
                                    </tr>
                                    <tr>
                                        <td>County:</td>
                                        <td>{county}</td>
                                    </tr>
                                    <tr>
                                        <td>Address:</td>
                                        <td>{address}</td>
                                    </tr>
                                    {/* <tr>
                                        <td>Salary:</td>
                                        <td>Ksh. {salary}</td>
                                    </tr> */}
                                    <tr>
                                        <td>Leave:</td>
                                        <td>{leave_days} day(s)</td>
                                    </tr>
                                    <tr>
                                        <td>Payment Rate:</td>
                                        <td>{payment_rate}</td>
                                    </tr>
                                    <tr>
                                        <td>Employement Status:</td>
                                        <td>{emp_status}</td>
                                    </tr>
                                    <tr>
                                        <td>User Role:</td>
                                        <td>{is_admin}</td>
                                    </tr>
                                    <tr>
                                        <td>Created:</td>
                                        <td>{new Date(createdAt).toLocaleString()}</td>
                                    </tr>
                                    <tr>
                                        <td>Updated:</td>
                                        <td>{new Date(updatedAt).toDateString()}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <Table responsive hover striped>
                                <tbody>
                                    <tr>
                                        <td>Farm:</td>
                                        <td>{farm}</td>
                                    </tr>
                                    <tr>
                                        <td>Passport Photo:</td>
                                        <td>
                                            {passport ? (
                                                <Button onClick={() => viewImage(passport)}>
                                                    <i className="feather icon-download-cloud" /> View
                                                </Button>
                                            ) : (
                                                <Button variant="secondary" disabled>
                                                    <i className="feather icon-slash" /> No Image
                                                </Button>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>ID Photo:</td>
                                        <td>
                                            {id_card ? (
                                                <Button onClick={() => viewImage(id_card)}>
                                                    <i className="feather icon-download-cloud" /> View
                                                </Button>
                                            ) : (
                                                <Button variant="secondary" disabled>
                                                    <i className="feather icon-slash" /> No Image
                                                </Button>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Nhif Photo:</td>
                                        <td>
                                            {nhif_card ? (
                                                <Button onClick={() => viewImage(nhif_card)}>
                                                    <i className="feather icon-download-cloud" /> View
                                                </Button>
                                            ) : (
                                                <Button variant="secondary" disabled>
                                                    <i className="feather icon-slash" /> No Image
                                                </Button>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Nssf Photo:</td>
                                        <td>
                                            {nssf_card ? (
                                                <Button onClick={() => viewImage(nssf_card)}>
                                                    <i className="feather icon-download-cloud" /> View
                                                </Button>
                                            ) : (
                                                <Button variant="secondary" disabled>
                                                    <i className="feather icon-slash" /> No Image
                                                </Button>
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default DetailsUser;
