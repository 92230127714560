import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Alert, Button } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from './../../../config';
import Select from 'react-select';
import Card from '../../../components/Card/MainCard';
import axios from 'axios';
import ConfirmationModal from './ConfirmationModal.js';
import { usePermissions } from '../../../permissions/index.js';

const BasicTypography = () => {
    const account = useSelector((state) => state.account);
    const [data, setData] = useState({});
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [selectedSupplier, setSelectedSupplier] = useState('');
    const [supplierOptions, setSupplierOptions] = useState([]);
    const [selectedPoultry, setSelectedPoultry] = useState('');
    const [totalCost, setTotalCost] = useState(0);
    const [selectedImage, setSelectedImage] = useState('');
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [batchNumber, setBatchNumber] = useState(0);
    const [accounts, setAccounts] = useState([]);
    const [farmOptions, setFarmOptions] = useState([]);
    const [farm, setSelectedFarm] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const { createPermission, deletePermission, editPermission } = usePermissions();

    const handleInputs = (fieldName, value) => {
        if (fieldName === 'farm') {
            setSelectedFarm(value);
            setData((prevData) => ({ ...prevData, [fieldName]: value }));
        } else {
            setData((prevData) => ({ ...prevData, [fieldName]: value }));
        }
    };

    //  console.log(data)
    const generateTransactionId = () => {
        const timestamp = new Date().getTime();
        const random = Math.floor(Math.random() * 1000);
        return `SF-${random}`;
    };

    useEffect(() => {
        fetchAccounts();
    }, [accounts, farm, apiUrl]);

    const fetchAccounts = async () => {
        try {
            const response = await axios.get(`${apiUrl}/accounts`);
            const accounts = response.data;

            // const filteredData = accounts.filter((tag) => tag.farm === farm);

            // const filteredAccounts = accounts.filter(account => account.accountName !== 'Income');

            setAccounts(accounts);
        } catch (error) {
            console.error('Error fetching accounts:', error);
        }
    };

    useEffect(() => {
        const calculateTotalCost = () => {
            const totalCost = data.quantity * data.price;
            setTotalCost(totalCost);
            setData((prevData) => ({ ...prevData, totalCost }));
        };

        calculateTotalCost();
    }, [data.quantity, data.price]);

    const handleInput = (fieldName, value) => {
        setData((prevData) => ({
            ...prevData,
            [fieldName]: value,
            batch: batchNumber,
            type: selectedPoultry,
            userName: account.user.firstname,
            userId: account.user.id
        }));
    };

    useEffect(() => {
        fetchMaxBatchNumber();
    }, [batchNumber, apiUrl]);

    const fetchMaxBatchNumber = async () => {
        try {
            const response = await axios.get(`${apiUrl}/poultry/max-batch-number`);
            const maxBatchNumber = response.data.maxBatchNumber || 0;
            // console.log(maxBatchNumber)
            setBatchNumber(maxBatchNumber + 1);
        } catch (error) {
            console.log(error);
        }
    };

    const formData = new FormData();
    formData.append('image', selectedImage);
    formData.append('sup_info', data.sup_info);
    formData.append('quantity', data.quantity);
    formData.append('price', data.price);
    formData.append('totalCost', data.totalCost);
    formData.append('batch', data.batch);
    formData.append('type', data.type);
    formData.append('bankAccount', data.bankAccount);
    formData.append('transactionId', generateTransactionId());
    formData.append('farm', data.farm);
    formData.append('recorded', data.recorded);
    formData.append('userId', data.userId);
    formData.append('userName', data.userName);

    const handlePoultry = async (e) => {
        e.preventDefault();
        setShowConfirmationModal(true);
    };

    const handleConfirmation = async (e) => {
        if (!createPermission) {
            return;
        }
        axios
            .post(`${apiUrl}/poultry/new`, formData)
            .then((response) => {
                if (response.data) {
                    setSuccess(response.data.message);
                    setTimeout(() => {
                        setSuccess(null);
                    }, 10000);
                    toast.success(`Poultry added successfully`);
                    window.location.href = window.location.pathname + '?success=true';

                    const newBatchNumber = batchNumber + 1;
                    setBatchNumber(newBatchNumber);
                    setData({});
                    setShowConfirmationModal(false);
                    // localStorage.setItem('batchNumber', newBatchNumber.toString());
                }
                console.log(response);
            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    setError(`${error.response.data.message}`);
                    toast.error(`${error.response.data.message}`);
                    setShowConfirmationModal(false);
                } else {
                    setError('An error occurred. Please try again later.');
                    setShowConfirmationModal(false);
                }
                console.log(error);
            });
    };

    const handleCancelConfirmation = () => {
        // Handle cancel action if needed
        setShowConfirmationModal(false);
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const successParam = urlParams.get('success');

        if (successParam === 'true') {
            setSuccess('Poultry added successfully');
            toast.success(`Poultry added successfully`);
            setTimeout(() => {
                setSuccess(null);
            }, 10000);
            window.history.replaceState({}, document.title, window.location.pathname);
        }
    }, []);

    useEffect(() => {
        const fetchSuppliers = async () => {
            try {
                const response = await axios.get(`${apiUrl}/suppliers`);
                const data = response.data;

                // const filteredData = data.filter((tag) => tag.farm === farm);

                const options = data.map((supplier) => ({
                    value: supplier.name,
                    label: supplier.name
                }));
                setSupplierOptions(options);
            } catch (error) {
                console.error('Error fetching suppliers:', error);
            }
        };

        fetchSuppliers();
    }, [supplierOptions, apiUrl, farm]);

    const poultryOptions = [
        { value: 'Layers', label: 'Layers' },
        { value: 'Broilers', label: 'Broilers' }
    ];

    const handleImageChange = (e) => {
        setSelectedImage(e.target.files[0]);
    };

    const handleSelectChange = (name, selectedOption) => {
        setData({ ...data, [name]: selectedOption.value });
    };

    useEffect(() => {
        const fetchFarms = async () => {
            try {
                const response = await axios.get(`${apiUrl}/farm`);
                const farmData = response.data;

                const options = farmData.map((farm) => ({
                    value: farm.name,
                    label: farm.name
                }));

                setFarmOptions(options);
            } catch (error) {
                console.error('Error fetching Farms:', error);
            }
        };

        fetchFarms();
    }, [farmOptions, apiUrl, farm]);

    return (
        <React.Fragment>
            <Row>
                <Col sm={12}>
                    {success && (
                        <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                            {success}
                        </Alert>
                    )}
                    {error && (
                        <Alert variant="danger" onClose={() => setError(null)} dismissible>
                            {error}
                        </Alert>
                    )}
                    <ToastContainer />
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <Card title="Poultry Details" isOption>
                        <Form onSubmit={handlePoultry}>
                            <Row>
                                <Form.Group as={Col} md={6} sm={12} lg={6}>
                                    <Form.Label>Farm</Form.Label>
                                    <Select
                                        options={farmOptions}
                                        onChange={(selectedOption) => handleInputs('farm', selectedOption.value)}
                                        isSearchable
                                        name="farm"
                                    />
                                </Form.Group>

                                <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                    <Form.Label>Supplier</Form.Label>
                                    <Select
                                        value={selectedSupplier}
                                        onChange={(selectedOption) => setSelectedSupplier(handleInput('sup_info', selectedOption.value))}
                                        options={supplierOptions}
                                        isSearchable
                                        name="sup_info"
                                        placeholder="-- Select a supplier --"
                                        required
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                    <Form.Label>Poultry Type</Form.Label>
                                    <Select
                                        // value={selectedPoultry}
                                        onChange={(selectedOption) => setSelectedPoultry(selectedOption.value)}
                                        options={poultryOptions}
                                        isSearchable
                                        name="type"
                                        placeholder="-- Select type --"
                                        required
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                    <Form.Label>Number of Birds</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        required
                                        onChange={(e) => handleInput('quantity', e.target.value)}
                                        type="number"
                                        name="quantity"
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                    <Form.Label>Batch Number</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        required
                                        onChange={(e) => handleInput('batch', e.target.value)}
                                        type="number"
                                        name="batch"
                                        value={batchNumber}
                                        readOnly
                                    />
                                </Form.Group>

                                <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                    <Form.Label>Price/Cost Per Bird</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        required
                                        onChange={(e) => handleInput('price', e.target.value)}
                                        type="number"
                                        name="price"
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md={6} sm={12} lg={6}>
                                    <Form.Label>Total Cost</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        required
                                        type="text"
                                        onChange={(e) => handleInput('totalCost', e.target.value)}
                                        value={totalCost}
                                        disabled
                                        name="totalCost"
                                    />
                                </Form.Group>
                                <Col md={6} sm={12} lg={6}>
                                    <Form.Group>
                                        <Form.Label>Expense Account:</Form.Label>
                                        <Select
                                            onChange={(selectedOption) => handleSelectChange('bankAccount', selectedOption)}
                                            options={accounts.map((account) => ({
                                                value: account.description,
                                                label: (account.accountNumber, account.description)
                                            }))}
                                            placeholder="Select account"
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Form.Group as={Col} md={6} sm={12} lg={6}>
                                    <Form.Label>Receipt Document</Form.Label>
                                    <Form.Control type="file" onChange={handleImageChange} name="image" accept="image/*" required />
                                </Form.Group>

                                <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                    <Form.Label>Date</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        required
                                        onChange={(e) => handleInput('recorded', e.target.value)}
                                        type="date"
                                        name="recorded"
                                    />
                                </Form.Group>
                                {createPermission && (
                                    <Form.Group as={Col} md={12} sm={12} lg={12}>
                                        <Button type="submit">Submit</Button>
                                    </Form.Group>
                                )}
                            </Row>
                        </Form>
                    </Card>
                </Col>
            </Row>
            <ConfirmationModal show={showConfirmationModal} onHide={handleCancelConfirmation} onConfirm={handleConfirmation} />
        </React.Fragment>
    );
};

export default BasicTypography;
