import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Alert, Button } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import axios from 'axios';
import config from '../../config';
import Card from '../../components/Card/MainCard';
import ConfirmationModal from '../ui-elements/basic/ConfirmationModal';

const BasicTypography = () => {
    const account = useSelector((state) => state.account);
    const [data, setData] = useState({});
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [age, setAge] = useState(0);
    const [tagNumber, setTagNumber] = useState('');
    const [tagNumbers, setTagNumbers] = useState([]);
    const [birthDate, setBirthDate] = useState('');
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [supplierOptions, setSupplierOptions] = useState([]);
    const [selectedPig, setSelectedPig] = useState(null);
    const [selectedImage, setSelectedImage] = useState('');
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const formRef = useRef(null);
    const [siloNumber, setSiloNumber] = useState(0);
    const [accounts, setAccounts] = useState([]);
    const [farmOptions, setFarmOptions] = useState([]);
    const [farm, setSelectedFarm] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const memoizedApiUrl1 = useMemo(() => {
        return `${apiUrl}/farm`;
    }, [apiUrl]);

    const memoizedApiUrl2 = useMemo(() => {
        return `${apiUrl}/accounts`;
    }, [apiUrl]);

    const memoizedApiUrl3 = useMemo(() => {
        return `${apiUrl}/suppliers`;
    }, [apiUrl]);

    useEffect(() => {
        const fetchFarms = async () => {
            try {
                const response = await axios.get(memoizedApiUrl1);
                const farmData = response.data;
                const options = farmData.map((farm) => ({
                    value: farm.name,
                    label: farm.name
                }));

                setFarmOptions(options);
            } catch (error) {
                console.error('Error fetching Farms:', error);
            }
        };

        fetchFarms();
    }, [memoizedApiUrl1]);

    const generateTransactionId = () => {
        const timestamp = new Date().getTime();
        const random = Math.floor(Math.random() * 1000);
        return `SF-${random}`;
    };

    useEffect(() => {
        fetchAccounts();
    }, [memoizedApiUrl2]);

    const fetchAccounts = async () => {
        try {
            const response = await axios.get(memoizedApiUrl2);
            const accounts = response.data;

            // const filteredData = accounts.filter((tag) => tag.farm === farm);

            // const filteredAccounts = accounts.filter(account => account.accountName !== 'Income');

            setAccounts(accounts);
        } catch (error) {
            console.error('Error fetching accounts:', error);
        }
    };

    const handleSelectChange = (name, selectedOption) => {
        setData({ ...data, [name]: selectedOption.value });
    };

    const handleInpute = (fieldName, value) => {
        if (fieldName === 'farm') {
            setSelectedFarm(value);
            setData((prevData) => ({ ...prevData, [fieldName]: value }));
        } else {
            setData((prevData) => ({ ...prevData, [fieldName]: value }));
        }
        console.log(data);
    };

    const handleInput = (name, value) => {
        setData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
            tagNumber: tagNumber,
            siloNumber: siloNumber, userId: account.user.id, userName: account.user.firstname
        }));
        console.log(data);
    };

    useEffect(() => {
        const fetchMaxSiloNumber = async () => {
            try {
                const response = await axios.get(`${apiUrl}/goat/max-silo-number`);
                const maxSiloNumber = response.data.maxSiloNumber || 0;
                console.log(maxSiloNumber);
                setSiloNumber(maxSiloNumber + 1);
            } catch (error) {
                console.log(error);
            }
        };
        fetchMaxSiloNumber();
    }, [siloNumber, apiUrl, farm]);

    useEffect(() => {
        const fetchTagNumbers = async () => {
            try {
                const response = await axios.get(`${apiUrl}/all-goats`);
                setTagNumbers(response.data.map((item) => item.tagNumber));
            } catch (error) {
                console.error('Error fetching tag numbers:', error);
            }
        };

        fetchTagNumbers();
    }, [tagNumbers]);

    useEffect(() => {
        setTagNumber(generateTagNumber());
    }, [tagNumbers]);

    const generateTagNumber = () => {
        if (!tagNumbers || tagNumbers.length === 0) {
            return 'PG-01';
        }

        const numbers = tagNumbers.map((tag) => parseInt(tag.slice(3)));

        const maxNumber = Math.max(...numbers);

        return `GT-${(maxNumber + 1).toString().padStart(2, '0')}`;
    };

    const formData = new FormData();
    formData.append('image', selectedImage);
    formData.append('sup_name', data.sup_name);
    formData.append('remarks', data.remarks);
    formData.append('health', data.health);
    formData.append('cost', data.cost);
    formData.append('siloNumber', data.siloNumber);
    formData.append('tagNumber', data.tagNumber);
    formData.append('type', data.type);
    formData.append('birth', data.birth);
    formData.append('age', data.age);
    formData.append('weight', data.weight);
    formData.append('bankAccount', data.bankAccount);
    formData.append('transactionId', generateTransactionId());
    formData.append('recorded', data.recorded);
    formData.append('farm', data.farm);
    formData.append('userId', data.userId);
    formData.append('userName', data.userName);

    const handlePoultry = async (e) => {
        e.preventDefault();
        setShowConfirmationModal(true);
    };

    const handleConfirmation = async (e) => {
        e.preventDefault();

        axios
            .post(`${apiUrl}/goats/new`, formData)
            .then((response) => {
                if (response.data) {
                    setSuccess(`Goat with tag ${tagNumber} added successfully`);
                    setTimeout(() => {
                        setSuccess(null);
                    }, 10000);
                    toast.success(`Goat with tag ${tagNumber} added successfully`);
                    setData({});
                    formRef.current.reset();
                    setSelectedSupplier(null);
                    setSelectedPig(null);
                    const newSiloNumber = siloNumber + 1;
                    setSiloNumber(newSiloNumber);
                    setTagNumber(tagNumber);
                    setShowConfirmationModal(false);
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    setError(`${error.response.data.message}`);
                    toast.error(`${error.response.data.message}`);
                    setShowConfirmationModal(false);
                } else {
                    setError('An error occurred. Please try again later.');
                    setShowConfirmationModal(false);
                }
                console.log(error);
                setShowConfirmationModal(false);
            });
    };

    useEffect(() => {
        const fetchSuppliers = async () => {
            try {
                const response = await axios.get(memoizedApiUrl3);
                const data = response.data;

                // const filteredData = data.filter((tag) => tag.farm === farm);

                const options = data.map((supplier) => ({
                    value: supplier.name,
                    label: supplier.name
                }));
                setSupplierOptions(options);
            } catch (error) {
                console.error('Error fetching suppliers:', error);
            }
        };

        fetchSuppliers();
    }, [memoizedApiUrl3]);

    const handleBirthDateChange = (e) => {
        const tagNumber = generateTagNumber();
        const selectedDate = new Date(e.target.value);
        handleInput('birth', e.target.value);

        const currentDate = new Date();
        const ageInMilliseconds = currentDate - selectedDate;
        const ageDate = new Date(ageInMilliseconds);
        const years = Math.floor(ageInMilliseconds / (365.25 * 24 * 60 * 60 * 1000));
        const months = ageDate.getUTCMonth();
        const days = ageDate.getUTCDate() - 1;

        const formattedAge = `${years} years ${months} months ${days} days`;
        handleInput('age', formattedAge);
    };

    useEffect(() => {
        if (birthDate) {
            handleBirthDateChange({ target: { value: birthDate } });
        }
    }, [birthDate]);

    const pigOptions = [
        { value: 'Alpine', label: 'Alpine' },
        { value: 'Anglo-Nubian', label: 'Anglo-Nubian' },
        { value: 'Auckland Island', label: 'Auckland Island' },
        { value: 'Australian Miniature', label: 'Australian Miniature' },
        { value: 'Bagot', label: 'Bagot' },
        { value: 'Golden Guernsey', label: 'Golden Guernsey' },
        { value: 'Chamois Coloured', label: 'Chamois Coloured' },
        { value: 'Dutch Landrace', label: 'Dutch Landrace' }
    ];

    const handleImageChange = (e) => {
        setSelectedImage(e.target.files[0]);
    };

    const handleCancelConfirmation = () => {
        // Handle cancel action if needed
        setShowConfirmationModal(false);
    };

    return (
        <React.Fragment>
            <Row>
                <Col sm={12}>
                    {success && (
                        <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                            {success}
                        </Alert>
                    )}
                    {error && (
                        <Alert variant="danger" onClose={() => setError(null)} dismissible>
                            {error}
                        </Alert>
                    )}
                    <ToastContainer />
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <Card title="Goat Details" isOption>
                        <Form onSubmit={handlePoultry} ref={formRef}>
                            <Row>
                                <Form.Group as={Col} md={6} sm={12} lg={6}>
                                    <Form.Label>Farm</Form.Label>
                                    <Select
                                        options={farmOptions}
                                        onChange={(selectedOption) => setSelectedFarm(handleInput('farm', selectedOption.value))}
                                        isSearchable
                                        name="farm"
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                    <Form.Label>Supplier</Form.Label>
                                    <Select
                                        value={selectedSupplier}
                                        onChange={(selectedOption) => setSelectedSupplier(handleInput('sup_name', selectedOption.value))}
                                        options={supplierOptions}
                                        isSearchable
                                        name="sup_name"
                                        placeholder="-- Select a supplier --"
                                        required
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                    <Form.Label>Goat Type</Form.Label>
                                    <Select
                                        size="sm"
                                        required
                                        value={selectedPig}
                                        options={pigOptions}
                                        isSearchable
                                        onChange={(selectedOption) => setSelectedPig(handleInput('type', selectedOption.value))}
                                        type="text"
                                        name="type"
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                    <Form.Label>Date of birth</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        required
                                        onChange={handleBirthDateChange}
                                        type="date"
                                        name="birth"
                                        value={formData.birth}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                    <Form.Label>Age</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        required
                                        value={data.age}
                                        onChange={(e) => handleInput('age', e.target.value)}
                                        type="text"
                                        name="age"
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                    <Form.Label>Silo Number</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        required
                                        value={siloNumber}
                                        onChange={(e) => handleInput('siloNumber', e.target.value)}
                                        type="number"
                                        name="siloNumber"
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                    <Form.Label>Tag Number</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        required
                                        onChange={(e) => handleInput('tagNumber', e.target.value)}
                                        type="text"
                                        name="tagNumber"
                                        value={tagNumber}
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                    <Form.Label>Weight</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        required
                                        onChange={(e) => handleInput('weight', e.target.value)}
                                        type="number"
                                        name="weight"
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                    <Form.Label>Cost</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        required
                                        onChange={(e) => handleInput('cost', e.target.value)}
                                        type="number"
                                        name="cost"
                                    />
                                </Form.Group>

                                <Form.Group as={Col} md={6} sm={12} lg={6}>
                                    <Form.Label>Expense Account:</Form.Label>
                                    <Select
                                        onChange={(selectedOption) => handleSelectChange('bankAccount', selectedOption)}
                                        options={accounts.map((account) => ({
                                            value: account.description,
                                            label: (account.accountNumber, account.description)
                                        }))}
                                        placeholder="Select account"
                                        required
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md={6} sm={12} lg={6}>
                                    <Form.Label>Receipt Document</Form.Label>
                                    <Form.Control type="file" onChange={handleImageChange} name="image" accept="image/*" required />
                                </Form.Group>

                                <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                    <Form.Label>Date</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        required
                                        onChange={(e) => handleInput('recorded', e.target.value)}
                                        type="date"
                                        name="recorded"
                                    />
                                </Form.Group>

                                <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                    <Form.Label>Health Status</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        required
                                        onChange={(e) => handleInput('health', e.target.value)}
                                        type="text"
                                        name="health"
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                    <Form.Label>Remarks /Notes</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        required
                                        onChange={(e) => handleInput('remarks', e.target.value)}
                                        type="text"
                                        name="remarks"
                                    />
                                </Form.Group>

                                <Form.Group as={Col} md={12} sm={12} lg={12}>
                                    <Button type="submit">Submit</Button>
                                </Form.Group>
                            </Row>
                        </Form>
                    </Card>
                </Col>
            </Row>

            <ConfirmationModal show={showConfirmationModal} onHide={handleCancelConfirmation} onConfirm={handleConfirmation} />
        </React.Fragment>
    );
};

export default BasicTypography;
