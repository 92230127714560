import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { Row, Col, Form, Button, Table, Alert, Tabs, Tab, Modal, Pagination} from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Font, PDFViewer  } from '@react-pdf/renderer';
import Card from '../../components/Card/MainCard';
import config from '../../config';
import Receipt from './GeneralReceipt1.js';

// Register a custom font (optional)
Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
});

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Roboto',
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  heading: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  label: {
    fontSize: 12,
    marginBottom: 3,
  },
  value: {
    fontSize: 12,
  },
  footer: {
    fontSize: 12,
    marginTop: 10,
    textAlign: 'center',
  },
});

const ReceiptPDF = ({ receiptData }) => {
  return (
    <PDFViewer style={{ width: '100%', height: '100vh' }}>
      <Document>
        <Page style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.heading}>SFARMS SALES RECEIPT</Text>
            <Text style={styles.heading}>RECEIPT: #{receiptData.transactionId}</Text>
            <Text style={styles.heading}>DATE/TIME: {new Date(receiptData.createdAt).toLocaleString()}</Text>
            {receiptData.cus_name && (
              <View>
                <Text style={styles.label}>Customer:</Text>
                <Text style={styles.value}>{receiptData.cus_name}</Text>
              </View>
            )}
            {receiptData.birds && (
              <View>
                <Text style={styles.label}>No. of Birds:</Text>
                <Text style={styles.value}>{receiptData.birds}</Text>
              </View>
            )}
            {receiptData.tagNumber && (
              <View>
                <Text style={styles.label}>Tag No.:</Text>
                <Text style={styles.value}>{receiptData.tagNumber}</Text>
              </View>
            )}
            {receiptData.goats && (
              <View>
                <Text style={styles.label}>No. of Goats:</Text>
                <Text style={styles.value}>{receiptData.goats}</Text>
              </View>
            )}
            {receiptData.pigs && (
              <View>
                <Text style={styles.label}>No. of Pigs:</Text>
                <Text style={styles.value}>{receiptData.pigs}</Text>
              </View>
            )}
            {receiptData.price && (
              <View>
                <Text style={styles.label}>Price:</Text>
                <Text style={styles.value}>Ksh. {receiptData.price.toLocaleString()}</Text>
              </View>
            )}
            {receiptData.pay_method && (
              <View>
                <Text style={styles.label}>Payment Mode:</Text>
                <Text style={styles.value}>{receiptData.pay_method}</Text>
              </View>
            )}
            {receiptData.delivery && (
              <View>
                <Text style={styles.label}>Delivery Mode:</Text>
                <Text style={styles.value}>{receiptData.delivery}</Text>
              </View>
            )}
            {receiptData.del_guy && (
              <View>
                <Text style={styles.label}>Delivery Guy:</Text>
                <Text style={styles.value}>{receiptData.del_guy}</Text>
              </View>
            )}
            {receiptData.pay_reference && (
              <View>
                <Text style={styles.label}>Payment Reference:</Text>
                <Text style={styles.value}>{receiptData.pay_reference}</Text>
              </View>
            )}
            {receiptData.transactionId && (
              <View>
                <Text style={styles.label}>Transaction ID :</Text>
                <Text style={styles.value}>{receiptData.transactionId}</Text>
              </View>
            )}
            {receiptData.createdAt && (
              <View>
                <Text style={styles.label}>Date / Time :</Text>
                <Text style={styles.value}>{new Date(receiptData.createdAt).toLocaleString()}</Text>
              </View>
            )}
            <View style={styles.footer}>
              <Text>Welcome Again</Text>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

const TransactionHistory = () => {
  const [error, setError] = useState(null);
  const formRef = useRef(null);
  const [success, setSuccess] = useState(null);
  const [selectedPigTag, setSelectedPigTag] = useState(null);
  const [selectedGoatTag, setSelectedGoatTag] = useState(null);
  const [broilerBatches, setBroilerBatches] = useState([]);
  const [layerBatches, setLayerBatches] = useState([]);
  const [allBatches, setAllBatches] = useState([]);
  const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
  const apiUrl = config[environment].apiUrl;
  const [receiptData, setReceiptData] = useState(null);
  const [tagNumber, setTagNumber] = useState('');
  const [animalHistory, setAnimalHistory] = useState([]);

  const handleSearch = () => {
    setError(null);
    axios.get(`${apiUrl}/animal-history/${tagNumber}`)
      .then((response) => {
        setAnimalHistory(response.data);
      })
      .catch((error) => {
        console.error('Error fetching animal history: ', error);
        setError('Error fetching animal history. Please try again.');
      });
  };

  const handlePigTagChange = (selectedOption) => {
    setSelectedPigTag(selectedOption);
  };

  const handleGoatTagChange = (selectedOption) => {
    setSelectedGoatTag(selectedOption);
  };

  const tagOptions = [
    { value: 'tag1', label: 'Tag 1' },
    { value: 'tag2', label: 'Tag 2' },
    // Add more tag options...
  ];

  useEffect(() => {
    fetch(`${apiUrl}/poultry`)
      .then(response => response.json())
      .then(data => {
        setAllBatches(data);

        const filteredBroilerBatches = data.filter(batch => batch.type === 'Broilers');
        setBroilerBatches(filteredBroilerBatches);

        const filteredLayerBatches = data.filter(batch => batch.type=== 'Layers');
        setLayerBatches(filteredLayerBatches);
      })
      .catch(error => console.error('Error fetching batches:', error));
  }, [apiUrl, allBatches]);

  useEffect(() => {
    fetch(`${apiUrl}/pigs`)
      .then(response => response.json())
      .then(data => {
        setAllBatches(data);
      })
      .catch(error => console.error('Error fetching batches:', error));
  }, [apiUrl, allBatches]);

  const handleBroilerBatchChange = (selectedOption) => {
  };

  const handleLayerBatchChange = (selectedOption) => {
  };

  return (
    <React.Fragment>
      <Row>
          <Col sm={12}>
          {success && <Alert variant="success" onClose={() => setSuccess(null)} dismissible >{success}</Alert>}
          {error && <Alert variant="danger" onClose={() => setError(null)} dismissible >{error}</Alert>}
          <ToastContainer />
          </Col>
      </Row>

      <Row>
        <Col>
            <Card title="Animal History">
              <Row>
              <Col md={3} sm={12} lg={3}>
                <Form.Group>
                  <Form.Label>Broilers</Form.Label>
                  <Select
                    onChange={handleBroilerBatchChange}
                    options={broilerBatches.map(batch => ({ value: batch.batch, label: batch.batch}))}
                  />
                </Form.Group>
              </Col>
              <Col md={3} sm={12} lg={3}>
                <Form.Group>
                  <Form.Label>Layers</Form.Label>
                  <Select
                    onChange={handleLayerBatchChange}
                    options={layerBatches.map(batch => ({ value: batch.batch, label: batch.batch }))}
                  />
                </Form.Group>
              </Col>
                <Col md={3} sm={12} lg={3}>
                  <Form.Group>
                    <Form.Label>Pigs</Form.Label>
                    <Select
                      value={selectedPigTag}
                      onChange={handlePigTagChange}
                      options={tagOptions}
                    />
                  </Form.Group>
                </Col>
                <Col md={3} sm={12} lg={3}>
                  <Form.Group>
                    <Form.Label>Goats</Form.Label>
                    <Select
                      value={selectedGoatTag}
                      onChange={handleGoatTagChange}
                      options={tagOptions}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Button onClick={handleSearch}>Submit</Button>
            </Card>
          </Col>
      </Row>

      <Row>
        <Col>
          <Card title="Animal Activity" isOption>
            <Row>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Activity</th>
                  <th>Quantity</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {animalHistory.map((activity, index) => (
                  <tr key={index}>
                    <td>{activity.activity}</td>
                    <td>{activity.quantity}</td>
                    <td>{new Date(activity.date).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            </Row>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default TransactionHistory;
