// import React from 'react';
// import { Redirect, useLocation } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import { BASE_URL } from '../../config/constant';

// const AuthGuard = ({ children }) => {
//     const account = useSelector((state) => state.account);
//     const { isLoggedIn } = account;
//     const location = useLocation();

//     React.useEffect(() => {
//         if (isLoggedIn) {
//             localStorage?.setItem('lastLocation', location?.pathname);
//         }
//     }, [isLoggedIn, location]);

//     if (!isLoggedIn) {
//         return (
//             <Redirect
//                 to={{
//                     pathname: '/auth/signin',
//                     state: { from: location }
//                 }}
//             />
//         );
//     }

//     return children;
// };

// export default AuthGuard;
// import React, { useEffect } from 'react';
// import { Redirect, useLocation } from 'react-router-dom';
// import { useSelector } from 'react-redux';

// const AuthGuard = ({ children }) => {
//     const account = useSelector((state) => state.account);
//     const { isLoggedIn } = account;
//     const location = useLocation();

//     useEffect(() => {
//         if (isLoggedIn) {
//             const lastLocation = localStorage.getItem('lastLocation');
//             if (lastLocation !== location.pathname) {
//                 localStorage.setItem('lastLocation', location.pathname);
//             }
//         }
//     }, [isLoggedIn, location]);

//     if (!isLoggedIn) {
//         return (
//             <Redirect
//                 to={{
//                     pathname: '/auth/signin',
//                     state: { from: location }
//                 }}
//             />
//         );
//     }

//     return children;
// };

// export default AuthGuard;

import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BASE_URL } from '../../config/constant';

const AuthGuard = ({ children }) => {
    const account = useSelector((state) => state.account);
    const { isLoggedIn } = account;
    const location = useLocation();

    useEffect(() => {
        if (isLoggedIn) {
            const lastLocation = localStorage.getItem('lastLocation');
            if (lastLocation !== location.pathname) {
                localStorage.setItem('lastLocation', location.pathname);
            }
        }
    }, [isLoggedIn, location]);

    if (!isLoggedIn) {
        return (
            <Redirect
                to={{
                    pathname: '/auth/signin',
                    state: { from: location }
                }}
            />
        );
    }

    return children;
};

export default AuthGuard;


