import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { useParams, useHistory } from 'react-router-dom';
import { Row, Col, Form, Button, Alert, Modal } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Font, PDFViewer } from '@react-pdf/renderer';
import config from '../../config';
import { usePermissions } from '../../permissions';

const TransactionHistory = () => {
    const [error, setError] = useState(null);
    const formRef = useRef(null);
    const [success, setSuccess] = useState(null);
    const [transactionData, setTransactionData] = useState([]);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const history = useHistory();
    const [selectedSuppliers, setSelectedSuppliers] = useState([]);
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [users, setUsers] = useState([]);
    const [vetenary, setVetenary] = useState([]);
    // const [farm, setFarms] = useState([]);
    const [selectedType, setSelectedType] = useState(null);
    const [showModal, setShowModal] = useState(true);
    const [interestRate, setInterestRate] = useState('');
    const [totalAmountToRepay, setTotalAmountToRepay] = useState(0);
    const [dueDate, setDueDate] = useState('');
    const [interestAmount, setInterestAmount] = useState(0);
    const [loanPeriod, setLoanPeriod] = useState([]);
    const [farmOptions, setFarmOptions] = useState([]);
    const [farm, setSelectedFarm] = useState(null);
    const { createPermission, deletePermission, editPermission } = usePermissions();

    useEffect(() => {
        const fetchFarms = async () => {
            try {
                const response = await axios.get(`${apiUrl}/farm`);
                const farmData = response.data;
                const options = farmData.map((farm) => ({
                    value: farm.name,
                    label: farm.name
                }));

                setFarmOptions(options);
            } catch (error) {
                console.error('Error fetching Farms:', error);
            }
        };

        fetchFarms();
    }, [farm]);

    const handleInpute = (fieldName, value) => {
        if (fieldName === 'farm') {
            setSelectedFarm(value);
            setTransactionDetails((prevData) => ({ ...prevData, [fieldName]: value }));
        } else {
            setTransactionDetails((prevData) => ({ ...prevData, [fieldName]: value }));
        }
        console.log(transactionDetails);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const generateTransactionId = () => {
        const timestamp = new Date().getTime();
        const random = Math.floor(Math.random() * 1000);
        return `SF-${random}`;
    };

    const [transactionDetails, setTransactionDetails] = useState({
        coInput: '',
        category: '',
        bankAccount: '',
        amount: '',
        type: 'Loan Issuance',
        date: new Date().toISOString().split('T')[0],
        method: '',
        loanDuration: '',
        dueDate: '',
        interestRate: '',
        amountToRepay: '',
        loanPurpose: '',
        notes: '',
        status: 'Ongoing',
        transactionId: generateTransactionId(),
        balance: '',
        farm: ''
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!createPermission) {
            return;
        }
        try {
            const response = await axios.post(`${apiUrl}/api/loan-transaction`, transactionDetails);

            if (response.data.success) {
                setShowModal(false);
                toast.success('Transaction submitted successfully.');
                setSuccess('Transaction submitted successfully.');
                setTransactionDetails({
                    coInput: '',
                    bankAccount: '',
                    amount: '',
                    type: '',
                    date: new Date().toISOString().split('T')[0],
                    method: '',
                    notes: '',
                    farm: '',
                    balance: '',
                    transactionId: generateTransactionId()
                });
            } else {
                toast.error('Transaction submission failed.');
                setError('Transaction submission failed.');
            }
        } catch (error) {
            toast.error('Error submitting transaction.');
            setError('Error submitting transaction.');
            console.error('Error submitting transaction:', error);
        }
    };

    useEffect(() => {
        fetchSuppliers();
        fetchCustomers();
        fetchAccounts();
        fetchUsers();
        // fetchFarms();
    }, [apiUrl,selectedType]);

    const fetchSuppliers = async () => {
        try {
            const response = await axios.get(`${apiUrl}/suppliers`);
            setSuppliers(response.data);
        } catch (error) {
            console.error('Error fetching suppliers:', error);
        }
    };

    const fetchCustomers = async () => {
        try {
            const response = await axios.get(`${apiUrl}/customer`);
            setCustomers(response.data);
        } catch (error) {
            console.error('Error fetching customers:', error);
        }
    };

    const fetchUsers = async () => {
        try {
            const response = await axios.get(`${apiUrl}/users`);
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const fetchAccounts = async () => {
        try {
            const response = await axios.get(`${apiUrl}/accounts`);
            const accounts = response.data;

            // const filteredData = accounts.filter((tag) => tag.farm === farm);

            // const filteredAccounts = accounts.filter(account => account.accountName !== 'Income');

            setAccounts(accounts);
        } catch (error) {
            console.error('Error fetching accounts:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/vetenary`);
                setVetenary(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [apiUrl,selectedType]);

    // const fetchVets = async () => {
    //   try {
    //     const response = await axios.get(`${apiUrl}/vetenary`);
    //     const ve = response.data;
    //     setVetenary(ve);
    //   } catch (error) {
    //     console.error('Error fetching customers:', error);
    //   }
    // };

    useEffect(() => {
        fetchTrialBalance();
    }, []);

    const fetchTrialBalance = async () => {
        try {
            const response = await axios.get(`${apiUrl}/transactions`);
            setTransactionData(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleTypeChange = (fieldName, value) => {
        if (fieldName === 'category') {
            setSelectedType(value);
        // setSelectedSuppliers([]);
        // setSelectedCustomers([]);
        // setSelectedUsers([]);
        // setVetenary([]);
        // setFarms([]);
        // setTransactionDetails({ ...transactionDetails, category: selectedType, coInput: '' });
        setTransactionDetails((prevData) => ({ ...prevData, [fieldName]: value, coInput: '' }));
        }
    };

    const availableOptions =
        selectedType === 'supplier'
            ? suppliers
            : selectedType === 'customer'
            ? customers
            : selectedType === 'employee'
            ? users
            : selectedType === 'vetenary'
            ? vetenary
            : [];

    // const availableOptions =
    // selectedType === 'supplier'
    //   ? suppliers.filter((supplier) => supplier.farm === farm)
    //   : selectedType === 'customer'
    //   ? customers.filter((customer) => customer.farm === farm)
    //   : selectedType === 'employee'
    //   ? users.filter((user) => user.farm === farm)
    //   : selectedType === 'vetenary'
    //   ? vet.filter((veterinary) => veterinary.farm === farm)
    //   : [];

    // const type = [
    //   { value: 'Payments', name: 'Payments' },
    //   { value: 'Loans', name: 'Loans' },
    //   { value: 'Purchases', name: 'Purchases' },
    //   { value: 'Accounts Transfer', name: 'Accounts Transfer' },
    // ];

    const purpose = [
        { value: 'Emergencies', name: 'Emergencies' },
        { value: 'Debt consolidation', name: 'Debt consolidation' },
        { value: 'Purchases', name: 'Purchases' },
        { value: 'Studies', name: 'Studies' },
        { value: 'Repairs', name: 'Repairs' },
        { value: 'Vacation', name: 'Vacation' },
        { value: 'Funeral', name: 'Funeral' }
    ];

    const method = [
        { value: 'Cash', name: 'Cash' },
        { value: 'MPesa', name: 'MPesa' },
        { value: 'Bank', name: 'Bank' },
        { value: 'Cheque', name: 'Cheque' }
    ];

    const options = [
        { value: 'supplier', label: 'Supplier' },
        { value: 'customer', label: 'Customer' },
        { value: 'employee', label: 'Employee' },
        { value: 'vetenary', label: 'Vetenary' }
    ];

    const rate = [
        { value: 2, label: 2 },
        { value: 5, label: 5 },
        { value: 10, label: 10 },
        { value: 15, label: 15 },
        { value: 20, label: 20 },
        { value: 25, label: 25 }
    ];

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setTransactionDetails({
            ...transactionDetails,
            [name]: value,
            loanDuration: loanPeriod,
            dueDate: dueDate,
            amountToRepay: totalAmountToRepay,
            interestRate: interestRate,
            balance: totalAmountToRepay
        });
    };

    const handleSelectChange = (name, selectedOption) => {
        setTransactionDetails({ ...transactionDetails, [name]: selectedOption.value });
    };

    const handleInterestRateChange = (selectedOption) => {
        setInterestRate(parseInt(selectedOption.value, 10));
        console.log(selectedOption.value);
        setTransactionDetails({
            ...transactionDetails,
            interestRate: interestRate,
            amountToRepay: totalAmountToRepay,
            balance: totalAmountToRepay
        });
    };

    useEffect(() => {
        const loanAmount = parseFloat(transactionDetails.amount) || 0;
        const interestPercentage = interestRate / 100;
        const interest = loanAmount * interestPercentage;
        const totalRepayment = loanAmount + interest + interestAmount;
        setTotalAmountToRepay(totalRepayment.toFixed(2));
    }, [transactionDetails.amount, transactionDetails.interestRate, interestRate, totalAmountToRepay]);

    useEffect(() => {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + loanPeriod);
        const formattedDueDate = currentDate.toISOString().split('T')[0];
        setDueDate(formattedDueDate);
    }, [loanPeriod]);

    console.log(transactionDetails);

    return (
        <Modal show={showModal} onHide={handleCloseModal} centered size="lg">
            <Form onSubmit={handleSubmit} ref={formRef}>
                <Modal.Header closeButton>
                    <Modal.Title>Loan Issuance</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {success && (
                        <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                            {success}
                        </Alert>
                    )}
                    {error && (
                        <Alert variant="danger" onClose={() => setError(null)} dismissible>
                            {error}
                        </Alert>
                    )}
                    <ToastContainer />
                    <Row>
                        <Col md={6} sm={12} lg={6}>
                            <Form.Group>
                                <Form.Label>Farm:</Form.Label>
                                <Select
                                    // value={farmOptions.find(option => option.value === users.farm)}
                                    options={farmOptions}
                                    onChange={(selectedOption) => handleInpute('farm', selectedOption.value)}
                                    isSearchable
                                    name="farm"
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6} sm={2} lg={6}>
                            <Form.Group>
                                <Form.Label>Category:</Form.Label>
                                <Select
                                    options={options}
                                    // value={options.find((option) => option.value === selectedType)}
                                    name="category"
                                    // onChange={handleTypeChange}
                                    onChange={(selectedOption) => handleTypeChange('category', selectedOption.value)}
                                    required
                                    isSearchable
                                />
                            </Form.Group>
                        </Col>

                        <Col md={6} sm={12} lg={6}>
                            <Form.Group>
                                <Form.Label>Beneficiary:</Form.Label>
                                <Select
                                    onChange={(selectedOption) => handleSelectChange('coInput', selectedOption)}
                                    options={availableOptions.map((option) => ({
                                        value: option.name || `${option.firstname} ${option.lastname}`,
                                        label: option.name || `${option.firstname} ${option.lastname}`
                                    }))}
                                    placeholder="Select a person"
                                    required
                                />
                            </Form.Group>
                        </Col>

                        <Col md={6} sm={12} lg={6}>
                            <Form.Group>
                                <Form.Label>Expense Account:</Form.Label>
                                <Select
                                    onChange={(selectedOption) => handleSelectChange('bankAccount', selectedOption)}
                                    options={accounts.map((account) => ({
                                        value: account.description,
                                        label: (account.accountNumber, account.description)
                                    }))}
                                    placeholder="Select account"
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6} sm={12} lg={6}>
                            <Form.Group>
                                <Form.Label>Loan Amount:</Form.Label>
                                <Form.Control type="number" name="amount" onChange={handleInputChange} placeholder="Amount" required />
                            </Form.Group>
                        </Col>

                        <Col md={6} sm={12} lg={6}>
                            <Form.Group>
                                <Form.Label>Payment Method:</Form.Label>
                                <Select
                                    onChange={(selectedOption) => handleSelectChange('method', selectedOption)}
                                    options={method.map((option) => ({
                                        value: option.name,
                                        label: option.name
                                    }))}
                                    placeholder="Method of Payment"
                                    required
                                />
                            </Form.Group>
                        </Col>

                        <Col md={6} sm={12} lg={6}>
                            <Form.Group>
                                <Form.Label>Transaction ID:</Form.Label>
                                <Form.Control type="text" name="transactionId" value={transactionDetails.transactionId} readOnly />
                            </Form.Group>
                        </Col>
                        <Col md={6} sm={12} lg={6}>
                            <Form.Group>
                                <Form.Label>Date:</Form.Label>
                                <Form.Control type="text" name="date" value={transactionDetails.date} readOnly />
                            </Form.Group>
                        </Col>
                        <Col md={6} sm={12} lg={6}>
                            <Form.Group>
                                <Form.Label>Loan Period:</Form.Label>
                                <Select
                                    options={[
                                        { value: 1, label: '1 Day' },
                                        { value: 7, label: '1 Week' },
                                        { value: 14, label: '2 Weeks' },
                                        { value: 21, label: '3 Weeks' },
                                        { value: 30, label: '1 Month' },
                                        { value: 60, label: '2 Months' },
                                        { value: 90, label: '3 Months' },
                                        { value: 120, label: '4 Months' },
                                        { value: 150, label: '5 Months' },
                                        { value: 180, label: '6 Months' },
                                        { value: 210, label: '7 Months' },
                                        { value: 240, label: '8 Months' }
                                    ]}
                                    isSearchable
                                    name="loanDuration"
                                    value={{ value: loanPeriod, label: `${loanPeriod} ${loanPeriod === 1 ? 'Day' : 'Days'}` }}
                                    onChange={(selectedOption) => setLoanPeriod(selectedOption.value)}
                                    required
                                />
                            </Form.Group>
                        </Col>

                        <Col md={6} sm={12} lg={6}>
                            <Form.Group>
                                <Form.Label>Due Date:</Form.Label>
                                <Form.Control type="text" name="dueDate" value={dueDate} readOnly required />
                            </Form.Group>
                        </Col>

                        <Col md={6} sm={12} lg={6}>
                            <Form.Group>
                                <Form.Label>Interest Rate:</Form.Label>
                                <Select
                                    onChange={handleInterestRateChange}
                                    options={rate.map((option) => ({
                                        value: option.value,
                                        label: option.label
                                    }))}
                                    required
                                />
                            </Form.Group>
                        </Col>

                        <Col md={6} sm={12} lg={6}>
                            <Form.Group>
                                <Form.Label>Total Amount to Repay:</Form.Label>
                                <Form.Control type="text" name="amountToRepay" value={totalAmountToRepay} readOnly />
                            </Form.Group>
                        </Col>

                        <Col md={6} sm={12} lg={6}>
                            <Form.Group>
                                <Form.Label>Loan Purpose:</Form.Label>
                                <Select
                                    onChange={(selectedOption) => handleSelectChange('loanPurpose', selectedOption)}
                                    options={purpose.map((option) => ({
                                        value: option.name,
                                        label: option.name
                                    }))}
                                    name="loanPurpose"
                                    required
                                />
                            </Form.Group>
                        </Col>

                        <Col md={12} sm={12} lg={12}>
                            <Form.Group>
                                <Form.Label>Notes:</Form.Label>
                                <Form.Control as="textarea" name="notes" onChange={handleInputChange} placeholder="Notes" required />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <>
                        {createPermission && <Button type="submit">Transact</Button>}
                        <Button variant="secondary" onClick={() => setShowModal(false)}>
                            Close
                        </Button>
                    </>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default TransactionHistory;
