import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Row, Col, Form, Button, Alert, Container, Table } from 'react-bootstrap';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, PDFViewer, Font, Image } from '@react-pdf/renderer';
import config from './../../../config';
import axios from 'axios';
import Card from '../../../components/Card/MainCard';
import MontserratRegular from './../../../fonts/static/Montserrat-Regular.ttf';
import MontserratBold from './../../../fonts/static/Montserrat-Bold.ttf';
import Receipt from './../../extra/GeneralReceipt011';

Font.register({
    family: 'Montserrat',
    fonts: [
        {
            src: MontserratRegular,
            fontWeight: 'normal'
        },
        {
            src: MontserratBold,
            fontWeight: 'bold'
        }
    ]
});

const UpdateUser = () => {
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [user, setUser] = useState({});
    const [invoiceDetails, setUsers] = useState({});
    const { id } = useParams();
    const history = useHistory();
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [pdfBlob, setPdfBlob] = useState(null);
    const [expenses, setPayment] = useState([]);

    useEffect(() => {
        axios
            .get(`${apiUrl}/salary_payment/${id}`)
            .then((response) => {
                const salaryData = response.data[0];
                setUser(salaryData);
                setUsers(salaryData);
            })
            .catch((error) => {
                console.error('Error fetching user data:', error);
            });
    }, [user, apiUrl]);

    const generatePdf = () => {
        const blob = new Blob([<PDFContent id={id} />], { type: 'application/pdf' });
        setPdfBlob(blob);
    };

    return (
        <React.Fragment>
            <Row>
                <Col sm={12} lg={12}>
                    <Link to="/users/payroll">
                        <Button variant="primary" className="mb-3 mt-2">
                            <span className="feather icon-arrow-left"></span> Go Back
                        </Button>
                    </Link>
                    <Card title="View Transaction" isOption>
                        <Row>
                            <Col sm={12} md={12} className="border-bottom">
                                <div className="row d-flex align-items-center m-b-10">
                                    <div className="col-12">
                                        <div className="pdf-button-container">
                                            <Button onClick={generatePdf} className="no-pdf">
                                                <i className="feather icon-printer f-40 text-c-white" />
                                            </Button>
                                            {pdfBlob && (
                                                <PDFDownloadLink
                                                    document={<PDFContent id={id} user={user} />}
                                                    fileName={`invoice_${id}.pdf`}
                                                >
                                                    {({ blob, url, loading, error }) => (loading ? 'Loading...' : 'Print PDF')}
                                                </PDFDownloadLink>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12} md={12} className="border-bottom">
                                <div className="row d-flex align-items-center m-b-10">
                                    <div className="col-4">
                                        <img
                                            src={avatarImage}
                                            alt="Comapny Logo"
                                            style={{ width: '100px', height: 'auto', align: 'center' }}
                                        />
                                    </div>
                                    <div className="col-4"></div>
                                    <div className="col-4">
                                        {/* <div>SFARMS.CO.KE</div> */}
                                        <div>P.O BOX 800-222</div>
                                        <div>Naivasha</div>
                                        <div>Phone : 254-787-689-760</div>
                                        <div>Email : info@sfarms.co.ke</div>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="invoice-top">
                                            <div Name="row">
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="invoice-top-left">
                                                        {/* <h2 className="client-company-name">SFARMS</h2>
                                    <h6 Name="client-address">P.O. BOX 000-00100, 
                                    <h6>Naivasha Village.</h6><h6>Kenya.</h6>
                                    </h6> */}
                                                        <h4>Reference</h4>
                                                        <h6>{invoiceDetails?.paymentMonth} Payslip</h6>
                                                        <h6>{invoiceDetails?.paymentType}</h6>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12 border-bottom">
                                                    <div className="invoice-top-rights">
                                                        <h2 className="our-company-name mb-1">To: {invoiceDetails?.employee}</h2>
                                                        <h5>Date: {new Date(invoiceDetails?.createdAt).toLocaleDateString()}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="invoice-bottom">
                                            <div className="row">
                                                {/* <div className="col-sm-12">
                                                    <h6 className="title">Receipt</h6>
                                                </div> */}
                                                {/* <div className="clearfix"></div> */}

                                                <div className="col-sm-3">
                                                    <div className="invoice-bottom-left">
                                                        <h6>Receipt No.</h6>
                                                        <h5>{invoiceDetails.invoiceNumber}</h5>
                                                    </div>
                                                    <h4 className="terms">Other Info:</h4>
                                                    <ul className="termss">
                                                        <li>{invoiceDetails.paymentMonth}.</li>
                                                        <li>{invoiceDetails.paymentType}.</li>
                                                        <li>{invoiceDetails.status}.</li>
                                                    </ul>
                                                </div>
                                                <div className="col-sm-9">
                                                    <div className="invoice-bottom-right">
                                                        <Table responsive striped hover>
                                                            <thead>
                                                                <tr>
                                                                    <th>Qty</th>
                                                                    <th>Description</th>
                                                                    <th>Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>1</td>
                                                                    <td>Gross Pay</td>
                                                                    <td>
                                                                        KES{' '}
                                                                        {invoiceDetails.salary?.toLocaleString(undefined, {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2
                                                                        })}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>1</td>
                                                                    <td>NSSF Deduction</td>
                                                                    <td>
                                                                         KES{' '}{invoiceDetails.nssfDeduction?.toLocaleString(undefined, {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2
                                                                        })}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>1</td>
                                                                    <td>NHIF Deduction</td>
                                                                    <td>
                                                                        KES{' '} {invoiceDetails.nhifDeduction?.toLocaleString(undefined, {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2
                                                                        })}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>1</td>
                                                                    <td>Other Deduction</td>
                                                                    <td>
                                                                    KES{' '} {invoiceDetails.otherDeduction?.toLocaleString(undefined, {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2
                                                                        })}
                                                                    </td>
                                                                </tr>
                                                                <tr></tr>
                                                            </tbody>
                                                            <thead className="m-t-6">
                                                                <tr className="m-t-6">
                                                                    <th>Net Total</th>
                                                                    <th>{invoiceDetails?.paymentType}</th>
                                                                    <th>
                                                                        kes{' '}
                                                                        {invoiceDetails?.totalPayableSalary?.toLocaleString(undefined, {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2
                                                                        })}
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                        </Table>

                                                        <div className="invoice-bottom-left">
                                                            <h6>Notes:</h6>
                                                            <p>{invoiceDetails.notes}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-12">
                                                    <hr className="divider" />
                                                </div>
                                                <div className="col-sm-4">
                                                    <h6 className="text-left">sfarms.co.ke</h6>
                                                </div>
                                                <div className="col-sm-4">
                                                    <h6 className="text-center">contact@sfarms.co.ke</h6>
                                                </div>
                                                <div className="col-sm-4">
                                                    <h6 className="text-right">+254 700 000 000</h6>
                                                </div>
                                            </div>
                                            <div className="bottom-bar text-center text-white">&#169;SFARMS</div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

const avatarImage = '/assets/farm-logo.jpg';

const PDFContent = ({ user }) => (
    <Document>
        <Page style={styles.page} size="A4">
            <View style={styles.invoiceContainer}>
                <View style={styles.row}>
                    <View style={styles.header} fixed>
                        <View style={styles.rows}>
                            <View style={styles.column}>
                                <Image source={avatarImage} style={styles.letterheadLogo} />
                            </View>

                            <View style={styles.column}></View>
                            <View style={styles.column}>
                                <Text>SFARMS.CO.KE</Text>
                                <Text>P.O BOX 800-222</Text>
                                <Text>Naivasha</Text>
                                <Text>Phone : 254-787-689-760</Text>
                                <Text>Email : info@sfarms.co.ke</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={styles.invoiceHeader}>
                    <Text style={styles.reference}>Reference</Text>
                    <Text>{user.paymentMonth} Payslip</Text>
                </View>
                <View style={styles.invoiceDetails}>
                    <Text style={styles.to}>To: {user.employee}</Text>
                    <Text style={styles.date}>Date: {new Date(user.createdAt).toLocaleDateString()}</Text>
                    <Text style={styles.invoice}>Invoice No: {user.invoiceNumber}</Text>
                    <View style={styles.invoiceTable}>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableCell}>Qty</Text>
                            <Text style={styles.tableCell}>Description</Text>
                            <Text style={styles.tableCell}>Amount</Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableCell}>1</Text>
                            <Text style={styles.tableCell}>Gross Pay</Text>
                            <Text style={styles.tableCell}>Ks {user.salary.toLocaleString()}</Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableCell}>1</Text>
                            <Text style={styles.tableCell}>NSSF Deduction</Text>
                            <Text style={styles.tableCell}>-{user.nssfDeduction.toLocaleString()}</Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableCell}>1</Text>
                            <Text style={styles.tableCell}>NHIF Deduction</Text>
                            <Text style={styles.tableCell}>-{user.nhifDeduction.toLocaleString()}</Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableCell}>1</Text>
                            <Text style={styles.tableCell}>Other Deduction</Text>
                            <Text style={styles.tableCell}>-{user.otherDeduction}</Text>
                        </View>
                    </View>
                    <Text style={styles.netTotal}>
                        {user && user.paymentType === 'Advance Salary'
                            ? `Advance Salary Amount: Ks ${user.totalPayableSalary.toLocaleString()}`
                            : `Net Total: Ks ${user.totalPayableSalary.toLocaleString()}`}
                    </Text>

                    <Text style={styles.otherInfo}>Other Info:</Text>
                    <Text>{user.paymentMonth}.</Text>
                    <Text>{user.paymentType}.</Text>
                    <Text>{user.status}.</Text>
                    <Text style={styles.otherInfo}>Notes:</Text>
                    <Text>{user.notes}.</Text>
                </View>
                <View style={styles.invoiceFooter}>
                    <Text>sfarms.co.ke</Text>
                    <Text>contact@sfarms.co.ke</Text>
                    <Text>+254 700 000 000</Text>
                </View>
            </View>
        </Page>
    </Document>
);

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Montserrat',
        paddingTop: 30,
        paddingBottom: 30,
        paddingHorizontal: 50
    },
    invoiceContainer: {
        marginTop: 10,
        flexDirection: 'column',
        fontSize: 9
    },
    invoiceHeader: {
        marginBottom: 20
    },
    rows: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 10
    },
    row: {
        flexDirection: 'column',
        borderBottom: '0.4px solid grey'
    },
    letterheadLogo: {
        width: 100,
        height: 100
    },
    companyName: {
        fontSize: 12,
        fontWeight: 'bold'
    },
    address: {
        marginTop: 10
    },
    reference: {
        fontSize: 12,
        marginTop: 20
    },
    invoiceDetails: {
        marginTop: 20
    },
    to: {
        fontSize: 12,
        fontWeight: 'bold'
    },
    date: {
        marginTop: 5
    },
    invoiceTable: {
        marginTop: 10,
        flexDirection: 'column'
    },
    tableRow: {
        flexDirection: 'row',
        borderBottom: 0.8,
        alignItems: 'center',
        textAlign: 'left',
        height: 30
    },
    invoice: {
        textAlign: 'right',
        marginTop: 10,
        marginBottom: 10,
        fontWeight: 'bold',
        fontSize: 13
        // borderBottom: '0.5px solid grey',
    },
    tableCell: {
        flex: 1
    },
    netTotal: {
        marginTop: 10,
        fontSize: 13,
        fontWeight: 'bold',
        textAlign: 'right'
    },
    otherInfo: {
        marginTop: 20,
        fontSize: 11,
        fontWeight: 'bold'
    },
    invoiceFooter: {
        flexDirection: 'row',
        marginTop: 20,
        justifyContent: 'space-between'
    }
});

export default UpdateUser;
