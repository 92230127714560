import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Row, Col, Form, Button, Alert } from 'react-bootstrap';
import Select from 'react-select';
import config from './../../../config';
import axios from 'axios';
import Card from '../../../components/Card/MainCard';

const UpdateUser = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [users, setUser] = useState({});
  const { id } = useParams();
  const history = useHistory();
  const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
  const apiUrl = config[environment].apiUrl;
  const [farmOptions, setFarmOptions] = useState([]);
    const [farm, setSelectedFarm] = useState(null);

    useEffect(() => {
        const fetchFarms = async () => {
          try {
            const response = await axios.get(`${apiUrl}/farm`);
            const farmData = response.data;
            const options = farmData.map((farm) => ({
              value: farm.name, 
              label: farm.name, 
            }));
      
            setFarmOptions(options);
          } catch (error) {
            console.error('Error fetching Farms:', error);
          }
        };
      
        fetchFarms();
      }, [farm]);

      const handleInpute = (fieldName, value) => {
        if (fieldName === 'farm') {
          setSelectedFarm(value);
          setUser((prevData) => ({ ...prevData, [fieldName]: value }));
        } else {
          setUser((prevData) => ({ ...prevData, [fieldName]: value }));
         
        } console.log(users)
      };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${apiUrl}/vetenary/${id}`);
        setUser(response.data[0]);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, [id]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${apiUrl}/vetenary/${id}`, users);
      setSuccess("Vetenary updated successfully!");
    //   history.push('/'); // Redirect to the home page after successful update
    } catch (error) {
      setError(error.message);
      console.log(error)
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col sm={12}>
          {success && (
            <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
              {success}
            </Alert>
          )}
          {error && (
            <Alert variant="danger" onClose={() => setError(null)} dismissible>
              {error}
            </Alert>
          )}
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <Link to="/vetenary">
            <Button variant="primary" className="">
              <span className='feather icon-arrow-left'></span>  Go Back
            </Button>
          </Link>
          <Card title="Update Vetenary" isOption>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col sm={12} md={6}>
                  <div className="p-2">
                    <Form.Label>Name:</Form.Label>
                    <Form.Control type="text" name="name" value={users.name || ''} onChange={handleInput} />
                  </div>
                </Col>
                <Col sm={12} md={6}>
                  <div className="p-2">
                    <Form.Label>Email:</Form.Label>
                    <Form.Control type="email" name="email" value={users.email || ''} onChange={handleInput} />
                  </div>
                </Col>
                <Col sm={12} md={6}>
                  <div className="p-2">
                    <Form.Label>Phone Number:</Form.Label>
                    <Form.Control type="text" name="phone" value={users.phone || ''} onChange={handleInput} />
                  </div>
                </Col>
                <Col sm={12} md={6}>
                  <div className="p-2">
                    <Form.Label>Speciality:</Form.Label>
                    <Form.Control type="text" name="speciality" value={users.speciality || ''} onChange={handleInput} />
                  </div>
                </Col>
                <Col sm={12} md={6}>
                  <div className="p-2">
                    <Form.Label>Location:</Form.Label>
                    <Form.Control type="text" name="location" value={users.location || ''} onChange={handleInput} />
                  </div>
                </Col>
                {/* <Col md={6} sm={12} lg={6} >
                  <div className="p-2">
                      <Form.Label>Farm</Form.Label>
                      <Select
                      value={farmOptions.find(option => option.value === users.farm)}
                      options={farmOptions}
                      onChange={(selectedOption) => handleInpute('farm', selectedOption.value)}
                      isSearchable
                      name="farm"
                      />
                    </div>
                </Col> */}
                
                <Col sm={12}>
                  <div className="p-2">
                    <Button type="submit">Update</Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UpdateUser;
