import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { CSVLink } from 'react-csv';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { Row, Col, Form, Button, Table, Alert, Tabs, Tab, Modal, Pagination } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Font, PDFViewer } from '@react-pdf/renderer';
// import Card from '../../components/Card/MainCard.js';
// import Receipt from './GeneralReceipt01.js';
import { Link, useHistory } from 'react-router-dom';
import config from '../../../config';
import SamplePage2 from './BasicCollapse03.js';
import SamplePage6 from './BasicCollapse04.js';
import SamplePage4 from './BasicCollapse05.js';
import SamplePage8 from './BasicCollapse06.js';
import SamplePage7 from './BasicCollapse08.js';
import SamplePage10 from './BasicCollapse11.js';
import SamplePage11 from './BasicCollapse13.js';
import SamplePage5 from './BasicCollapse14.js';
import { usePermissions } from '../../../permissions/index.js';

const TransactionHistory = () => {
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const { createPermission, deletePermission, editPermission } = usePermissions();

    return (
        <React.Fragment>
            <Row>
                <Col sm={12}>
                    {success && (
                        <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                            {success}
                        </Alert>
                    )}
                    {error && (
                        <Alert variant="danger" onClose={() => setError(null)} dismissible>
                            {error}
                        </Alert>
                    )}
                    <ToastContainer />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="pb-3">
                            {/* <Link to="/reports/stock">
                                <Button variant="primary" className="mt-2">
                                    <span className="feather icon-arrow-left"></span> Go Back
                                </Button>
                            </Link> */}
                        </div>
                    </div>
                    <Tabs defaultActiveKey="houses">
                        <Tab eventKey="houses" title="Groups & Houses" className="mt-4">
                            <SamplePage7 />
                        </Tab>
                        <Tab eventKey="feeds" title="Move Piglets" className="mt-4">
                            <SamplePage2 />
                        </Tab>
                        <Tab eventKey="food" title="Move Weaners" className="mt-4">
                            <SamplePage5 />
                        </Tab>
                        <Tab eventKey="water" title="Feed Weaners" className="mt-4">
                            <SamplePage4 />
                        </Tab>
                        <Tab eventKey="vaccine" title="Vaccinate Weaners" className="mt-4">
                            <SamplePage6 />
                        </Tab>
                        <Tab eventKey="history" title="Weaners Update" className="mt-4">
                            <SamplePage10 />
                        </Tab>
                        <Tab eventKey="growing" title="Grow-Finish" className="mt-4">
                            <SamplePage11 />
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default TransactionHistory;
