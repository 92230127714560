import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Row, Col, Form, Button, Alert, Table } from 'react-bootstrap';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, PDFViewer, Font, Image} from '@react-pdf/renderer';
import config from './../../../config';
import axios from 'axios';
import Card from '../../../components/Card/MainCard';
import MontserratRegular from './../../../fonts/static/Montserrat-Regular.ttf';
import MontserratBold from './../../../fonts/static/Montserrat-Bold.ttf';

Font.register({
  family: 'Montserrat',
  fonts: [
    {
      src: MontserratRegular,
      fontWeight: 'normal',
    },
    {
      src: MontserratBold,
      fontWeight: 'bold',
    },
    // Register other font weights if needed
  ],
});


const UpdateUser = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [user, setUser] = useState({});
  const [loan, setLoan] = useState({});
  const [loanRepayments, setLoanRepayments] = useState([]);
  const { id, loanId } = useParams();
  const history = useHistory();
  const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
  const apiUrl = config[environment].apiUrl;
  const [pdfBlob, setPdfBlob] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${apiUrl}/eggs/update/${id}`);
        setUser(response.data[0]);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, [id]);


  const generatePdf = () => {
    const blob = new Blob([<PDFContent id={id} loanRepayments={loanRepayments}/>], { type: 'application/pdf' });
    setPdfBlob(blob);
  };

  const getLabelColorClass = (status) => {
    switch (status) {
      case 'Fully Paid':
        return 'label-orange';
      case 'Partially Paid':
        return 'label-blue';
      case 'Ongoing':
        return 'label-till';
      case 'Expense':
        return 'label-brown';
      default:
        return '';
    }
  };

  

  return (
    <React.Fragment>

      <Row>
        <Col sm={12}>
        <Link to="/poultry/eggs-updates">
            <Button variant="primary" className="mb-3 mt-2">
              <span className='feather icon-arrow-left'></span>  Go Back
            </Button>
          </Link>
          <Card title="View Eggs Update" isOption>
              <Row>
                <Col sm={12} md={12} className='border-bottom'>
                    <div className="row d-flex align-items-center m-b-10">
                        <div className="col-12">
                            <div className="pdf-button-container">
                                <Button onClick={generatePdf} className="no-pdf">
                                  <i className="feather icon-printer f-40 text-c-white" />
                                </Button>
                                {pdfBlob && (
                                    <PDFDownloadLink
                                    document={<PDFContent id={id} user={user} loanRepayments={loanRepayments}/>}
                                    fileName={`eggs_update_${id}.pdf`}
                                    >
                                    {({ blob, url, loading, error }) =>
                                        loading ? 'Loading...' : 'Print PDF'
                                    }
                                    </PDFDownloadLink>
                                )}
                                
                            </div>
                        </div>
                    </div>
                </Col>

                <Col sm={12} md={12} className='border-bottom'>
                  <div className="row d-flex align-items-center m-b-10">
                    <div className="col-4">
                      <img src={avatarImage} alt='Comapny Logo' style={{ width: '100px', height: 'auto', align: 'center' }} />
                    </div>
                    <div className="col-4">
                    </div>
                    <div className="col-4">
                      {/* <div>SFARMS.CO.KE</div> */}
                      <div>P.O BOX 800-222</div>
                      <div>Naivasha</div>
                      <div>Phone : 254-787-689-760</div>
                      <div>Email : info@sfarms.co.ke</div>
                    </div>
                  </div>
                </Col>

                <Col sm={12} md={12} className='border-bottom'>
                    <div className="row d-flex align-items-center m-t-20 m-b-20">
                        <div className="col-4">
                            <p className='m-t-10 m-b-10'>Batch No.: {user.batch}</p>
                            <p className='m-t-10 m-b-10'>Collected Eggs: {user.collected?.toLocaleString()}</p>
                            <p className='m-t-10 m-b-10'>Broken Eggs: {user.spoilt?.toLocaleString()}</p>
                            <p className='m-t-10 m-b-10'>No. Of Trays: {user.numOfTrays?.toLocaleString()}</p>
                            <p className='m-t-10 m-b-10'>ID: {user.id}</p>
                        </div>

                        <div className="col-4">
                    </div>

                        <div className="col-4">
                          <p className='m-t-10 m-b-10'>Rem Eggs: {user.remainingEggs?.toLocaleString()}</p>
                          <p className='m-t-10 m-b-10'>Occurance Date: {new Date(user.recorded).toLocaleDateString()}</p> 
                          <p className='m-t-10 m-b-10'>Updated Date : {new Date(user.createdAt).toLocaleString()}</p> 
                          <p className='m-t-10 m-b-10'>Farm: {user.farm}</p>
                        </div>
                    </div>
                </Col>

                <Col sm={12} md={12} className="border-bottom">
                  <p className="text-uppercase m-1 font-weight-bold">Remarks/Notes: {user.remarks}</p>                       
                </Col>
              </Row>
          </Card>
        </Col>
      </Row>      
    </React.Fragment>
  );
};

const avatarImage = '/assets/farm-logo.jpg';

const PDFContent = ({ id, user}) => (
    <Document>
      <Page style={styles.page} size="A4" orientation='portrait'>
        <View style={styles.section}>
          <View style={styles.row}> 
            <View style={styles.header} fixed>
              <View style={styles.rows}>
                <View style={styles.column}>
                  <Image source={avatarImage} style={styles.letterheadLogo} />
                </View>
              
                <View style={styles.column}>

                </View>
                <View style={styles.column}>
                  <Text>Address :</Text>
                  <Text>P.O BOX 800-222</Text>
                  <Text>Naivasha</Text>
                  <Text>Phone : 254-787-689-760</Text>
                  <Text>Email : info@sfarms.co.ke</Text>
                </View>
              </View>
            </View>
          </View>
          
          <Text style={styles.transactionId}>Eggs Update Details ID : #{id}</Text>
          <View style={styles.row}>
            <View style={styles.column}>
              <Text style={styles.coh}>Updated : {new Date(user.createdAt).toLocaleString()}</Text>
            </View>
            <View style={styles.column}>
              <Text style={styles.coh}> </Text>
            </View>
            <View style={styles.column}>
              <Text style={styles.coh}> </Text>
            </View>
          </View>
          
          <View style={styles.row}>
            <View style={styles.column}>
              <Text style={styles.co}>Batch No.: {user.batch}</Text>
              <Text style={styles.co}>Collected Eggs: {user.collected.toLocaleString()}</Text>
              <Text style={styles.co}>Broken Eggs: {user.spoilt.toLocaleString()}</Text>
              <Text style={styles.co}>No. Of Trays: {user.numOfTrays.toLocaleString()}</Text>
              <Text style={styles.co}>ID: {user.id}</Text>
            </View>

            <View style={styles.column}>
              
            </View>

            <View style={styles.column}> 
            <Text style={styles.co}>Rem Eggs: {user.remainingEggs.toLocaleString()}</Text>
              <Text style={styles.co}>Occurance Date: {new Date(user.recorded).toLocaleDateString()}</Text>
              <Text style={styles.co}>Updated Date : {new Date(user.createdAt).toLocaleDateString()}</Text>
              <Text style={styles.co}>Farm: {user.farm}</Text>
            </View>
          </View>
          
          <Text style={styles.notes}>Notes: {user.remarks}</Text>
        </View>
      </Page>
    </Document>
  );
  
  const styles = StyleSheet.create({
    page: {
      fontFamily: 'Montserrat',
      fontSize: 8.5,
      flexDirection: 'row',
      backgroundColor: '#ffffff',
      width: '100%',
      // marginTop: 50,
      height: '20px',
    },
    section: {
      marginTop: 5,
      marginLeft: 50,
      marginRight: 50,
      padding: 10,
      flexGrow: 1,
    },
    header: {
      width: '100%',
      alignContent: 'center',
      // alignItems: 'center'
    },
    letterheadLogo: {
      width: 100,
      height: 100,
    },
    transactionId: {
      fontSize: 12,
      fontWeight: 'bold',
      marginBottom: 10,
      // borderBottom: '0.4px solid grey',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 10,
    },
    rows: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems:'center',
      marginBottom: 10,
    },
    column: {                      
      width: '50%',
    },
    notes: {
      marginTop: 20,
      fontSize: 10,
    },
    balance: {
      marginTop: 20,
      fontSize: 10,
      fontWeight: 'bold',
      borderBottom: '0.4px solid grey',
      borderTop: '0.4px solid grey',
      textTransform: 'uppercase',
      paddingTop: 3,
      paddingBottom: 3,
    },
    invoiceTable: {
      marginTop: 10,
      flexDirection: 'column',
      // color: 'grey',                                                                       
    },
    tableRow: {
      flexDirection: 'row',
      borderBottom: 0.2,
      alignItems: 'center',
      textAlign: 'left',
      height: 30,
    },
    tableRowh: {
      flexDirection: 'row',
      borderBottom: 0.1,
      alignItems: 'center',
      textAlign: 'left',
      height: 30,
      backgroundColor: '#279e6c',
      fontWeight: 'bold',
      color: '#fff',
    },
    tableCell: {
      flex: 1,
      padding: 5,
    },
    tableCellh: {
      flex: 1,
      fontWeight: 'bold',
      padding: 5,
      textTransform: 'uppercase',
    },
    co: {
      paddingBottom: 6,
      paddingTop: 6,
    },
    coh: {
      paddingBottom: 6,
      paddingTop: 6,
      fontWeight: 'bold',
      borderBottom: '0.4px solid grey',
      borderTop: '0.4px solid grey',
      textTransform: 'uppercase',
    }
  });
  
export default UpdateUser;
