import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ConfigContext } from '../../../contexts/ConfigContext';
import useWindowSize from '../../../hooks/useWindowSize';

import NavLogo from './NavLogo';
import NavContent from './NavContent';
import navigation from '../../../menu-items';
import axios from 'axios';
import config from '../../../config';
import { usePermissions } from '../../../permissions';

const Navigation = () => {
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const configContext = useContext(ConfigContext);
    const { collapseMenu } = configContext.state;
    const windowSize = useWindowSize();
    const account = useSelector((state) => state.account.user.is_admin);
    const space = account;
    const [permissionMap, setPermissionMap] = useState([]);
    const { filteredNavigation } = usePermissions();

    let navClass = [
        'pcoded-navbar',
        'menupos-static',
        'menu-dark',
        'navbar-default',
        'brand-default',
        'drp-icon-style1',
        'menu-item-icon-style1',
        'active-default',
        'title-default'
    ];

    if (windowSize.width < 992 && collapseMenu) {
        navClass = [...navClass, 'mob-open'];
    } else if (collapseMenu) {
        navClass = [...navClass, 'navbar-collapsed'];
    }

    let navStyle;

    let navBarClass = ['navbar-wrapper'];

    let navContent = (
        <div className={navBarClass.join(' ')}>
            <NavLogo />
            <NavContent navigation={filteredNavigation} />
        </div>
    );
    if (windowSize.width < 992) {
        navContent = (
            <div className="navbar-wrapper">
                <NavLogo />
                <NavContent navigation={filteredNavigation} />
            </div>
        );
    }
    return (
        <React.Fragment>
            <nav className={navClass.join(' ')} style={navStyle}>
                {navContent}
            </nav>
        </React.Fragment>
    );
};

export default Navigation;
