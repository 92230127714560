import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, PDFViewer, Font, Image } from '@react-pdf/renderer';
import config from './../../../config';
import axios from 'axios';
import Card from '../../../components/Card/MainCard';
import MontserratRegular from './../../../fonts/static/Montserrat-Regular.ttf';
import MontserratBold from './../../../fonts/static/Montserrat-Bold.ttf';

Font.register({
  family: 'Montserrat',
  fonts: [
    {
      src: MontserratRegular,
      fontWeight: 'normal',
    },
    {
      src: MontserratBold,
      fontWeight: 'bold',
    },
  ],
});

const avatarImage = '/assets/farm-logo.jpg';

const UpdateUser = () => {
  const [user, setUser] = useState({});
  const [users, setUsers] = useState({});
  const [mot, setMot] = useState({});
  const [vac, setVac] = useState({});
  const [sel, setSel] = useState({});
  const [fee, setFee] = useState({});
  const [egg, setEgg] = useState({});
  const { batch, batchVaccinated } = useParams();
  const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
  const apiUrl = config[environment].apiUrl;
  const [pdfBlob, setPdfBlob] = useState(null);
  const [pdfData, setPdfData] = useState([]);
  const [totalConsumedQuantity, setTotalConsumedQuantity] = useState(0);
  const [totalSelConsumed, setTotalSelConsumed] = useState(0);
  const [totalVacConsumed, setTotalVacConsumed] = useState(0);
  const [totalEggConsumed, setTotalEggConsumed] = useState(0);
  const [totalMotConsumed, setTotalMotConsumed] = useState(0);
  const [totalUserConsumed, setTotalUserConsumed] = useState(0);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${apiUrl}/eggs_batch/${batch}`);
        setEgg(response.data);
        console.log(response.data)
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, [apiUrl]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${apiUrl}/poultry/batch/${batch}`);
        setUser(response.data);
        setUsers(response.data[0]);
        console.log(response.data)
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, [apiUrl]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${apiUrl}/vaccinated_batch/${batch}`);
        setVac(response.data);
        console.log(response.data)
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, [apiUrl]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${apiUrl}/mortality/batch/${batch}`);
        setMot(response.data);
        console.log(response.data)
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, [apiUrl]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${apiUrl}/sold_batch/${batch}`);
        setSel(response.data[0]);
        console.log(response.data[0])
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, [batch]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${apiUrl}/feed_batch/${batch}`);
        setFee(response.data);
        console.log(response.data[0])
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, [batch]);

  const generatePdf = () => {
    const combinedData = {
      user: Array.isArray(user) ? user : [user],
      fee: Array.isArray(fee) ? fee : [fee],
      vac: Array.isArray(vac) ? vac : [vac],
      mot: Array.isArray(mot) ? mot : [mot],
      sel: Array.isArray(sel) ? sel : [sel],
      egg: Array.isArray(egg) ? egg : [egg],
      pdfData: pdfData
    };
  
    setPdfData(combinedData);
  
    const blob = new Blob([<PDFContent {...combinedData} batch={batch} />], {
      type: 'application/pdf'
    });
    setPdfBlob(blob);
  };

let selTotal = 0;
let vacTotal = 0;
let eggTotal = 0;
let motTotal = 0;
let userTotal = 0;
let feeTotal = 0;

if (Array.isArray(sel)) {
  selTotal = sel.reduce((acc, item) => acc + item.totalCost, 0);
} else if (sel) {
  selTotal = sel.totalCost;
}

if (Array.isArray(vac)) {
  vacTotal = vac.reduce((acc, item) => acc + item.vaccineQuantity, 0);
} else if (vac) {
  vacTotal = vac.vaccineQuantity;
}

if (Array.isArray(egg)) {
  eggTotal = egg.reduce((acc, item) => acc + item.collected, 0);
} else if (egg) {
  eggTotal = egg.collected;
}

if (Array.isArray(mot)) {
  motTotal = mot.reduce((acc, item) => acc + item.mortality, 0);
} else if (mot) {
  motTotal = mot.mortality;
}

if (Array.isArray(user)) {
  userTotal = user.reduce((acc, item) => acc + item.totalCost, 0);
} else if (user) {
  userTotal = user.totalCost;
}

if (Array.isArray(fee)) {
  feeTotal = fee.reduce((acc, item) => acc + item.consumed, 0);
} else if (fee) {
  feeTotal = fee.consumed;
}

const data = [sel, vac, egg, mot, user];

// Initialize an object to store the counts
const counts = {
  sel: 0,
  vac: 0,
  egg: 0,
  mot: 0,
  user: 0,
};

// Loop through the data and count the occurrences
data.forEach(item => {
  if (Array.isArray(item) && item.length > 0) {
    // If it's an array and not empty, increment the corresponding counter
    const type = item[0].type;
    if (counts[type] !== undefined) {
      counts[type]++;
    }
  } else if (item && item.type) {
    // If it's not an array (a single value) and has a 'type' property, consider it as one occurrence
    const type = item.type;
    if (counts[type] !== undefined) {
      counts[type]++;
    }
  }
});

console.log('Counts:', counts);



  return (
    <React.Fragment>

      <Row>
        <Col sm={12}>
        <Link to="/poultry/poultry">
            <Button variant="primary" className="mb-3 mt-2">
              <span className='feather icon-arrow-left'></span>  Go Back
            </Button>
          </Link>
          <Card title="Batch history" isOption>
              <Row>
                <Col sm={12} md={12} className='border-bottom'>
                    <div className="row d-flex align-items-center m-b-10">
                        <div className="col-12">
                            <div className="pdf-button-container">
                                <Button onClick={generatePdf} className="no-pdf">
                                  <i className="feather icon-printer f-40 text-c-white" />
                                </Button>
                                {pdfBlob && (
                                  <PDFDownloadLink
                                    document={<PDFContent batch={batch} user={user} egg={egg} fee={fee} vac={vac} mot={mot} sel={sel} pdfData={pdfData} motTotal={motTotal} eggTotal={eggTotal} vacTotal={vacTotal} feeTotal={feeTotal} userTotal={userTotal} selTotal={selTotal} />}
                                    fileName={`poultry_batch_${batch}.pdf`}
                                  >
                                    {({ blob, url, loading, error }) =>
                                      loading ? 'Loading...' : 'Print PDF'
                                    }
                                  </PDFDownloadLink>
                                )}
                            </div>
                        </div>
                    </div>
                </Col>

                <Col sm={12} md={12} className='border-bottom'>
                  <div className="row d-flex align-items-center m-b-10">
                    <div className="col-4">
                      <img src={avatarImage} alt='Comapny Logo' style={{ width: '100px', height: 'auto', align: 'center' }} />
                    </div>
                    <div className="col-4">
                    </div>
                    <div className="col-4">
                      {/* <div>SFARMS.CO.KE</div> */}
                      <div>P.O BOX 800-222</div>
                      <div>Naivasha</div>
                      <div>Phone : 254-787-689-760</div>
                      <div>Email : info@sfarms.co.ke</div>
                    </div>
                  </div>
                </Col>

                <Col sm={12} md={12} className='border-bottom color'>
                  <div className="row d-flex align-items-center m-t-20 m-b-20">
                    <div className="col-4">
                      <h6 className="d-block text-uppercase m-1 color">Summary</h6>
                      <p className='m-1'><strong className='font-weight-bold'>Total Income:</strong> KS {(selTotal !== null && selTotal !== undefined) ? selTotal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''}</p>
                      <p className='m-1'><strong className='font-weight-bold'>Total Expense:</strong>
                      {users.archived === 0 ? (
                        // Display the total when user is not archived
                        <span>
                          KS {((userTotal !== null && userTotal !== undefined) ? userTotal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '')}
                        </span>
                      ) : (
                        // Display "Archived" when user is archived
                        <span>Archived</span>
                      )}
                      </p>
                      
                    </div>
                    <div className="col-4">
                      <p className='m-1'><strong className='font-weight-bold'>Feed Quantity:</strong> {(feeTotal !== null && feeTotal !== undefined) ? feeTotal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''} Kgs/lts</p>
                      <p className='m-1'><strong className='font-weight-bold'>Vaccine Quantity:</strong> {(vacTotal !== null && vacTotal !== undefined) ? vacTotal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''} Kgs/lts</p>
                    </div>
                    <div className="col-4">
                      <p className='m-1'><strong className='font-weight-bold'>Eggs Laid:</strong> {eggTotal}</p>
                      <p className='m-1'><strong className='font-weight-bold'>Total Mortality:</strong> {motTotal}</p>
                    </div>
                  </div>
                </Col>

                {Array.isArray(user) ? (
                  user.map((item, index) => (
                    <Col key={index} sm={12} md={12} className='border-bottom'>
                      <div className="row d-flex align-items-center m-t-20 m-b-20">
                        <div className="col-4">
                          <h6 className="d-block text-uppercase m-1">Bought</h6>
                          <p className='m-1'><strong className='font-weight-bold'>Supplier:</strong> {item.sup_info}</p>
                          <p className='m-1'><strong className='font-weight-bold'>Quantity:</strong> {item.quantity}</p>
                          <p className='m-1'><strong className='font-weight-bold'>Occurence Date:</strong> {new Date(item.recorded).toDateString()}</p>
                        </div>
                        <div className="col-4">
                          <p className='m-1'><strong className='font-weight-bold'>Bird Type:</strong> {item.type}</p>
                          <p className='m-1'><strong className='font-weight-bold'>Price Per Bird:</strong> Ksh {item.price}</p>
                          <p className='m-1'><strong className='font-weight-bold'>Total Cost:</strong> Ksh {item.totalCost}</p>
                        </div>
                        <div className="col-4">
                          <p className='m-1'><strong className='font-weight-bold'>Date:</strong> {new Date(item.createdAt).toLocaleString()}</p>
                          <p className='m-1'><strong className='font-weight-bold'>ID:</strong> {item.id}</p>
                          <p className='m-1'><strong className='font-weight-bold'>Status:</strong> {item.archived === 0 ? ('Not Archived') : ('Archived')}</p>
                        </div>
                      </div>
                    </Col>
                  ))
                ) : (
                  user && user.batch ? (
                    <Col sm={12} md={12} className='border-bottom'>
                      <div className="row d-flex align-items-center m-t-20 m-b-20">
                        <div className="col-4">
                          <h6 className="d-block text-uppercase m-1">Bought</h6>
                          <p className='m-1'><strong className='font-weight-bold'>Supplier:</strong> {user.sup_info}</p>
                          <p className='m-1'><strong className='font-weight-bold'>Quantity:</strong> {user.quantity}</p>
                          <p className='m-1'><strong className='font-weight-bold'>Occurence Date:</strong> {new Date(user.recorded).toDateString()}</p>
                        </div>
                        <div className="col-4">
                          <p className='m-1'><strong className='font-weight-bold'>Bird Type:</strong> {user.type}</p>
                          <p className='m-1'><strong className='font-weight-bold'>Price Per Bird:</strong> Ksh {user.price}</p>
                          <p className='m-1'><strong className='font-weight-bold'>Total Cost:</strong> Ksh {user.totalCost}</p>
                        </div>
                        <div className="col-4">
                          <p className='m-1'><strong className='font-weight-bold'>Date:</strong> {new Date(user.createdAt).toLocaleString()}</p>
                          <p className='m-1'><strong className='font-weight-bold'>ID:</strong> {user.id}</p>
                          <p className='m-1'><strong className='font-weight-bold'>Status:</strong> {user.archived}</p>
                        </div>
                      </div>
                    </Col>
                  ) : null
                )}
                
                {Array.isArray(egg) ? (
                  egg.map((item, index) => (
                    <Col key={index} sm={12} md={12} className='border-bottom'>
                    <div className="row d-flex align-items-center m-t-20 m-b-20">
                      <div className="col-4">
                        <h6 className="d-block text-uppercase m-1">Laid Eggs</h6>
                        <p className='m-1'><strong className='font-weight-bold'>Collected Eggs:</strong> {item.collected}</p>
                        <p className='m-1'><strong className='font-weight-bold'>Spoilt Eggs:</strong> {item.spoilt}</p>
                        <p className='m-1'><strong className='font-weight-bold'>Occurence Date:</strong> {new Date(item.recorded).toDateString()}</p>
                      </div>
                      <div className="col-4">
                        <p className='m-1'><strong className='font-weight-bold'>Batch:</strong> {item.batch}</p>
                        <p className='m-1'><strong className='font-weight-bold'>Remarks:</strong> {item.remarks}</p>
                      </div>
                      <div className="col-4">
                        <p className='m-1'><strong className='font-weight-bold'>Date:</strong> {new Date(item.createdAt).toLocaleString()}</p>
                        <p className='m-1'><strong className='font-weight-bold'>ID:</strong> {item.id}</p>
                      </div>
                    </div>
                  </Col>
                  ))
                ) : (
                  egg && egg.batch && (
                    <Col sm={12} md={12} className='border-bottom'>
                      <div className="row d-flex align-items-center m-t-20 m-b-20">
                        <div className="col-4">
                          <h6 className="d-block text-uppercase m-1">Laid Eggs</h6>
                          <p className='m-1'><strong className='font-weight-bold'>Collected Eggs:</strong> {egg.collected}</p>
                          <p className='m-1'><strong className='font-weight-bold'>Spoilt Eggs:</strong> {egg.spoilt}</p>
                          <p className='m-1'><strong className='font-weight-bold'>Occurence Date:</strong> {new Date(egg.recorded).toDateString()}</p>
                        </div>
                        <div className="col-4">
                          <p className='m-1'><strong className='font-weight-bold'>Batch:</strong> {egg.batch}</p>
                          <p className='m-1'><strong className='font-weight-bold'>Remarks:</strong> {egg.remarks}</p>
                        </div>
                        <div className="col-4">
                          <p className='m-1'><strong className='font-weight-bold'>Date:</strong> {new Date(egg.createdAt).toLocaleString()}</p>
                          <p className='m-1'><strong className='font-weight-bold'>ID:</strong> {egg.id}</p>
                        </div>
                      </div>
                    </Col>
                  )
                )}

                {Array.isArray(fee) ? (
                  fee.map((item, index) => (
                    <Col key={index} sm={12} md={12} className='border-bottom'>
                      <div className="row d-flex align-items-center m-t-20 m-b-20">
                        <div className="col-4">
                          <h6 className="d-block text-uppercase m-1">Fed</h6>
                          <p className='m-1'><strong className='font-weight-bold'>Feed Name:</strong> {item.feed_name}</p>
                          <p className='m-1'><strong className='font-weight-bold'>Quantity:</strong> {item.consumed} Kgs/Litres</p>
                          <p className='m-1'><strong className='font-weight-bold'>Occurence Date:</strong> {new Date(item.recorded).toDateString()}</p>
                        </div>
                        <div className="col-4">
                          <p className='m-1'><strong className='font-weight-bold'>Bird Type:</strong> {item.type}</p>
                          <p className='m-1'><strong className='font-weight-bold'>Batch:</strong> {item.batch}</p>
                          <p className='m-1'><strong className='font-weight-bold'>Remarks:</strong> {item.remarks}</p>
                        </div>
                        <div className="col-4">
                          <p className='m-1'><strong className='font-weight-bold'>Date:</strong> {new Date(item.createdAt).toLocaleString()}</p>
                          <p className='m-1'><strong className='font-weight-bold'>ID:</strong> {item.id}</p>
                        </div>
                      </div>
                    </Col>
                  ))
                ) : (
                  fee && fee.batch && (
                    <Col sm={12} md={12} className='border-bottom'>
                      <div className="row d-flex align-items-center m-t-20 m-b-20">
                        <div className="col-4">
                          <h6 className="d-block text-uppercase m-1">Fed</h6>
                          <p className='m-1'><strong className='font-weight-bold'>Feed Name:</strong> {fee.feed_name}</p>
                          <p className='m-1'><strong className='font-weight-bold'>Quantity:</strong> {fee.consumed} Kgs/Litres</p>
                          <p className='m-1'><strong className='font-weight-bold'>Occurence Date:</strong> {new Date(fee.recorded).toDateString()}</p>
                        </div>
                        <div className="col-4">
                          <p className='m-1'><strong className='font-weight-bold'>Bird Type:</strong> {fee.type}</p>
                          <p className='m-1'><strong className='font-weight-bold'>Batch:</strong> {fee.batch}</p>
                          <p className='m-1'><strong className='font-weight-bold'>Remarks:</strong> {fee.remarks}</p>
                        </div>
                        <div className="col-4">
                          <p className='m-1'><strong className='font-weight-bold'>Date:</strong> {new Date(fee.createdAt).toLocaleString()}</p>
                          <p className='m-1'><strong className='font-weight-bold'>ID:</strong> {fee.id}</p>
                        </div>
                      </div>
                    </Col>
                  )
                )}

                {Array.isArray(vac) ? (
                  vac.map((item, index) => (
                    <Col key={index} sm={12} md={12} className='border-bottom'>
                      <div className="row d-flex align-items-center m-t-20 m-b-20">
                        <div className="col-4">
                          <h6 className="d-block text-uppercase m-1">vaccinated</h6>
                          <p className='m-1'><strong className='font-weight-bold'>Vaccine Name:</strong> {item.vaccineName}</p>
                          <p className='m-1'><strong className='font-weight-bold'>Quantity:</strong> {item.vaccineQuantity} Kgs/Litres</p>
                          <p className='m-1'><strong className='font-weight-bold'>Occurence Date:</strong> {new Date(item.recorded).toDateString}</p>
                        </div>
                        <div className="col-4">
                          <p className='m-1'><strong className='font-weight-bold'>Vaccinated By:</strong> {item.servedBy}</p>
                          <p className='m-1'><strong className='font-weight-bold'>Batch:</strong> {item.batchVaccinated}</p>
                        </div>
                        <div className="col-4">
                          <p className='m-1'><strong className='font-weight-bold'>Date:</strong> {new Date(item.createdAt).toLocaleString()}</p>
                          <p className='m-1'><strong className='font-weight-bold'>ID:</strong> {item.id}</p>
                        </div>
                      </div>
                    </Col>
                  ))
                ) : (
                  vac && vac.batchVaccinated && (
                    <Col sm={12} md={12} className='border-bottom'>
                      <div className="row d-flex align-items-center m-t-20 m-b-20">
                        <div className="col-4">
                          <h6 className="d-block text-uppercase m-1">vaccinated</h6>
                          <p className='m-1'><strong className='font-weight-bold'>Vaccine Name:</strong> {vac.vaccineName}</p>
                          <p className='m-1'><strong className='font-weight-bold'>Quantity:</strong> {vac.vaccineQuantity} Kgs/Litres</p>
                          <p className='m-1'><strong className='font-weight-bold'>Occurence Date:</strong> {new Date(vac.recorded).toDateString()}</p>
                        </div>
                        <div className="col-4">
                          <p className='m-1'><strong className='font-weight-bold'>Vaccinated By:</strong> {vac.servedBy}</p>
                          <p className='m-1'><strong className='font-weight-bold'>Batch:</strong> {vac.batchVaccinated}</p>
                        </div>
                        <div className="col-4">
                          <p className='m-1'><strong className='font-weight-bold'>Date:</strong> {new Date(vac.createdAt).toLocaleString()}</p>
                          <p className='m-1'><strong className='font-weight-bold'>ID:</strong> {vac.id}</p>
                        </div>
                      </div>
                    </Col>
                  )
                )}

                  {Array.isArray(mot) ? (
                    mot.map((item, index) => (
                      <Col key={index} sm={12} md={12} className='border-bottom'>
                        <div className="row d-flex align-items-center m-t-20 m-b-20">
                          <div className="col-4">
                            <h6 className="d-block text-uppercase m-1">Mortality</h6>
                            <p className='m-1'><strong className='font-weight-bold'>Mortality:</strong> {item.mortality}</p>
                            <p className='m-1'><strong className='font-weight-bold'>Health:</strong> {item.health}</p>
                            <p className='m-1'><strong className='font-weight-bold'>Occurence Date:</strong> {new Date(item.recorded).toDateString}</p>
                          </div>
                          <div className="col-4">
                            <p className='m-1'><strong className='font-weight-bold'>Batch:</strong> {item.batch}</p>
                            <p className='m-1'><strong className='font-weight-bold'>Remarks:</strong> {item.remarks}</p>
                          </div>
                          <div className="col-4">
                            <p className='m-1'><strong className='font-weight-bold'>Date:</strong> {new Date(item.createdAt).toLocaleString()}</p>
                            <p className='m-1'><strong className='font-weight-bold'>ID:</strong> {item.id}</p>
                          </div>
                        </div>
                      </Col>
                    ))
                  ) : (
                    mot && mot.batch && (
                      <Col sm={12} md={12} className='border-bottom'>
                        <div className="row d-flex align-items-center m-t-20 m-b-20">
                          <div className="col-4">
                            <h6 className="d-block text-uppercase m-1">Mortality</h6>
                            <p className='m-1'><strong className='font-weight-bold'>Mortality:</strong> {mot.mortality}</p>
                            <p className='m-1'><strong className='font-weight-bold'>Health:</strong> {mot.health}</p>
                            <p className='m-1'><strong className='font-weight-bold'>Occurence Date:</strong> {new Date(mot.recorded).toDateString()}</p>
                          </div>
                          <div className="col-4">
                            <p className='m-1'><strong className='font-weight-bold'>Batch:</strong> {mot.batch}</p>
                            <p className='m-1'><strong className='font-weight-bold'>Remarks:</strong> {mot.remarks}</p>
                          </div>
                          <div className="col-4">
                            <p className='m-1'><strong className='font-weight-bold'>Date:</strong> {new Date(mot.createdAt).toLocaleString()}</p>
                            <p className='m-1'><strong className='font-weight-bold'>ID:</strong> {mot.id}</p>
                          </div>
                        </div>
                      </Col>
                    )
                  )}

                  {Array.isArray(sel) ? (
                    sel.map((item, index) => (
                      <Col sm={12} md={12} className='border-bottom'>
                      <div className="row d-flex align-items-center m-t-20 m-b-20">
                        <div className="col-4">
                          <h6 className="d-block text-uppercase m-1">Sold</h6>
                          <p className='m-1'><strong className='font-weight-bold'>Customer:</strong> {item.cus_name}</p>
                          <p className='m-1'><strong className='font-weight-bold'>Delivery Guy:</strong> {item.del_guy}</p>
                          <p className='m-1'><strong className='font-weight-bold'>Payment Method:</strong> {item.pay_method}</p>
                          <p className='m-1'><strong className='font-weight-bold'>Delivery:</strong> {item.delivery}</p>
                          <p className='m-1'><strong className='font-weight-bold'>Occurence Date:</strong> {new Date(item.recorded).toDateString}</p>
                        </div>
                        <div className="col-4">
                          <p className='m-1'><strong className='font-weight-bold'>Bird Type:</strong> {item.type}</p>
                          <p className='m-1'><strong className='font-weight-bold'>Price Per Bird:</strong> {item.price}</p>
                          <p className='m-1'><strong className='font-weight-bold'>Total Cost:</strong> {item.totalCost}</p>
                          <p className='m-1'><strong className='font-weight-bold'>No. of Birds:</strong> {item.birds}</p>
                          <p className='m-1'><strong className='font-weight-bold'>Payment Reference:</strong> {item.pay_reference}</p>
                        </div>
                        <div className="col-4">
                          <p className='m-1'><strong className='font-weight-bold'>Date:</strong> {new Date(item.createdAt).toLocaleString()}</p>
                          <p className='m-1'><strong className='font-weight-bold'>Transaction ID:</strong> {item.transactionId}</p>
                          <p className='m-1'><strong className='font-weight-bold'>Served By:</strong> {item.servedBy}</p>
                          <p className='m-1'><strong className='font-weight-bold'>Batch No.:</strong> {item.batch}</p>
                        </div>
                      </div>
                    </Col>
                    ))
                  ) : (
                    sel && sel.batch && (
                      <Col sm={12} md={12} className='border-bottom'>
                        <div className="row d-flex align-items-center m-t-20 m-b-20">
                          <div className="col-4">
                            <h6 className="d-block text-uppercase m-1">Sold</h6>
                            <p className='m-1'><strong className='font-weight-bold'>Customer:</strong> {sel.cus_name}</p>
                            <p className='m-1'><strong className='font-weight-bold'>Delivery Guy:</strong> {sel.del_guy}</p>
                            <p className='m-1'><strong className='font-weight-bold'>Payment Method:</strong> {sel.pay_method}</p>
                            <p className='m-1'><strong className='font-weight-bold'>Delivery:</strong> {sel.delivery}</p>
                            <p className='m-1'><strong className='font-weight-bold'>Occurence Date:</strong> {new Date(sel.recorded).toDateString()}</p>
                          </div>
                          <div className="col-4">
                            <p className='m-1'><strong className='font-weight-bold'>Bird Type:</strong> {sel.type}</p>
                            <p className='m-1'><strong className='font-weight-bold'>Price Per Bird:</strong> {sel.price}</p>
                            <p className='m-1'><strong className='font-weight-bold'>Total Cost:</strong> {sel.totalCost}</p>
                            <p className='m-1'><strong className='font-weight-bold'>No. of Birds:</strong> {sel.birds}</p>
                            <p className='m-1'><strong className='font-weight-bold'>Payment Reference:</strong> {sel.pay_reference}</p>
                          </div>
                          <div className="col-4">
                            <p className='m-1'><strong className='font-weight-bold'>Date:</strong> {new Date(sel.createdAt).toLocaleString()}</p>
                            <p className='m-1'><strong className='font-weight-bold'>Transaction ID:</strong> {sel.transactionId}</p>
                            <p className='m-1'><strong className='font-weight-bold'>Served By:</strong> {sel.servedBy}</p>
                            <p className='m-1'><strong className='font-weight-bold'>Batch No.:</strong> {sel.batch}</p>
                          </div>
                        </div>
                      </Col>
                    )
                  )}              
              </Row>
          </Card>
        </Col>
      </Row>      
    </React.Fragment>
  );
};



const PDFContent = ({ batch, user, egg, fee, vac, mot, sel, pdfData, selTotal, userTotal, feeTotal, vacTotal, eggTotal, motTotal }) => (
  <Document>
    <Page style={styles.page} size="A4">
      <View style={styles.section}>
      <View style={styles.row}> 
            <View style={styles.header} fixed>
              <View style={styles.rows}>
                <View style={styles.column}>
                  <Image source={avatarImage} style={styles.letterheadLogo} />
                </View>
              
                <View style={styles.column}>

                </View>
                <View style={styles.column}>
                  <Text>SFARMS.CO.KE</Text>
                  <Text>P.O BOX 800-222</Text>
                  <Text>Naivasha</Text>
                  <Text>Phone : 254-787-689-760</Text>
                  <Text>Email : info@sfarms.co.ke</Text>
                </View>
              </View>
            </View>
          </View>
        <Text style={styles.ft}>SFARMS BATCH HISTORY</Text>
        <Text style={styles.transactionId}>Batch Number Details: Batch #{batch}</Text>
        <View>

        <View style={styles.row}>
          <View style={styles.column}>
            <Text style={styles.tile}>Summary</Text>
            <Text>Total Income: KS {selTotal}</Text>
            <Text>Total Expense: KS {userTotal}</Text>
          </View>
          <View style={styles.column}>
            <Text>Feed Quantity: {feeTotal} Kgs/lts</Text>
            <Text>Vaccine Quantity: {vacTotal} Kgs/lts</Text>
          </View>
          <View style={styles.column}>
            <Text>Eggs Laid: {eggTotal}</Text>
            <Text>Total Mortality: {motTotal}</Text>
          </View>
        </View>

        {Array.isArray(user) ? (
          user.map((item, index) => (
            <View key={index} style={styles.row}>
              <View style={styles.column}>
                <Text style={styles.tile}>Bought</Text>
                <Text>Supplier: {item.sup_info}</Text>
                <Text>Quantity: {item.quantity}</Text>
                <Text>Occurence Date: {new Date(item.recorded).toDateString()}</Text>
              </View>
              <View style={styles.column}>
                <Text>Bird Type: {item.type}</Text>
                <Text>Price Per Bird: Ksh {item.price}</Text>
                <Text>Total Cost: Ksh {item.totalCost}</Text>
              </View>
              <View style={styles.column}>
                <Text>Date: {new Date(item.createdAt).toLocaleString()}</Text>
                <Text>ID: {item.id}</Text>
              </View>
            </View>
          ))
        ) : user && user.batch && (
          <View style={styles.row}>
            <View style={styles.column}>
              <Text style={styles.tile}>Bought</Text>
              <Text>Supplier: {user.sup_info}</Text>
              <Text>Quantity: {user.quantity}</Text>
              <Text>Occurence Date: {new Date(user.recorded).toDateString()}</Text>
            </View>
            <View style={styles.column}>
              <Text>Bird Type: {user.type}</Text>
              <Text>Price Per Bird: Ksh {user.price}</Text>
              <Text>Total Cost: Ksh {user.totalCost}</Text>
            </View>
            <View style={styles.column}>
              <Text>Date: {new Date(user.createdAt).toLocaleString()}</Text>
              <Text>ID: {user.id}</Text>
            </View>
          </View>
        )}

        {/* Render Fed data */}
        {Array.isArray(fee) ? (
          fee.map((item, index) => (
            <View key={index} style={styles.row}>
              <View style={styles.column}>
                <Text style={styles.tile}>FED</Text>
                <Text>Feed Name: {item.feed_name}</Text>
                <Text>Quantity: {item.consumed} Kgs/Litres</Text>
                <Text>Occurence Date: {new Date(item.recorded).toDateString()}</Text>
              </View>
              <View style={styles.column}>
                <Text>Bird Type: {item.type}</Text>
                <Text>Batch: {item.batch}</Text>
                <Text>Remarks: {item.remarks}</Text>
              </View>
              <View style={styles.column}>
                <Text>Date: {new Date(item.createdAt).toLocaleString()}</Text>
                <Text>ID: {item.id}</Text>
              </View>
            </View>
          ))
        ) : fee && fee.batch && (
          <View style={styles.row}>
            <View style={styles.column}>
              <Text style={styles.tile}>FED</Text>
              <Text>Feed Name: {fee.feed_name}</Text>
              <Text>Quantity: {fee.consumed} Kgs/Litres</Text>
              <Text>Occurence Date: {new Date(fee.recorded).toDateString()}</Text>
            </View>
            <View style={styles.column}>
              <Text>Bird Type: {fee.type}</Text>
              <Text>Batch: {fee.batch}</Text>
              <Text>Remarks: {fee.remarks}</Text>
            </View>
            <View style={styles.column}>
              <Text>Date: {new Date(fee.createdAt).toLocaleString()}</Text>
              <Text>ID: {fee.id}</Text>
            </View>
          </View>
        )}

        {Array.isArray(egg) ? (
          egg.map((item, index) => (
            <View key={index} style={styles.row}>
              <View style={styles.column}>
                <Text style={styles.tile}>LAID EGGS</Text>
                <Text>Collected Eggs: {item.collected}</Text>
                <Text>Spoilt Eggs: {item.spoilt} Kgs/Litres</Text>
                <Text>Occurence Date: {new Date(item.recorded).toDateString()}</Text>
              </View>
              <View style={styles.column}>
                <Text>Batch: {item.batch}</Text>
                <Text>Remarks: {item.remarks}</Text>
              </View>
              <View style={styles.column}>
                <Text>Date: {new Date(item.createdAt).toLocaleString()}</Text>
                <Text>ID: {item.id}</Text>
              </View>
            </View>
          ))
        ) : egg && egg.batch && (
          <View style={styles.row}>
            <View style={styles.column}>
              <Text style={styles.tile}>LAID EGGS</Text>
              <Text>Collected Eggs: {egg.collected}</Text>
              <Text>Spoilt Eggs: {egg.spoilt} Kgs/Litres</Text>
              <Text>Occurence Date: {new Date(egg.recorded).toDateString()}</Text>
            </View>
            <View style={styles.column}>
              <Text>Batch: {egg.batch}</Text>
              <Text>Remarks: {egg.remarks}</Text>
            </View>
            <View style={styles.column}>
              <Text>Date: {new Date(egg.createdAt).toLocaleString()}</Text>
              <Text>ID: {egg.id}</Text>
            </View>
          </View>
        )}

        {/* Render Vaccinated data */}
        {Array.isArray(vac) ? (
          vac.map((item, index) => (
            <View key={index} style={styles.row}>
              <View style={styles.column}>
                <Text style={styles.tile}>Vaccinated</Text>
                <Text>Vaccine Name: {item.vaccineName}</Text>
                <Text>Quantity: {item.vaccineQuantity} Kgs/Litres</Text>
                <Text>Occurence Date: {new Date(item.recorded).toDateString()}</Text>
              </View>
              <View style={styles.column}>
                <Text>Vaccinated By: {item.servedBy}</Text>
                <Text>Batch: {item.batchVaccinated}</Text>
              </View>
              <View style={styles.column}>
                <Text>Date: {new Date(item.createdAt).toLocaleString()}</Text>
                <Text>ID: {item.id}</Text>
              </View>
            </View>
          ))
        ) : vac && vac.batchVaccinated && (
          <View style={styles.row}>
            <View style={styles.column}>
              <Text style={styles.tile}>Vaccinated</Text>
              <Text>Vaccine Name: {vac.vaccineName}</Text>
              <Text>Quantity: {vac.vaccineQuantity} Kgs/Litres</Text>
              <Text>Occurence Date: {new Date(vac.recorded).toDateString()}</Text>
            </View>
            <View style={styles.column}>
              <Text>Vaccinated By: {vac.servedBy}</Text>
              <Text>Batch: {vac.batchVaccinated}</Text>
            </View>
            <View style={styles.column}>
              <Text>Date: {new Date(vac.createdAt).toLocaleString()}</Text>
              <Text>ID: {vac.id}</Text>
            </View>
          </View>
        )}

        {/* Render Mortality data */}
        {Array.isArray(mot) ? (
          mot.map((item, index) => (
            <View key={index} style={styles.row}>
              <View style={styles.column}>
                <Text style={styles.tile}>Mortality</Text>
                <Text>Mortality: {item.mortality}</Text>
                <Text>Health: {item.health}</Text>
                <Text>Occurence Date: {new Date(item.recorded).toDateString()}</Text>
              </View>
              <View style={styles.column}>
                <Text>Batch: {item.batch}</Text>
                <Text>Remarks: {item.remarks}</Text>
              </View>
              <View style={styles.column}>
                <Text>Date: {new Date(item.createdAt).toLocaleString()}</Text>
                <Text>ID: {item.id}</Text>
              </View>
            </View>
          ))
        ) : mot && mot.batch && (
          <View style={styles.row}>
            <View style={styles.column}>
              <Text style={styles.tile}>Mortality</Text>
              <Text>Mortality: {mot.mortality}</Text>
              <Text>Health: {mot.health}</Text>
              <Text>Occurence Date: {new Date(mot.recorded).toDateString()}</Text>
            </View>
            <View style={styles.column}>
              <Text>Batch: {mot.batch}</Text>
              <Text>Remarks: {mot.remarks}</Text>
            </View>
            <View style={styles.column}>
              <Text>Date: {new Date(mot.createdAt).toLocaleString()}</Text>
              <Text>ID: {mot.id}</Text>
            </View>
          </View>
        )}

        {/* Render Sold data */}
        {Array.isArray(sel) ? (
          sel.map((item, index) => (
            <View key={index} style={styles.row}>
              <View style={styles.column}>
                <Text style={styles.tile}>Sold</Text>
                <Text>Customer: {item.cus_name}</Text>
                <Text>Delivery Guy: {item.del_guy}</Text>
                <Text>Payment Method: {item.pay_method}</Text>
                <Text>Delivery: {item.delivery}</Text>
                <Text>Occurence Date: {new Date(item.recorded).toDateString()}</Text>
              </View>
              <View style={styles.column}>
                <Text>Bird Type: {item.type}</Text>
                <Text>Price Per Bird: {item.price}</Text>
                <Text>Total Cost: {item.totalCost}</Text>
                <Text>No. of Birds: {item.birds}</Text>
                <Text>Payment Reference: {item.pay_reference}</Text>
              </View>
              <View style={styles.column}>
                <Text>Date: {new Date(item.createdAt).toLocaleString()}</Text>
                <Text>Transaction ID: {item.transactionId}</Text>
                <Text>Served By: {item.servedBy}</Text>
                <Text>Batch No.: {item.batch}</Text>
              </View>
            </View>
          ))
        ) : sel && sel.batch && (
          <View style={styles.row}>
            <View style={styles.column}>
              <Text style={styles.tile}>Sold</Text>
              <Text>Customer: {sel.cus_name}</Text>
              <Text>Delivery Guy: {sel.del_guy}</Text>
              <Text>Payment Method: {sel.pay_method}</Text>
              <Text>Delivery: {sel.delivery}</Text>
              <Text>Occurence Date: {new Date(sel.recorded).toDateString()}</Text>
            </View>
            <View style={styles.column}>
              <Text>Bird Type: {sel.type}</Text>
              <Text>Price Per Bird: {sel.price}</Text>
              <Text>Total Cost: {sel.totalCost}</Text>
              <Text>No. of Birds: {sel.birds}</Text>
              <Text>Payment Reference: {sel.pay_reference}</Text>
            </View>
            <View style={styles.column}>
              <Text>Date: {new Date(sel.createdAt).toLocaleString()}</Text>
              <Text>Transaction ID: {sel.transactionId}</Text>
              <Text>Served By: {sel.servedBy}</Text>
              <Text>Batch No.: {sel.batch}</Text>
            </View>
          </View>
        )}
        
        </View><Text style={styles.ft}>#SFARMS POULTRY BATCH HISTORY</Text>
      </View>
    </Page>
  </Document>
);

  const styles = StyleSheet.create({
    page: {
      fontFamily: 'Montserrat',
      fontSize: 8.5,
      flexDirection: 'row',
      backgroundColor: '#ffffff',
      width: '100%',
      height: '20px',
      marginBottom: 40,
    },
    pageBreak: {
      marginTop: 20,
      pageBreakBefore: 'always',
    },
    section: {
      marginTop: 5,
      marginLeft: 50,
      marginRight: 50,
      padding: 10,
      flexGrow: 1,
    },
    header: {
      width: '100%',
      alignContent: 'center',
      // alignItems: 'center'
    },
    letterheadLogo: {
      width: 100,
      height: 100,
    },
    rows: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems:'center',
      marginBottom: 10,
    },
    transactionId: {
      fontSize: 12,
      fontWeight: 'bold',
      marginBottom: 10,
      color: '#ffffff',
      borderBottom: '0.5px solid grey',
      backgroundColor: '#279e6c',
    },
    tile: {
      fontSize: 8,
      fontWeight: 'bold',
      color: '#279e6c',
      textTransform: 'uppercase',
      marginBottom: 5,
    },
    ft: {
      fontSize: 8,
      fontWeight: 'bold',
      color: '#279e6c',
      textTransform: 'uppercase',
      textAlign: 'center',
      margin: 5,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 10,
      marginTop: 5,
      borderBottom: '0.45px solid grey',
    },
    column: {
      width: '50%',
      marginBottom: 10,
    },
    notes: {
      marginTop: 20,
      fontSize: 10,
    },
  });
  
export default UpdateUser;
