import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import config from '../../../config';
import Card from '../../../components/Card/MainCard';
import Select from 'react-select';
import axios from 'axios';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import Farm from './BasicTabsPills003.js';
import { Row, Col, Form, Button, Table, Alert, Tabs, Tab, Modal, Pagination } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { usePermissions } from '../../../permissions/index.js';
import DataTable from 'react-data-table-component';

const SamplePage = () => {
    const account = useSelector((state) => state.account);
    const [isPopupVisible, setPopupVisible] = useState(false);
    const history = useHistory();
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage, setUsersPerPage] = useState(5);
    const { createPermission, deletePermission, editPermission, viewPermission } = usePermissions();
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        fetchTrialBalance();
    }, [data, apiUrl]);

    const fetchTrialBalance = async () => {
        try {
            const response = await axios.get(`${apiUrl}/farm`);
            const logs = response.data;
            setData(logs);
        } catch (error) {
            console.error(error);
        }
    };

    const handleDelete = async (userId, name) => {
        try {
            const confirmed = window.confirm(`Are you sure you want to archive ${name}?`);
            if (confirmed) {
                await axios.put(`${apiUrl}/farm/${userId}/archive`);
                alert('Farm archived successfully!');
                window.location.reload();
            }
        } catch (error) {
            alert(error.message);
        }
    };

    const handleUpdate = (userId) => {
        const userToUpdate = data.find((user) => user.id === userId);
        history.push(`farm/update-farm/${userId}`, { user: userToUpdate });
    };
    const hidePopup = () => {
        setPopupVisible(false);
    };

    const showPopup = () => {
        setPopupVisible(true);
    };

    const calculateAge = (birthDate) => {
        const today = new Date();
        const birth = new Date(birthDate);

        // Get the UTC values for year, month, and day
        const todayUTC = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
        const birthUTC = Date.UTC(birth.getFullYear(), birth.getMonth(), birth.getDate());

        // Calculate the age difference in milliseconds
        const ageDiffMillis = todayUTC - birthUTC;

        // Convert milliseconds to years, months, and days
        const yearDiff = Math.floor(ageDiffMillis / (1000 * 60 * 60 * 24 * 365));
        const monthDiff = Math.floor((ageDiffMillis % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30));
        const dayDiff = Math.floor((ageDiffMillis % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24));

        if (yearDiff > 0) {
            return yearDiff === 1 ? `${yearDiff} year ago` : `${yearDiff} years ago`;
        } else if (monthDiff > 0) {
            return monthDiff === 1 ? `${monthDiff} month ago` : `${monthDiff} months ago`;
        } else if (dayDiff > 0) {
            return dayDiff === 1 ? `${dayDiff} day ago` : `${dayDiff} days ago`;
        } else {
            return dayDiff === 0 ? `Today` : `${dayDiff} days ago`;
        }
    };

    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = data.slice(indexOfFirstUser, indexOfLastUser);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handlePerPageChange = (selectedOption) => {
        setUsersPerPage(selectedOption.value);
        setCurrentPage(1);
    };

    const options = [
        { value: 5, label: '5' },
        { value: 10, label: '10' },
        { value: 25, label: '25' },
        { value: 50, label: '50' },
        { value: 150, label: '150' },
        { value: 200, label: '200' },
        { value: 500, label: '500' }
    ];

    const totalPages = Math.ceil(data.length / usersPerPage);

    const csvLinkRef = useRef();

    const exportToCSV = () => {
        const csvData = [['Farm Name', 'ID', 'Date/Time'], ...data.map((item) => [item.name, item.id, item.createdAt])];

        const csvContent = csvData.map((row) => row.join(',')).join('\n');
        const blob = new Blob(['\ufeff', csvContent], { type: 'text/csv;charset=utf-8;' });

        const csvUrl = URL.createObjectURL(blob);
        csvLinkRef.current.href = csvUrl;
        csvLinkRef.current.download = 'sfarms.csv';
        csvLinkRef.current.click();
    };

    const exportToPDF = () => {
        const doc = new jsPDF();
        doc.autoTable({
            head: [['Farm Name', 'ID', 'Date/Time']],
            body: data.map((user) => [
                user.name,
                user.id,
                new Intl.DateTimeFormat('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                }).format(new Date(user.createdAt))
            ])
        });
        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl);
    };

    const columns = [
        {
            name: 'ID',
            selector: (row) => row.id,
            sortable: true,
            minWidth: '100px',
            maxWidth: '100px'
        },

        {
            name: 'Farm Name',
            selector: (row) => row?.name,
            sortable: true,
            wrap: true,
            minWidth: '150px'
        },
        {
            name: 'Created',
            selector: (row) => new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            }).format(new Date(row.createdAt)),
            sortable: true,
            wrap: true,
            minWidth: '200px'
        },
        {
            name: 'Timestamp',
            selector: (row) => calculateAge(row.createdAt),
            sortable: true,
            wrap: true,
            minWidth: '130px',

        },
        {
            name: 'Action',
            cell: (row) => (
                <td>
                    {editPermission ? (
                        <Button size="sm" onClick={() => handleUpdate(row.id)}>
                            <span className="feather icon-edit"></span> Edit
                        </Button>
                    ) : (
                        '-'
                    )}
                    {deletePermission ? (
                        <Button variant="danger" size="sm" onClick={() => handleDelete(row.id, row.name)}>
                            <span className="feather icon-delete"></span> Delete
                        </Button>
                    ) : (
                        '-'
                    )}
                </td>
            ),
            wrap: true,
            minWidth: '180px'
        }
    ];

    const filteredData = searchText
        ? data.filter((item) =>
              Object.values(item).some((value) => typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase()))
          )
        : data;

    return (
        <React.Fragment>
            <Row>
                <Col sm={12}>
                    {success && (
                        <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                            {success}
                        </Alert>
                    )}
                    {error && (
                        <Alert variant="danger" onClose={() => setError(null)} dismissible>
                            {error}
                        </Alert>
                    )}
                    <ToastContainer />
                </Col>
            </Row>
            <Row className="btn-page">
                <Col>
                    <div className="d-flex justify-content-between align-items-center m-b-10">
                   
                        {createPermission && (
                            <Button onClick={showPopup} className="mb-10">
                                <span className="feather icon-plus-circle"></span> Add Farm
                            </Button>
                        )}
                        {isPopupVisible && <Farm onCancel={hidePopup} />}
                    </div>

                    <Row className="btn-page">
                        <Col>
                            {data && data.length > 0 && (
                                <Row>
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasicName">
                                        <Form.Control
                                            type="text"
                                            placeholder="Search..."
                                            value={searchText}
                                            onChange={(e) => setSearchText(e.target.value)}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasicName">
                                        <div className="d-flex">
                                            <Button onClick={exportToCSV}>
                                                <span className="feather icon-file"></span> Export CSV
                                            </Button>
                                            <a ref={csvLinkRef} style={{ display: 'none' }} />
                                            <a href="#" className="btn btn-primary" onClick={exportToPDF}>
                                                <span className="feather icon-file-text"></span> Export PDF
                                            </a>
                                        </div>
                                    </Form.Group>
                                </Row>
                            )}
                            <Row>
                                <Col>
                                    <DataTable
                                        columns={columns}
                                        data={filteredData}
                                        pagination
                                        highlightOnHover
                                        striped
                                        // noHeader
                                        dense
                                        bordered
                                        paginationPerPage={10}
                                        paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                                        paginationComponentOptions={{
                                            rowsPerPageText: 'Rows per page:'
                                        }}
                                        paginationResetDefaultPage={true}
                                        paginationTotalRows={data.length}
                                        // expandableRows
                                        selectableRows
                                        onSelectedRowsChange={(selectedRows) => console.log(selectedRows)}
                                        customStyles={{
                                            headRow: {
                                                style: {
                                                    backgroundColor: '#279e6c', // Green background
                                                    color: '#fff', // White text
                                                    textTransform: 'uppercase', // Uppercase text
                                                    // fontSize: '10px', // Adjust font size for header row
                                                    minHeight: '1.5rem',
                                                    paddingTop: '8px',
                                                    paddingBottom: '8px'
                                                }
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default SamplePage;
