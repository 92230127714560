import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Form, Table, Button, Pagination, Alert } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { useTable, usePagination } from 'react-table';
import config from '../../../config';
import { CSVLink } from 'react-csv';
import axios from 'axios';
import Card from '../../../components/Card/MainCard';
import DataTable from 'react-data-table-component';

const SamplePage = () => {
    const { selectedFarm } = useParams();
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [accounts, setAccounts] = useState([]);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [expenses, setExpenses] = useState([]);
    const [earnings, setEarnings] = useState({ totalEarnings: 0 });
    const [dailyEarnings, setDailyEarnings] = useState(0);
    const [weeklyEarnings, setWeeklyEarnings] = useState(0);
    const [monthlyEarnings, setMonthlyEarnings] = useState(0);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        fetch(`${apiUrl}/earnings?farm=${selectedFarm}`)
            .then((response) => response.json())
            .then((data) => setEarnings(data))
            .catch((error) => console.error('Error:', error));
    }, [selectedFarm]);

    useEffect(() => {
        axios
            .get(`${apiUrl}/earnings/stats?period=daily&&farm=${selectedFarm}`)
            .then((response) => {
                setDailyEarnings(response.data.totalEarnings);
            })
            .catch((error) => {
                console.error('Error fetching daily earnings:', error);
            });

        axios
            .get(`${apiUrl}/earnings/stats?period=weekly&&farm=${selectedFarm}`)
            .then((response) => {
                setWeeklyEarnings(response.data.totalEarnings);
                console.log(response.data.totalEarnings);
            })
            .catch((error) => {
                console.error('Error fetching weekly earnings:', error);
            });

        axios
            .get(`${apiUrl}/earnings/stats?period=monthly&&farm=${selectedFarm}`)
            .then((response) => {
                setMonthlyEarnings(response.data.totalEarnings);
            })
            .catch((error) => {
                console.error('Error fetching monthly earnings:', error);
            });
    }, [selectedFarm, apiUrl]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/earn?farm=${selectedFarm}`);
                setExpenses(response.data);
                console.log(response.data);
            } catch (error) {
                console.error('Error fetching expenses data:', error);
            }
        };

        fetchData();
    }, []);

    const csvData = expenses.map((expense) => ({
        'Earning Type': expense.earning_type,
        Date: new Date(expense.createdAt).toLocaleDateString(),
        Description: expense.description,
        Amount: expense.amount,
        Quantity: expense.quantity,
        Account: expense.bankAccount
    }));

    const columns = [
        {
            name: 'Earning Type',
            selector: (row) => row.earning_type,
            sortable: true,
            wrap: true
        },
        { name: 'Description', selector: (row) => row.description, sortable: true, wrap: true },
        { name: 'quantity', selector: (row) => row.quantity, sortable: true, wrap: true },
        {
            name: 'Amount',
            selector: (row) =>
                row.amount !== null
                    ? row.amount.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                      })
                    : '',
            sortable: true,
            wrap: true
        },
        { name: 'Account', selector: (row) => row.bankAccount, sortable: true, wrap: true },

        { name: 'Timestamp', selector: (row) => new Date(row.createdAt).toLocaleString(), sortable: true, wrap: true }
    ];

    const filteredData = expenses.filter((item) => {
        // Check if the item matches the search text
        const matchesSearchText =
            !searchText ||
            Object.values(item).some((value) => typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase()));

        return matchesSearchText;
        // return matchesSearchText && matchesStatusFilter && matchesEventTypeFilter;
    });

    return (
        <React.Fragment>
            <Row>
                <Col sm={12}>
                    {success && (
                        <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                            {success}
                        </Alert>
                    )}
                    {error && (
                        <Alert variant="danger" onClose={() => setError(null)} dismissible>
                            {error}
                        </Alert>
                    )}
                </Col>
            </Row>
            <Row>
                <Col>
                    <Row>
                        <Col sm={12} md={6} lg={3}>
                            <Card title="Today Earnings">
                                <div className="row d-flex align-items-center">
                                    <div className="col-12">
                                        <h6>KES {dailyEarnings.toLocaleString()}</h6>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col sm={12} md={6} lg={3}>
                            <Card title="Weekly Earnings">
                                <div className="row d-flex align-items-cente">
                                    <div className="col-12">
                                        <h6>KES {weeklyEarnings.toLocaleString()}</h6>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col sm={12} md={6} lg={3}>
                            <Card title="Monthly Earnings">
                                <div className="row d-flex align-items-cente">
                                    <div className="col-12">
                                        <h6>KES {monthlyEarnings.toLocaleString()}</h6>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col sm={12} md={6} lg={3}>
                            <Card title="Earnings">
                                <div className="row d-flex align-items-cente">
                                    <div className="col-12">
                                        <h6>KES {earnings.totalEarnings.toLocaleString()}</h6>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <Link to="/farms/manage">
                        <Button variant="primary" className="m-b-10">
                            <span className="feather icon-arrow-left"></span> Go Back
                        </Button>
                    </Link>

                    <Row>
                        <Form.Group as={Col} md={4} sm={12} lg={4} controlId="formBasicName">
                            <Form.Control
                                type="text"
                                placeholder="Search..."
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                        </Form.Group>
                        {csvData.length > 0 && (
                            <CSVLink data={csvData} headers={Object.keys(csvData[0])} filename="farm_expense.csv">
                                <Button>Export to CSV</Button>
                            </CSVLink>
                        )}
                    </Row>

                    <DataTable
                        columns={columns}
                        data={filteredData}
                        // expandableRows
                        // expandableRowsComponent={ExpandableComponent}
                        pagination
                        highlightOnHover
                        striped
                        // noHeader
                        dense
                        bordered
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100, 250, 500]}
                        paginationComponentOptions={{
                            rowsPerPageText: 'Rows per page:'
                        }}
                        paginationResetDefaultPage={true}
                        paginationTotalRows={expenses.length}
                        // expandableRows
                        // selectableRows
                        onSelectedRowsChange={(selectedRows) => console.log(selectedRows)}
                        customStyles={{
                            headRow: {
                                style: {
                                    backgroundColor: '#279e6c', // Green background
                                    color: '#fff', // White text
                                    textTransform: 'uppercase', // Uppercase text
                                    // fontSize: '10px', // Adjust font size for header row
                                    minHeight: '1.5rem',
                                    paddingTop: '8px',
                                    paddingBottom: '8px'
                                }
                            }
                        }}
                    />
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default SamplePage;
