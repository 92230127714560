import { ACCOUNT_INITIALIZE, LOGIN, LOGOUT } from './actions';

const AUTOMATIC_LOGOUT_TIME = 20 * 60 * 1000; // 2 minutes in milliseconds

let logoutTimer = null;

export const initialState = {
    token: '',
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

//-----------------------|| ACCOUNT REDUCER ||-----------------------//

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACCOUNT_INITIALIZE: {
            const { isLoggedIn, user, token } = action.payload;
            clearTimeout(logoutTimer);
            return {
                ...state,
                isLoggedIn,
                isInitialized: true,
                token,
                user
            };
        }
        case LOGIN: {
            const { user, token } = action.payload;
            clearTimeout(logoutTimer);
            return {
                ...state,
                isLoggedIn: true,
                user,
                token
            };
        }
        case LOGOUT: {
            return {
                ...state,
                isLoggedIn: false,
                token: '',
                user: null
            };
        }
        default: {
            if (state.isLoggedIn) {
                if (!logoutTimer) {
                    logoutTimer = setTimeout(() => {
                        clearTimeout(logoutTimer);
                    }, AUTOMATIC_LOGOUT_TIME);
                }
            }
            return { ...state };
        }
    }
};

export default accountReducer;
