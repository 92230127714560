import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Col, Row } from 'react-bootstrap';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { differenceInCalendarDays, format, parse } from 'date-fns';
import config from './../../../config';
import axios from 'axios';

const LeaveForm = ({ leave, onSave, onCancel, users }) => {
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
  const apiUrl = config[environment].apiUrl;
  const [formData, setFormData] = useState(leave || {
    employee: '',
    startDate: '',
    endDate: '',
    description: '',
    type: '',
    createdAt: ''
  });
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [numberOfDays, setNumberOfDays] = useState('');

  // const formatDate = (dateString) => {
  //   const options = { year: '2-digit', month: '2-digit', day: '2-digit' };
  //   return new Date(dateString).toLocaleDateString(undefined, options);
  // };

  useEffect(() => {
    if (leave) {
      setFormData(leave);
    }
  }, [leave]);

    const requestData = {
      employee: selectedEmployees.map((employee) => employee.value),
      startDate: startDate,
      endDate: endDate,
      numberOfDays: numberOfDays,
      description: formData.description,
      type: formData.type
    }

    // console.log(formData)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (leave) {
      axios.put(`${apiUrl}/leave_request/${leave.id}`, formData)
        .then((response) => {
          if (response.data.message) {
            toast.success(response.data.message);
          }
          onSave(response.data);
        })
        .catch((error) => {
          console.error('Error updating leave request:', error);
          toast.error('Error updating leave request:', error);
        });
    } else {
      axios.post(`${apiUrl}/leave_request`, requestData)
        .then((response) => {
          if (response.data.message) {
            toast.success(response.data.message);
          }
          onSave(response.data);
        })
        .catch((error) => {
          console.error('Error submitting leave request:', error);
          toast.error('Error submitting leave request:', error);
        });
    }
  
    // Close the form popup
    // setPopupVisible(false);
  };
  

  const handleEmployeeChange = (selectedOptions) => {
    setSelectedEmployees(selectedOptions);
    calculateNumberOfDays()
  };

  const calculateNumberOfDays = () => {
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
  
      if (!isNaN(start) && !isNaN(end) && end >= start) {
        const diffTime = differenceInCalendarDays(end, start);
  
        if (diffTime === 1) {
          setNumberOfDays('1 day');
        } else if (diffTime > 1 && diffTime < 30) {
          setNumberOfDays(`${diffTime} days`);
        } else {
          const startYear = start.getFullYear();
          const startMonth = start.getMonth();
          const endYear = end.getFullYear();
          const endMonth = end.getMonth();
          const months = (endYear - startYear) * 12 + (endMonth - startMonth);
          
          if (months === 1) {
            setNumberOfDays('1 month');
          } else {
            setNumberOfDays(`${months} months`);
          }
        }
      } else {
        setNumberOfDays('');
      }
    } else {
      setNumberOfDays('');
    }
  };

  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    setStartDate(newStartDate);
    calculateNumberOfDays();
    setFormData({
      ...formData,
      startDate: newStartDate, 
    });
  };
  
  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    setEndDate(newEndDate);
    calculateNumberOfDays();
    setFormData({
      ...formData,
      endDate: newEndDate,
    });
  };
  
  
  const options = [
    { value: 'All Employees', label: 'All Employees' },
    ...users.map((user) => ({
      value: `${user.firstname} ${user.lastname}`,
      label: `${user.firstname} ${user.lastname}`,
    })),
  ];

  const leaveTypeOptions = [
    { value: 'Annual Leave', label: 'Annual Leave' },
    { value: 'Sick Leave', label: 'Sick Leave' },
    { value: 'Maternity Leave', label: 'Maternity Leave' },
    { value: 'Holidays', label: 'Holidays' },
  ];

  const formatDate = (dateString) => {
    if (!dateString) return ''; // Handle empty or undefined dates
  
    const date = new Date(dateString);
    return format(date, 'MM/dd/yy');
  };

  const formattedStartDate = new Date(formData.startDate).toLocaleDateString('en-CA');
  const formattedEndDate = new Date(formData.endDate).toLocaleDateString('en-CA');
  console.log(formData)

  const isAllEmployeesSelected = selectedEmployees.some((option) => option.value === 'All Employees');

  return (
    <Modal show={true} onHide={onCancel} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>{leave ? 'View Leave' : 'Add Holidays & Leave'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
           <Row>
          <Form.Group as={Col} md={6} lg={6} sm={12}>
            <Form.Label>Type of Leave</Form.Label>
            {leave ? (
              <Form.Control
                value={formData.type}
                disabled
              />
            ) : (
            <Select
              required
              name="type"
              value={leaveTypeOptions.find((option) => option.value === formData.type)}
              onChange={(selectedOption) => setFormData({ ...formData, type: selectedOption.value })}
              options={leaveTypeOptions}
            />
            )}
          </Form.Group>

          <Form.Group as={Col} md={6} lg={6} sm={12}>
            <Form.Label>Employee</Form.Label>
            {leave ? (
              <Form.Control
                type="text"
                name="employee"
                value={formData.employee}
                onChange={handleStartDateChange}
                required
                onBlur={calculateNumberOfDays}
                disabled
              />
            ) : (
              <Select
                required
                isMulti
                name="employee"
                value={selectedEmployees}
                onChange={handleEmployeeChange}
                options={options}
                isOptionDisabled={(option) => isAllEmployeesSelected && option.value !== 'All Employees'}
              />
            )}
          </Form.Group>
          </Row>
          <Form.Group>
            <Form.Label>Start Date</Form.Label>
            {leave ? (
            <Form.Control
              type="text"
              name="startDate"
              value={formattedStartDate}
              onChange={handleStartDateChange}
              required
              onBlur={calculateNumberOfDays}
              disabled
            />
            ) : (
              <Form.Control
              type="date"
              name="startDate"
              value={startDate}
              onChange={handleStartDateChange}
              required
              onBlur={calculateNumberOfDays}
            />
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>End Date</Form.Label>
            {leave ? (
            <Form.Control
              type="text"
              name="endDate"
              value={formattedEndDate}
              onChange={handleEndDateChange}
              required
              onBlur={calculateNumberOfDays}
              disabled
            />
            ) : (
              <Form.Control
              type="date"
              name="endDate"
              value={endDate}
              onChange={handleEndDateChange}
              required
              onBlur={calculateNumberOfDays}
              
            />
              )}
          </Form.Group>
          <Form.Group>
            <Form.Label>Number Of Days</Form.Label>
            {leave ? (
            <Form.Control
              name="numberOfDays"
              type="text"
              value={formData.numberOfDays}
              disabled
              required
            />
            ) : (
              <Form.Control
                name="numberOfDays"
                type="text"
                value={numberOfDays}
                disabled
                required
              />
            )}
          </Form.Group>
          
          <Form.Group>
            <Form.Label>Description</Form.Label>
            {leave ? (
            <Form.Control
              as="textarea"
              name="description"
              value={formData.description}
              onChange={handleChange}
              required
              disabled
            />
            ) : (
            <Form.Control
              as="textarea"
              name="description"
              value={formData.description}
              onChange={handleChange}
              required
              
            />
              )}
          </Form.Group>
          {leave ? (
          <Form.Group>
            <Form.Label>Date Created</Form.Label>
            <Form.Control
              value={new Date(formData.createdAt).toLocaleString()}
              onChange={handleChange}
              required
              disabled
            />
          </Form.Group>
          ):(
            null
          )}

          <Modal.Footer>
            
            {leave ? (null):(
              <Button variant="primary" type="submit">
                Submit
              </Button>
            )}

          {leave ? (null):(
            <Button variant="secondary" onClick={onCancel}>
              Cancel
            </Button>
          )}
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default LeaveForm;
