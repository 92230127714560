import React, {useEffect, useState, useRef, useMemo} from 'react';
import { Row, Col, Form, Tab, Tabs, Alert, Button, Modal  } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.css';
import config from '../../../config';
// import Card from '../../components/Card/MainCard';
import axios from 'axios';
import ConfirmationModal from '../../ui-elements/basic/ConfirmationModal';

const SamplePage = ({onCancel}) => {
    const [data, setData] = useState({});
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const formRef = useRef(null);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [farmOptions, setFarmOptions] = useState([]);
    const [farm, setSelectedFarm] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);   

    const handleInput = (e) => {
        const {name, value} = e.target; 

        setData({ ...data, [name]: value });
        console.log(data);

    };

    const handleGoat = async (e) => {
        e.preventDefault();
        setShowConfirmationModal(true);
      };
    
    const handleConfirmation = async (e) => {
      
        try {
          const response = await axios.post(`${apiUrl}/roles`, data);
      
          if (response.data.message === 'Role already exists') {
            toast.error(`Role ${data.name} already exists`);
          } else {
            setSuccess(response.data.message);
            setTimeout(() => {
                setSuccess(null);
              }, 10000);
            toast.success(`Role ${data.name} added successfully`);
            formRef.current.reset();
            onCancel();
            setShowConfirmationModal(false);
          }
        } catch (error) {
            toast.error(`Role ${data.name} already exists`);
            setError(`Role ${data.name} already exists`);
            console.log(error.message);
            setShowConfirmationModal(false);
        }
      };
      
      const handleCancelConfirmation = () => {
        setShowConfirmationModal(false);
      };

    return (
        <React.Fragment>
            <Modal show={true} onHide={onCancel} size='lg' centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Roles</Modal.Title>
                </Modal.Header>
                <Modal.Body>
            <Row>
                <Col sm={12}>
                {success && <Alert variant="success" onClose={() => setSuccess(null)} dismissible >{success}</Alert>}
                {error && <Alert variant="danger" onClose={() => setError(null)} dismissible >{error}</Alert>}
                <ToastContainer />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form onSubmit={handleGoat} ref={formRef}>
                        <Row>
                            <Form.Group as={Col} md={4} sm={12} lg={4} controlId="formBasicName">
                                <Form.Label>Space Name</Form.Label>
                                {/* <Form.Text className="text-muted">We'll never share your email with anyone else.</Form.Text> */}
                            </Form.Group>
                            <Form.Group as={Col} md={8} sm={12} lg={8} controlId="formBasicName">
                                <Form.Control size="sm" required onChange={handleInput} type="text" name='name'/>
                                {/* <Form.Text className="text-muted">We'll never share your email with anyone else.</Form.Text> */}
                            </Form.Group>                            
                        </Row>
                        <Modal.Footer>
                            <Button type='submit'>Submit</Button>
                            <Button variant="secondary" onClick={onCancel}>
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Col>
            </Row>
            </Modal.Body>
            </Modal>

            <ConfirmationModal
              show={showConfirmationModal}
              onHide={handleCancelConfirmation}
              onConfirm={handleConfirmation}
            />
        </React.Fragment>
    );
};

export default SamplePage;
