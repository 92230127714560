import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Row, Col, Form, Tab, Tabs, Alert, Button, Table, Pagination } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import Kids from './nvd3-chart4';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import { CSVLink } from 'react-csv';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import config from '../../config';
import Card from '../../components/Card/MainCard';
import axios from 'axios';
import { usePermissions } from '../../permissions';
import DataTable from 'react-data-table-component';

const SamplePage = () => {
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage, setUsersPerPage] = useState(10);
    const formRef = useRef(null);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const { createPermission, deletePermission, editPermission } = usePermissions();
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        const fetchTags = async () => {
            try {
                const response = await axios.get(`${apiUrl}/kids_sired`);
                const data = response.data;
                setUsers(data);
            } catch (error) {
                console.error('Error fetching Tags:', error);
            }
        };
        fetchTags();
    }, [users, apiUrl]);

    const calculateAge = (birthDate) => {
        const today = new Date();
        const birth = new Date(birthDate);

        const todayUTC = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
        const birthUTC = Date.UTC(birth.getFullYear(), birth.getMonth(), birth.getDate());

        const ageDiffMillis = todayUTC - birthUTC;

        const yearDiff = Math.floor(ageDiffMillis / (1000 * 60 * 60 * 24 * 365));
        const monthDiff = Math.floor((ageDiffMillis % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30));
        const dayDiff = Math.floor((ageDiffMillis % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24));

        if (yearDiff > 0) {
            return yearDiff === 1 ? `${yearDiff} year ago` : `${yearDiff} years ago`;
        } else if (monthDiff > 0) {
            return monthDiff === 1 ? `${monthDiff} month ago` : `${monthDiff} months ago`;
        } else if (dayDiff > 0) {
            return dayDiff === 1 ? `${dayDiff} day ago` : `${dayDiff} days ago`;
        } else {
            return dayDiff === 0 ? `Today` : `${dayDiff} days ago`;
        }
    };

    const hidePopup = () => {
        setPopupVisible(false);
    };

    const showPopup = () => {
        setPopupVisible(true);
    };

    const csvLinkRef = useRef();

    const exportToCSV = () => {
        const csvData = [
            ['Silo No.', 'Tag No', 'No. of Kids', 'D.O.B', 'Date', 'Farm'],
            ...filteredData.map((item) => [item.siloNumber, item.tagNumber, item.kidsSired, item.dateSired, item.createdAt, item.farm])
        ];

        const csvContent = csvData.map((row) => row.join(',')).join('\n');
        const blob = new Blob(['\ufeff', csvContent], { type: 'text/csv;charset=utf-8;' });

        const csvUrl = URL.createObjectURL(blob);
        csvLinkRef.current.href = csvUrl;
        csvLinkRef.current.download = 'sfarms.csv';
        csvLinkRef.current.click();
    };

    const exportToPDF = () => {
        const doc = new jsPDF();
        doc.autoTable({
            head: [['Silo No.', 'Tag No', 'No. of Kids', 'D.O.B', 'Date', 'Farm']],
            body: filteredData.map((user) => [
                user.siloNumber,
                user.tagNumber,
                user.kidsSired,
                user.dateSired,
                new Intl.DateTimeFormat('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                }).format(new Date(user.createdAt)),
                user.farm
            ])
        });
        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl);
    };

    const columns = [
        {
            name: 'Silo No.',
            selector: (row) => row.siloNumber,
            sortable: true
        },
        {
            name: 'Tag No',
            selector: (row) => row?.tagNumber,
            sortable: true,
            wrap: true
        },
        {
            name: 'No. of Kids',
            selector: (row) => row?.kidsSired,
            sortable: true,
            wrap: true
        },

        {
            name: 'D.O.B',
            selector: (row) => new Date(row.dateSired).toLocaleDateString(),
            sortable: true
        },
        {
            name: 'Timestamp',
            selector: (row) => calculateAge(row.createdAt),
            sortable: true,
            wrap: true
        },
        {
            name: 'FArm',
            selector: (row) => row.farm,
            sortable: true,
            wrap: true
        }
    ];

    const filteredData = searchText
        ? users.filter((item) =>
              Object.values(item).some((value) => typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase()))
          )
        : users;

    return (
        <React.Fragment>
            <Row>
                <Col sm={12}>
                    {success && (
                        <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                            {success}
                        </Alert>
                    )}
                    {error && (
                        <Alert variant="danger" onClose={() => setError(null)} dismissible>
                            {error.message}
                        </Alert>
                    )}
                    <ToastContainer />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="d-flex justify-content-between align-center m-b-10">
                        {createPermission && (
                            <Button onClick={showPopup} className="mb-10">
                                <span className="feather icon-plus-circle"></span>Record Kids Sired
                            </Button>
                        )}
                        {isPopupVisible && <Kids onCancel={hidePopup} />}
                    </div>
                    <Row className="btn-page">
                        <Col>
                            <Row className="btn-page">
                                <Col>
                                    {filteredData && filteredData.length > 0 && (
                                        <Row>
                                            <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasicName">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Search..."
                                                    value={searchText}
                                                    onChange={(e) => setSearchText(e.target.value)}
                                                />
                                            </Form.Group>

                                            <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasicName">
                                                <div className="d-flex">
                                                    <Button onClick={exportToCSV}>Export as CSV</Button>
                                                    <a ref={csvLinkRef} style={{ display: 'none' }} />
                                                    <a href="#" className="btn btn-primary" onClick={exportToPDF}>
                                                        Export PDF
                                                    </a>
                                                </div>
                                            </Form.Group>
                                        </Row>
                                    )}
                                    <Row>
                                        <Col>
                                            <DataTable
                                                columns={columns}
                                                data={filteredData}
                                                pagination
                                                highlightOnHover
                                                striped
                                                // noHeader
                                                dense
                                                bordered
                                                paginationPerPage={10}
                                                paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                                                paginationComponentOptions={{
                                                    rowsPerPageText: 'Rows per page:'
                                                }}
                                                paginationResetDefaultPage={true}
                                                paginationTotalRows={users.length}
                                                // expandableRows
                                                selectableRows
                                                onSelectedRowsChange={(selectedRows) => console.log(selectedRows)}
                                                customStyles={{
                                                    headRow: {
                                                        style: {
                                                            backgroundColor: '#279e6c', // Green background
                                                            color: '#fff', // White text
                                                            textTransform: 'uppercase', // Uppercase text
                                                            // fontSize: '10px', // Adjust font size for header row
                                                            minHeight: '1.5rem',
                                                            paddingTop: '8px',
                                                            paddingBottom: '8px'
                                                        }
                                                    }
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default SamplePage;
