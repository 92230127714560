import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { usePermissions } from '../../permissions';
import { BASE_URL } from '../../config/constant';

const RoleBasedGuard = ({ children }) => {
    const { filteredNavigation } = usePermissions();
    const location = useLocation();
    const currentPath = location.pathname;
    
    const flattenRoutes = (items, parentPath = '') => {
        return items.reduce((acc, item) => {
            const fullPath = `${parentPath}${item.path || ''}`;
            if (item.children) {
                acc.push({ ...item, path: fullPath });
                acc.push(...flattenRoutes(item.children, fullPath));
            } else {
                acc.push({ ...item, path: fullPath });
            }
            return acc;
        }, []);
    };

    const allRoutes = flattenRoutes(filteredNavigation);
    const currentRoute = allRoutes.find((route) => route.url === currentPath);

    const viewPermission = currentRoute ? currentRoute.permissions.view_permission : false;

    // const lastLocation = localStorage.getItem('lastLocation') || BASE_URL;
    const lastLocation = BASE_URL;

    if (!viewPermission) {
        return <Redirect to={lastLocation} />;
    }

    const childWithProps = React.cloneElement(children, {
        viewPermission,
    });

    return childWithProps;
    
};

export default RoleBasedGuard;
