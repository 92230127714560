import React, {useState, useEffect, useRef} from 'react';
import config from '../../config';
import Card from '../../components/Card/MainCard';
import { Col, Row,Alert } from 'react-bootstrap';
import axios from 'axios';

const avatarImage = '/assets/farm-logo.jpg';

const Receipt = ({ transactionId }) => {
  const [receiptData, setReceiptData] = useState(null);
  const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
  const apiUrl = config[environment].apiUrl;
  const printContentRef = useRef(null);

  const handlePrint = () => {
    const printContent = printContentRef.current.innerHTML;
    const originalContent = document.body.innerHTML;

    document.body.innerHTML = printContent;
    window.print();
    document.body.innerHTML = originalContent;
  };

  useEffect(() => {
    console.log("Transaction ID:", transactionId);
    console.log("Received Transaction ID in Receipt component:", transactionId);
  
    const fetchReceiptData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/emergency_vaccination_receipt/${transactionId}`);
        const { receiptData } = response.data;
        setReceiptData(receiptData);
        console.log(receiptData);
      } catch (error) {
        console.error('Error retrieving receipt data:', error);
      }
    };
  
    if (transactionId) {
      fetchReceiptData();
    }
  }, [transactionId]);
  

  if (!receiptData) {
    return <div>Loading receipt...</div>;
  }

  return (

    <div className="receipt" id="receipt-section" ref={printContentRef}>
      <Col sm={12} md={12} className='border-bottom'>
                  <div className="row d-flex align-items-center m-b-10">
                    <div className="col-4">
                      <img src={avatarImage} alt='Comapny Logo' style={{ width: '100px', height: 'auto', align: 'center' }} />
                    </div>
                    <div className="col-4">
                    </div>
                    <div className="col-4">
                      {/* <div>SFARMS.CO.KE</div> */}
                      <div>P.O BOX 800-222</div>
                      <div>Naivasha</div>
                      <div>Phone : 254-787-689-760</div>
                      <div>Email : info@sfarms.co.ke</div>
                    </div>
                  </div>
                </Col>
      <h4 className="receipt-heading"><Alert variant='success'>SFARMS EMERGENCY VACCINATION RECEIPT</Alert></h4>
      <h4 className="receipt-heading">RECEIPT: #{receiptData.transactionId}</h4>
      <p className="receipt-heading">DATE/TIME: {new Date(receiptData.createdAt).toLocaleString()}</p>
      {receiptData.vetenary && (
      <div className="receipt-item">
        <span className="receipt-label">Vetenary:</span>
        <span className="receipt-value">{receiptData.vetenary}</span>
      </div>
      )}
      {receiptData.animal && (
      <div className="receipt-item">
        <span className="receipt-label">Animal(s):</span>
        <span className="receipt-value">{receiptData.animal}</span>
      </div>
      )}
      {receiptData.transactionId && (
      <div className="receipt-item">
        <span className="receipt-label">Transaction ID :</span>
        <span className="receipt-value">{receiptData.transactionId}</span>
      </div>
      )}
      {receiptData.createdAt && (
      <div className="receipt-item">
        <span className="receipt-label">Date / Time :</span>
        <span className="receipt-value">{new Date(receiptData.createdAt).toLocaleString()}</span>
      </div>
      )}
      <div className="receipt-item">
        <span className="receipt-label">Remarks :</span>
      </div>
      {receiptData.remarks && (
      <div className="receipt-item">
        <span className="receipt-label">{receiptData.remarks}</span>
      </div>
       )}
     
      <hr/>

      <div className="receipt-footer">
        <span className="receipt-label">Total Cost: </span>
        <span className="receipt-value">Ksh. {receiptData.totalCost.toLocaleString()}</span>
      </div>
      <div className="receipt-footer">
        <span className="receipt-label">Served By: </span>
        <span className="receipt-value">{receiptData.servedBy}</span>
      </div>
      <div className="receipt-footer">
        <h4>Welcome Again</h4>
      </div>
    </div>
    
  );
};

export default Receipt;
