import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import config from './../../../config';
import axios from 'axios';
import Card from '../../../components/Card/MainCard';

const avatarImage = '/assets/farm-logo.jpg';

const UpdateUser = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [user, setUser] = useState({});
  const { id } = useParams();
  const history = useHistory();
  const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
  const apiUrl = config[environment].apiUrl;
  const [pdfBlob, setPdfBlob] = useState(null);
  const [expenses, setExpenses] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/spend/${id}`);
        setExpenses(response.data);
        console.log(response.data)
      } catch (error) {
        console.error('Error fetching expenses data:', error);
      }
    };

    fetchData();
  }, [id, apiUrl]);

  const generatePdf = () => {
    const blob = new Blob([<PDFContent id={id} />], { type: 'application/pdf' });
    setPdfBlob(blob);
  };

  

  return (
    <React.Fragment>

      <Row>
        <Col sm={12}>
        <Link to="/expenditure">
            <Button variant="primary" className="mb-3 mt-2">
              <span className='feather icon-arrow-left'></span>  Go Back
            </Button>
          </Link>
          <Card title="View Transaction" isOption>
              <Row>
                <Col sm={12} md={12} className='border-bottom'>
                    <div className="row d-flex align-items-center m-b-10">
                        <div className="col-12">
                            <div className="pdf-button-container">
                                <Button onClick={generatePdf} className="no-pdf">
                                  <i className="feather icon-printer f-40 text-c-white" />
                                </Button>
                                {pdfBlob && (
                                    <PDFDownloadLink
                                    document={<PDFContent id={id} user={user} />}
                                    fileName={`transaction_${id}.pdf`}
                                    >
                                    {({ blob, url, loading, error }) =>
                                        loading ? 'Loading...' : 'Print PDF'
                                    }
                                    </PDFDownloadLink>
                                )}
                                
                            </div>
                        </div>
                    </div>
                </Col>
                <Col sm={12} md={12} className='border-bottom'>
                  <div className="row d-flex align-items-center m-b-10">
                    <div className="col-4">
                      <img src={avatarImage} alt='Comapny Logo' style={{ width: '100px', height: 'auto', align: 'center' }} />
                    </div>
                    <div className="col-4">
                    </div>
                    <div className="col-4">
                      {/* <div>SFARMS.CO.KE</div> */}
                      <div>P.O BOX 800-222</div>
                      <div>Naivasha</div>
                      <div>Phone : 254-787-689-760</div>
                      <div>Email : info@sfarms.co.ke</div>
                    </div>
                  </div>
                </Col>
                <Col sm={12} md={12} className='border-bottom'>
                    <div className="row d-flex align-items-center m-t-20 m-b-20">
                        <div className="col-6">
                            <h6 className="d-block text-uppercase m-1">SFARMS Company</h6>
                            <p className='m-1'>P.O BOX 800-222</p>
                            <p className='m-1'>Naivasha,</p>
                            <p className='m-1'>Phone : 254-787-689-760</p>
                            <p className='m-1'>Email : info@sfarms.co.ke</p>
                        </div>

                        <div className="col-6 text-right">
                            <p className='m-1'>{user.bankAccount}</p>
                            <p className='m-1'>{user.coInput}</p>
                            <p className='m-1'>{user.method}</p>
                        </div>
                    </div>
                </Col>
                <Col sm={12} md={12} className='border-bottom'>
                    <div className="row d-flex align-items-center m-t-20 m-b-20">
                        <div className="col-6">
                            <p className='m-l-5 m-t-10 m-b-10'>Amount : Ksh {user.amount}</p>
                            <p className='m-l-5 m-t-10 m-b-10'>Type : {user.type}</p>
                        </div>

                        <div className="col-6 text-right">
                            <p className='m-t-10 m-b-10'>Date : {new Date(user.createdAt).toLocaleString()}</p>
                            <p className='m-t-10 m-b-10'>#ID : {user.transactionId}</p>
                       
                        </div>
                    </div>
                </Col>
                <Col sm={12} md={12}>
                  <div className="row d-flex align-items-center m-t-20 m-b-20">
                        <div className="col-12">
                            <p className="text-uppercase m-1">Notes : {user.notes}</p>
                        </div>
                    </div>
                </Col>
              </Row>
          </Card>
        </Col>
      </Row>      
    </React.Fragment>
  );
};

const PDFContent = ({ id, user }) => (
    <Document>
      <Page style={styles.page} size="A5" orientation="landscape">
        <View style={styles.section}>
          <Text style={styles.transactionId}>Transaction Details ID : #{id}</Text>
          
          <View style={styles.row}>
            <View style={styles.column}>
              <Text>Date : {new Date(user.createdAt).toLocaleString()}</Text>
            </View>
            <View style={styles.column}>
              <Text>Transaction ID : {user.transactionId}</Text>
            </View>
            <View style={styles.column}>
              <Text>{user.coInput}</Text>
              <Text>{user.method}</Text>
            </View>
          </View>
          
          <View style={styles.row}>
            <View style={styles.column}>
              <Text>Address :</Text>
              <Text>P.O BOX 800-222</Text>
              <Text>Naivasha</Text>
            </View>
            <View style={styles.column}>
              <Text>Phone : 254-787-689-760</Text>
              <Text>Email : info@sfarms.co.ke</Text>
            </View>
            <View style={styles.column}>
              <Text>{user.bankAccount}</Text>
              <Text>Amount : Ksh {user.amount.toLocaleString()}</Text>
              <Text>Type : {user.type}</Text>
            </View>
          </View>
          
          <Text style={styles.notes}>Notes: {user.notes}</Text>
        </View>
      </Page>
    </Document>
  );
  
  const styles = StyleSheet.create({
    page: {
        fontSize: 9,
        flexDirection: 'row',
        backgroundColor: '#ffffff',
        width: '100%',
        marginTop: 50,
        height: '20px',
    },
    section: {
      margin: 50,
      padding: 10,
      flexGrow: 1,
    },
    transactionId: {
      fontSize: 12,
      fontWeight: 'bold',
      marginBottom: 10,
      borderBottom: '0.5px solid grey',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 10,
    },
    column: {
      width: '50%',
    },
    notes: {
      marginTop: 20,
      fontSize: 10,
    },
  });
  
export default UpdateUser;
