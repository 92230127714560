import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Alert, Button, Tabs, Tab } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import Card from './../../components/Card/MainCard';
import axios from 'axios';
import config from '../../config';
import ConfirmationModal from '../ui-elements/basic/ConfirmationModal';
import { usePermissions } from '../../permissions';
import PigletsUpdate from './FormsElements01';
import PigsUpdate from './FormsElements02';

const FormsElements = () => {
    const account = useSelector((state) => state.account);
    const [data, setData] = useState({});
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [selectedTag, setSelectedTag] = useState(null);
    const [tagOptions, setTagOptions] = useState([]);
    const [selectedTagM, setSelectedTagM] = useState(null);
    const [tagMOptions, setTagMOptions] = useState([]);
    const formRef = useRef(null);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [farmOptions, setFarmOptions] = useState([]);
    const [farm, setSelectedFarm] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showConfirmationModal1, setShowConfirmationModal1] = useState(false);
    const { createPermission, deletePermission, editPermission } = usePermissions();

    const memoizedApiUrl1 = useMemo(() => {
        return `${apiUrl}/farm`;
    }, [apiUrl]);

    const memoizedApiUrl2 = useMemo(() => {
        return `${apiUrl}/accounts`;
    }, [apiUrl]);

    const memoizedApiUrl3 = useMemo(() => {
        return `${apiUrl}/suppliers`;
    }, [apiUrl]);

    useEffect(() => {
        const fetchFarms = async () => {
            try {
                const response = await axios.get(memoizedApiUrl1);
                const farmData = response.data;
                const options = farmData.map((farm) => ({
                    value: farm.name,
                    label: farm.name
                }));

                setFarmOptions(options);
            } catch (error) {
                console.error('Error fetching Farms:', error);
            }
        };

        fetchFarms();
    }, [memoizedApiUrl1]);

    const handleInpute = (fieldName, value) => {
        if (fieldName === 'farm') {
            setSelectedFarm(value);
            setData((prevData) => ({ ...prevData, [fieldName]: value }));
        } else {
            setData((prevData) => ({ ...prevData, [fieldName]: value }));
        }
        console.log(data);
    };

    const handleInput = (fieldName, value) => {
        setData((prevData) => ({ ...prevData, [fieldName]: value, userName: account.user.firstname, userId: account.user.id }));
        console.log(data);
    };

    const handleInputs = (fieldName, value) => {
        if (fieldName === 'tagNumber') {
            setSelectedTag(value);
            const tagNumber = value ? value.map((option) => option.value) : [];
            setData((prevData) => ({ ...prevData, [fieldName]: value, tagNumber }));
        } else {
            setData((prevData) => ({ ...prevData, [fieldName]: value }));
        }
        console.log(data);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setShowConfirmationModal1(true);
    };

    const handleConfirmation1 = async (e) => {
        if (!createPermission) {
            return;
        }

        axios
            .post(`${apiUrl}/pigs/update`, data)
            .then((response) => {
                if (response.data) {
                    setSuccess(`${data.tagNumber.lenght} dead Pig(s) Updated successfully`);
                    setTimeout(() => {
                        setSuccess(null);
                    }, 10000);
                    toast.success(`${data.tagNumber.lenght} dead Pig(s) Updated successfully`);
                    setData({});
                    formRef.current.reset();
                    setSelectedTag(null);
                    setShowConfirmationModal1(false);
                }
            })
            .catch((error) => {
                setError(error.message);
                toast.error(error.message);
                console.log(error);
                setShowConfirmationModal1(false);
            });
    };

    const handlePiglets = async (e) => {
        e.preventDefault();
        setShowConfirmationModal(true);
    };

    const handleConfirmation = async (e) => {
        if (!createPermission) {
            return;
        }

        axios
            .post(`${apiUrl}/piglets/update`, data)
            .then((response) => {
                if (response.data) {
                    setSuccess(`Piglets of ${data.tagNumber} Updated successfully`);
                    setTimeout(() => {
                        setSuccess(null);
                    }, 10000);
                    toast.success(`Piglets of ${data.tagNumber} Updated successfully`);
                    setData({});
                    formRef.current.reset();
                    setSelectedTagM(null);
                    setShowConfirmationModal(false);
                }
                console.log(response);
            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    setError(`${error.response.data.message}`);
                    toast.error(`${error.response.data.message}`);
                    setShowConfirmationModal(false);
                } else {
                    setError('An error occurred. Please try again later.');
                    setShowConfirmationModal(false);
                }
                console.log(error);
                setShowConfirmationModal(false);
            });
    };

    useEffect(() => {
        const fetchTags = async () => {
            try {
                const response = await axios.get(`${apiUrl}/pigs`);
                const data = response.data;
                const filteredData = data.filter((tag) => tag.farm === farm);
                const options = filteredData.map((tag) => ({
                    value: tag.tagNumber,
                    label: tag.tagNumber
                }));
                setTagOptions(options);
            } catch (error) {
                console.error('Error fetching Tags:', error);
            }
        };

        fetchTags();
    }, [farm, tagOptions, apiUrl]);

    useEffect(() => {
        const fetchTags = async () => {
            try {
                const response = await axios.get(`${apiUrl}/piglets_sired`);
                const data = response.data;
                const filteredData = data.filter((tag) => tag.farm === farm);
                const options = filteredData.map((tag) => ({
                    value: tag.tagNumber,
                    label: tag.tagNumber
                }));
                setTagMOptions(options);
            } catch (error) {
                console.error('Error fetching Tags:', error);
            }
        };

        fetchTags();
    }, [farm, apiUrl, tagMOptions]);

    const handleCancelConfirmation = () => {
        // Handle cancel action if needed
        setShowConfirmationModal(false);
    };

    const handleCancelConfirmation1 = () => {
        // Handle cancel action if needed
        setShowConfirmationModal1(false);
    };

    return (
        <React.Fragment>
            <Tabs defaultActiveKey="feeds">
                <Tab eventKey="feeds" title="Piglets" className="mt-4"><PigletsUpdate/></Tab>
                <Tab eventKey="pigs" title="Pigs" className="mt-4"><PigsUpdate/></Tab>
        </Tabs>
        </React.Fragment>
    );
};

export default FormsElements;
