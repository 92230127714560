import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Table, Button, Pagination, Form  } from 'react-bootstrap';
import Select from 'react-select';
import { CSVLink } from 'react-csv';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import axios from 'axios';
import config from '../../config';
import Cus from './SamplePage0131';
import { useParams, useHistory } from 'react-router-dom';
import Card from '../../components/Card/MainCard';

const UserList = () => {
  const account = useSelector((state) => state.account);
  const [users, setUsers] = useState([]);
  const { id } = useParams();
  const history = useHistory();
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [csvData, setCSVData] = useState([]);
  const [pdfData, setPDFData] = useState(new jsPDF());
  const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
  const apiUrl = config[environment].apiUrl;
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(10);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/mpesa_payments`);
        setUsers(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [users, apiUrl]);

  const handleDelete = async (userId, name) => {
    try {
      const confirmed = window.confirm(`Are you sure you want to delete this customer ${name}?`);
      if (confirmed) {
        await axios.delete(`${apiUrl}/customer/${userId}`);
        alert('Customer deleted successfully!');
        window.location.reload();
      }
    } catch (error) {
      alert(error.message);
    }
  };

   // Calculate index of the last user on the current page
   const indexOfLastUser = currentPage * usersPerPage;
   // Calculate index of the first user on the current page
   const indexOfFirstUser = indexOfLastUser - usersPerPage;
   // Get the current users to display on the current page
   const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);
 
   const paginate = (pageNumber) => setCurrentPage(pageNumber);
 
   const handlePerPageChange = (selectedOption) => {
     setUsersPerPage(selectedOption.value);
     setCurrentPage(1); // Reset to the first page when changing the number of users per page
   };
  const options = [
    { value: 5, label: '5' },
    { value: 10, label: '10' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 150, label: '150' },
    { value: 200, label: '200' }
  ];

  const totalPages = Math.ceil(users.length / usersPerPage);

  const handleUpdate = (userId) => {
    const userToUpdate = users.find((user) => user.id === userId);
    history.push(`customer/update-customer/${userId}`, { user: userToUpdate });
  };

  const calculateAge = (birthDate) => {
    const today = new Date();
    const birth = new Date(birthDate);
  
    // Get the UTC values for year, month, and day
    const todayUTC = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
    const birthUTC = Date.UTC(birth.getFullYear(), birth.getMonth(), birth.getDate());
  
    // Calculate the age difference in milliseconds
    const ageDiffMillis = todayUTC - birthUTC;
  
    // Convert milliseconds to years, months, and days
    const yearDiff = Math.floor(ageDiffMillis / (1000 * 60 * 60 * 24 * 365));
    const monthDiff = Math.floor((ageDiffMillis % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30));
    const dayDiff = Math.floor((ageDiffMillis % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24));
  
    if (yearDiff > 0) {
      return yearDiff === 1 ? `${yearDiff} year ago` : `${yearDiff} years ago`;
    } else if (monthDiff > 0) {
      return monthDiff === 1 ? `${monthDiff} month ago` : `${monthDiff} months ago`;
    } else {
      return dayDiff === 1 ? `${dayDiff} day ago` : `${dayDiff} days ago`;
    }
  };

  const hidePopup = () => {
    setPopupVisible(false);
  };

  const showPopup = () => {
    setPopupVisible(true);
  };

  const csvLinkRef = useRef();

  const exportToCSV = () => {
    const csvData = [
        ['Receipt', 'Phone No', 'Amount', 'Date/Time'],
        ...currentUsers.map((item) => [item.mpesaReceiptNumber, item.phoneNumber, item.amount, item.transactionDate]),
      ];
  
      const csvContent = csvData.map((row) => row.join(',')).join('\n');
      const blob = new Blob(['\ufeff', csvContent], { type: 'text/csv;charset=utf-8;' });
  
      const csvUrl = URL.createObjectURL(blob);
      csvLinkRef.current.href = csvUrl;
      csvLinkRef.current.download = 'mpesa_payments.csv';
      csvLinkRef.current.click();
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [['Receipt', 'Phone No.', 'Amount', 'Date/Time']],
      body: currentUsers.map((user) => [
        user.mpesaReceiptNumber,
        user.phoneNumber,
        parseFloat(user.amount).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "short",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }).format(new Date(user.transactionDate)),
      ]),
    });
    const pdfBlob = doc.output('blob');
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl);
  };

  return (
    <React.Fragment>
      <Row className="btn-page">
        <Col>
        <div className="d-flex justify-content-between align-items-center m-b-10">
          
           <img src="/assets/M-PESA_LOGO.png" alt="Mpesa Logo" style={{ width: '100px', height: 'auto', align: 'center' }} />
          
          <Button onClick={showPopup} className='mb-10'><span className='feather icon-plus-circle'></span> Mpesa Pay</Button>
            {isPopupVisible && (
                <Cus
                onCancel={hidePopup}
                />
            )}
        </div>
        
          <Card title="Payments" isOption>
          
            <Table responsive hover striped bordered>
              <thead variant="dark" className="bg-c-blue border-bottom text-uppercase">
                <tr variant="dark">
                  <th>receipt</th>
                  {/* <th>Name</th>
                  <th>Email</th> */}
                  <th>Phone No.</th>
                  <th>Amount</th>
                  <th>Date/Time</th>
                  <th>Purpose</th>
                  {/* {account.user.is_admin === 'Admin' && (
                  <th>ACtions</th>
                  )} */}
                </tr>
              </thead>
              <tbody>
              {currentUsers.map((user) => (
                  <tr key={user.id}>
                    <td scope="row">
                      {/* <Link to={`details/${user.id}`}> */}
                        {user.mpesaReceiptNumber}
                      {/* </Link> */}
                    </td>
                    {/* <td>{user.name}</td>
                    <td>{user.email}</td> */}
                    <td>{user.phoneNumber}</td>
                    <td>
                      {parseFloat(user.amount).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </td>
                    <td>
                      {new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(new Date(user.transactionDate))}
                    </td>
                    <td>{user.originalPurpose}</td>
                    {/* {account.user.is_admin === 'Admin' ? (
                    <td>
                      <Button size="sm" onClick={() => handleUpdate(user.id)}>
                        <span className='feather icon-edit'></span> Edit
                      </Button>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => handleDelete(user.id, user.mpesaReceiptNumber)}
                      >
                        <span className='feather icon-delete'></span> Delete
                      </Button>
                    </td>
                    ):(null)
                  } */}
                  </tr>
                ))}
              </tbody>
            </Table>
            {/* Pagination component */}
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex" >
              <Pagination>
                <Pagination.Prev
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                />
                {Array.from({ length: totalPages }).map((_, index) => (
                  <Pagination.Item
                    key={index + 1}
                    active={index + 1 === currentPage}
                    onClick={() => paginate(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage === totalPages}
                />
              </Pagination>
            <Select
              options={options}
              value={{ value: usersPerPage, label: `${usersPerPage}` }}
              onChange={handlePerPageChange}
            /></div>
            <div className="d-flex" >

            <Button onClick={exportToCSV}>Export as CSV</Button>
            <a ref={csvLinkRef} style={{ display: 'none' }} />
              <a
                href="#"
                className="btn btn-primary"
                onClick={exportToPDF}
              >
                Export PDF
              </a>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UserList;
