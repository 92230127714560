import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Container, Col, Row, Alert } from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import config from './../../../config';
import Card from '../../../components/Card/MainCard';
import { useHistory, Redirect } from 'react-router-dom';

const SalaryUpdateForm = ({ onUpdate }) => {
  const { salaryId } = useParams();
  const history = useHistory();
  const [formData, setFormData] = useState({
    employee: '',
    salaryAmount: 0,
    nhifDeduction: 0,
    nssfDeduction: 0,
    otherDeduction: 0,
    payableSalary: 0,
  });
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const formRef = useRef(null);
  const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
  const apiUrl = config[environment].apiUrl;


  const calculatePayableSalary = () => {
    const {
      salaryAmount,
      nhifDeduction,
      nssfDeduction,
      otherDeduction,
    } = formData;

    const totalNhfifDeduction = parseFloat(nhifDeduction) || 0;
    const totalNssfDeduction = parseFloat(nssfDeduction) || 0;
    const totalOtherDeduction = parseFloat(otherDeduction) || 0;
    const totalDeductions = totalNhfifDeduction + totalNssfDeduction + totalOtherDeduction;
    const salary = parseFloat(salaryAmount);
    const payable = salary - totalDeductions;

    setFormData({
      ...formData,
      payableSalary: payable.toFixed(2),
    });
  };
  
  useEffect(() => {
    calculatePayableSalary();
  }, [formData.salaryAmount, formData.nhifDeduction, formData.nssfDeduction, formData.otherDeduction]);

  useEffect(() => {
    axios.get(`${apiUrl}/employees_salary/${salaryId}`)
      .then((response) => {
        const userSalary = response.data[0];
        setFormData(userSalary);
      })
      .catch((error) => {
        console.error('Error fetching user salary information:', error);
        setError('Error fetching user salary information:', error);
        toast.error('Error fetching user salary information:', error);
      });
  }, [salaryId]);
  

  const handleSubmit = (e) => {
    e.preventDefault();

    const updatedData = {
      ...formData
    };

    axios.put(`${apiUrl}/employees_salary/${salaryId}`, updatedData)
      .then((response) => {
        console.log('Salary updated successfully:', response.data);
        setSuccess('Salary updated successfully');
        setTimeout(() => {
          setSuccess(null);
        }, 5000);
        console.log(response)
      })
      .catch((error) => {
        console.error('Error updating salary:', error);
        setError('Error updating salary:', error);
      });
  };

  const handleNhifDeductionChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: parseFloat(value) || 0,
    });
  };

  const handleNssfDeductionChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: parseFloat(value) || 0,
    });
  };

  const handleOtherDeductionChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: parseFloat(value) || 0,
    });
  };

  return (
    <>
      <Row>
        <Col sm={12}>
          {success && <Alert variant="success" onClose={() => setSuccess(null)} dismissible>{success}</Alert>}
          {error && <Alert variant="danger" onClose={() => setError(null)} dismissible>{error}</Alert>}
          <ToastContainer />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Link to="/users/payroll">
            <Button variant="primary" className="mb-3 mt-2">
              <span className='feather icon-arrow-left'></span> Go Back
            </Button>
          </Link>
          <Card title="Edit" isOption>
            <Container>
              <Form onSubmit={handleSubmit} className="mt-4" ref={formRef}>
                <Form.Group as={Row}>
                  <Form.Label column sm={3}>
                    Employee:
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      name="employee"
                      value={formData.employee || ''}
                      onChange={() => { }}
                      disabled
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={3} className=''>
                    Salary Amount:
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="number"
                      name="salaryAmount"
                      value={formData.salaryAmount}
                      onChange={handleNhifDeductionChange}
                      required
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={3} className=''>
                    NHIF Deduction:
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="number"
                      name="nhifDeduction"
                      value={formData.nhifDeduction}
                      onChange={handleNhifDeductionChange}
                      required
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={3} className=''>
                    NSSF Deduction:
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="number"
                      name="nssfDeduction"
                      value={formData.nssfDeduction}
                      onChange={handleNssfDeductionChange}
                      required
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={3} className=''>
                    Other Deduction:
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="number"
                      name="otherDeduction"
                      value={formData.otherDeduction}
                      onChange={handleOtherDeductionChange}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={3} className=''>
                    Payable Salary:
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      name="payableSalary"
                      value={formData.payableSalary !== null ? formData.payableSalary.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''}
                      readOnly
                    />
                  </Col>
                </Form.Group>
                <Button variant="primary" type="submit">
                  Update
                </Button>
              </Form>
            </Container>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SalaryUpdateForm;
