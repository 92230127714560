import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { CSVLink } from 'react-csv';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { Row, Col, Form, Button, Table, Alert, Tabs, Tab, Modal, Pagination} from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Font, PDFViewer  } from '@react-pdf/renderer';
import Card from '../../../components/Card/MainCard';
import config from '../../../config';
// import Receipt from './GeneralReceipt01.js';
import { Link, useHistory, useParams } from 'react-router-dom';

// Register a custom font (optional)
Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
});

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Roboto',
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  heading: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  label: {
    fontSize: 12,
    marginBottom: 3,
  },
  value: {
    fontSize: 12,
  },
  footer: {
    fontSize: 12,
    marginTop: 10,
    textAlign: 'center',
  },
});

const ReceiptPDF = ({ receiptData, onDownloadButtonClick }) => {
  return (
    <PDFViewer style={{ width: '100%', height: '100vh' }}>
      <Document>
        <Page style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.heading}>SFARMS SALES RECEIPT</Text>
            <Text style={styles.heading}>RECEIPT: #{receiptData.transactionId}</Text>
            <Text style={styles.heading}>DATE/TIME: {new Date(receiptData.createdAt).toLocaleString()}</Text>
            {receiptData.cus_name && (
              <View>
                <Text style={styles.label}>Customer:</Text>
                <Text style={styles.value}>{receiptData.cus_name}</Text>
              </View>
            )}
            {receiptData.birds && (
              <View>
                <Text style={styles.label}>No. of Birds:</Text>
                <Text style={styles.value}>{receiptData.birds}</Text>
              </View>
            )}
            {receiptData.trays && (
              <View>
                <Text style={styles.label}>No. of Trays:</Text>
                <Text style={styles.label}>{receiptData.trays}</Text>
              </View>
            )}
            {receiptData.eggs && (
              <View>
                <Text style={styles.label}>No. of Eggs:</Text>
                <Text style={styles.label}>{receiptData.eggs}</Text>
              </View>
            )}
            {receiptData.tagNumber && (
              <View>
                <Text style={styles.label}>Tag No.:</Text>
                <Text style={styles.value}>{receiptData.tagNumber}</Text>
              </View>
            )}
            {receiptData.goats && (
              <View>
                <Text style={styles.label}>No. of Goats:</Text>
                <Text style={styles.value}>{receiptData.goats}</Text>
              </View>
            )}
            {receiptData.pigs && (
              <View>
                <Text style={styles.label}>No. of Pigs:</Text>
                <Text style={styles.value}>{receiptData.pigs}</Text>
              </View>
            )}
            {receiptData.price && (
              <View>
                <Text style={styles.label}>Price:</Text>
                <Text style={styles.value}>Ksh. {receiptData.price.toLocaleString()}</Text>
              </View>
            )}
            {receiptData.pay_method && (
              <View>
                <Text style={styles.label}>Payment Mode:</Text>
                <Text style={styles.value}>{receiptData.pay_method}</Text>
              </View>
            )}
            {receiptData.delivery && (
              <View>
                <Text style={styles.label}>Delivery Mode:</Text>
                <Text style={styles.value}>{receiptData.delivery}</Text>
              </View>
            )}
            {receiptData.del_guy && (
              <View>
                <Text style={styles.label}>Delivery Guy:</Text>
                <Text style={styles.value}>{receiptData.del_guy}</Text>
              </View>
            )}
            {receiptData.pay_reference && (
              <View>
                <Text style={styles.label}>Payment Reference:</Text>
                <Text style={styles.value}>{receiptData.pay_reference}</Text>
              </View>
            )}
            {receiptData.transactionId && (
              <View>
                <Text style={styles.label}>Transaction ID :</Text>
                <Text style={styles.value}>{receiptData.transactionId}</Text>
              </View>
            )}
            {receiptData.createdAt && (
              <View>
                <Text style={styles.label}>Date / Time :</Text>
                <Text style={styles.value}>{new Date(receiptData.createdAt).toLocaleString()}</Text>
              </View>
            )}
            <View style={styles.footer}>
              <Text>Welcome Again</Text>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

const TransactionHistory = () => {
  const { selectedFarm } = useParams();
  const [error, setError] = useState(null);
  const formRef = useRef(null);
  const history = useHistory();
  const [success, setSuccess] = useState(null);
  const [transactionData, setTransactionData] = useState([]);
  const [showReceipt, setShowReceipt] = useState(false);
  const [showReceiptModal, setShowReceiptModal] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(10);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
  const apiUrl = config[environment].apiUrl;
  const [receiptData, setReceiptData] = useState(null);

  
  const handleDownloadButtonClick = (fileUrl) => {
    axios
      .get(fileUrl, { responseType: 'blob' })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileUrl.split('/').pop());
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error('Error downloading file:', error);
        toast.error('Error downloading file.');
      });
  };
  
  useEffect(() => {
    const fetchTransactionData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/animal-acquisition-history?farm=${selectedFarm}`);
        setTransactionData(response.data);
        toast.success('Transaction history fetched successfully.');
      } catch (error) {
        console.error('Error fetching transaction history:', error);
        toast.error('Error fetching transaction history.');
      }
    };

    fetchTransactionData();
  }, [apiUrl, selectedFarm]);

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currenttransactionData = transactionData.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePerPageChange = (selectedOption) => {
    setUsersPerPage(selectedOption.value);
    setCurrentPage(1); 
  };

 const options = [
   { value: 5, label: '5' },
   { value: 10, label: '10' },
   { value: 25, label: '25' },
   { value: 50, label: '50' },
   { value: 150, label: '150' },
   { value: 200, label: '200' },
   { value: 500, label: '500' },
 ];

 const totalPages = Math.ceil(transactionData.length / usersPerPage);

 const csvLinkRef = useRef();

 const exportToCSV = () => {
   const csvData = [
       ['Description','Supplier', 'Amount', 'Quantity', 'Date','Farm'],
       ...transactionData.map((item) => [item.description, item.supplier, item.amount, item.quantity, item.createdAt, item.farm]),
     ];
 
     const csvContent = csvData.map((row) => row.join(',')).join('\n');
     const blob = new Blob(['\ufeff', csvContent], { type: 'text/csv;charset=utf-8;' });
 
     const csvUrl = URL.createObjectURL(blob);
     csvLinkRef.current.href = csvUrl;
     csvLinkRef.current.download = 'sfarms.csv';
     csvLinkRef.current.click();
 };

 const exportToPDF = () => {
   const doc = new jsPDF();
   doc.autoTable({
     head: [['Description','Supplier', 'Amount', 'Quantity', 'Date','Farm']],
     body: transactionData.map((user) => [
       user.description,
       user.supplier,
       user.amount,
       user.quantity,  
       new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "short",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }).format(new Date(user.createdAt)),
     ]),
   });
   const pdfBlob = doc.output('blob');
   const pdfUrl = URL.createObjectURL(pdfBlob);
   window.open(pdfUrl);
 };


 const getLabelColorClass = (description) => {
  switch (description) {
    case 'Goats Purchase':
      return 'label-orange';
    case 'Eggs Purchase':
      return 'label-blue';
    case 'Pigs Purchase':
      return 'label-till';
    case 'Poultry Purchase':
      return 'label-brown';
    case 'Broken Eggs':
      return 'label-kink';
    default:
      return '';
  }
};

const handleView = (id, description) => {
  const transactionToUpdate = transactionData.find((transaction) => transaction.id === id);
  history.push(`/reports/animal-acquisition/view/${id}/${description}`, { transaction: transactionToUpdate });
};

const handleViewFile = (image) => {
  window.open(`${apiUrl}/uploads/${image}`, '_blank');
};

  return (
    <React.Fragment>
      <Row>
          <Col sm={12}>
          {success && <Alert variant="success" onClose={() => setSuccess(null)} dismissible >{success}</Alert>}
          {error && <Alert variant="danger" onClose={() => setError(null)} dismissible >{error}</Alert>}
          <ToastContainer />
          </Col>
      </Row>
      <Row>
        <Col>
          <div className="d-flex justify-content-between align-items-center">          
            <div className='pb-3'>
              <Link to="/poultry/new-poultry">
                <Button variant="primary" className="">
                <span className='feather icon-plus-circle'></span>  Purchase Birds
                </Button>
              </Link>
              <Link to="/pigs/add-pig">
                <Button variant="primary" className="">
                <span className='feather icon-plus-circle'></span>  Purchase Pigs
                </Button>
              </Link>
              <Link to="/goats/new-goats">
                <Button variant="primary" className="">
                <span className='feather icon-plus-circle'></span>  Purchase Goats
                </Button>
              </Link>
            </div>
            <div className='pb-3'>                        
            <Link to="/farms/manage">
                            <Button variant="primary" className="">
                                <span className='feather icon-arrow-left'></span>  Go Back
                            </Button>
                            </Link>
            </div>
          </div>       
          <Card title="Acquisition Expenses Summarry" isOption>
            <Row className='table-accounts'>
            <Table striped bordered hover>
              <thead className="bg-c-blue border-bottom text-uppercase">
                <tr>
                  <th>ID</th>
                  <th>Description</th>
                  <th>Supplier</th>
                  <th>Amount</th>
                  <th>Quantity</th>
                  <th>Date / Time</th>
                  <th>Farm</th>
                  <th colSpan={2}>Receipt</th>
                </tr>
              </thead>
              <tbody>
                {currenttransactionData.map((transaction) => (
                  <tr key={transaction.createdAt}>
                    <td>{transaction.id}</td>
                    <td>
                      <span className={getLabelColorClass(transaction.description)}>
                        {transaction.description}
                      </span>
                    </td>
                    <td>{transaction.supplier}</td>
                    <td>Ksh {(transaction.amount).toLocaleString()}</td>
                    <td>{transaction.quantity}</td>
                    <td>{new Date(transaction.createdAt).toLocaleString()}</td>
                    <td>{transaction.farm}</td>
                    <td>
                      <Button onClick={() => handleView(transaction.id, transaction.description)}>
                        <i className="feather icon-eye" />View
                      </Button>
                        {transaction.image && (
                        <Button onClick={() => handleViewFile(transaction.image)}>
                          <i className="feather icon-download-cloud" />Receipt File
                        </Button>
                       )}
                      </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
          <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex" >
              <Pagination>
                <Pagination.Prev
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                />
                {Array.from({ length: totalPages }).map((_, index) => (
                  <Pagination.Item
                    key={index + 1}
                    active={index + 1 === currentPage}
                    onClick={() => paginate(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage === totalPages}
                />
              </Pagination>
            <Select
              options={options}
              value={{ value: usersPerPage, label: `${usersPerPage}` }}
              onChange={handlePerPageChange}
            />
            
          </div>
          <div className="d-flex" >

            <Button onClick={exportToCSV}>Export as CSV</Button>
            <a ref={csvLinkRef} style={{ display: 'none' }} />
              <a
                href="#"
                className="btn btn-primary"
                onClick={exportToPDF}
              >
                Export PDF
              </a>
              </div>
            </div>
          </Card>
          
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default TransactionHistory;
