import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { usePermissions } from '../../../permissions';

const ConfirmationModal = ({ show, onHide, onConfirm }) => {
    const { createPermission, deletePermission, editPermission } = usePermissions();

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to proceed with this action?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                {/* {!createPermission && ( */}
                    <Button variant="primary" onClick={onConfirm}>
                        Confirm
                    </Button>
                {/* )} */}
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmationModal;
