import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Button, Alert, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from './../../../config';
import Card from '../../../components/Card/MainCard';
import axios from 'axios';
import Receipt from './Receipt2';
import ConfirmationModal from './ConfirmationModal';
import { usePermissions } from '../../../permissions';

const SamplePage = ({ onCancel }) => {
    const account = useSelector((state) => state.account);
    const dispatcher = useDispatch();
    const [transactionId, setTransactionId] = useState(null);
    const [data, setData] = useState({});
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [selectedFeed, setSelectedFeed] = useState(null);
    const [feedOptions, setFeedOptions] = useState([]);
    const [vaccineOptions, setVaccineOptions] = useState([]);
    const [selectedVaccine, setSelectedVaccine] = useState(null);
    const [selectedBatch, setSelectedBatch] = useState(null);
    const [batches, setBatches] = useState([]);
    const [selectedType, setSelectedType] = useState('');
    const formRef = useRef(null);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [farmOptions, setFarmOptions] = useState([]);
    const [farm, setSelectedFarm] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const { createPermission, deletePermission, editPermission } = usePermissions();

    const memoizedApiUrl1 = useMemo(() => {
        return `${apiUrl}/farm`;
    }, [apiUrl]);

    useEffect(() => {
        const fetchFarms = async () => {
            try {
                const response = await axios.get(memoizedApiUrl1);
                const farmData = response.data;
                const options = farmData.map((farm) => ({
                    value: farm.name,
                    label: farm.name
                }));

                setFarmOptions(options);
            } catch (error) {
                console.error('Error fetching Farms:', error);
            }
        };

        fetchFarms();
    }, [memoizedApiUrl1]);

    const handleInputs = (fieldName, value) => {
        if (fieldName === 'farm') {
            setSelectedFarm(value);
            setData((prevData) => ({ ...prevData, [fieldName]: value }));
        } else {
            setData((prevData) => ({ ...prevData, [fieldName]: value }));
        }
        console.log(data);
    };

    const handleInput = (fieldName, value) => {
        if (fieldName === 'batchVaccinated') {
            setSelectedFeed(value);
            const batchVaccinated = value ? value.map((option) => option.value) : [];
            setData((prevData) => ({
                ...prevData,
                [fieldName]: value,
                batchVaccinated,
                servedBy: account.user.firstname,
                type: selectedType.value
            }));
        } else {
            setData((prevData) => ({ ...prevData, [fieldName]: value, servedBy: account.user.firstname, userName: account.user.firstname, userId: account.user.id}));
        }
        console.log(data);
    };

    const memoizedApiUrl4 = useMemo(() => {
        return `${apiUrl}/vaccines`;
    }, [apiUrl]);

    useEffect(() => {
        const fetchVaccines = async () => {
            try {
                const response = await axios.get(memoizedApiUrl4);
                const data = response.data;
                // const filteredData = data.filter((tag) => tag.farm === farm);
                const options = data.map((vaccine) => ({
                    value: vaccine.vaccine,
                    label: vaccine.vaccine
                }));
                console.log(options);
                setVaccineOptions(options);
            } catch (error) {
                console.error('Error fetching vaccines:', error);
            }
        };

        fetchVaccines();
    }, [memoizedApiUrl4]);

    const handleEggs = async (e) => {
        e.preventDefault();
        setShowConfirmationModal(true);
    };

    const handleConfirmation = async (e) => {
        if (!createPermission) {
            return;
        }
        axios
            .post(`${apiUrl}/poultry-vaccination`, data)
            .then((response) => {
                if (response.data) {
                    setSuccess(response.data.message);
                    toast.success(response.data.message);
                    setTimeout(() => {
                        setSuccess(null);
                    }, 10000);
                    onCancel();
                    formRef.current.reset();
                    setSelectedType({});
                    setSelectedBatch({});
                    setSelectedVaccine({});
                    setShowConfirmationModal(false);
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    setError(`${error.response.data.message}`);
                    toast.error(`${error.response.data.message}`);
                    setShowConfirmationModal(false);
                } else if (error.response) {
                    toast.error(`Error vaccinating batch ${data.batchVaccinated}`);
                    setError(`Error vaccinating batch ${data.batchVaccinated}`);
                    setShowConfirmationModal(false);
                }
            });
    };

    useEffect(() => {
        // fetchSuppliers();
        fetchAllBatches();
    }, [farm, batches, apiUrl]);

    const fetchAllBatches = async () => {
        try {
            const response = await axios.get(`${apiUrl}/poultry`);
            const data = response.data;

            const filteredData = data.filter((tag) => tag.farm === farm);
            setBatches(filteredData);
        } catch (error) {
            console.error('Error fetching batches:', error);
        }
    };

    const handlePoultryTypeChange = (selectedOption) => {
        setSelectedType(selectedOption);
    };

    const filteredBatches = batches.filter((batch) => selectedType && batch.type === selectedType.value);

    const poultryType = [
        { value: 'Layers', label: 'Layers' },
        { value: 'Broilers', label: 'Broilers' }
    ];

    const handleCancelConfirmation = () => {
        // Handle cancel action if needed
        setShowConfirmationModal(false);
    };

    return (
        <React.Fragment>
            <Modal show={true} onHide={onCancel} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Vaccinate Poultry</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={12}>
                            {success && (
                                <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                                    {success}
                                </Alert>
                            )}
                            {error && (
                                <Alert variant="danger" onClose={() => setError(null)} dismissible>
                                    {error}
                                </Alert>
                            )}
                            <ToastContainer />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form onSubmit={handleEggs} ref={formRef}>
                                <Row>
                                    <Form.Group as={Col} md={4} sm={12} lg={4}>
                                        <Form.Label>Farm</Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Col} md={8} sm={12} lg={8}>
                                        <Select
                                            options={farmOptions}
                                            onChange={(selectedOption) => handleInputs('farm', selectedOption.value)}
                                            isSearchable
                                            name="farm"
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={4} sm={12} lg={4} controlId="formBasic">
                                        <Form.Label>Vaccine Name</Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Col} md={8} sm={12} lg={8} controlId="formBasic">
                                        <Select
                                            value={selectedVaccine}
                                            onChange={(selectedOption) =>
                                                setSelectedVaccine(handleInput('vaccineName', selectedOption.value))
                                            }
                                            options={vaccineOptions}
                                            isSearchable
                                            name="vaccineName"
                                            placeholder="--Select Vaccine--"
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={4} sm={12} lg={4} controlId="formBasic">
                                        <Form.Label>Bird Type</Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Col} md={8} sm={12} lg={8} controlId="formBasic">
                                        <Select
                                            value={selectedType}
                                            onChange={handlePoultryTypeChange}
                                            options={poultryType}
                                            isSearchable
                                            name="type"
                                            placeholder="--Select type-"
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={4} sm={12} lg={4} controlId="formBasic">
                                        <Form.Label>Batch No.</Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Col} md={8} sm={12} lg={8} controlId="formBasic">
                                        <Select
                                            onChange={(selectedOption) => setSelectedBatch(handleInput('batchVaccinated', selectedOption))}
                                            options={filteredBatches.map((batch) => ({
                                                value: batch.batch,
                                                label: batch.batch
                                            }))}
                                            isSearchable
                                            isMulti
                                            type="text"
                                            name="batchVaccinated"
                                            placeholder="-- Select Batch --"
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={4} sm={12} lg={4} controlId="formBasic">
                                        <Form.Label>Vaccine Quantity</Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Col} md={8} sm={12} lg={8} controlId="formBasic">
                                        <Form.Control
                                            size="sm"
                                            onChange={(e) => handleInput('vaccineQuantity', e.target.value)}
                                            name="vaccineQuantity"
                                            required
                                            type="number"
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md={4} sm={12} lg={4} controlId="formBasic">
                                        <Form.Label>Date</Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Col} md={8} sm={12} lg={8} controlId="formBasic">
                                        <Form.Control
                                            size="sm"
                                            onChange={(e) => handleInput('recorded', e.target.value)}
                                            name="recorded"
                                            required
                                            type="date"
                                        />
                                    </Form.Group>

                                    {/* <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                            <Form.Label>Cost Per Kgs/litres </Form.Label>
                            <Form.Control
                                size='sm' 
                                onChange={(e) => handleInput('vaccinePer', e.target.value)}
                                name="vaccinePer"
                                required
                                type="number" 
                            />
                        </Form.Group> */}

                                    {/* <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                            <Form.Label>Vaccine Cost</Form.Label>
                            <Form.Control
                                size='sm' 
                                onChange={(e) => handleInput('vaccineCost', e.target.value)}
                                value={vaccineCost}
                                name="vaccineCost"
                                required
                                type="number" 
                                disabled
                            />
                        </Form.Group> */}
                                </Row>
                                <Modal.Footer>
                                    {createPermission && <Button type="submit">Submit</Button>}
                                    <Button variant="secondary" onClick={onCancel}>
                                        Cancel
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <ConfirmationModal show={showConfirmationModal} onHide={handleCancelConfirmation} onConfirm={handleConfirmation} />
        </React.Fragment>
    );
};

export default SamplePage;
