import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Alert, Button, Tabs, Tab } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import Card from './../../components/Card/MainCard';
import axios from 'axios';
import config from '../../config';
import ConfirmationModal from '../ui-elements/basic/ConfirmationModal';
import { usePermissions } from '../../permissions';

const FormsElements = () => {
    const account = useSelector((state) => state.account);
    const [data, setData] = useState({});
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [totalPig, setTotalPig] = useState(0);
    const [selectedTag, setSelectedTag] = useState(null);
    const [tagOptions, setTagOptions] = useState([]);
    const [selectedTagM, setSelectedTagM] = useState(null);
    const [tagMOptions, setTagMOptions] = useState([]);
    const formRef = useRef(null);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [farmOptions, setFarmOptions] = useState([]);
    const [farm, setSelectedFarm] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showConfirmationModal1, setShowConfirmationModal1] = useState(false);
    const { createPermission, deletePermission, editPermission } = usePermissions();

    const memoizedApiUrl1 = useMemo(() => {
        return `${apiUrl}/farm`;
    }, [apiUrl]);

    const memoizedApiUrl2 = useMemo(() => {
        return `${apiUrl}/accounts`;
    }, [apiUrl]);

    const memoizedApiUrl3 = useMemo(() => {
        return `${apiUrl}/suppliers`;
    }, [apiUrl]);

    useEffect(() => {
        const fetchFarms = async () => {
            try {
                const response = await axios.get(memoizedApiUrl1);
                const farmData = response.data;
                const options = farmData.map((farm) => ({
                    value: farm.name,
                    label: farm.name
                }));

                setFarmOptions(options);
            } catch (error) {
                console.error('Error fetching Farms:', error);
            }
        };

        fetchFarms();
    }, [memoizedApiUrl1]);

    const handleInpute = (fieldName, value) => {
        if (fieldName === 'farm') {
            setSelectedFarm(value);
            setData((prevData) => ({ ...prevData, [fieldName]: value }));
        } else {
            setData((prevData) => ({ ...prevData, [fieldName]: value }));
        }
        console.log(data);
    };

    const handleInput = (fieldName, value) => {
        setData((prevData) => ({ ...prevData, [fieldName]: value, userName: account.user.firstname, userId: account.user.id }));
        console.log(data);
    };

    const handleInputs = (fieldName, value) => {
        if (fieldName === 'tagNumber') {
            setSelectedTag(value);
            const tagNumber = value ? value.map((option) => option.value) : [];
            setData((prevData) => ({ ...prevData, [fieldName]: value, tagNumber }));
        } else {
            setData((prevData) => ({ ...prevData, [fieldName]: value }));
        }
        console.log(data);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setShowConfirmationModal(true);
    };

    const handleConfirmation = async (e) => {
        if (!createPermission) {
            return;
        }

        axios
            .post(`${apiUrl}/goats/update`, data)
            .then((response) => {
                if (response.data) {
                    setSuccess(`${data.tagNumber.lenght} dead Goat(s) Updated successfully`);
                    setTimeout(() => {
                        setSuccess(null);
                    }, 10000);
                    toast.success(`${data.tagNumber.lenght} dead Goat(s) Updated successfully`);
                    setData({});
                    formRef.current.reset();
                    setSelectedTag(null);
                    setShowConfirmationModal(false);
                }
            })
            .catch((error) => {
                setError(error.message);
                toast.error(error.message);
                console.log(error);
                setShowConfirmationModal(false);
            });
    };

    const handlePiglets = async (e) => {
        e.preventDefault();
        setShowConfirmationModal1(true);
    };

    const handleConfirmation1 = async (e) => {
        if (!createPermission) {
            return;
        }
        axios
            .post(`${apiUrl}/kids/update`, data)
            .then((response) => {
                if (response.data) {
                    setSuccess(`Kids of ${data.tagNumber} Updated successfully`);
                    setTimeout(() => {
                        setSuccess(null);
                    }, 10000);
                    toast.success(`Kids of ${data.tagNumber} Updated successfully`);
                    setData({});
                    formRef.current.reset();
                    setSelectedTagM(null);
                    setShowConfirmationModal1(false);
                }
                console.log(response);
            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    setError(`${error.response.data.message}`);
                    toast.error(`${error.response.data.message}`);
                    setShowConfirmationModal1(false);
                } else {
                    setError('An error occurred. Please try again later.');
                    setShowConfirmationModal1(false);
                }
                console.log(error);
                setShowConfirmationModal1(false);
            });
    };

    useEffect(() => {
        const fetchTags = async () => {
            try {
                const response = await axios.get(`${apiUrl}/goats`);
                const data = response.data;
                const filteredData = data.filter((tag) => tag.farm === farm);
                const options = filteredData.map((tag) => ({
                    value: tag.tagNumber,
                    label: tag.tagNumber
                }));
                setTagOptions(options);
            } catch (error) {
                console.error('Error fetching Tags:', error);
            }
        };

        fetchTags();
    }, [farm, apiUrl, tagOptions]);

    useEffect(() => {
        const fetchTags = async () => {
            try {
                const response = await axios.get(`${apiUrl}/kids_sired`);
                const data = response.data;
                const filteredData = data.filter((tag) => tag.farm === farm);
                const options = filteredData.map((tag) => ({
                    value: tag.tagNumber,
                    label: tag.tagNumber
                }));
                setTagMOptions(options);
            } catch (error) {
                console.error('Error fetching Tags:', error);
            }
        };

        fetchTags();
    }, [farm, tagOptions, apiUrl]);

    const handleCancelConfirmation = () => {
        // Handle cancel action if needed
        setShowConfirmationModal(false);
    };

    const handleCancelConfirmation1 = () => {
        // Handle cancel action if needed
        setShowConfirmationModal1(false);
    };

    return (
        <React.Fragment>
            <Row>
                <Col sm={12}>
                    {success && (
                        <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                            {success}
                        </Alert>
                    )}
                    {error && (
                        <Alert variant="danger" onClose={() => setError(null)} dismissible>
                            {error}
                        </Alert>
                    )}
                    <ToastContainer />
                </Col>
            </Row>
            <Tabs defaultActiveKey="feeds">
                <Tab eventKey="feeds" title="Update Goats" className="mt-4">
                    <Row>
                        <Col>
                            <Card title="Update Goats" isOption>
                                <Form onSubmit={handleSubmit} ref={formRef}>
                                    <Row>
                                        <Form.Group as={Col} md={6} sm={12} lg={6}>
                                            <Form.Label>Farm</Form.Label>
                                            <Select
                                                options={farmOptions}
                                                onChange={(selectedOption) => handleInpute('farm', selectedOption.value)}
                                                isSearchable
                                                name="farm"
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                            <Form.Label>Goats Mortality</Form.Label>
                                            <Select
                                                value={selectedTag}
                                                onChange={(selectedOption) => handleInputs('tagNumber', selectedOption)}
                                                options={tagOptions}
                                                isSearchable
                                                isMulti
                                                name="tagNumber"
                                                placeholder="-- Select Tag Number --"
                                                required
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                            <Form.Label>Date</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                required
                                                onChange={(e) => handleInput('recorded', e.target.value)}
                                                type="date"
                                                name="recorded"
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} md={12} sm={12} lg={12} controlId="formBasic">
                                            <Form.Label>Remarks/Notes</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                required
                                                onChange={(e) => handleInputs('remarks', e.target.value)}
                                                type="text"
                                                name="remarks"
                                            />
                                        </Form.Group>
                                        {createPermission && (
                                            <Form.Group as={Col} md={12} sm={12} lg={12}>
                                                <Button type="submit">Submit</Button>
                                            </Form.Group>
                                        )}
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="pigs" title="Update Kids" className="mt-4">
                    <Row>
                        <Col>
                            <Card title="Update Kids" isOption>
                                <Form onSubmit={handlePiglets} ref={formRef}>
                                    <Row>
                                        <Form.Group as={Col} md={6} sm={12} lg={6}>
                                            <Form.Label>Farm</Form.Label>
                                            <Select
                                                options={farmOptions}
                                                onChange={(selectedOption) => handleInpute('farm', selectedOption.value)}
                                                isSearchable
                                                name="farm"
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                            <Form.Label>Morther Tag No.</Form.Label>
                                            <Select
                                                value={selectedTagM}
                                                onChange={(selectedOption) =>
                                                    setSelectedTagM(handleInput('tagNumber', selectedOption.value))
                                                }
                                                options={tagMOptions}
                                                isSearchable
                                                // isMulti
                                                name="tagNumber"
                                                placeholder="-- Select Tag Number --"
                                                required
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                            <Form.Label>Goats Mortality No.</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                required
                                                onChange={(e) => handleInput('kidMortality', e.target.value)}
                                                type="text"
                                                name="kidMortality"
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                            <Form.Label>Records Date</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                required
                                                onChange={(e) => handleInput('recorded', e.target.value)}
                                                type="date"
                                                name="recorded"
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} md={12} sm={12} lg={12} controlId="formBasic">
                                            <Form.Label>Remarks/Notes</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                required
                                                onChange={(e) => handleInput('remarks', e.target.value)}
                                                type="text"
                                                name="remarks"
                                            />
                                        </Form.Group>
                                        {createPermission && (
                                            <Form.Group as={Col} md={12} sm={12} lg={12}>
                                                <Button type="submit">Submit</Button>
                                            </Form.Group>
                                        )}
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Tab>
            </Tabs>
            <ConfirmationModal show={showConfirmationModal} onHide={handleCancelConfirmation} onConfirm={handleConfirmation} />

            <ConfirmationModal show={showConfirmationModal1} onHide={handleCancelConfirmation1} onConfirm={handleConfirmation1} />
        </React.Fragment>
    );
};

export default FormsElements;
