import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { CSVLink } from 'react-csv';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { Row, Col, Form, Button, Table, Alert, Tabs, Tab, Modal, Pagination } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Font, PDFViewer } from '@react-pdf/renderer';
import Card from '../../components/Card/MainCard.js';
import config from '../../config.js';
import Receipt from './GeneralReceipt01.js';
import { Link, useHistory } from 'react-router-dom';
import SamplePage2 from './SamplePage2.js';
import SamplePage6 from './SamplePage6.js';
import SamplePage4 from './SamplePage4.js';
import SamplePage8 from './SamplePage8.js';

const TransactionHistory = () => {
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;

    return (
        <React.Fragment>
            <Row>
                <Col sm={12}>
                    {success && (
                        <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                            {success}
                        </Alert>
                    )}
                    {error && (
                        <Alert variant="danger" onClose={() => setError(null)} dismissible>
                            {error}
                        </Alert>
                    )}
                    <ToastContainer />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="pb-3">
                            <Link to="/reports/stock">
                                <Button variant="primary" className="mt-2">
                                    <span className="feather icon-arrow-left"></span> Go Back
                                </Button>
                            </Link>
                        </div>
                    </div>
                    <Tabs defaultActiveKey="feeds">
                        <Tab eventKey="feeds" title="Purchase Feeds" className="mt-4">
                            <SamplePage2 />
                        </Tab>
                        <Tab eventKey="food" title="Purchase Food" className="mt-4">
                            <SamplePage8 />
                        </Tab>
                        <Tab eventKey="water" title="Purchase Water" className="mt-4">
                            <SamplePage4 />
                        </Tab>
                        <Tab eventKey="vaccine" title="Purchase Vaccine" className="mt-4">
                            <SamplePage6 />
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default TransactionHistory;
