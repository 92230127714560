import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Table, Button, Pagination, Form, Card } from 'react-bootstrap';
import Select from 'react-select';
import { CSVLink } from 'react-csv';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

const UserList = ({ eggsData }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage, setUsersPerPage] = useState(10);

    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUser = eggsData.slice(indexOfFirstUser, indexOfLastUser);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handlePerPageChange = (selectedOption) => {
        setUsersPerPage(selectedOption.value);
        setCurrentPage(1); // Reset to the first page when changing the number of users per page
    };

    const options = [
        { value: 5, label: '5' },
        { value: 10, label: '10' },
        { value: 25, label: '25' },
        { value: 50, label: '50' },
        { value: 150, label: '150' },
        { value: 200, label: '200' }
    ];

    const totalPage = Math.ceil(eggsData.length / usersPerPage);

    const calculateAge = (birthDate) => {
        const today = new Date();
        const birth = new Date(birthDate);

        const todayUTC = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
        const birthUTC = Date.UTC(birth.getFullYear(), birth.getMonth(), birth.getDate());

        const ageDiffMillis = todayUTC - birthUTC;

        const yearDiff = Math.floor(ageDiffMillis / (1000 * 60 * 60 * 24 * 365));
        const monthDiff = Math.floor((ageDiffMillis % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30));
        const dayDiff = Math.floor((ageDiffMillis % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24));

        if (yearDiff > 0) {
            return yearDiff === 1 ? `${yearDiff} year ago` : `${yearDiff} years ago`;
        } else if (monthDiff > 0) {
            return monthDiff === 1 ? `${monthDiff} month ago` : `${monthDiff} months ago`;
        } else if (dayDiff > 0) {
            return dayDiff === 1 ? `${dayDiff} day ago` : `${dayDiff} days ago`;
        } else {
            return dayDiff === 0 ? `Today` : `${dayDiff} days ago`;
        }
    };

    const csvLinkRef = useRef();

    const exportToCSVs = () => {
        const csvData = [
            ['ID', 'Batch No.', 'Collected', 'Broken', 'No. Trays', 'Rem Eggs', 'Date', 'Updated', 'Farm'],
            ...eggsData.map((item) => [
                item.id,
                item.batch,
                item.collected,
                item.spoilt,
                item.numOfTrays,
                item.remainingEggs,
                new Date(item.recorded).toLocaleDateString(),
                new Date(item.createdAt).toLocaleDateString(),
                item.farm
            ])
        ];

        const csvContent = csvData.map((row) => row.join(',')).join('\n');
        const blob = new Blob(['\ufeff', csvContent], { type: 'text/csv;charset=utf-8;' });

        const csvUrl = URL.createObjectURL(blob);
        csvLinkRef.current.href = csvUrl;
        csvLinkRef.current.download = 'sfarms.csv';
        csvLinkRef.current.click();
    };

    const exportToPDFs = () => {
        const doc = new jsPDF();
        doc.autoTable({
            head: [['Batch No.', 'Collected', 'Broken', 'No. Trays', 'Rem Eggs', 'Date', 'Updated', 'Farm']],
            body: eggsData.map((user) => [
                user.batch,
                user.collected,
                user.spoilt,
                user.numOfTrays,
                user.remainingEggs,
                new Intl.DateTimeFormat('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit'
                }).format(new Date(user.recorded)),
                new Intl.DateTimeFormat('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                }).format(new Date(user.createdAt)),
                user.farm
            ])
        });
        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl);
    };

    return (
        <React.Fragment>
            <Row className="btn-page">
                <Col>
                    <Card title="" isOption>
                        <Table responsive hover striped bordered>
                            <thead variant="dark" className="bg-c-blue border-bottom text-uppercase">
                                <tr variant="dark">
                                    <th>ID</th>
                                    <th>Batch</th>
                                    <th>Collected</th>
                                    <th>Broken</th>
                                    <th>Trays</th>
                                    <th>Rem Eggs</th>
                                    <th>Updated</th>
                                    <th>Date</th>
                                    <th>Farm</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentUser.map((user) => (
                                    <tr key={user.id}>
                                        <td scope="row">
                                            <Link to={`view-eggs-update/${user.id}`}>{user.id}</Link>
                                        </td>
                                        <td scope="row">
                                            <Link to={`view-batch/${user.batch}`}># {user.batch}</Link>
                                        </td>
                                        <td>{user.collected}</td>
                                        <td>{user.spoilt}</td>
                                        <td>{user.numOfTrays}</td>
                                        <td>{user.remainingEggs}</td>
                                        <td>{calculateAge(user.createdAt)}</td>
                                        <td>{new Date(user.recorded).toDateString()}</td>
                                        <td>{user.farm}</td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                            <tr >
                                <td colSpan="2" className='font-weight-bold'>TOTALS</td>
                                <td>Collected: {eggsData.reduce((acc, user) => acc + user.collected, 0).toLocaleString()}</td>
                                <td>Broken: {eggsData.reduce((acc, user) => acc + user.spoilt, 0).toLocaleString()}</td>
                                <td>Trays: {eggsData.reduce((acc, user) => acc + user.numOfTrays, 0).toLocaleString()}</td>
                                <td>Aggregate Rem Eggs: {eggsData.reduce((acc, user) => acc + user.remainingEggs, 0).toLocaleString()}</td>
                                <td colSpan="4"></td>
                            </tr>
                        </tfoot>
                        </Table>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex">
                                <Pagination>
                                    <Pagination.Prev onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} />
                                    {Array.from({ length: Math.min(totalPage, 5) }).map((_, index) => (
                                        <Pagination.Item
                                            key={index + 1}
                                            active={index + 1 === currentPage}
                                            onClick={() => paginate(index + 1)}
                                        >
                                            {index + 1}
                                        </Pagination.Item>
                                    ))}
                                    {totalPage > 5 && <Pagination.Ellipsis />}
                                    {totalPage > 5 && <Pagination.Item onClick={() => paginate(totalPage)}>{totalPage}</Pagination.Item>}
                                    <Pagination.Next onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPage} />
                                </Pagination>

                                <Select
                                    options={options}
                                    value={{ value: usersPerPage, label: `${usersPerPage}` }}
                                    onChange={handlePerPageChange}
                                />
                            </div>
                            <div className="d-flex">
                                <Button onClick={exportToCSVs}>Export as CSV</Button>
                                <a ref={csvLinkRef} style={{ display: 'none' }} />
                                <a href="#" className="btn btn-primary" onClick={exportToPDFs}>
                                    Export PDF
                                </a>
                            </div>
                        </div>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default UserList;
