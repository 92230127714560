import React, { useState, useEffect, useRef} from 'react';
import { Row, Col, Card, Form, Button, Alert, Container, ListGroup, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModuleNotification from '../../../components/Widgets/Statistic/Notification';
import config from '../../../config';
import axios from 'axios';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import EventForm from './EventForm';

const localizer = momentLocalizer(moment);

// const kenyanHolidays = [
//   { id: 1, title: 'New Year\'s Day', date: '2024-01-01' },
//   { id: 2, title: 'Good Friday', date: '2024-04-19' },
//   { id: 3, title: 'Easter Monday', date: '2024-04-22' },
//   { id: 4, title: 'Labour Day', date: '2024-05-01' },
//   { id: 5, title: 'Madaraka Day', date: '2024-06-01' },
//   { id: 6, title: 'Mashujaa Day', date: '2024-10-20' },
//   { id: 7, title: 'Jamhuri Day', date: '2024-12-12' },
//   { id: 8, title: 'Christmas Day', date: '2024-12-25' },
//   { id: 9, title: 'Boxing Day', date: '2024-12-26' },
// ];


const fetchHolidays = async () => {
  try {
      // const response = await axios.get('https://holidayapi.com/v1/holidays', {
      const response = await axios.get('https://calendarific.com/api/v2/holidays', {
          params: {
              // key: 'aa88af1b-1c1c-4595-bb21-6a66dc72eaf9', // Replace with your actual API key
              api_key: 'xBfXyZoVzDMIM5PA70dOaOU17FMpjQJE', // Replace with your actual API key
              country: 'KE',
              year: new Date().getFullYear()
          }
      });
      return response.data.response.holidays.map(holiday => ({
          id: holiday.name,
          title: holiday.name,
          start: new Date(holiday.date.iso),
          end: new Date(holiday.date.iso)
      }));
  } catch (error) {
      console.error('Error fetching holidays:', error);
      return [];
  }
};
// const fetchHolidays = async () => {
//   try {
//       const response = await axios.get('https://holidayapi.com/v1/holidays', {
//           params: {
//               key: 'aa88af1b-1c1c-4595-bb21-6a66dc72eaf9',
//               country: 'KE',
//               year: new Date().getFullYear(),
//           },
//       });

//       if (response.data && response.data.holidays) {
//           console.log('Fetched holidays:', response.data.holidays);
//           const holidays = response.data.holidays.map(holiday => ({
//               id: holiday.name,
//               title: holiday.name,
//               start: new Date(holiday.date),
//               end: new Date(holiday.date),
//           }));
//           return holidays;
//       } else {
//           console.log('No holidays found in the response');
//           return [];
//       }
//   } catch (error) {
//       console.error('Error fetching holidays:', error);
//       return [];
//   }
// };

const Calendar = ({ events, onEventClick, onDateClick }) => {
    const [showEventForm, setShowEventForm] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [holidays, setHolidays] = useState([]);

    useEffect(() => {
        const loadHolidays = async () => {
            const holidaysData = await fetchHolidays();
            setHolidays(holidaysData);
            // console.log(holidaysData)
        };
        loadHolidays();
    }, []);

    const handleSelectSlot = (slotInfo) => {
        console.log('Handling slot selection:', slotInfo);
        const { start, end, action } = slotInfo;
        if (action === 'click') {
          console.log('Clicked on a date!');
          setSelectedEvent({
            start,
            end,
            title: '', // You can set other default values
            description: '',
            eventType: '',
            employeeSelectOption: '',
            alerts: '',
          });
          setShowEventForm(true);
        }
      };

      const handleCloseEventForm = () => {
        setShowEventForm(false);
        setSelectedEvent(null);
      };

      // const filteredEvents = events.map((event) => ({
      //   id: event.id,
      //   start: new Date(event.fromDate),
      //   end: new Date(event.toDate),
      //   title: event.title,
      //   description: event.description,
      //   employeeSelectOption: event.employeeSelectOption,
      //   eventType: event.eventType,
      //   alerts: event.alerts,
      // }));

      const filteredEvents = [
        ...events.map((event) => ({
          id: event.id,
          start: new Date(event.fromDate),
          end: new Date(event.toDate),
          title: event.title,
          description: event.description,
          employeeSelectOption: event.employeeSelectOption,
          eventType: event.eventType,
          alerts: event.alerts,
        })),
        ...holidays
      ];

  return (
    <React.Fragment>
       <BigCalendar
        localizer={localizer}
        events={filteredEvents}
        startAccessor="start"
        endAccessor="end"
        onSelectEvent={onEventClick}
        onSelectSlot={handleSelectSlot}
        style={{ height: 650, width: 1920 }}
        selectRange={true}
      />

        {showEventForm && (
            <EventForm
                onClose={handleCloseEventForm}
                date={selectedDate}
                onAddOrEditEvent={(event) => {
                    onEventClick(event);
                    handleCloseEventForm();
                }}
            />
        )}

    </React.Fragment>
  );
};

export default Calendar;
