import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Table, Button, Pagination, Form, Card, Tabs, Tab } from 'react-bootstrap';
import Select from 'react-select';
import { CSVLink } from 'react-csv';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import axios from 'axios';
import config from '../../../config';
import { useParams, useHistory } from 'react-router-dom';
import { usePermissions } from '../../../permissions';
// import Card from '../../components/Card/MainCard';
import PiVac from './BasicTabsPills40';
import ReadyFeed from './BasicTabsPills4012.js';
import RawFeed from './BasicTabsPills4021.js';

const UserList = () => {
    const [users, setUsers] = useState([]);
    const [Da, setDa] = useState(0);
    const [We, setWe] = useState(0);
    const [Mo, setMo] = useState(0);
    const [DaC, setDaC] = useState(0);
    const [WeC, setWeC] = useState(0);
    const [MoC, setMoC] = useState(0);
    const [ready, setReady] = useState([]);
    const [raw, setRaw] = useState([]);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const { createPermission, deletePermission, editPermission } = usePermissions();
    const [isPopupVisible, setPopupVisible] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/goats_feed_consumption`);
                const data = response?.data;
                const rawData = data?.filter((item) => item.feed_type === 'Raw Feeds');
                const readyData = data?.filter((item) => item.feed_type === 'Ready Feeds');

                setReady(readyData);
                setRaw(rawData);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/goats/feeds/daily`);
                setDa(response.data[0]);
                const responsec = await axios.get(`${apiUrl}/goats/costs/daily`);
                setDaC(responsec.data[0]);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/goats/feeds/weekly`);
                setWe(response.data[0]);
                const responsec = await axios.get(`${apiUrl}/goats/costs/weekly`);
                setWeC(responsec.data[0]);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/goats/feeds/monthly`);
                setMo(response.data[0]);
                const responsec = await axios.get(`${apiUrl}/goats/costs/monthly`);
                setMoC(responsec.data[0]);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    
    const calculateAge = (birthDate) => {
        const today = new Date();
        const birth = new Date(birthDate);

        const todayUTC = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
        const birthUTC = Date.UTC(birth.getFullYear(), birth.getMonth(), birth.getDate());

        const ageDiffMillis = todayUTC - birthUTC;

        const yearDiff = Math.floor(ageDiffMillis / (1000 * 60 * 60 * 24 * 365));
        const monthDiff = Math.floor((ageDiffMillis % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30));
        const dayDiff = Math.floor((ageDiffMillis % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24));

        if (yearDiff > 0) {
            return yearDiff === 1 ? `${yearDiff} year` : `${yearDiff} years`;
        } else if (monthDiff > 0) {
            return monthDiff === 1 ? `${monthDiff} month` : `${monthDiff} months`;
        } else {
            return dayDiff === 1 ? `${dayDiff} day` : `${dayDiff} days`;
        }
    };

    const csvLinkRef = useRef();

    const hidePopup = () => {
        setPopupVisible(false);
    };

    const showPopup = () => {
        setPopupVisible(true);
    };

    return (
        <React.Fragment>
            <Row>
                <Col md={4} xl={4}>
                    <Card style={{}}>
                        <Card.Body className="">
                            <div className="row d-flex align-items-center">
                                <div className="col-6">
                                    <h6 className="">Daily</h6>
                                </div>

                                <div className="col-6 text-right">
                                    {Da && (
                                        <span>
                                            {Da?.total_consumed?.toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })}{' '}
                                            Kgs/Lts
                                        </span>
                                    )}
                                </div>
                                <div className="col-6">
                                    <h6 className="">Amount</h6>
                                </div>

                                <div className="col-6 text-right">
                                    {DaC && (
                                        <span>
                                            {' '}
                                            KS{' '}
                                            {DaC?.total_cost?.toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4} xl={4}>
                    <Card style={{}}>
                        <Card.Body className="">
                            <div className="row d-flex align-items-center">
                                <div className="col-6">
                                    <h6 className="">Weekly</h6>
                                </div>
                                <div className="col-6 text-right">
                                    {We && (
                                        <span>
                                            {We?.total_consumed?.toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })}{' '}
                                            Kgs/Lts
                                        </span>
                                    )}
                                </div>
                                <div className="col-6">
                                    <h6 className="">Amount</h6>
                                </div>
                                <div className="col-6 text-right">
                                    {WeC && (
                                        <span>
                                            KS{' '}
                                            {WeC?.total_cost?.toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4} xl={4}>
                    <Card style={{}}>
                        <Card.Body className="">
                            <div className="row d-flex align-items-center">
                                <div className="col-6">
                                    <h6 className="">Monthly</h6>
                                </div>

                                <div className="col-6 text-right">
                                    {Mo && (
                                        <span>
                                            {Mo?.total_consumed?.toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })}{' '}
                                            Kgs/Lts
                                        </span>
                                    )}
                                </div>
                                <div className="col-6">
                                    <h6 className="">Amount</h6>
                                </div>

                                <div className="col-6 text-right">
                                    {MoC && (
                                        <span>
                                            KS{' '}
                                            {MoC?.total_cost?.toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="btn-page">
                <Col>
                    {/* <div className="d-flex justify-content-between align-center m-b-10">
                        {createPermission && (
                            <Link to="/goats/consume-feeds">
                                <Button variant="primary" className="">
                                    <span className="feather icon-plus-circle"></span> Consume Goat Feeds
                                </Button>
                            </Link>
                        )}
                    </div> */}
                    <div className="d-flex justify-content-between align-center m-b-10">
                        <div className="d-flex justify-content-between align-center m-b-10">
                            {createPermission && (
                                <Button onClick={showPopup} className="mb-10">
                                    <span className="feather icon-plus-circle"></span> Feed Goats
                                </Button>
                            )}
                            {isPopupVisible && <PiVac onCancel={hidePopup} />}
                        </div>
                    </div>
                </Col>
            </Row>
            <Tabs defaultActiveKey="feeds">
                <Tab eventKey="feeds" title="Ready Feeds" className="mt-4">
                    <ReadyFeed />
                </Tab>
                <Tab eventKey="raw" title="Raw Feeds" className="mt-4">
                    <RawFeed />
                </Tab>
            </Tabs>
        </React.Fragment>
    );
};
export default UserList;
