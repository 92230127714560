import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Row, Col, Table, Button, Pagination, Form } from 'react-bootstrap';
import Select from 'react-select';
import { CSVLink } from 'react-csv';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import axios from 'axios';
import { useParams, useHistory, Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import config from '../../config';
import Card from '../../components/Card/MainCard';
import ConfirmationModal from '../ui-elements/basic/ConfirmationModal';
import { usePermissions } from '../../permissions';
import Sup from './nvd3-chart01';

const UserList = () => {
    const [users, setUsers] = useState([]);
    const { id } = useParams();
    const history = useHistory();
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [searchText, setSearchText] = useState('');
    const [totalCost, setTotalCost] = useState(0);
    const [selectedColumns, setSelectedColumns] = useState(['Asset Type', 'Name', 'Cost', 'Created At', 'Action']);
    const { createPermission, deletePermission, editPermission, viewPermission } = usePermissions();
    const [isPopupVisible, setPopupVisible] = useState(false);

    // console.log(createPermission)

    const handleSelectedColumnsChange = (newSelectedColumns) => {
        setSelectedColumns(newSelectedColumns);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/assets`);
                setUsers(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [users]);

    // console.log(users);

    const handleDelete = async (userId, name) => {
        try {
            const confirmed = window.confirm(`Are you sure you want to delete ${name}?`);
            if (confirmed) {
                await axios.put(`${apiUrl}/assets/${userId}/archive`);
                alert('Asset archived successfully!');
                window.location.reload();
            }
        } catch (error) {
            alert(error.message);
        }
    };

    const handleUpdate = (userId) => {
        const userToUpdate = users.find((user) => user.id === userId);
        history.push(`assets/${userId}/edit`, { user: userToUpdate });
    };

    const handleUpdatePass = (userId) => {
        const userToUpdate = users.find((user) => user.id === userId);
        history.push(`assets/view/${userId}`, { user: userToUpdate });

        console.log(userId);
    };

    const csvLinkRef = useRef();

    const exportToCSV = () => {
        const csvData = [
            ['Id', 'Name', 'Type', 'Farm', 'Cost', 'Date', 'Remarks'],
            ...filteredData.map((item) => [
                item.id,
                item.asset_name,
                item.type,
                item.farm,
                item.cost,
                new Date(item.recorded).toLocaleDateString(),
                item.remarks
            ])
        ];

        const csvContent = csvData.map((row) => row.join(',')).join('\n');
        const blob = new Blob(['\ufeff', csvContent], { type: 'text/csv;charset=utf-8;' });

        const csvUrl = URL.createObjectURL(blob);
        csvLinkRef.current.href = csvUrl;
        csvLinkRef.current.download = 'sfarms.csv';
        csvLinkRef.current.click();
    };

    const exportToPDF = () => {
        const doc = new jsPDF();
        doc.autoTable({
            head: [['Id', 'Name', 'Type', 'Farm', 'Cost', 'Date', 'Remarks']],
            body: filteredData.map((user) => [
                user.id,
                user.asset_name,
                user.type,
                user.farm,
                user.cost,
                new Intl.DateTimeFormat('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit'
                }).format(new Date(user.recorded)),
                user.remarks
            ])
        });
        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl);
    };

    const handleViewFile = (image) => {
        window.open(`${apiUrl}/uploads/${image}`, '_blank');
    };

    const columns = [
        {
            name: 'Asset Type',
            selector: (row) => row.type,
            sortable: true,
            minWidth: '250px',
        },
        {
            name: 'NAme',
            selector: (row) => row.asset_name,
            sortable: true,
            minWidth: '150px',
        },
        {
            name: 'cOST',
            selector: (row) => row.cost.toLocaleString('en-US', { minimumFractionDigits: 2 }),
            sortable: true,
            minWidth: '120px',
        },
        {
            name: 'Created At',
            selector: (row) => new Date(row.created_At).toLocaleString(),
            sortable: true,
            minWidth: '180px',
            // omit: !selectedColumns?.includes('Created At')
        },
        {
            name: 'Action',
            cell: (row) => (
                <div>
                    {editPermission ? (
                        <Button disabled={!editPermission} size="sm" onClick={() => handleUpdate(row.id)}>
                            <span className="feather icon-edit"></span> Edit
                        </Button>
                    ) : (
                        '-'
                    )}
                    {viewPermission ? (
                        row.image ? (
                            <Button size="sm" variant="info" onClick={() => handleViewFile(row.image)}>
                                <i className="feather icon-download-cloud" /> Receipt
                            </Button>
                        ) : (
                            <Button size="sm" variant="secondary">
                                <i className="feather icon-cancel" /> 
                            </Button>
                        )
                    ) : (
                        '-'
                    )}
                    {deletePermission ? (
                        <Button
                            variant="danger"
                            disabled={!deletePermission}
                            size="sm"
                            onClick={() => handleDelete(row.id, row.asset_name)}
                        >
                            <span className="feather icon-trash-2"></span>
                        </Button>
                    ) : (
                        '-'
                    )}
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            minWidth: '200px',
            // button: true,
            // right: true
        }
    ];

    const ExpandableRow = ({ data }) => {
        return (
            <div style={{ padding: '10px 20px' }}>
                <p className="m-0">
                    <strong className="text-c-blue">Farm:</strong> {data.farm}
                </p>

                <p className="m-0">
                    <strong className="text-c-blue">Vendor/Supplier:</strong> {data.vendor}
                </p>
                <p className="m-0">
                    <strong className="text-c-blue">Description:</strong> {data.remarks}
                </p>
            </div>
        );
    };

    // const handleSearch = (e) => {
    //     setSearchText(e.target.value);
    // };

    const hidePopup = () => {
        setPopupVisible(false);
    };

    const showPopup = () => {
        setPopupVisible(true);
    };

    const filteredData = useMemo(() => {
        return searchText
            ? users.filter((item) =>
                  Object.values(item).some((value) => typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase()))
              )
            : users;
    }, [searchText, users]);

    const calculateTotalCost = (filteredData) => {
        let total = 0;
        filteredData.forEach((item) => {
            total += item.cost;
        });
        return total;
    };

    useMemo(() => {
        const cost = calculateTotalCost(filteredData);
        setTotalCost(cost);
    }, [filteredData]);

    return (
        <React.Fragment>
            <Row className="btn-page">
                <Col>
                    <div className="d-flex justify-content-between align-center m-b-10">
                        <div>
                            <span> </span>
                        </div>
                        {createPermission && (
                            <Button onClick={showPopup} className="mb-10">
                                <span className="feather icon-plus-circle"></span> Add Asset
                            </Button>
                        )}
                        {isPopupVisible && <Sup onCancel={hidePopup} />}
                    </div>
                    <Card title="Assets List" isOption>
                        <div>
                            <Row>
                                <Form.Group as={Col} md={1} sm={12} lg={1} controlId="formBasicName">
                                    <Form.Label>Search</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md={3} sm={12} lg={3} controlId="formBasicName">
                                    <Form.Control
                                        type="text"
                                        placeholder="Search..."
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                    />
                                </Form.Group>
                            </Row>
                        </div>
                        <DataTable
                            columns={columns}
                            data={filteredData}
                            pagination
                            highlightOnHover
                            striped
                            // noHeader
                            bordered
                            // dense
                            paginationPerPage={10}
                            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                            paginationComponentOptions={{
                                rowsPerPageText: 'Rows per page:'
                            }}
                            paginationResetDefaultPage={true}
                            paginationTotalRows={users.length}
                            expandableRows
                            expandableRowsComponent={ExpandableRow}
                            selectableRows
                            onSelectedRowsChange={handleSelectedColumnsChange}
                            // onSelectedRowsChange={(selectedRows) => console.log(selectedRows)}
                            customStyles={{
                                headRow: {
                                    style: {
                                        backgroundColor: '#279e6c', // Green background
                                        color: '#fff', // White text
                                        textTransform: 'uppercase', // Uppercase text
                                        // fontSize: '10px', // Adjust font size for header row
                                        minHeight: '1.5rem',
                                        paddingTop: '8px',
                                        paddingBottom: '8px'
                                    }
                                }
                            }}
                            subHeader
                            subHeaderComponent={
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="pr-2">
                                        Total Cost: {totalCost.toLocaleString('en-US', { minimumFractionDigits: 2 })}
                                    </div>
                                    <div>
                                        <Button onClick={exportToCSV}>
                                            <span className="feather icon-file"></span> CSV
                                        </Button>
                                        <a ref={csvLinkRef} style={{ display: 'none' }} />
                                        <a href="#" className="btn btn-primary" onClick={exportToPDF}>
                                            <span className="feather icon-file-text"></span> PDF
                                        </a>
                                    </div>
                                </div>
                            }
                            subFooter={<div>Total Cost: {totalCost.toLocaleString('en-US', { minimumFractionDigits: 2 })}</div>}
                        />
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default UserList;
