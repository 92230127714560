import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import NavLeft from './NavLeft';
import NavRight from './NavRight';

import { ConfigContext } from '../../../contexts/ConfigContext';
import * as actionType from '../../../store/actions';

const NavBar = () => {
    const configContext = useContext(ConfigContext);
    const { collapseMenu } = configContext.state;
    const { dispatch } = configContext;

    let headerClass = ['navbar', 'pcoded-header', 'navbar-expand-lg', 'navbar-default', 'navbar-fixed-top'];

    let toggleClass = ['mobile-menu'];
    if (collapseMenu) {
        toggleClass = [...toggleClass, 'on'];
    }

    const navToggleHandler = () => {
        dispatch({ type: actionType.COLLAPSE_MENU });
    };

    let collapseClass = ['collapse navbar-collapse'];

    let navBar = (
        <React.Fragment>
            <div className="m-header" style={{backgroundColor: '#228a67'}}>
                <Link to="#" className={toggleClass.join(' ')} id="mobile-collapse" onClick={navToggleHandler}>
                    <span />                    
                </Link>
                <Link to="#" className="b-brand">
                    <div className="b-bg">
                        {/* <i className="feather icon-trending-up" /> */}
                        <img 
                            className="" 
                            src='/assets/farm.png' 
                            alt='Farm Logo'
                            style={{ width: '35px', height: '35px' }}
                        />
                    </div>
                    <span className="b-title">SFARMS</span>
                </Link>
                {/* <Link to='#' className={moreClass.join(' ')} onClick={() => setMoreToggle(!moreToggle)}>
                    <i className="feather icon-more-vertical"/>
                </Link> */}
            </div>
            <div className={collapseClass.join(' ')}>
                <NavLeft />
                <NavRight />
            </div>
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <header className={headerClass.join(' ')} style={{backgroundColor: 'white', position: 'fixed', marginBottom: '5rem', top: '0px'}}>{navBar}</header>
        </React.Fragment>
    );
};

export default NavBar;
