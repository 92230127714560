import React from 'react';
import { Alert, Row, Col } from 'react-bootstrap';

const Notification = (props) => {
    return (
        <React.Fragment>
            <Alert variant='warning'>
                <Row>
                    <Col className='font-weight-bold' xl={8} lg={12} md={12} sm={12} xs={12}>{props.message}</Col>
                    <Col className='text-right' xl={4} lg={12} md={12} sm={12} xs={12}>{props.count}</Col>
                </Row>
             
            </Alert>
        </React.Fragment>
    );
};

export default Notification;
