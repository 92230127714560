import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import config from '../../config';
import { Row, Col, Form, Table, Button, Alert, Modal } from 'react-bootstrap';
import Card from '../../components/Card/MainCard';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';

const SamplePage = ({ onCancel }) => {
  const [phone, setPhone] = useState('');
  const [amount, setAmount] = useState('');
  const [purpose, setPurpose] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const formRef = useRef(null);
  const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
  const apiUrl = config[environment].apiUrl;

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handlePurposeChange = (e, selectedOption) => {
    setPurpose(selectedOption.value);
  };

  const options = [
    { value: 'Product Sales', label: 'Product Sales' },
    { value: 'Livestock Sales', label: 'Livestock Sales' },
    { value: 'Agricultural Services', label: 'Agricultural Services' },
    { value: 'Agritourism', label: 'Agritourism' },
    { value: 'Land Leasing', label: 'Land Leasing' },
    { value: 'Transportation', label: 'Transportation' },
    { value: 'Debt Repayment', label: 'Debt Repayment' },
  ];

  console.log(purpose, amount, phone)

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(`${apiUrl}/api/stkpush`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ phone, amount, purpose }),
    })
      .then((response) => {
        if (response.status === 200) {
          setAmount('');
          setPhone('');
          return response.json().then((data) => {
            setSuccessMessage(data.message);
            toast.success(data.message);
            setErrorMessage('');
            // handleCallbackRequest();
            // onCancel()
          });
        } 
        else if (response.status === 400) {
          return response.json().then((errorData) => {
            setErrorMessage(errorData.errorMessage); // Set the error message from the response
            toast.error(errorData.errorMessage); // Set the error message from the response
            setSuccessMessage('');
          });
        } else {
          // Handle other status codes here
          return response.json().then((errorData) => {
            if (errorData.errorMessage) {
              toast.error('Error: ' + errorData.errorMessage);
            } else {
              toast.error('Unknown error occurred');
            }
            setSuccessMessage('');
          });
        }
      })
      .catch((error) => {
        console.error(error.message);
        toast.error('An error occurred');
        setSuccessMessage('');
      });
  };
  

  return (
    <React.Fragment>
      <Modal show={true} onHide={onCancel} centered>
        <Modal.Header closeButton>
          <Modal.Title>Recieve Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              {successMessage && (
                <Alert variant="success" onClose={() => setSuccessMessage('')} dismissible>
                  {successMessage}
                </Alert>
              )}
              {errorMessage && (
                <Alert variant="danger" onClose={() => setErrorMessage('')} dismissible>
                  {errorMessage}
                </Alert>
              )}
              <ToastContainer />
            </Col>
          </Row>
          <Row>
            <Col>
            <img src="/assets/M-PESA_LOGO.png" alt="Mpesa Logo" style={{ width: '100px', height: 'auto', align: 'center' }} />
              <Form onSubmit={handleSubmit} ref={formRef}>
                <Row>
                  <Form.Group as={Col} md={4} sm={12} lg={4} controlId="phoneNumber">
                    <Form.Label>Phone Number</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} md={8} sm={12} lg={8} controlId="phoneNumber">
                    <Form.Control
                      type="number"
                      id="phone"
                      value={phone}
                      onChange={handlePhoneChange}
                      required
                      placeholder='e.g. 0742342222'
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={4} sm={12} lg={4} controlId="amount">
                    <Form.Label>Amount</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} md={8} sm={12} lg={8} controlId="amount">
                    <Form.Control
                      type="number"
                      id="amount"
                      value={amount}
                      onChange={handleAmountChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={4} sm={12} lg={4} controlId="purpose">
                    <Form.Label>Purpose</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} md={8} sm={12} lg={8} controlId="purpose">
                    <Select
                      type="text"
                      id="purpose"
                      options={options.map((option) => ({
                        value: option.label,
                        label: option.label,
                      }))}
                      // value={purpose}
                      onChange={(selectedOption) => handlePurposeChange('purpose', selectedOption)}
                      required
                    />
                  </Form.Group>
                </Row>
                <Modal.Footer>
                  <Button type="submit">Send</Button>
                  <Button variant="secondary" onClick={onCancel}>
                    Cancel
                  </Button>
                </Modal.Footer>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default SamplePage;
