import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Alert, Button, Modal } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import Card from '../../../components/Card/MainCard';
import axios from 'axios';
import config from '../../../config';
import ConfirmationModal from '../../ui-elements/basic/ConfirmationModal';
import { usePermissions } from '../../../permissions';

const FormsElements = ({ onCancel }) => {
    const account = useSelector((state) => state.account);
    const [data, setData] = useState({});
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [selectedTagM, setSelectedTagM] = useState(null);
    const [tagMOptions, setTagMOptions] = useState([]);
    const formRef = useRef(null);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const { createPermission, deletePermission, editPermission } = usePermissions();

    const memoizedApiUrl1 = useMemo(() => {
        return `${apiUrl}/farm`;
    }, [apiUrl]);

    const handleInput = (fieldName, value) => {
        setData((prevData) => ({ ...prevData, [fieldName]: value, userName: account.user.firstname, userId: account.user.id }));
        console.log(data);
    };

    const handlePiglets = async (e) => {
        e.preventDefault();
        setShowConfirmationModal(true);
    };

    const handleConfirmation = async (e) => {
        if (!createPermission) {
            return;
        }

        axios
            .post(`${apiUrl}/weaners/update`, data)
            .then((response) => {
                if (response.data) {
                    setSuccess(`Weaners of ${data.weanersNumber} Updated successfully`);
                    setTimeout(() => {
                        setSuccess(null);
                    }, 10000);
                    toast.success(`Weaners of ${data.weanersNumber} Updated successfully`);
                    onCancel();
                    setData({});
                    formRef.current.reset();
                    setSelectedTagM(null);
                    setShowConfirmationModal(false);
                }
                console.log(response);
            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    setError(`${error.response.data.message}`);
                    toast.error(`${error.response.data.message}`);
                    setShowConfirmationModal(false);
                    onCancel();
                } else {
                    setError('An error occurred. Please try again later.');
                    setShowConfirmationModal(false);
                    onCancel();
                }
                console.log(error);
                setShowConfirmationModal(false);
                onCancel();
            });
    };

    useEffect(() => {
        const fetchTags = async () => {
            try {
                const response = await axios.get(`${apiUrl}/weaners`);
                const data = response.data;
                const filteredData = data.filter((tag) => tag.capacity > 0 && tag.archive !== 1);
                const options = filteredData.map((tag) => ({
                    // const options = data.map((tag) => ({
                    value: tag.weanersGroup,
                    label: tag.weanersGroup
                }));
                setTagMOptions(options);
            } catch (error) {
                console.error('Error fetching Tags:', error);
            }
        };

        fetchTags();
    }, [apiUrl, tagMOptions]);

    const handleCancelConfirmation = () => {
        setShowConfirmationModal(false);
    };

    return (
        <React.Fragment>
            <Modal show={true} onHide={onCancel} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Weaners Mortality</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Row>
                        <Col sm={12}>
                            {success && (
                                <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                                    {success}
                                </Alert>
                            )}
                            {error && (
                                <Alert variant="danger" onClose={() => setError(null)} dismissible>
                                    {error}
                                </Alert>
                            )}
                            <ToastContainer />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form onSubmit={handlePiglets} ref={formRef}>
                                <Row>
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Weaners Group</Form.Label>
                                        <Select
                                            value={selectedTagM}
                                            onChange={(selectedOption) =>
                                                setSelectedTagM(handleInput('weanersNumber', selectedOption.value))
                                            }
                                            options={tagMOptions}
                                            isSearchable
                                            name="weanersNumber"
                                            placeholder="-- Select Weaners Group --"
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Piglets Mortality No.</Form.Label>
                                        <Form.Control
                                            size="sm"
                                            required
                                            onChange={(e) => handleInput('pigletMortality', e.target.value)}
                                            type="number"
                                            name="pigletMortality"
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                                        <Form.Label>Date</Form.Label>
                                        <Form.Control
                                            size="sm"
                                            required
                                            onChange={(e) => handleInput('recorded', e.target.value)}
                                            type="date"
                                            name="recorded"
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md={12} sm={12} lg={12} controlId="formBasic">
                                        <Form.Label>Remarks/Notes</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            required
                                            onChange={(e) => handleInput('remarks', e.target.value)}
                                            type="text"
                                            name="remarks"
                                        />
                                    </Form.Group>
                                </Row>
                                <Modal.Footer>
                                    {createPermission && <Button type="submit">Submit</Button>}
                                    <Button variant="secondary" onClick={onCancel}>
                                        Cancel
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <ConfirmationModal show={showConfirmationModal} onHide={handleCancelConfirmation} onConfirm={handleConfirmation} />
        </React.Fragment>
    );
};

export default FormsElements;
