import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Table, Button, Pagination, Form, Card, Tabs, Tab } from 'react-bootstrap';
import Select from 'react-select';
import { CSVLink } from 'react-csv';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import axios from 'axios';
import config from '../../../config';
import { useParams, useHistory } from 'react-router-dom';
import { usePermissions } from '../../../permissions';
// import Card from '../../components/Card/MainCard';
import ReadyFeed from './BasicCollapse401';
import RawFeed from './BasicCollapse402';
import PiVac from './BasicCollapse403';

const UserList = () => {
    const [Da, setDa] = useState(0);
    const [We, setWe] = useState(0);
    const [Mo, setMo] = useState(0);
    const [DaC, setDaC] = useState(0);
    const [WeC, setWeC] = useState(0);
    const [MoC, setMoC] = useState(0);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const { createPermission, deletePermission, editPermission } = usePermissions();
    const [isPopupVisible, setPopupVisible] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/weaners/feeds/daily`);
                setDa(response.data[0]);
                const responsec = await axios.get(`${apiUrl}/weaners/costs/daily`);
                setDaC(responsec.data[0]);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/weaners/feeds/weekly`);
                setWe(response.data[0]);
                const responsec = await axios.get(`${apiUrl}/weaners/costs/weekly`);
                setWeC(responsec.data[0]);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/weaners/feeds/monthly`);
                setMo(response.data[0]);
                const responsec = await axios.get(`${apiUrl}/weaners/costs/monthly`);
                setMoC(responsec.data[0]);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);


    const hidePopup = () => {
        setPopupVisible(false);
    };

    const showPopup = () => {
        setPopupVisible(true);
    };

    return (
        <React.Fragment>
            <Row>
                <Col md={4} xl={4}>
                    <Card style={{}}>
                        <Card.Body className="">
                            <div className="row d-flex align-items-center">
                                <div className="col-6">
                                    <h6 className="">Daily</h6>
                                </div>

                                <div className="col-6 text-right">
                                    {Da && (
                                        <span>
                                            {Da?.total_consumed?.toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })}{' '}
                                            Kgs/Lts
                                        </span>
                                    )}
                                </div>
                                <div className="col-6">
                                    <h6 className="">Amount</h6>
                                </div>

                                <div className="col-6 text-right">
                                    {DaC && (
                                        <span>
                                            {' '}
                                            KS{' '}
                                            {DaC?.total_cost?.toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4} xl={4}>
                    <Card style={{}}>
                        <Card.Body className="">
                            <div className="row d-flex align-items-center">
                                <div className="col-6">
                                    <h6 className="">Weekly</h6>
                                </div>
                                <div className="col-6 text-right">
                                    {We && (
                                        <span>
                                            {We?.total_consumed?.toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })}{' '}
                                            Kgs/Ltr
                                        </span>
                                    )}
                                </div>
                                <div className="col-6">
                                    <h6 className="">Amount</h6>
                                </div>
                                <div className="col-6 text-right">
                                    {WeC && (
                                        <span>
                                            KS{' '}
                                            {WeC?.total_cost?.toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4} xl={4}>
                    <Card style={{}}>
                        <Card.Body className="">
                            <div className="row d-flex align-items-center">
                                <div className="col-6">
                                    <h6 className="">Monthly</h6>
                                </div>

                                <div className="col-6 text-right">
                                    {Mo && (
                                        <span>
                                            {Mo?.total_consumed?.toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })}{' '}
                                            Kgs/Ltr
                                        </span>
                                    )}
                                </div>
                                <div className="col-6">
                                    <h6 className="">Amount</h6>
                                </div>

                                <div className="col-6 text-right">
                                    {MoC && (
                                        <span>
                                            KS{' '}
                                            {MoC?.total_cost?.toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })}{' '}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="btn-page">
                <Col>
                    <div className="d-flex justify-content-between align-center m-b-10">
                        <div>
                            <span> </span>
                        </div>
                        {createPermission && (
                            <Button onClick={showPopup} className="mb-10">
                                <span className="feather icon-plus-circle"></span> Feed Weaners
                            </Button>
                        )}
                        {isPopupVisible && <PiVac onCancel={hidePopup} />}
                    </div>
                </Col>
            </Row>
            <Tabs defaultActiveKey="feeds">
                <Tab eventKey="feeds" title="Ready Feeds" className="mt-4">
                    <ReadyFeed />
                </Tab>
                <Tab eventKey="raw" title="Raw Feeds" className="mt-4">
                    <RawFeed />
                </Tab>
            </Tabs>
        </React.Fragment>
    );
};

export default UserList;
