import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Row, Col, Form, Button, Table, Alert, Pagination, Modal} from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../../../components/Card/MainCard';
import config from '../../../config';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

const TransactionHistory = ({resetTypeInput}) => {
  const { selectedFarm } = useParams();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [transactionData, setTransactionData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(10);
  const history = useHistory();
  const [selectedType, setSelectedType] = useState([]);
  const [transactionDetails, setTransactionDetails] = useState({type: '',});
  const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
  const apiUrl = config[environment].apiUrl;
  const [showModal, setShowModal] = useState(false);
  const [selectedLoan, setSelectedLoan] = useState([]);
  const [repayAmount, setRepayAmount] = useState('');

  const generateTransactionId = () => {
    const timestamp = new Date().getTime();
    const random = Math.floor(Math.random() * 1000);
    return `SF-${random}`;
  };

  useEffect(() => {
    fetchTrialBalance();
  }, [selectedType, apiUrl, showModal, selectedFarm]);

  const fetchTrialBalance = async () => {
    try {
      const response = await axios.get(`${apiUrl}/loans?farm=${selectedFarm}`);
      setTransactionData(response.data);
      
    } catch (error) {
      console.error(error);
    }
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currenttransactionData = transactionData.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePerPageChange = (selectedOption) => {
    setUsersPerPage(selectedOption.value);
    setCurrentPage(1); 
  };

 const options = [
   { value: 5, label: '5' },
   { value: 10, label: '10' },
   { value: 25, label: '25' },
   { value: 50, label: '50' },
   { value: 150, label: '150' },
   { value: 200, label: '200' },
   { value: 500, label: '500' },
 ];

 const totalPages = Math.ceil(transactionData.length / usersPerPage);

  const handleSelectChange = (name, selectedOption,event) => {
    setSelectedType(selectedOption.value);
    setSelectedLoan({ ...selectedLoan, [name]: selectedOption.value, repayAmount: repayAmount, loanId: selectedLoan.id, transactionId: generateTransactionId()  });
  };

console.log(selectedLoan);

const getLabelColorClass = (status) => {
  switch (status) {
    case 'Fully Paid':
      return 'label-orange';
    case 'Income':
      return 'label-blue';
    case 'Partially Paid':
      return 'label-till';
    case 'Due':
      return 'label-brown';
    case 'Ongoing':
      return 'label-kink';
    default:
      return '';
  }
};

const handleView = (transactionId) => {
  const transactionToUpdate = transactionData.find((transaction) => transaction.id === transactionId);
  history.push(`view-loan/${transactionId}`, { transaction: transactionToUpdate });
};

const handleDelete = async (id, transactionId) => {
  try {
    const confirmed = window.confirm(`Are you sure you want to delete this transaction ${transactionId} ?`);
    if (confirmed) {
      await axios.delete(`${apiUrl}/transaction/${id}`);
      alert('Transaction deleted successfully!');
      window.location.reload();
    }
  } catch (error) {
    alert(error.message);
  }
};

const resetSelectedType = () => {
  setSelectedType(null);
};

const handleRepayClick = async (id) => {
  try {
    const response = await axios.get(`${apiUrl}/loans/${id}`); 
    const loanDetails = response.data[0];
    setSelectedLoan(loanDetails);
    console.log(selectedLoan)
    setShowModal(true);
  } catch (error) {
    console.error(error);
  }
};

const handleCloseModal = () => {
  setShowModal(false);
};

const handleRepaySubmit = async () => {
  try {
    const response = await axios.post(`${apiUrl}/repayLoan`, selectedLoan);
    if(response.data.message){
      setSuccess(response.data.message);
      toast.success(response.data.message);
    };
  } catch (error) {
    setError(error.message);
    console.error('Repayment error:', error);
  } finally {
    setShowModal(false);
  }
};

const method = [
  { value: 'Cash', name: 'Cash' },
  { value: 'MPesa', name: 'MPesa' },
  { value: 'Bank', name: 'Bank' },
  { value: 'Cheque', name: 'Cheque' },
];

const csvLinkRef = useRef();


  

  return (
    <React.Fragment>
      <Row>
          <Col sm={12}>
          {success && <Alert variant="success" onClose={() => setSuccess(null)} dismissible >{success}</Alert>}
          {error && <Alert variant="danger" onClose={() => setError(null)} dismissible >{error}</Alert>}
          <ToastContainer />
          </Col>
      </Row>
      <div className='pb-3'>                        
            <Link to="/farms/manage">
                            <Button variant="primary" className="">
                                <span className='feather icon-arrow-left'></span>  Go Back
                            </Button>
                            </Link>
            </div>

      <Row>
        <Col>
          <Card title="Transactions" isOption>
            <Row className='table-accounts'>
            <Table striped bordered hover>
              <thead className="bg-c-blue border-bottom text-uppercase">
                <tr> 
                  <th>Paid To</th>
                  <th>Amount</th>
                  <th>ToRepay</th>
                  <th>Date</th>
                  <th>Due DAte</th>
                  <th>Purpose</th>
                  {/* <th>Farm</th> */}
                  <th>Status</th>
                  <th>Balance</th>
                  <th colSpan={2}>Action</th>
                </tr>
              </thead>
              <tbody>
                {currenttransactionData.map((transaction) => (
                  <tr key={transaction.id}>
                    <td>{transaction.coInput}</td>
                    <td>Ks {(transaction.amount) !== null ? transaction.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''}</td>
                    <td>Ks {(transaction.amountToRepay) !== null ? transaction.amountToRepay.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''}</td>
                    <td>{new Date(transaction.date).toLocaleDateString()}</td>
                    <td>{new Date(transaction.dueDate).toLocaleDateString()}</td>
                    <td>{transaction.loanPurpose}</td>
                    {/* <td>{transaction.farm}</td> */}
                    <td>
                      <span className={getLabelColorClass(transaction.status)}>
                        {transaction.status}
                      </span>
                    </td>
                    <td>Ks {(transaction.balance) !== null ? transaction.balance.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''}</td>
                    <td>
                      <Button onClick={() => handleView(transaction.id)}>
                        <i className="feather icon-eye" /> View
                      </Button>
                      <Button onClick={() => handleRepayClick(transaction.id, transaction.transactionId)}>
                        <i className="feather icon-printer" /> Repay
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
          <div className="d-flex">
              <Pagination>
                <Pagination.Prev
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                />
                {Array.from({ length: totalPages }).map((_, index) => (
                  <Pagination.Item
                    key={index + 1}
                    active={index + 1 === currentPage}
                    onClick={() => paginate(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage === totalPages}
                />
              </Pagination>
            <Select
              options={options}
              value={{ value: usersPerPage, label: `${usersPerPage}` }}
              onChange={handlePerPageChange}
            />
            
          </div>
          </Card>
        </Col>
      </Row>
      {selectedLoan && (
        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>Repay Loan</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Form.Group as={Col} md={6} sm={12} lg={6}>
                <Form.Label>Farm:</Form.Label>
                <Form.Control type="text" value={selectedLoan.farm} disabled />
              </Form.Group>
              <Form.Group as={Col} md={6} sm={12} lg={6}>
                <Form.Label>Loan ID:</Form.Label>
                <Form.Control type="text" value={selectedLoan.id} disabled />
              </Form.Group>
            </Row>
            <Row>
            <Form.Group as={Col} md={6} sm={12} lg={6}>
              <Form.Label>Beneficiary:</Form.Label>
              <Form.Control type="text" value={selectedLoan.coInput} disabled />
            </Form.Group>
            <Form.Group as={Col} md={6} sm={12} lg={6}>
              <Form.Label>Account:</Form.Label>
              <Form.Control type="text" value={selectedLoan.bankAccount} disabled />
            </Form.Group>
            <Form.Group as={Col} md={6} sm={12} lg={6}>
              <Form.Label>Loan Amount:</Form.Label>
              <Form.Control type="text" value={selectedLoan.amount} disabled />
            </Form.Group>
            <Form.Group as={Col} md={6} sm={12} lg={6}>
              <Form.Label>AmountToRepay:</Form.Label>
              <Form.Control type="text" value={selectedLoan.amountToRepay} disabled />
            </Form.Group>
            <Form.Group as={Col} md={6} sm={12} lg={6}>
              <Form.Label>Balance:</Form.Label>
              <Form.Control type="text" value={selectedLoan.balance} disabled />
            </Form.Group>
            <Form.Group as={Col} md={6} sm={12} lg={6}>
              <Form.Label>Loan Purpose:</Form.Label>
              <Form.Control type="text" value={selectedLoan.loanPurpose} disabled />
            </Form.Group>
            <Form.Group as={Col} md={6} sm={12} lg={6}>
              <Form.Label htmlFor="repayAmount">Repay Amount:</Form.Label>
              <Form.Control
                type="number"
                id="repayAmount"
                value={repayAmount}
                onChange={(e) => setRepayAmount(e.target.value)}
                placeholder="Enter amount repaid"
              />
            </Form.Group>
            <Form.Group as={Col} md={6} sm={12} lg={6}>
              <Form.Label>Repayment Method:</Form.Label>
                <Select
                  onChange={(selectedOption) =>
                    handleSelectChange('repayMethod', selectedOption)
                  }
                  options={method.map((option) => ({
                    value: option.name,
                    label: option.name,
                  }))}
                  placeholder="Method of Repayment"
                  required
                />
            </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleRepaySubmit}>
              Repay
            </Button>
          </Modal.Footer>
          
        </Modal>
      )}

    </React.Fragment>
  );
};

export default TransactionHistory;
