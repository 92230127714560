import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Button, Alert, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../../../config';
import Card from '../../../components/Card/MainCard';
import axios from 'axios';
import ConfirmationModal from './ConfirmationModal';
import { usePermissions } from '../../../permissions';

const SamplePage = ({ onCancel }) => {
    const account = useSelector((state) => state.account);
    const [data, setData] = useState({
        feed_name2: '',
        consumed2: '',
        cost2: '',
        feed_name1: '',
        consumed1: '',
        cost1: '',
        feed_name: '',
        consumed: '',
        cost: '',
        totalQuantity: 0,
        totalCost: 0,
        remarks: '',
        siloNumber: [],
        farm: '',
        recorded: '',
        userId: account.user.id,
        userName: account.user.firstname
    });
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [selectedFeedType, setSelectedFeedType] = useState(null);
    const [feedOptions, setFeedOptions] = useState([]);
    const [selectedSilo, setSelectedSilo] = useState([]);
    const [filteredFeedOptions, setFilteredFeedOptions] = useState([]);
    const [cost, setCost] = useState(0);
    const [cost1, setCost1] = useState(0);
    const [cost2, setCost2] = useState(0);
    const [feed, setFeed] = useState({});
    const [feeds, setFeeds] = useState({});
    const [feeds1, setFeeds1] = useState({});
    const [feeds2, setFeeds2] = useState({});
    const [totalCost, setTotalCost] = useState(0);
    const [totalQ, setTotalsQ] = useState(0);
    const [quantityError, setQuantityError] = useState(null);
    const [quantityError1, setQuantityError1] = useState(null);
    const [quantityError2, setQuantityError2] = useState(null);
    const [isFeedNameSelected, setIsFeedNameSelected] = useState(false);
    const formRef = useRef(null);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [farmOptions, setFarmOptions] = useState([]);
    const [farm, setSelectedFarm] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const { createPermission, deletePermission, editPermission } = usePermissions();

    const memoizedApiUrl1 = useMemo(() => {
        return `${apiUrl}/farm`;
    }, [apiUrl]);

    const memoizedApiUrl2 = useMemo(() => {
        return `${apiUrl}/feeds`;
    }, [apiUrl]);

    useEffect(() => {
        const fetchFeeds = async () => {
            try {
                const response = await axios.get(memoizedApiUrl2);
                const data = response.data;
                // const filteredData = data.filter((tag) => tag.farm === farm);
                const options = data.map((feed) => ({
                    value: feed.feed_name,
                    label: feed.feed_name,
                    feed_type: feed.feed_type,
                    costper: feed.costper,
                    totalCost: feed.totalCost,
                    quantity: feed.quantity
                }));

                setFeedOptions(options);
            } catch (error) {
                console.error('Error fetching feeds:', error);
            }
        };

        fetchFeeds();
    }, [memoizedApiUrl2]);

    useEffect(() => {
        const fetchFarms = async () => {
            try {
                const response = await axios.get(memoizedApiUrl1);
                const farmData = response.data;
                const options = farmData.map((farm) => ({
                    value: farm.name,
                    label: farm.name
                }));

                setFarmOptions(options);
            } catch (error) {
                console.error('Error fetching Farms:', error);
            }
        };

        fetchFarms();
    }, [memoizedApiUrl1]);

    useEffect(() => {
        const filteredOptions = feedOptions.filter((feed) => {
            return feed.feed_type === selectedFeedType;
        });

        setFilteredFeedOptions(filteredOptions);
    }, [feedOptions, selectedFeedType]);

    useEffect(() => {
        const consumedValue = parseInt(data.consumed, 10) || 0;
        const consumed1Value = parseInt(data.consumed1, 10) || 0;
        const consumed2Value = parseInt(data.consumed2, 10) || 0;

        const tQuantity = consumedValue + consumed1Value + consumed2Value;

        setTotalsQ(tQuantity);
        setData((prevData) => ({
            ...prevData,
            totalQuantity: tQuantity
        }));
    }, [
        data.consumed,
        data.consumed1,
        data.consumed2,
        selectedFeedType,
        data.feed_name,
        data.feed_name1,
        data.feed_name2,
        data.feede,
        data.feed1,
        data.feed2
    ]);

    useEffect(() => {
        const consumedValue = parseInt(cost, 10) || 0;
        const consumed1Value = parseInt(cost1, 10) || 0;
        const consumed2Value = parseInt(cost2, 10) || 0;

        const tCost = consumedValue + consumed1Value + consumed2Value;
        setTotalCost(tCost);
        setData((prevData) => ({
            ...prevData,
            totalCost: tCost
        }));
    }, [
        data.consumed,
        data.consumed1,
        data.consumed2,
        selectedFeedType,
        data.feed_name,
        data.feed_name1,
        data.feed_name2,
        data.feede,
        data.feed1,
        data.feed2,
        cost,
        cost1,
        cost2
    ]);

    useEffect(() => {
        const f = data.feede;
        const f1 = data.feed1;
        const f2 = data.feed2;
        setFeeds(f);
        setFeeds1(f1);
        setFeeds2(f2);
        console.log(f1);
        console.log(f);
        console.log(f2);
    }, [
        data.consumed,
        data.consumed1,
        data.consumed2,
        selectedFeedType,
        data.feed_name,
        data.feed_name1,
        data.feed_name2,
        data.feede,
        data.feed1,
        data.feed2
    ]);

    useEffect(() => {
        const c1 = parseInt(feeds1 && feeds1.costper);
        const c = parseInt(feeds && feeds.costper);
        const c2 = parseInt(feeds2 && feeds2.costper);

        const ct = c * parseInt(data.consumed);
        const ct1 = c1 * parseInt(data.consumed1);
        const ct2 = c2 * parseInt(data.consumed2);

        setCost(ct);
        setCost1(ct1);
        setCost2(ct2);
        console.log(c1);
        console.log(c);
        console.log(c2);

        setData((prevData) => ({
            ...prevData,
            cost: cost,
            cost1: cost1,
            cost2: cost2
        }));
    }, [
        data.consumed,
        data.consumed1,
        data.consumed2,
        selectedFeedType,
        data.feed_name,
        data.feed_name1,
        data.feed_name2,
        data.feede,
        data.feed1,
        data.feed2,
        feeds,
        feeds1,
        feeds2,
        cost,
        cost1,
        cost2
    ]);

    useEffect(() => {
        if (selectedFeedType === 'Ready Feeds' || 'Raw Feeds') {
            setData({
                feed_type: '',
                feed_name2: '',
                consumed2: '',
                cost2: '',
                feed_name1: '',
                consumed1: '',
                cost1: '',
                feed_name: '',
                consumed: '',
                cost: '',
                totalQuantity: 0,
                totalCost: 0,
                remarks: '',
                siloNumber: [],
                farm: ''
            });
            formRef.current.reset();
            setFeeds('');
            setFeeds1('');
            setFeeds2('');
            setCost('');
            setCost1('');
            setCost2('');
            setQuantityError(null);
            setQuantityError1(null);
            setQuantityError2(null);
            // setSelectedSilo({});
        } else if (selectedFeedType === 'Ready Feeds') {
            setSelectedFeedType(null);
        }
    }, [selectedFeedType]);

    const handleInput = (fieldName, value) => {
        setData((prevData) => ({ ...prevData, [fieldName]: value }));
        if (fieldName === 'feed_type') {
            setSelectedFeedType(value);
            // setFeed({});
            setCost(0);
        } else if (fieldName.startsWith('feed_name')) {
            const index = fieldName.slice(-1);

            if ([1, 2, 3].some((i) => data[`feed_name${i}`] === value)) {
                setError('The same feed name cannot be selected more than once.');
                return;
            }

            const selectedFeed = feedOptions.find((feed) => feed.value === value);
            const consumedAmount = parseFloat(data[`consumed${index}` || 0]);
            const cost = consumedAmount * selectedFeed.costper;

            const feedKey = `feed${index}`;
            const consumedKey = `consumed${index}`;
            const costKey = `cost${index}`;

            // setCost(cost);
            setFeed((prevFeed) => ({
                ...prevFeed,
                [feedKey]: selectedFeed
            }));

            setData((prevData) => ({
                ...prevData,
                // [costKey]: cost,
                [feedKey]: selectedFeed,
                feed_type: selectedFeedType,
                userId: account.user.id,
                userName: account.user.firstname
            }));
        }
        setError(null);
    };

    console.log(data);

    const handleInpute = (fieldName, value) => {
        if (fieldName === 'farm') {
            setSelectedFarm(value);
            setData((prevData) => ({ ...prevData, [fieldName]: value }));
        } else {
            setData((prevData) => ({ ...prevData, [fieldName]: value }));
        }
        console.log(data);
    };

    const handleInputs = (fieldName, value) => {
        if (fieldName === 'siloNumber') {
            const siloNumber = value ? value.map((option) => option.value) : [];
            setData((prevData) => ({ ...prevData, [fieldName]: value, siloNumber }));
        } else {
            setData((prevData) => ({ ...prevData, [fieldName]: value }));
        }
    };

    const handleQuantityBlur = (fieldName, value) => {
        const selectedFeed = feedOptions.find((feed) => feed.value === data[`feed_name${fieldName.charAt(fieldName.length - 1)}`]);
        const consumedQuantity = parseInt(value);

        if (selectedFeed && consumedQuantity > selectedFeed.quantity) {
            if (fieldName === 'consumed') {
                setQuantityError('Consumed Qty exceeds available quantity.');
            } else if (fieldName === 'consumed1') {
                setQuantityError1('Consumed Qty exceeds available quantity.');
            } else if (fieldName === 'consumed2') {
                setQuantityError2('Consumed Qty exceeds available quantity.');
            }
        } else {
            if (fieldName === 'consumed') {
                setQuantityError(null);
            } else if (fieldName === 'consumed1') {
                setQuantityError1(null);
            } else if (fieldName === 'consumed2') {
                setQuantityError2(null);
            }
        }
    };

    const handleQuantityBlurs = (fieldName, value) => {
        const selectedFeed = feedOptions.find((feed) => feed.value === data.feed_name);
        const consumedQuantity = parseInt(value);

        if (selectedFeed && consumedQuantity > selectedFeed.quantity) {
            setQuantityError('Consumed Qty exceeds available quantity.');
        } else {
            setQuantityError(null);
        }
    };

    const handleEggs = async (e) => {
        e.preventDefault();
        setShowConfirmationModal(true);
    };

    const handleConfirmation = async (e) => {
        if (quantityError || quantityError1 || quantityError2) {
            setError('The quantity consumed cannot exceed the available quantity.');
            return;
        } else if (!createPermission) {
            return;
        }

        axios
            .post(`${apiUrl}/pigs/feed-consumption`, data)
            .then((response) => {
                if (response.data) {
                    setSuccess(`${data.feed_name}, ${data.feed_name1}, ${data.feed_name2} consumed successfully`);
                    setTimeout(() => {
                        setSuccess(null);
                    }, 10000);
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                    toast.success(`${data.feed_name}, ${data.feed_name1}, ${data.feed_name2} consumed successfully`);
                    formRef.current.reset();
                    setSelectedFeedType(null);
                    setShowConfirmationModal(false);
                    onCancel();
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    setError(`${error.response.data.message}`);
                    setShowConfirmationModal(false);
                    onCancel();
                } else {
                    toast.error(`Error consuming ${data.feed_name}, ${data.feed_name1}, ${data.feed_name2}`);
                    setError(`Error consuming ${data.feed_name}, ${data.feed_name1}, ${data.feed_name2}`);
                    setShowConfirmationModal(false);
                    onCancel();
                }
                console.log(error);
                setShowConfirmationModal(false);
                onCancel();
            });
    };

    useEffect(() => {
        const fetchFeeds = async () => {
            try {
                const response = await axios.get(`${apiUrl}/pigs`);
                const data = response.data;
                const filteredData = data.filter((tag) => tag.farm === farm);
                const options = filteredData.map((feed) => ({
                    value: feed.siloNumber,
                    label: feed.siloNumber
                }));
                setSelectedSilo(options);
            } catch (error) {
                console.error('Error fetching feeds:', error);
            }
        };

        fetchFeeds();
    }, [farm, apiUrl]);

    const handleCancelConfirmation = () => {
        // Handle cancel action if needed
        setShowConfirmationModal(false);
    };

    return (
        <React.Fragment>
            <Modal show={true} onHide={onCancel} size="xl" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Feed Pigs</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={12}>
                            {success && (
                                <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                                    {success}
                                </Alert>
                            )}
                            {error && (
                                <Alert variant="danger" onClose={() => setError(null)} dismissible>
                                    {error}
                                </Alert>
                            )}
                            <ToastContainer />
                        </Col>
                    </Row>
                    <Form onSubmit={handleEggs} ref={formRef}>
                        <Row>
                            <Col>
                                <Row>
                                    <Form.Group as={Col} md={4} sm={6} lg={4} controlId="formBasic">
                                        <Form.Label>Feed Type</Form.Label>
                                        <Select
                                            onChange={(selectedOption) => handleInput('feed_type', selectedOption.value)}
                                            options={[
                                                { value: 'Ready Feeds', label: 'Ready Feeds' },
                                                { value: 'Raw Feeds', label: 'Raw Feeds' }
                                            ]}
                                            isSearchable
                                            name="feed_type"
                                            placeholder="--Select Feed Type--"
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={4} sm={4} lg={4}>
                                        <Form.Label>Farm</Form.Label>
                                        <Select
                                            options={farmOptions}
                                            onChange={(selectedOption) => handleInpute('farm', selectedOption.value)}
                                            isSearchable
                                            name="farm"
                                        />
                                    </Form.Group>
                                </Row>

                                <Row>
                                    <Form.Group as={Col} md={4} sm={6} lg={4} controlId="feed_name">
                                        <Form.Label>Feed</Form.Label>
                                        <Select
                                            onChange={(selectedOption) => handleInput('feed_name', selectedOption.value)}
                                            options={filteredFeedOptions}
                                            isSearchable
                                            name="feed_name"
                                            placeholder="--Select Feed --"
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md={4} sm={6} lg={4} controlId="consumed">
                                        <Form.Label>Quantity</Form.Label>
                                        <Form.Control
                                            required
                                            onBlur={(e) => handleQuantityBlurs('consumed', e.target.value)}
                                            onChange={(e) => handleInput('consumed', e.target.value)}
                                            type="number"
                                            name="consumed"
                                            disabled={!data.feed_name}
                                        />
                                        {feeds && <code>Available Quantity: {feeds.quantity}</code>}
                                        {quantityError && <code>{quantityError}</code>}
                                    </Form.Group>

                                    <Form.Group as={Col} md={4} sm={6} lg={4} controlId="cost">
                                        <Form.Label>Cost</Form.Label>
                                        <Form.Control readOnly value={cost} type="number" name="cost" />
                                    </Form.Group>
                                </Row>

                                {selectedFeedType && (
                                    // {selectedFeedType === 'Raw Feeds' && (
                                    <React.Fragment>
                                        <Row>
                                            <Form.Group as={Col} md={4} sm={6} lg={4} controlId="feed_name1">
                                                <Form.Label>Feed (1)</Form.Label>
                                                <Select
                                                    onChange={(selectedOption) => handleInput(`feed_name1`, selectedOption.value)}
                                                    options={filteredFeedOptions.filter(
                                                        (feed) => ![data.feed_name, data.feed_name2].includes(feed.value)
                                                    )}
                                                    isSearchable
                                                    name="feed_name1"
                                                    placeholder={`--Select Feed--`}
                                                />
                                            </Form.Group>

                                            <Form.Group as={Col} md={4} sm={6} lg={4} controlId={`consumed1`}>
                                                <Form.Label>Quantity (1)</Form.Label>
                                                <Form.Control
                                                    required
                                                    onBlur={(e) => handleQuantityBlur('consumed1', e.target.value)}
                                                    onChange={(e) => handleInput(`consumed1`, e.target.value)}
                                                    type="number"
                                                    name={`consumed1`}
                                                    disabled={!data.feed_name1}
                                                />
                                                {feeds1 && <code>Available Quantity: {feeds1.quantity}</code>}
                                                {quantityError1 && <code>{quantityError1}</code>}
                                            </Form.Group>

                                            <Form.Group as={Col} md={4} sm={6} lg={4} controlId={`cost1`}>
                                                <Form.Label>Cost (1)</Form.Label>
                                                <Form.Control readOnly value={cost1} type="number" name="cost1" />
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group as={Col} md={4} sm={6} lg={4} controlId="feed_name1">
                                                <Form.Label>Feed (2)</Form.Label>
                                                <Select
                                                    onChange={(selectedOption) => handleInput(`feed_name2`, selectedOption.value)}
                                                    options={filteredFeedOptions.filter(
                                                        (feed) => ![data.feed_name1, data.feed_name].includes(feed.value)
                                                    )}
                                                    isSearchable
                                                    name="feed_name2"
                                                    placeholder={`--Select Feed--`}
                                                />
                                            </Form.Group>

                                            <Form.Group as={Col} md={4} sm={6} lg={4} controlId={`consumed2`}>
                                                <Form.Label>Quantity (2)</Form.Label>
                                                <Form.Control
                                                    required
                                                    onBlur={(e) => handleQuantityBlur('consumed2', e.target.value)}
                                                    onChange={(e) => handleInput(`consumed2`, e.target.value)}
                                                    type="number"
                                                    name={`consumed2`}
                                                    disabled={!data.feed_name2}
                                                />
                                                {feeds2 && <code>Available Quantity: {feeds2.quantity}</code>}
                                                {quantityError2 && <code>{quantityError2}</code>}
                                            </Form.Group>

                                            <Form.Group as={Col} md={4} sm={6} lg={4} controlId={`cost2`}>
                                                <Form.Label>Cost (2)</Form.Label>
                                                <Form.Control readOnly value={cost2} type="number" name="cost2" />
                                            </Form.Group>
                                        </Row>
                                    </React.Fragment>
                                )}

                                <Row>
                                    <Form.Group as={Col} md={4} sm={4} lg={4} controlId="formBasic">
                                        <Form.Label>Pig Silo No.</Form.Label>
                                        <Select
                                            onChange={(selectedOption) => handleInputs('siloNumber', selectedOption)}
                                            options={selectedSilo}
                                            isSearchable
                                            isMulti
                                            type="text"
                                            name="siloNumber"
                                            placeholder="-- Select Silo No. --"
                                            required
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md={4} sm={4} lg={4} controlId="formBasic">
                                        <Form.Label>Total Quantity </Form.Label>
                                        <Form.Control size="sm" required value={totalQ} type="number" disabled />
                                    </Form.Group>

                                    <Form.Group as={Col} md={4} sm={4} lg={4} controlId="formBasic">
                                        <Form.Label>Total Cost</Form.Label>
                                        <Form.Control size="sm" required value={totalCost} type="number" name="totalCost" disabled />
                                    </Form.Group>
                                    <Form.Group as={Col} md={4} sm={12} lg={4} controlId="formBasic">
                                        <Form.Label>Records Date</Form.Label>
                                        <Form.Control
                                            size="sm"
                                            required
                                            onChange={(e) => handleInput('recorded', e.target.value)}
                                            type="date"
                                            name="recorded"
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md={12} sm={12} lg={12} controlId="formBasic">
                                        <Form.Label>Remarks/Notes</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            required
                                            onChange={(e) => handleInputs('remarks', e.target.value)}
                                            type="text"
                                            name="remarks"
                                            value={data.remarks || ''}
                                        />
                                    </Form.Group>
                                </Row>
                                <Modal.Footer>
                                    {createPermission && <Button type="submit">Submit</Button>}
                                    <Button variant="secondary" onClick={onCancel}>
                                        Cancel
                                    </Button>
                                </Modal.Footer>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>

            <ConfirmationModal show={showConfirmationModal} onHide={handleCancelConfirmation} onConfirm={handleConfirmation} />
        </React.Fragment>
    );
};

export default SamplePage;
