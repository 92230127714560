import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Table, Button, Pagination, Form } from 'react-bootstrap';
import Select from 'react-select';
import { CSVLink } from 'react-csv';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import axios from 'axios';
import config from './../../../config';
import { useParams, useHistory, Link } from 'react-router-dom';
import Card from '../../../components/Card/MainCard';
import { usePermissions } from '../../../permissions';
import DataTable from 'react-data-table-component';

const UserList = () => {
    const [users, setUsers] = useState([]);
    const { id } = useParams();
    const history = useHistory();
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage, setUsersPerPage] = useState(10);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const { createPermission, deletePermission, editPermission, viewPermission } = usePermissions();
    const [searchText, setSearchText] = useState('');
    const [isPopupVisible, setPopupVisible] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/users`);
                setUsers(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [users, apiUrl]);

    const handleDelete = async (userId, firstname, lastname) => {
        try {
            const confirmed = window.confirm(`Are you sure you want to archive this employee ${firstname} ${lastname}?`);
            if (confirmed) {
                await axios.put(`${apiUrl}/user/${userId}/archive`);
                alert('User archived successfully!');
                window.location.reload();
            }
        } catch (error) {
            alert(error.message);
        }
    };

    const handleUpdate = (userId) => {
        const userToUpdate = users.find((user) => user.id === userId);
        history.push(`update-user/${userId}`, { user: userToUpdate });
    };

    const handleUpdatePass = (userId) => {
        const userToUpdate = users.find((user) => user.id === userId);
        history.push(`update-password/${userId}`, { user: userToUpdate });
    };

    const calculateAge = (birthDate) => {
        const today = new Date();
        const birth = new Date(birthDate);

        // Get the UTC values for year, month, and day
        const todayUTC = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
        const birthUTC = Date.UTC(birth.getFullYear(), birth.getMonth(), birth.getDate());

        // Calculate the age difference in milliseconds
        const ageDiffMillis = todayUTC - birthUTC;

        // Convert milliseconds to years, months, and days
        const yearDiff = Math.floor(ageDiffMillis / (1000 * 60 * 60 * 24 * 365));
        const monthDiff = Math.floor((ageDiffMillis % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30));
        const dayDiff = Math.floor((ageDiffMillis % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24));

        if (yearDiff > 0) {
            return yearDiff === 1 ? `${yearDiff} year ago` : `${yearDiff} years ago`;
        } else if (monthDiff > 0) {
            return monthDiff === 1 ? `${monthDiff} month ago` : `${monthDiff} months ago`;
        } else if (dayDiff > 0) {
            return dayDiff === 1 ? `${dayDiff} day ago` : `${dayDiff} days ago`;
        } else {
            return dayDiff === 0 ? `Today` : `${dayDiff} days ago`;
        }
    };

    const csvLinkRef = useRef();

    const exportToCSV = () => {
        const csvData = [
            ['UserId', 'First Name', 'Last Name', 'Email', 'Phone No.', 'Date', 'Farm'],
            ...filteredData.map((item) => [
                item.id,
                item.firstname,
                item.lastname,
                item.email,
                item.phone_number,
                item.createdAt,
                item.farm
            ])
        ];

        const csvContent = csvData.map((row) => row.join(',')).join('\n');
        const blob = new Blob(['\ufeff', csvContent], { type: 'text/csv;charset=utf-8;' });

        const csvUrl = URL.createObjectURL(blob);
        csvLinkRef.current.href = csvUrl;
        csvLinkRef.current.download = 'sfarms.csv';
        csvLinkRef.current.click();
    };

    const exportToPDF = () => {
        const doc = new jsPDF();
        doc.autoTable({
            head: [['UserId', 'F.Name', 'L.Name', 'Email', 'Phone No.', 'Date', 'Farm']],
            body: filteredData.map((user) => [
                user.id,
                user.firstname,
                user.lastname,
                user.email,
                user.phone_number,
                new Intl.DateTimeFormat('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                }).format(new Date(user.createdAt)),
                user.farm
            ])
        });
        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl);
    };

    const columns = [
        {
            name: 'User ID',
            selector: (row) => row.id,
            cell: (row) => <Link to={`details/${row.id}`}>#{row.id}</Link>,
            sortable: true,
            wrap: true,
            minWidth: `80px`
        },
        {
            name: 'Full Name',
            selector: (row) => `${row.firstname} ${row.lastname}`,
            cell: (row) => <Link to={`details/${row.id}`}>{`${row.firstname} ${row.lastname}`}</Link>,
            sortable: true,
            wrap: true,
            minWidth: `150px`
        },
        {
            name: 'Email',
            selector: (row) => row.email,
            cell: (row) => <Link to={`details/${row.id}`}>{row.email}</Link>,
            sortable: true,
            wrap: true,
            minWidth: `200px`
        },

        {
            name: 'Phone No',
            selector: (row) => row.phone_number,
            cell: (row) => <Link to={`details/${row.id}`}>{row.phone_number}</Link>,
            sortable: true,
            wrap: true,
            minWidth: `100px`
        },
        {
            name: 'Status',
            selector: (row) => row.is_active,
            cell: (row) => <Link to={`details/${row.id}`}>{row.is_active}</Link>,
            sortable: true,
            wrap: true,
            minWidth: `90px`
        },

        {
            name: 'Ceated',
            selector: (row) => calculateAge(row.createdAt),
            cell: (row) => <Link to={`details/${row.id}`}>{calculateAge(row.createdAt)}</Link>,
            sortable: true,
            wrap: true,
            minWidth: `110px`
        },
        {
            name: 'Action',
            cell: (row) => (
                <div>
                    {editPermission ? (
                        <Button variant="info" size="sm" onClick={() => handleUpdate(row.id)}>
                            <span className="feather icon-edit"></span> Edit
                        </Button>
                    ) : (
                        '-'
                    )}
                    {editPermission ? (
                        <Button size="sm" onClick={() => handleUpdatePass(row.id)}>
                            Reset
                        </Button>
                    ) : (
                        '-'
                    )}

                    {deletePermission ? (
                        <Button variant="danger" size="sm" onClick={() => handleDelete(row.id, row.firstname, row.lastname)}>
                            <span className="feather icon-trash-2"></span>
                        </Button>
                    ) : (
                        '-'
                    )}
                </div>
            ),
            minWidth: `180px`
        }
    ];

    const filteredData = searchText
        ? users.filter((item) =>
              Object.values(item).some((value) => typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase()))
          )
        : users;

    return (
        <React.Fragment>
            <Row className="btn-page">
                <Col>
                    <div className="d-flex justify-content-between align-center m-b-10">
                        {createPermission && (
                            <Link to="/users/new-user">
                                <Button variant="primary" className="" disabled={!createPermission}>
                                    <span className="feather icon-user-plus"></span> Add Employee
                                </Button>
                            </Link>
                        )}
                    </div>
                    <Row className="btn-page">
                        <Col>
                            {filteredData && filteredData.length > 0 && (
                                <Row>
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasicName">
                                        <Form.Control
                                            type="text"
                                            placeholder="Search..."
                                            value={searchText}
                                            onChange={(e) => setSearchText(e.target.value)}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasicName">
                                        <div className="d-flex">
                                            <Button onClick={exportToCSV}>
                                                <span className="feather icon-file"></span> CSV
                                            </Button>
                                            <a ref={csvLinkRef} style={{ display: 'none' }} />
                                            <a href="#" className="btn btn-primary" onClick={exportToPDF}>
                                                <span className="feather icon-file-text"></span> PDF
                                            </a>
                                        </div>
                                    </Form.Group>
                                </Row>
                            )}
                            <Row>
                                <Col>
                                    <DataTable
                                        columns={columns}
                                        data={filteredData}
                                        pagination
                                        highlightOnHover
                                        striped
                                        // noHeader
                                        responsive
                                        dense
                                        bordered
                                        paginationPerPage={10}
                                        paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                                        paginationComponentOptions={{
                                            rowsPerPageText: 'Rows per page:'
                                        }}
                                        paginationResetDefaultPage={true}
                                        paginationTotalRows={users.length}
                                        // expandableRows
                                        selectableRows
                                        onSelectedRowsChange={(selectedRows) => console.log(selectedRows)}
                                        customStyles={{
                                            headRow: {
                                                style: {
                                                    backgroundColor: '#279e6c', // Green background
                                                    color: '#fff', // White text
                                                    textTransform: 'uppercase', // Uppercase text
                                                    // fontSize: '10px', // Adjust font size for header row
                                                    minHeight: '1.5rem',
                                                    paddingTop: '8px',
                                                    paddingBottom: '8px'
                                                }
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default UserList;
