import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Row, Col, Form, Table, Button, Pagination, Alert } from 'react-bootstrap';
import config from '../../config';
import axios from 'axios';
import Select from 'react-select';
import Card from '../../components/Card/MainCard';
import EggsTable from './EggsTable';
import UpdateForm from './../../views/extra/SamplePage9';
import { usePermissions } from '../../permissions';

const SamplePage = () => {
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [batchOptions, setBatchOptions] = useState([]);
    const [selectedBatch, setSelectedBatch] = useState(null);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [eggsData, setEggsData] = useState([]);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [open, setOpen] = useState(false);
    const [dailyEarnings, setDailyEarnings] = useState({});
    const [weeklyEarnings, setWeeklyEarnings] = useState({});
    const [monthlyEarnings, setMonthlyEarnings] = useState({});
    const [totalCollected, setTotalCollected] = useState(0);
    const [totalBroken, setTotalBroken] = useState(0);
    const { createPermission, deletePermission, editPermission } = usePermissions();

    useEffect(() => {
        // Fetch daily earnings
        axios
            .get(`${apiUrl}/eggs/daily`)
            .then((response) => {
                setDailyEarnings(response.data);
            })
            .catch((error) => {
                console.error('Error fetching daily earnings:', error);
            });

        // Fetch weekly earnings
        axios
            .get(`${apiUrl}/eggs/weekly`)
            .then((response) => {
                setWeeklyEarnings(response.data);
            })
            .catch((error) => {
                console.error('Error fetching weekly earnings:', error);
            });

        // Fetch monthly earnings
        axios
            .get(`${apiUrl}/eggs/monthly`)
            .then((response) => {
                setMonthlyEarnings(response.data);
            })
            .catch((error) => {
                console.error('Error fetching monthly earnings:', error);
            });

        // Fetch total earnings
        axios
            .get(`${apiUrl}/eggs/total`)
            .then((response) => {
                setTotalCollected(response.data.collected);
                setTotalBroken(response.data.broken);
            })
            .catch((error) => {
                console.error('Error fetching total earnings:', error);
            });
    }, []);

    const memoizedApiUrl = useMemo(() => {
        return `${apiUrl}/poultry`;
    }, [apiUrl]);

    const memoizedApiUrl1 = useMemo(() => {
        return `${apiUrl}/eggs`;
    }, [apiUrl]);

    useEffect(() => {
        const fetchTags = async () => {
            try {
                const response = await axios.get(memoizedApiUrl);
                const data = response.data;

                const layerBatches = data.filter((tag) => tag.type === 'Layers');

                const options = layerBatches.map((tag) => ({
                    value: tag.batch,
                    label: `${tag.batch} - ${tag.farm}`,
                    farm: tag.farm
                }));
                setBatchOptions(options);
            } catch (error) {
                console.error('Error fetching Tags:', error);
            }
        };

        fetchTags();
    }, [memoizedApiUrl]);

    const OpenForm = () => {
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    const handleInput = (e) => {
        const { name, value } = e.target;
        if (name === 'fromDate') {
            setFromDate(value);
        } else if (name === 'toDate') {
            setToDate(value);
        }
    };

    const handleInputs = (selectedOption) => {
        const selectedBatch = selectedOption.value;

        setSelectedBatch(selectedBatch);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                let params = {};
                if (selectedBatch) params.batch = selectedBatch;
                if (fromDate) params.fromDate = fromDate;
                if (toDate) params.toDate = toDate;

                const response = await axios.get(memoizedApiUrl1, { params });
                setEggsData(response.data);
            } catch (error) {
                console.error('Error fetching eggs data:', error);
                setError('Error fetching eggs data');
            }
        };

        fetchData();
    }, [selectedBatch, fromDate, toDate, apiUrl, memoizedApiUrl1]);

    const resetFilter = () => {
        setSelectedBatch('');
        setFromDate('');
        setToDate('');
    };

    return (
        <React.Fragment>
            <Row>
                <Col sm={12}>
                    {success && (
                        <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                            {success}
                        </Alert>
                    )}
                    {error && (
                        <Alert variant="danger" onClose={() => setError(null)} dismissible>
                            {error}
                        </Alert>
                    )}
                </Col>
            </Row>
            <Row>
                <Col>
                    <Row>
                        <Col sm={12} md={6} lg={3}>
                            <Card title="Today">
                                <div className="row d-flex align-items-center">
                                    <div className="col-6">
                                        <h6>Collected</h6>
                                    </div>
                                    <div className="col-6">
                                        <h6>{dailyEarnings && dailyEarnings.collected ? dailyEarnings.collected.toLocaleString() : '-'}</h6>
                                    </div>
                                </div>
                                <div className="row d-flex align-items-center">
                                    <div className="col-6">
                                        <h6>Broken</h6>
                                    </div>
                                    <div className="col-6">
                                        <h6>{dailyEarnings && dailyEarnings.broken ? dailyEarnings.broken.toLocaleString() : '-'}</h6>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col sm={12} md={6} lg={3}>
                            <Card title="Weekly">
                                <div className="row d-flex align-items-center">
                                    <div className="col-6">
                                        <h6>Collected</h6>
                                    </div>
                                    <div className="col-6">
                                        <h6>
                                            {weeklyEarnings && weeklyEarnings.collected ? weeklyEarnings.collected.toLocaleString() : '-'}
                                        </h6>
                                    </div>
                                </div>
                                <div className="row d-flex align-items-center">
                                    <div className="col-6">
                                        <h6>Broken</h6>
                                    </div>
                                    <div className="col-6">
                                        <h6>{weeklyEarnings && weeklyEarnings.broken ? weeklyEarnings.broken.toLocaleString() : '-'}</h6>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col sm={12} md={6} lg={3}>
                            <Card title="Monthly">
                                <div className="row d-flex align-items-center">
                                    <div className="col-6">
                                        <h6>Collected</h6>
                                    </div>
                                    <div className="col-6">
                                        <h6>
                                            {monthlyEarnings && monthlyEarnings.collected
                                                ? monthlyEarnings.collected.toLocaleString()
                                                : '-'}
                                        </h6>
                                    </div>
                                </div>
                                <div className="row d-flex align-items-center">
                                    <div className="col-6">
                                        <h6>Broken</h6>
                                    </div>
                                    <div className="col-6">
                                        <h6>{monthlyEarnings && monthlyEarnings.broken ? monthlyEarnings.broken.toLocaleString() : '-'}</h6>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col sm={12} md={6} lg={3}>
                            <Card title="Totals">
                                <div className="row d-flex align-items-center">
                                    <div className="col-6">
                                        <h6>Collected</h6>
                                    </div>
                                    <div className="col-6">
                                        <h6>{totalCollected ? totalCollected.toLocaleString() : '-'}</h6>
                                    </div>
                                </div>
                                <div className="row d-flex align-items-center">
                                    <div className="col-6">
                                        <h6>Broken</h6>
                                    </div>
                                    <div className="col-6">
                                        <h6>{totalBroken ? totalBroken.toLocaleString() : '-'}</h6>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>

                    <div className="d-flex justify-content-between align-items-center m-b-10">
                        <div>
                            <span> </span>
                        </div>
                        {createPermission && (
                            <Button variant="primary" className="" onClick={OpenForm}>
                                <span className="feather icon-plus-circle"></span> Update Eggs
                            </Button>
                        )}
                    </div>
                    <Form>
                        <Row>
                            <Form.Group as={Col} md={3} sm={12} lg={3} controlId="formBasic">
                                <Form.Label>Batch</Form.Label>
                                <Select type="text" onChange={handleInputs} name="batch" options={batchOptions} isSearchable />
                            </Form.Group>
                            <Form.Group as={Col} md={3} sm={12} lg={3}>
                                <Form.Label>From Date</Form.Label>
                                <Form.Control onChange={handleInput} type="date" name="fromDate" />
                            </Form.Group>
                            <Form.Group as={Col} md={3} sm={12} lg={3} controlId="formBasic">
                                <Form.Label>To Date</Form.Label>
                                <Form.Control onChange={handleInput} type="date" name="toDate" />
                            </Form.Group>
                        </Row>
                        <button onClick={resetFilter}>Reset Filter</button>
                    </Form>
                    <EggsTable eggsData={eggsData} />
                    {open && <UpdateForm onHide={handleCloseModal} />}
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default SamplePage;
